import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Routes
import routes from '../../config/routesConfig';

//
const withAuthenticate = ComposedComponent => {
	const Authenticate = props => {
		const { userIsLoggedIn } = props;

		// Redirect the user if they are not logged in.
		if (!userIsLoggedIn) {
			return <Redirect to={routes.LOGIN} />;
		}

		//
		return <ComposedComponent {...props} />;
	};

	//
	const mapStateToProps = state => {
		const { userIsLoggedIn } = state.login;

		//
		return {
			userIsLoggedIn,
		};
	};

	return connect(mapStateToProps)(Authenticate);
};

//
export default withAuthenticate;
