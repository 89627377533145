import React from 'react';
import PropTypes from 'prop-types';

// Style.
import './SubHeadingSection.scss';

/**
 * This component is used to render the heading for each view and the
 * cart or action button on the right-hand side.
 */
const SubHeadingSection = props => {
	// console.log(props);

	const { heading, children } = props;

	return (
		<header className="sub-heading-section container-fluid">
			<div className="row">
				<div className="col-md-6">
					<h1 className="sub-heading-section__heading">{heading}</h1>
				</div>
				{/* Output children, if we have any */}
				{children && (
					<div className="col-md-6 sub-heading-section__right">{children}</div>
				)}
			</div>
		</header>
	);
};

SubHeadingSection.propTypes = {
	heading: PropTypes.string.isRequired,
};

export default SubHeadingSection;
