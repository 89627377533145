import { getApi } from '../../services/api';
import pretendRestApi from '../../utils/pretendRestApi';
import getRandomItem from '../../utils/getRandomItem';

//
const fixtureUpdateProfile = () => {
	const files = ['updateProfileSuccess', 'updateProfileFailure'];
	// const files = ['cancelContractFailure'];
	const responseFile = getRandomItem(files);
	let responseData = require(`../../fixtures/user/${responseFile}.json`);

	//
	return pretendRestApi(() => responseData);
};

//
const apiUpdateProfile = async (api, payload) => {
	try {
		const { customerId, firstName, lastName, phone, role, email } = payload;
		const endpoint = customerId ? `/wp/v2/users/${customerId}` : '/wp/v2/users/me';
		const response = await api.post(endpoint, {
			first_name: firstName,
			last_name: lastName,
			email: email,
			// Custom meta data including user's phone number and role/job position.
			meta: {
				phone: phone,
				role: role,
			},
		});

		// success
		return {
			success: true,
			message: 'Your profile has been updated',
			payload: response.data,
		};

		// success
		// return require('../../fixtures/user/updateProfileSuccess.json');
	} catch (error) {
		return require('../../fixtures/user/updateProfileFailure.json');
	}
};

//
const updateProfile = payload => {
	const api = getApi();
	// api is null - use fixture
	if (!api) {
		return fixtureUpdateProfile();
	}

	// restApi update profile
	return apiUpdateProfile(api, payload);
};

//
export default updateProfile;
