import { fork, all, cancel, take, put } from 'redux-saga/effects';

//
import { loginActionTypes } from '../actions/loginActions';
import { customerActionTypes } from '../actions/customerActions';
import { commentModalActionCreators } from '../actions/commentModalActions';
import { cartActionsCreator } from '../actions/cartActions';

// sagas
import loginFlow from './loginSaga';
import { watchPersist } from './persistSaga';
import watchCustomer from './customerSaga';
import watchCommentModal from './commentModalSaga';
import registerFlow from './registerSaga';
import passwordReset from './passwordResetSaga';
import watchAdminActions from './adminSaga';
import watchCartActions from './cartSaga';

// customer actions
function* watchCustomerActions() {
  try {
    yield all([fork(watchCommentModal), fork(watchCartActions)]);
  } catch (error) {}
}

// reset data before user logout
function* onUserLogout() {
  yield put(commentModalActionCreators.closeCommentModal());
  yield put(cartActionsCreator.clearCart(true));
}

// customer actions flow
function* customerActionsFlow() {
  while (true) {
    // wait for customer loaded
    yield take([
      customerActionTypes.CUSTOMER_DATA_LOADED,
      customerActionTypes.PERSIST_CUSTOMER_DATA_LOADED,
    ]);

    // watch customer actions
    const watchCustomerActionsTask = yield fork(watchCustomerActions);

    // listen to a logout request
    yield take([
      loginActionTypes.USER_LOGOUT,
      loginActionTypes.VERIFY_TOKEN_FAILURE,
    ]);

    // reset data
    yield fork(onUserLogout);

    // cancel all customer actions
    yield cancel(watchCustomerActionsTask);
  }
}

// watch user actions
function* watchUserActions() {
  try {
    yield all([fork(watchCustomer), fork(watchAdminActions)]);
  } catch (error) {}
}

// user actions
function* userActionsFlow() {
  while (true) {
    // wait for user loaded
    yield take([
      loginActionTypes.USER_LOGIN_SUCCESS,
      loginActionTypes.VERIFY_TOKEN_REQUEST,
    ]);

    // watch user actions
    const watchCustomerActionsTask = yield fork(watchUserActions);

    // listen to a logout request
    yield take([
      loginActionTypes.USER_LOGOUT,
      loginActionTypes.VERIFY_TOKEN_FAILURE,
    ]);

    // cancel all user actions
    yield cancel(watchCustomerActionsTask);
  }
}

// root saga
export default function* rootSaga() {
  yield all([
    // login flow
    fork(loginFlow),
    // customer actions
    fork(customerActionsFlow),
    // user actions
    fork(userActionsFlow),
    // Register a new user.
    fork(registerFlow),
    // Password reset.
    fork(passwordReset),
    /**** Non-user actions ****/
    fork(watchPersist), // redux-persist
  ]);
}
