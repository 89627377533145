const pretendRestApi = (callback, delay) => {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve(callback());
		}, delay || 200);
	});
};

//
export default pretendRestApi;
