import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// HOC
import { withCustomerDataLoaded, withSwitchPageTransition } from '../../components/hoc';

// Components for the Dashboard.
import Summary from './Summary';
import Welcome from './Welcome';

const Dashboard = props => {
	const { isCustomerDataLoaded } = props;
	if (!isCustomerDataLoaded) {
		return <Welcome name={props.username} />;
	}

	// The current venue.
	const { venue, venueList } = props;

	// Does the user have any active units?
	const userHasContract = venue && venue.hasOwnProperty('contracts') && venue.contracts.length > 0;
	// const userHasVenue = venueList.length > 0;

	return (
		<div className="row h-100">
			{/* Show the welcome page or units, depending on the customer data. */
			userHasContract ? (
				<Summary venue={venue} venueList={venueList} />
			) : (
				<Welcome
					name={props.customer.firstName + ' ' + props.customer.lastName}
					venueList={venueList}
					currentVenue={venue}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { customer, comments, venues, currentVenueId } = state.customer;
	const { user } = state.login;

	// Extract the current venue.
	const venue = venues.find(object => object.id === currentVenueId);

	// A list of venues for the <select> which changes venue.
	const venueList = venues.map((object, key) => ({
		id: object.id,
		name: object.name,
	}));

	return {
		customer,
		comments,
		venue,
		venueList,
		isCustomerDataLoaded: !!customer,
		username: user.name,
	};
};

export default compose(
	withCustomerDataLoaded,
	withSwitchPageTransition,
	connect(mapStateToProps),
)(Dashboard);
