import { put, call, cancel, take, fork } from 'redux-saga/effects';

// api
import { storeToken, clearToken } from '../../services/api';
import { login as apiLogin, verifyToken as apiVerifyToken } from '../../api/user/index';

// redux
import { loginActionCreators, loginActionTypes } from '../actions/loginActions';
import { customerActionCreators } from '../actions/customerActions';
import { globalActionCreators } from '../actions/globalActions';
import { cartActionsCreator } from '../actions/cartActions';

//
function* doLogin(credentials) {
	try {
		// global start fetching
		yield put(globalActionCreators.startFetching());

		// do login
		const response = yield call(apiLogin, credentials);

		//
		const { success } = response;

		// successful login
		if (success) {
			const { user, token } = response;
			const { is_admin: isAdmin } = user;

			// store the token
			yield call(storeToken, token);

			// dispatch the login success action
			yield put(loginActionCreators.userLoginSuccess(user, token));

			//  load customer data - for customer users
			if (!isAdmin) {
				const { customer } = response;
				yield put(customerActionCreators.customerDataLoaded(customer));
			}

			// success - refresh cart
			yield put(cartActionsCreator.updateCartRequest());

			// global end fetching
			yield put(globalActionCreators.endFetching());

			// done
			return;
		}

		// login failure
		const { message } = response;

		// throw the error
		throw new Error(message);
	} catch (error) {
		// console.log(error);

		// global end fetching
		yield put(globalActionCreators.endFetching());

		// login failure
		yield put(loginActionCreators.userLoginFailure(error));
		yield put(globalActionCreators.addErrorNotice('Email or password is invalid.'));
	}
}

//
function* verifyToken(credentials) {
	try {
		// do login
		const response = yield call(apiVerifyToken, credentials);

		//
		const { success } = response;

		// verify token failure
		if (!success) {
			const { message } = response;

			// throw token verification error
			throw new Error(message);
		}

		//
		// const { customer } = response;
		// console.log(customer);

		// success - update customer data
		// yield put(customerActionCreators.customerDataLoaded(customer));
	} catch (error) {
		// console.log(error);

		// verify token failure
		yield put(loginActionCreators.verifyTokenFailure(error));
	}
}

// login flow
function* loginFlow() {
	while (true) {
		// listen to USER_LOGIN_REQUEST or VERIFY_TOKEN_REQUEST
		const { credentials, type: actionType } = yield take([
			loginActionTypes.USER_LOGIN_REQUEST,
			loginActionTypes.VERIFY_TOKEN_REQUEST,
		]);

		// fork return a Task object
		let authorizeTask;
		if (actionType === loginActionTypes.USER_LOGIN_REQUEST) {
			// login request
			authorizeTask = yield fork(doLogin, credentials);
		} else {
			// verify token request
			authorizeTask = yield fork(verifyToken, credentials);
		}

		// listen to any action of [USER_LOGOUT, USER_LOGIN_FAILURE, VERIFY_TOKEN_FAILURE]
		const action = yield take([
			loginActionTypes.USER_LOGOUT,
			loginActionTypes.USER_LOGIN_FAILURE,
			loginActionTypes.VERIFY_TOKEN_FAILURE,
		]);

		// logout actions
		if (action.type === loginActionTypes.USER_LOGOUT) {
			// cancel the authorize task if logout
			yield cancel(authorizeTask);
		}

		// clear the token after logout
		yield call(clearToken);
	}
}

//
export default loginFlow;
