import React, { useEffect } from 'react';

// Common components.
import InputText from '../../../components/common/InputText';
import Select from '../../../components/common/Select';
import Button from '../../../components/common/Button';
import RadioButtons from '../../../components/common/RadioButtons';
import EstablishmentType from '../../../components/common/EstablishmentType';
import Address from '../../../components/common/Address';

// Config.
import routes from '../../../config/routesConfig';

// Custom hooks.
// import useTraceUpdate from '../../../hooks/useTraceUpdate';

// Helper functions.
import { getHospitalityOptions } from '../../../utils/formHelperFunctions';

// Style.
import './AboutTheVenue.scss';
import ErrorMessageBlock from '../../../components/common/ErrorMessageBlock';

//
const AboutTheVenue = (props) => {
	const {
		businessIndustry, // Form config.
		businessTypeOptions,
		canAdvanceRoutes,
		formData,
		inlineErrorMessages,
		isFormValid,
		onInputChange,
		route,
		setFormData,
		setInlineErrorMessages,
		updateCanAdvanceRoutes,
		yearsInBusinessOptions, // Form config
	} = props;

	// -- Any extra config for form data. --
	// All settings which are placed under Hospitality. This could be Pub, Bar etc
	const hospitalityOptions = getHospitalityOptions(businessIndustry);

	// -- Handle required fields. --
	// Core fields.
	let REQUIRED_INPUTS = [
		'name',
		'keyLabel',
		'phone',
		'email',
		'businessYears',
		'establishmentType',
		'venueAddressLine1',
		'venueCity',
		'venueCounty',
		'venuePostcode',
		'venueCountry',
		'companyLargerGroup',
		// For error handling only
		// 'venueAddress',
	];

	// Add any conditional required fields.
	// Company larger group / chain name.
	if (formData.companyLargerGroup === '1') {
		REQUIRED_INPUTS.push('companyLargerGroupName');
	}

	// Establishment type.
	if (
		formData.establishmentType &&
		hospitalityOptions.includes(formData.establishmentType)
	) {
		REQUIRED_INPUTS.push('businessType');
	}

	// Clear any error messages from step 2 of the form when this component mounts. This
	// also clears on mount which makes doesn't matter.
	useEffect(() => {
		setInlineErrorMessages({});
	}, [canAdvanceRoutes, setInlineErrorMessages]);

	/**
	 * Called when the "next step" button is clicked.
	 */
	const nextStep = (e) => {
		e.preventDefault();

		// Check that all required data for this step is complete before allowing the user to proceed.
		const result = isFormValid(
			REQUIRED_INPUTS, // Inputs that are required for this step to pass.
			true // Passing true will set the error messages.
		);

		// If the result is true there are no errors and the user can proceed to the next step.
		if (result === true) {
			updateCanAdvanceRoutes(routes.ADD_UNIT_VENUE_STEP_2);

			route.history.push(routes.ADD_UNIT_VENUE_STEP_2);
		}

		// If there are errors they will be displayed and the user cannot pass this step.
		return;
	};

	/**
	 * When the back button is clicked.
	 */
	const back = (e) => {
		e.preventDefault();
		route.history.push(routes.ADD_UNIT_KEY);
		return;
	};

	// Previous step
	// const prevStep = () => {
	//   // Send the user to the key page.
	//   route.history.push(routes.ADD_UNIT_KEY);
	// };

	// Hook used to trace properitis which were updated.
	// useTraceUpdate(
	//   {
	//     ...props,
	//   },
	//   'AboutTheVenue'
	// );

	return (
		<div className="about-the-venue">
			<h1>Step 1 of 2</h1>
			<p>Tell us about your venue</p>
			<div className="form-section">
				<div className="row">
					<div className="col-12 col-md-6">
						<p>
							<InputText
								labelText="Venue name"
								forAttribute="name"
								name="name"
								autoComplete="new-password"
								value={formData.name}
								onChange={onInputChange}
								errorMessage={
									inlineErrorMessages.name
										? inlineErrorMessages.name.message
										: ''
								}
								validationRules={['notEmpty']}
								readOnly
							/>
						</p>
						<p>
							<InputText
								labelText="Key label"
								forAttribute="keyLabel"
								name="keyLabel"
								autoComplete="new-password"
								value={formData.keyLabel}
								onChange={onInputChange}
								errorMessage={
									inlineErrorMessages.name
										? inlineErrorMessages.keyLabel.message
										: ''
								}
								validationRules={['notEmpty']}
								readOnly
							/>
						</p>
						<p>
							<InputText
								labelText="Venue phone number"
								forAttribute="phone"
								name="phone"
								value={formData.phone}
								onChange={onInputChange}
								errorMessage={
									inlineErrorMessages.phone
										? inlineErrorMessages.phone.message
										: ''
								}
								type="tel"
								autoComplete="tel"
								validationRules={['phoneNumber', 'notEmpty']}
							/>
						</p>
						<p>
							<InputText
								labelText="Venue email address"
								forAttribute="email"
								name="email"
								value={formData.email}
								onChange={onInputChange}
								errorMessage={
									inlineErrorMessages.email
										? inlineErrorMessages.email.message
										: ''
								}
								validationRules={['emailAddress', 'notEmpty']}
							/>
						</p>
					</div>
					<div className="col-12 col-md-6">
						<p>
							<Select
								labelText={'Years in business'}
								forAttribute="businessYears"
								name="businessYears"
								value={formData.businessYears}
								onChange={onInputChange}
								errorMessage={
									inlineErrorMessages.businessYears
										? inlineErrorMessages.businessYears.message
										: ''
								}
								options={yearsInBusinessOptions}
								validationRules={['notEmpty']}
							/>
						</p>
						{/* Business industry*/}
						<EstablishmentType
							labelText={'Business industry'}
							forAttribute="establishmentType"
							name="establishmentType"
							value={formData.establishmentType}
							onChange={onInputChange}
							errorMessage={
								inlineErrorMessages.establishmentType
									? inlineErrorMessages.establishmentType.message
									: ''
							}
							options={businessIndustry}
							validationRules={['notEmpty']}
						/>
						{/* The type of business: Tenanted, Managed, leased etd */}
						{hospitalityOptions.includes(formData.establishmentType) && (
							<p>
								<Select
									labelText="Business type:"
									forAttribute="businessType"
									name="businessType"
									value={formData.businessType}
									onChange={onInputChange}
									options={businessTypeOptions}
									errorMessage={
										inlineErrorMessages.businessType
											? inlineErrorMessages.businessType.message
											: ''
									}
									validationRules={['notEmpty']}
									conditionalValidation={{
										depend: 'establishmentType',
										expected: hospitalityOptions,
									}}
								/>
							</p>
						)}

						<RadioButtons
							label={
								<>
									Is &ldquo;<strong>{formData.name}</strong>&rdquo; part of a
									larger group?
								</>
							}
							forAttribute="companyLargerGroup"
							name="companyLargerGroup"
							value={formData.companyLargerGroup}
							onChange={onInputChange}
							errorMessage={
								inlineErrorMessages.companyLargerGroup
									? inlineErrorMessages.companyLargerGroup.message
									: ''
							}
							buttons={[
								{ value: '0', label: 'No' },
								{
									value: '1',
									label: 'Yes',
								},
							]}
						/>
						{formData.companyLargerGroup === '1' && (
							<p>
								<InputText
									labelText="Chain"
									forAttribute="companyLargerGroupName"
									name="companyLargerGroupName"
									value={formData.companyLargerGroupName}
									onChange={onInputChange}
									errorMessage={
										inlineErrorMessages.companyLargerGroupName
											? inlineErrorMessages.companyLargerGroupName.message
											: ''
									}
									validationRules={['notEmpty']}
									autoComplete="organization"
									conditionalValidation={{
										depend: 'companyLargerGroup',
										expected: 'yes',
									}}
								/>
							</p>
						)}
					</div>
				</div>
			</div>
			<div className="form-section">
				{/* Address of the venue */}
				<Address
					heading="Venue address"
					line1={{
						name: 'venueAddressLine1',
						value: formData.venueAddressLine1,
						error: inlineErrorMessages.venueAddressLine1
							? inlineErrorMessages.venueAddressLine1.message
							: '',
					}}
					line2={{
						name: 'venueAddressLine2',
						value: formData.venueAddressLine2,
						error: inlineErrorMessages.venueAddressLine2
							? inlineErrorMessages.venueAddressLine2.message
							: '',
					}}
					city={{
						name: 'venueCity',
						value: formData.venueCity,
						error: inlineErrorMessages.venueCity
							? inlineErrorMessages.venueCity.message
							: '',
					}}
					county={{
						name: 'venueCounty',
						value: formData.venueCounty,
						error: inlineErrorMessages.venueCounty
							? inlineErrorMessages.venueCounty.message
							: '',
					}}
					postcode={{
						name: 'venuePostcode',
						value: formData.venuePostcode,
						error: inlineErrorMessages.venuePostcode
							? inlineErrorMessages.venuePostcode.message
							: '',
					}}
					country={{
						name: 'venueCountry',
						value: formData.venueCountry,
						error: inlineErrorMessages.venueCountry
							? inlineErrorMessages.venueCountry.message
							: '',
					}}
					onInputChange={onInputChange}
					setFormData={setFormData}
					formData={formData}
				/>
			</div>

			<Button
				className="btn btn-secondary"
				onClick={nextStep}
				label="Next step"
			/>
			<ErrorMessageBlock inlineErrorMessages={inlineErrorMessages} />
			<hr />
			<Button
				className="btn btn-secondary back mr-3"
				onClick={back}
				label="Erase data and return to key settings"
			/>
		</div>
	);
};

export default AboutTheVenue;
