//
export const adminActionTypes = {
	ADMIN_CREATE_USER_REQUEST: 'ADMIN_CREATE_USER_REQUEST',
};

//
const createUserRequest = (formData) => ({
	type: adminActionTypes.ADMIN_CREATE_USER_REQUEST,
	formData,
});

//
export const adminActionCreators = {
	createUserRequest,
};
