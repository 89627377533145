import { put, call, takeLatest } from 'redux-saga/effects';

// API
import { updateProfile as apiUpdateProfile } from '../../../api/user';

// Redux
import {
  customerActionCreators,
  customerActionTypes,
} from '../../actions/customerActions';
import { globalActionCreators } from '../../actions/globalActions';

/**
 * Saga to send API request for user profile data.
 * @param contractId string|int
 */
function* updateUserProfile({ payload }) {
  try {
    // Loading state.
    yield put(globalActionCreators.startFetching('Updating profile...'));

    // Make request for user profile.
    const response = yield call(apiUpdateProfile, payload);

    const { success, message, payload: _payload } = response;

    // Error.
    if (!success) {
      throw new Error(message);
    }

    // Success.
    yield put(customerActionCreators.customerProfileUpdateSuccess(_payload));

    yield put(globalActionCreators.addSuccessNotice(message));
  } catch (error) {
    yield put(
      globalActionCreators.addErrorNotice(
        'Error: could not update your settings'
      )
    );
    // console.log(error);
  } finally {
    yield put(globalActionCreators.endFetching());
  }
}

//
export default function* watchProfile() {
  yield takeLatest(
    customerActionTypes.CUSTOMER_PROFILE_UPDATE_REQUEST,
    updateUserProfile
  );
}
