import { put, call, takeLatest } from 'redux-saga/effects';

// api
import {
  getCart as apiGetCart,
  removeItem as apiRemoveItem,
  addIMultipleItemsToCart as apiAddIMultipleItemsToCart,
  updateItemQuantity as apiUpdateItemQuantity,
  clearCart as apiClearCart,
  applyCoupon as apiApplyCoupon,
  removeCoupon as apiRemoveCoupon,
} from '../../api/cart';

// redux
import { cartActionsCreator, cartActionTypes } from '../actions/cartActions';
import { globalActionCreators } from '../actions/globalActions';

// Routes.
import routes from '../../config/routesConfig';

// add unit to cart
function* addUnitToCart(payload) {
  let isAddingItemsToCartSuccess = true;

  try {
    const { unit } = payload;

    const {
      quantity,
      cart_item_data: { keyStartNumber, unitStartNumber },
    } = unit;

    const cartMessage =
      quantity === 1 ? 'Adding unit to basket...' : 'Adding units to basket...';

    // start fetching
    yield put(globalActionCreators.startFetching(cartMessage));

    // Create array holding the item(s).
    let items = [unit];

    // Split multiples into single items, this is so each "row" in the basket
    // can be represented as a single item.
    if (quantity > 1) {
      items = Array.from(Array(quantity)).map((nullValue, arrayIndex) => ({
        ...unit,
        quantity: 1,
        cart_item_data: {
          ...unit.cart_item_data,
          keyStartNumber: parseInt(keyStartNumber) + arrayIndex * 10,
          unitStartNumber: unitStartNumber + arrayIndex,
        },
      }));
    }

    const response = yield call(apiAddIMultipleItemsToCart, items);
    const { success, message } = response;

    if (!success) {
      throw new Error(message);
    }
  } catch (error) {
    // Display any error message.
    isAddingItemsToCartSuccess = false;
    yield put(globalActionCreators.addErrorNotice(error.message));
  } finally {
    // Update cart.
    const cartResponse = yield call(apiGetCart);
    const { success, cart } = cartResponse;
    if (success) {
      yield put(cartActionsCreator.updateCart(cart));
    }

    // Redirect to the cart page if there were no problems.
    if (isAddingItemsToCartSuccess) {
      window.router.push(routes.CART);
    }

    // End the fecthing state.
    yield put(globalActionCreators.endFetching());
  }
}

// remove item
function* removeItem(payload) {
  // console.log(payload);
  // return false;

  try {
    // start fetching
    yield put(globalActionCreators.startFetching());

    //
    const { itemKey } = payload;
    const response = yield call(apiRemoveItem, itemKey);
    const { success, cart, message } = response;

    // failure
    if (!success) {
      throw new Error(message);
    }

    // success
    yield put(cartActionsCreator.updateCart(cart));
  } catch (error) {
    yield put(globalActionCreators.addErrorNotice(error.message));
  } finally {
    yield put(globalActionCreators.endFetching());
  }
}

//
function* addReplacePartsToCart(payload) {
  let isAddingItemsToCartSuccess = true;
  try {
    // start fetching
    yield put(globalActionCreators.startFetching());

    // Get the parts.
    const { parts: items } = payload;

    //
    const response = yield call(apiAddIMultipleItemsToCart, items);
    const { message } = response;

    // failure
    if (!response.success) {
      throw new Error(message);
    }
  } catch (error) {
    isAddingItemsToCartSuccess = false;
    yield put(globalActionCreators.addErrorNotice(error.message));
    yield put(cartActionsCreator.addReplacementPartsToCartFailure());
  } finally {
    // update cart
    const cartResponse = yield call(apiGetCart);
    // console.log(cartResponse);
    const { success, cart } = cartResponse;
    if (success) {
      yield put(cartActionsCreator.updateCart(cart));
    }

    // redirect to cart on success
    if (isAddingItemsToCartSuccess) {
      window.router.push(routes.CART);
    }

    //
    yield put(globalActionCreators.endFetching());
  }
}

//
function* updateItemQuantity(payload) {
  try {
    // start fetching
    yield put(globalActionCreators.startFetching());

    //
    const { itemKey, quantity } = payload;
    // console.log(payload);

    //
    const response = yield call(apiUpdateItemQuantity, itemKey, quantity);
    const { message, cart } = response;

    // failure
    if (!response.success) {
      throw new Error(message);
    }

    // success - refresh cart
    yield put(cartActionsCreator.updateCart(cart));

    // go to cart page
    window.router.push(routes.CART);
  } catch (error) {
    yield put(globalActionCreators.addErrorNotice(error.message));
  } finally {
    yield put(globalActionCreators.endFetching());
  }
}

// clear cart
function* clearCart(payload) {
  // only clear local cart
  const { localOnly } = payload;
  // console.log({ localOnly });
  if (localOnly) {
    return;
  }

  //
  try {
    // start fetching
    // yield put(globalActionCreators.startFetching());

    //
    const response = yield call(apiClearCart);
    const { success, message } = response;

    // failure
    if (!success) {
      throw new Error(message);
    }

    // success - do nothing
  } catch (error) {
    // yield put(globalActionCreators.addErrorNotice(error.message));
  } finally {
    // yield put(globalActionCreators.endFetching());
  }
}

// UPDATE_CART_REQUEST
function* updateCartRequest() {
  try {
    // start fetching
    yield put(globalActionCreators.startFetching());

    // update cart
    const cartResponse = yield call(apiGetCart);
    const { success, cart } = cartResponse;
    if (success) {
      yield put(cartActionsCreator.updateCart(cart));
    }
  } catch (error) {
  } finally {
    yield put(globalActionCreators.endFetching());
  }
}

// When applying a coupon code.
function* applyCouponCode({ code }) {
  try {
    // Start fetching animation.
    yield put(globalActionCreators.startFetching());

    // Make the API request.
    const response = yield call(apiApplyCoupon, code);
    const { success, message = '' } = response;

    // If the code did not work, tell the user.
    if (!success && message) {
      yield put(cartActionsCreator.setCouponCodeNotice(message));
    }

    if (success) {
      // Set the notice.
      yield put(cartActionsCreator.setCouponCodeNotice(message));

      // Update the redux store to show the new cart.
      yield put(cartActionsCreator.updateCartRequest());
    }
  } catch (error) {
    yield put(globalActionCreators.addErrorNotice(error.message));
  } finally {
    // Stop fetching animation.
    yield put(globalActionCreators.endFetching());
  }
}

// When removing a coupon code.
function* removeCouponCode({ code }) {
  try {
    // Start fetching animation.
    yield put(globalActionCreators.startFetching());

    // Make the API request.
    const response = yield call(apiRemoveCoupon, code);
    const { success, message = '' } = response;

    // If the code did not work, tell the user.
    if (!success && message) {
      yield put(cartActionsCreator.setCouponCodeNotice(message));
    }

    if (success) {
      yield put(cartActionsCreator.setCouponCodeNotice(message));

      yield put(cartActionsCreator.updateCartRequest());
    }
  } catch (error) {
    console.log(error);
    yield put(globalActionCreators.addErrorNotice(error.message));
  } finally {
    // Stop fetching animation.
    yield put(globalActionCreators.endFetching());
  }
}

//
export default function* watchCartActions() {
  yield takeLatest(cartActionTypes.ADD_UNIT_TO_CART, addUnitToCart);
  yield takeLatest(
    cartActionTypes.ADD_REPLACEMENT_PARTS_TO_CART,
    addReplacePartsToCart
  );
  yield takeLatest(cartActionTypes.UPDATE_ITEM_QUANTITY, updateItemQuantity);
  yield takeLatest(cartActionTypes.REMOVE_ITEM, removeItem);
  yield takeLatest(cartActionTypes.CLEAR_CART, clearCart);
  yield takeLatest(cartActionTypes.UPDATE_CART_REQUEST, updateCartRequest);
  yield takeLatest(cartActionTypes.APPLY_COUPON_CODE, applyCouponCode);
  yield takeLatest(cartActionTypes.REMOVE_COUPON_CODE, removeCouponCode);
}
