import camelCaseKeys from 'camelcase-keys';

//
import { getApi } from '../../services/api';
import getRandomItem from '../../utils/getRandomItem';
import pretendRestApi from '../../utils/pretendRestApi';

import { getVenueApiFields } from './updateVenue';

//
const fixtureAddVenue = async () => {
	// const files = ['addVenueFailure', 'addVenueSuccess'];
	const files = ['addVenueSuccess'];
	const responseFile = getRandomItem(files);
	// console.log(responseFile);

	//
	let responseData = require(`../../fixtures/venue/${responseFile}.json`);

	//
	const { success } = responseData;

	//
	if (success) {
		// fake venue id
		const venueId = Math.floor(Math.random() * 10000 + 1);

		//
		responseData = {
			...responseData,
			venue: {
				...responseData.venue,
				id: venueId,
				name: `${venueId} - ${responseData.venue.name}`,
			},
		};
	}

	//
	return await pretendRestApi(() => responseData, 1000);
};

//
async function apiCreateVenue(api, formData) {
	try {
		// TODO: fill missing post fields
		const { customerId, keyOffset, name } = formData;

		//
		const response = await api.post('/cardssafe-account/v1/createVenue', {
			...getVenueApiFields(formData),
			venue_name: name,
			customer: customerId,
			key_offset: keyOffset,
		});

		//
		// console.log(response);
		const venueObject = camelCaseKeys(response.data.data);

		//
		return {
			success: true,
			venue: {
				...venueObject,
				id: venueObject.venueId,
				name: venueObject.venueName,
			},
		};
	} catch (error) {
		return {
			success: false,
			message: error.response ? error.response.data.message : error.message,
		};
	}
}

//
const addVenue = form => {
	const api = getApi();
	// api is null - use fixture
	if (!api) {
		return fixtureAddVenue();
	}

	// restApi add venue
	return apiCreateVenue(api, form);
};

export default addVenue;
