import React from 'react';

class Notice extends React.Component {
  constructor(props) {
    super(props);

    //
    this.removeNotice = this.removeNotice.bind(this);
  }

  componentDidMount() {
    // Set how long the notice should be displayed for.
    setTimeout(() => this.removeNotice(), 5000);
  }

  shouldComponentUpdate() {
    return false;
  }

  removeNotice() {
    this.props.removeNotice(this.props.id);
  }

  render() {
    const { type, content } = this.props;

    //
    return (
      <div className={`notice notice--${type}`}>
        {content}
        <button type="button" onClick={this.removeNotice}>
          Close
        </button>
      </div>
    );
  }
}

export default Notice;
