import React from 'react';
import { Route, Switch, matchPath, Redirect } from 'react-router-dom';
import get from 'lodash.get';

// config
import routes from '../../config/routesConfig';

// Common components.
import SubHeadingSection from '../../components/SubHeadingSection';
import MiniCart from '../../components/MiniCart';
import NotFound from '../../components/NotFound';
import StageNavigation from '../../components/StageNavigation';

// Child components.
import CheckoutAddress from './step/CheckoutAddress';
import CheckoutPayment from './step/CheckoutPayment';
import CheckoutComplete from './step/CheckoutComplete';
import CheckoutConfirmation from './confirmation';

// Containers.
import Content from '../../containers/Content';

// Style.
import './Checkout.scss';

/**
 * The checkout process is managed in stages: confirm address, payment details (+Worldpay redirect), confirmation page.
 */
const Checkout = ({ location }) => {
  const match = matchPath(location.pathname, {
    path: routes.CHECKOUT_ORDER_CONFIRMATION,
  });
  const orderId = get(match, 'params.order_id');
  let activeRoutes = orderId
    ? [routes.getOrderConfirmationUrl(orderId)]
    : [routes.CHECKOUT, routes.CHECKOUT_PAYMENT];

  // @TODO is cart empty variable, used for redirect.
  let cartIsEmpty = false;

  // Redirect if the cart is empty.
  if (
    location.pathname === routes.CHECKOUT ||
    location.pathname === routes.CHECKOUT_PAYMENT
  ) {
    if (cartIsEmpty) {
      return <Redirect to={routes.CART} />;
    }
  }

  return (
    <>
      <SubHeadingSection heading="Checkout">
        <MiniCart />
      </SubHeadingSection>
      <Content customClasses="checkout">
        <StageNavigation
          links={[
            {
              to: routes.CHECKOUT,
              label: 'Address',
            },
            {
              to: routes.CHECKOUT_PAYMENT,
              label: 'Payment',
            },
            {
              to: routes.getOrderConfirmationUrl(orderId),
              label: 'Confirmation',
            },
          ]}
          activeRoutes={activeRoutes}
        />

        <Switch>
          <Route path={routes.CHECKOUT} exact component={CheckoutAddress} />
          <Route
            path={routes.CHECKOUT_PAYMENT}
            exact
            component={CheckoutPayment}
          />
          <Route
            path={routes.CHECKOUT_ORDER_CONFIRMATION}
            component={CheckoutConfirmation}
          />
          <Route
            path={'/checkout/completed/'}
            exact
            component={CheckoutComplete}
          />
          <Route component={NotFound} />
        </Switch>
      </Content>
    </>
  );
};

export default Checkout;
