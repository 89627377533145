import { getApi } from '../../services/api';
import pretendRestApi from '../../utils/pretendRestApi';
import getRandomItem from '../../utils/getRandomItem';

//
const fixtureCancelContract = () => {
	const files = ['cancelContractSuccess', 'cancelContractFailure'];
	// const files = ['cancelContractFailure'];
	const responseFile = getRandomItem(files);
	let responseData = require(`../../fixtures/user/${responseFile}.json`);

	//
	return pretendRestApi(() => responseData);
};

//
const apiCancelContract = async (api, contractId) => {
	try {
		const response = await api.post(`/cardssafe-account/v1/cancelContract/${contractId}`);

		// success
		return {
			success: true,
			contract: response.data.data,
		};
	} catch (error) {
		return {
			success: false,
			message: error.response.data.message || 'Your contract could not be cancelled. Please contact us for help.',
		};
	}
};

//
const cancelContract = contractId => {
	const api = getApi();
	// api is null - use fixture
	if (!api) {
		return fixtureCancelContract();
	}

	// restApi cancel contract
	return apiCancelContract(api, contractId);
};

//
export default cancelContract;
