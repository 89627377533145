import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Redux
import { commentModalActionCreators } from '../../redux/actions/commentModalActions';

// Components
import ConfirmModal from '../common/ConfirmModal';

// Helpers
import { timestampToDateTime } from '../../utils/dateHelperFunctions';
import { getInitials } from '../../utils/customerHelperFunctions';

// Style
import './CommentModal.scss';

//
const CommentModal = (props) => {
  const {
    heading,
    userId,
    customerId,
    unitId,
    comments,
    closeCommentModal,
    addComment,
    deleteComment,
  } = props;

  //
  const [commentContent, setCommentContent] = useState('');
  const [notice, setNotice] = useState({ type: '', message: '' });
  const [isConfirmBoxActive, setIsConfirmBoxActive] = useState(false);
  const [commentDeleteId, setCommentDeleteId] = useState(0);

  //
  const commentCount = comments.length;

  // Clear comment field when a comment has been added.
  useEffect(() => {
    setCommentContent('');
  }, [commentCount]);

  //
  const handleAddComment = () => {
    // reset notice
    setNotice(null);

    // comment is empty
    if (!commentContent) {
      setNotice({
        type: 'error',
        message: 'Please enter your comment',
      });
      return;
    }

    // dispatch
    addComment({ userId, customerId, unitId, commentContent });
  };

  //
  const handleDeleteComment = () => {
    // close the confirm box
    setIsConfirmBoxActive(false);
    // dispatch delete comment action
    deleteComment({ unitId, commentId: commentDeleteId });
  };

  //
  const onClickDeleteCommentButton = (commentId) => {
    // reset notice
    setNotice(null);
    // set comment delete id
    setCommentDeleteId(commentId);
    // open the confirm box
    setIsConfirmBoxActive(true);
  };

  //
  const { type: noticeType, message: noticeMessage } = notice || {};

  //
  return (
    <div className="modal fade comment-modal" tabIndex="-1" role="dialog">
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {heading} <span>-</span>
              <span>
                {commentCount} comment{commentCount > 1 ? 's' : ''}
              </span>
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeCommentModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {noticeMessage && (
              <div className="comment-modal__notice">
                {noticeType}: {noticeMessage}
              </div>
            )}

            {/* Comments */}
            {comments.map((comment) => {
              if (!comment) {
                return null;
              }
              const { id, authorName, date, content } = comment;

              // console.log(comment);

              return (
                <div className="comment-modal__comment" key={`comment-${id}`}>
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="comment-modal__author-avatar">
                        {getInitials(authorName)}
                      </div>
                    </div>
                    <div className="col-auto comment-modal__meta">
                      <div className="comment-modal__author-name">
                        {authorName}
                      </div>
                      <time className="comment-modal__time">
                        {timestampToDateTime(date)}
                      </time>
                    </div>
                    <div className="col-auto ml-auto">
                      <button
                        type="button"
                        className="comment-modal__delete icon-trash-bin"
                        onClick={onClickDeleteCommentButton.bind(this, id)}
                      >
                        <span className="sr-only">Delete</span>
                      </button>
                    </div>
                  </div>

                  <div className="comment-modal__content">{content}</div>
                </div>
              );
            })}

            {isConfirmBoxActive && (
              <ConfirmModal
                heading="Warning!"
                content={(props) => (
                  <div className="content-wrap">
                    <div className="icon icon-exclamation-triangle"></div>
                    <div className="divider"></div>
                    <h2 className="title">
                      Are you sure you want to delete the comment?
                    </h2>
                    <span className="description">
                      The comment will be deleted permanently.
                    </span>
                  </div>
                )}
                okText="Yes, I'm sure"
                onConfirm={handleDeleteComment}
                onCancel={() => setIsConfirmBoxActive(false)}
              />
            )}

            {/* Form */}
            <h3 className="h5">Add new comment</h3>
            <form className="comment-modal__form">
              <textarea
                value={commentContent}
                onChange={(e) => setCommentContent(e.target.value)}
                className="form-control"
              />
              <button
                className="btn btn-secondary"
                type="button"
                onClick={handleAddComment}
              >
                Submit
              </button>
            </form>
          </div>
          {/*<div className="modal-footer" />*/}
        </div>
      </div>
    </div>
  );
};

//
const getComments = (unitId, state) => {
  // unitId is null - return global comments
  if (!unitId) {
    const { comments: globalComments } = state.customer;
    return globalComments;
  }

  // unit comments
  const { venues } = state.customer;
  console.log(venues);
  return (
    venues
      .reduce((comments, venue) => {
        console.log(venue.contracts);
        // Make sure we have at least one contract for this venue.
        if (venue.contracts.length === 0) {
          return comments;
        }

        venue.contracts.find(
          (contract) =>
            contract.hasOwnProperty('units') &&
            contract.units.find((unit) =>
              unit.id === unitId ? comments.push(...unit.comments) : false
            )
        );
        return comments;
      }, [])
      // Latest comment at the top.
      .sort((a, b) => (a.date > b.date ? -1 : 1))
  );
};

//
const mapStateToProps = (state) => {
  const { heading, unitId } = state.commentModal;
  const { customer } = state.customer;
  const { id: customerId } = customer || {};
  const { user } = state.login;
  const { id: userId } = user || {};

  //
  const comments = getComments(unitId, state);

  //
  return {
    heading,
    userId,
    customerId,
    unitId,
    comments,
  };
};

//
const mapDispatchToProps = {
  closeCommentModal: commentModalActionCreators.closeCommentModal,
  addComment: commentModalActionCreators.addCommentRequest,
  deleteComment: commentModalActionCreators.deleteCommentRequest,
};

//
export default connect(mapStateToProps, mapDispatchToProps)(CommentModal);
