import React from 'react';

//
import './BoxOptions.scss';

// Config for the labels.
export const unitBoxOptions = [
	{
		value: 'box-key',
		label: 'Box & key',
	},
	{
		value: 'key-only',
		label: 'Key only',
	},
];

//
const BoxOptions = ({
	unitId,
	contractNumber,
	keyStartNumber,
	freeReplacementKeysAvailable,
	selectedItems,
	onClickBoxOption,
	unitNumber,
}) => {
	return (
		<div className="box-data">
			<div className="row">
				<div className="col-md-6">
					<h2 className="box-data__title">
						Unit N<sub>o</sub>
						{unitNumber}
					</h2>
				</div>
				<div className="col-md-6 box-data__contract-number">
					Contract: {contractNumber}
				</div>
			</div>
			<div className="box-data__keys-numbers">
				Keys {keyStartNumber} - {keyStartNumber + 9}
			</div>
			<div className="box-data__replacement-keys">
				<i className="icon-info-circle"></i> You have{' '}
				{freeReplacementKeysAvailable} free replacement key
				{freeReplacementKeysAvailable ? 's' : ''} still available
			</div>

			<div className="box-data__options">
				{selectedItems.map(({ boxNumber, boxOption }) => (
					<div className="box-data__options__option" key={`box-${boxNumber}`}>
						<span className="box-data__options__draw">Draw #{boxNumber}</span>
						{unitBoxOptions.map(({ value, label }) => (
							<div
								className={`box-data__options__config ${
									value === boxOption ? 'active' : ''
								}`}
								key={value}
								onClick={onClickBoxOption.bind(this, boxNumber, value)}
							>
								<span
									className={
										'box-data__options__label' +
										(value === 'box-key'
											? ' icon-cardssafe-box'
											: ' icon-cardssafe-key')
									}
								>
									{label}
								</span>
							</div>
						))}
					</div>
				))}
				{/* Help notice */}
				{selectedItems.length === 0 && (
					<div className="box-data__help notice">
						Please select parts by clicking the numbered boxes on the CardsSafe
						unit
					</div>
				)}
			</div>
		</div>
	);
};

//
export default BoxOptions;
