import React from 'react';

import decodeHTMLEntity from '../../utils/decodeHTMLEntity';

// components
import TableRow from '../../components/common/TableRow';

//
const UnitItem = (props) => {
  const {
    // productId,
    // lineTotal,
    // lineTax,
    productPrice,
    venueName,
    itemKey,
    quantity,
    keyStartNumber,
    unitStartNumber,
    removeItemFromCart,
    // updateItemQuantity,
  } = props;

  function getCellsForItem(rowIndex) {
    const rowKeyStartNumber = keyStartNumber + 10 * rowIndex;

    return [
      {
        id: `${itemKey}-${rowIndex}`,
        value: [
          <i className="icon-cardssafe-unit" key={`${itemKey}-item`}></i>,
          'Unit No',
          unitStartNumber + rowIndex,
        ],
        responsiveLabel: 'Item', // Label used on smaller screens. CSS uses this data-attr value.
      },
      {
        id: 'keyNumbers',
        value: `${rowKeyStartNumber}-${rowKeyStartNumber + 9}`,
        responsiveLabel: 'Key Number(s)',
      },
      {
        id: 'venue',
        value: decodeHTMLEntity(venueName),
        responsiveLabel: 'Venue',
      },
      {
        id: 'price',
        value: productPrice,
        responsiveLabel: 'Price',
      },
      {
        id: 'remove',
        value: (
          <button
            type="button"
            className={
              removeItemFromCart ? 'icon-trash-bin' : 'icon-trash-bin disabled'
            }
            onClick={
              removeItemFromCart
                ? removeItemFromCart.bind(this, itemKey)
                : void 0
            }
          >
            <span className="sr-only">Delete</span>
          </button>
        ),
        className: 'remove',
        responsiveLabel: 'Remove',
      },
    ];
  }

  // quantity = 1
  if (quantity < 2) {
    return <TableRow cells={getCellsForItem(0)} />;
  }

  // item rows
  let itemRows = [];
  for (let rowIndex = 0; rowIndex < quantity; rowIndex++) {
    itemRows.push(
      <TableRow key={rowIndex} cells={getCellsForItem(rowIndex)} />
    );
  }

  return <>{itemRows}</>;
};

//
export default UnitItem;
