import React from 'react';

// Common components.
import SubHeadingSection from '../../components/SubHeadingSection';

// Standard 404 page.
const NotFound = props => {
	return (
		<div className="not-found">
			<SubHeadingSection heading="404 - Not Found!" />
			<p>Sorry, we could not find the page you're looking for.</p>
		</div>
	);
};

export default NotFound;
