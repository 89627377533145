import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';

// Routes.
import routes from '../../config/routesConfig';

// Redux.
import { cartActionsCreator } from '../../redux/actions/cartActions';
import { customerActionCreators } from '../../redux/actions/customerActions';

// Import the steps.
import ProductPage from './step/ProductPage';
import SelectVenue from './step/SelectVenue';
import AddUnitVenue from './step/AddUnitVenue';
import Key from './step/Key';

// Common components.
import SubHeadingSection from '../../components/SubHeadingSection';

// Containers.
import Content from '../../containers/Content';
import Button from '../../components/common/Button';

/**
 * A user can order one or more new units. This process includes the product
 * page, quantity, key info, and also redirects the user to make a new venue if
 * required.
 */
const AddUnit = (props) => {
	const {
		addUnitToCart,
		cart,
		currentVenue,
		fetching,
		history,
		isCartContainingKeys,
		isCartContainingUnitFromAnotherVenue,
		clearCart,
		product: unitProduct = {},
		shipping,
		unitCustomProduct = {},
		updateCurrentVenueId,
		venueList,
	} = props;

	// Track the quantity selected by the user.
	const [quantity, setQuantity] = useState(1);

	// Track the venue that this unit is being purchased for.
	const [venue, setVenue] = useState(currentVenue);

	// Track which steps the user has passed.
	const [stepPassed, updateStepPassed] = useState(0);

	// New key number.
	const [newKeyNumber, updateNewKeyNumber] = useState(
		parseInt(currentVenue.keyOffset)
	);

	// New unit number.
	const [newUnitNumber, updateNewUnitNumber] = useState(1);

	// the product
	const product = venue.keyOffset ? unitCustomProduct : unitProduct;

	// Called after a venue is selected for the new unit.
	// @todo check if this is also called when a new venue is being created. This is called when selecting
	// a venue which already exists.
	function updateVenue(newVenue) {
		setVenue(newVenue);
		// console.log({ newVenue });
		updateCurrentVenueId(newVenue.id);

		// The unit product could be the standard unit, or the more expensive unit
		// if the user has previously opted to use a custom starting number.
		const product = newVenue.keyOffset ? unitCustomProduct : unitProduct;

		// Default next key number value.
		let venueNewKeyStartingNumber = parseInt(newVenue.keyOffset) || 1;
		let venueNewUnitNumber = newUnitNumber;

		// Calculate the number of existing units.
		const existingUnits = newVenue.contracts.reduce((accumulator, contract) => {
			if (!contract.units) return accumulator;
			return accumulator + contract.units.length;
		}, 0);

		// If any units exist for the venue the new number will be;
		// - The base starting number inc' offset.
		// - Plus the qty of existing units * 10 (keys).
		if (existingUnits > 0) {
			venueNewKeyStartingNumber =
				venueNewKeyStartingNumber + existingUnits * 10;

			// The new unit number will be equal to the current number of units plus 1.
			venueNewUnitNumber = existingUnits + 1;
		}

		// If the cart is holding one or more units the key number will need to be calculated with this in mind.
		Object.keys(cart).forEach((itemId) => {
			// Loop over each item and check if the product ID matches and check that the venue ID is the same as the current venue.
			if (
				cart[itemId].productId === product.id &&
				cart[itemId].venueId === newVenue.id
			) {
				venueNewKeyStartingNumber = Math.max(
					venueNewKeyStartingNumber,
					cart[itemId].keyStartNumber + 10 * cart[itemId].quantity
				);

				// Also increment the unit number to account for any unit(s) already added to the cart.
				venueNewUnitNumber++;
			}
		});

		// Update the state which hoilds the new unit and key numbers.
		updateNewKeyNumber(venueNewKeyStartingNumber);
		updateNewUnitNumber(venueNewUnitNumber);
	}

	// On select add new venue
	const onSelectAddNewVenue = () => {
		// Reset the venue if its id is set
		if (venue.id) {
			setVenue({});
		}

		// Update step passed
		updateStepPassed(2);

		// Reset new key number
		updateNewKeyNumber(1);

		// Reset the unit number.
		updateNewUnitNumber(1);

		// Go to the key page.
		history.push(routes.ADD_UNIT_KEY);
	};

	const handleAddUnitToCart = useCallback(
		(venueObject, keyOffset = null) => {
			// The unit's venue.
			const unitVenue = venueObject.id ? venueObject : venue;

			// Get the total number of units for this venue. This will be used to calculate the number
			// of the next unit.
			let numUnits = 0;
			unitVenue.contracts.forEach(
				(contract) => (numUnits += contract.units.length)
			);

			// Check if a normal unit or a custom ordering unit should be added to the cart.
			const product =
				parseInt(unitVenue.keyOffset) > 1 ? unitCustomProduct : unitProduct;
			// Get the product ID.
			const { id: productId } = product;

			// Get the starting number of the new unit.
			// const keyStartNumber = parseInt(unitVenue.keyOffset) || newKeyNumber;
			const keyStartNumber = newKeyNumber;

			// Dispatch add unit to cart action.
			addUnitToCart({
				product_id: productId,
				quantity,
				cart_item_data: {
					venue_id: unitVenue.id,
					venue_name: unitVenue.name,
					keyStartNumber,
					unitStartNumber: newUnitNumber,
					// unitStartNumber: parseInt(keyStartNumber / 10) + 1,
					// unitStartNumber: numUnits + 1,
				},
			});
		},
		[
			addUnitToCart,
			newKeyNumber,
			newUnitNumber,
			quantity,
			unitCustomProduct,
			unitProduct,
			venue,
		]
	);

	// redirect to the add new venue page
	const redirectToAddNewVenuePage = (name, keyLabel) => {
		// set venue name & key label
		setVenue({ name, keyLabel });

		// go to the add venue page
		history.push(routes.ADD_UNIT_VENUE);
	};

	// Get the current heading.
	const getStepHeading = () => {
		switch (stepPassed) {
			case 2:
				return 'Your key';

			case 3:
				return 'Your venue';

			// Step 0, 1.
			default:
				return 'Add Unit(s)';
		}
	};

	const AddUnitVenueComponent = useCallback(
		(routeProps) => {
			//
			const onAddNewVenueSuccess = (venueObject) => {
				// update the venue data
				setVenue(venueObject);
				updateCurrentVenueId(venueObject.id);

				const { keyOffset } = venueObject;

				// add to cart
				handleAddUnitToCart(venueObject, keyOffset);
			};

			return (
				<AddUnitVenue
					{...routeProps}
					onAddNewVenueSuccess={onAddNewVenueSuccess}
					venue={venue}
					stepPassed={stepPassed}
					requiredPassedStep={3}
					updateStepPassed={updateStepPassed}
				/>
			);
		},
		[venue, handleAddUnitToCart, stepPassed, updateCurrentVenueId]
	);

	// If the cart contains keys the user cannot add a new unit.
	if (isCartContainingKeys) {
		return (
			<>
				<SubHeadingSection heading={getStepHeading()} />
				<Content customClasses="add-units h-100">
					<div className="add-units__keys-in-cart text-center">
						<p className="notice">
							Your basket currently contains replacement keys or boxes.
							Keys/boxes and CardsSafe units cannot be processed in the same
							order. Using the buttons below, either navigate to the basket to
							complete your current order or empty the basket to continue
							purchasing a CardsSafe unit subscription.
						</p>
						<Link className="btn btn-primary mt-4" to={routes.CART}>
							Go to basket
						</Link>
						<Button
							label="Clear basket"
							className="btn btn-danger mt-4 ml-3"
							onClick={() => clearCart(false)}
						/>
					</div>
				</Content>
			</>
		);
	}

	// If the cart contins units from other venues the user cannot add a new unit.
	if (isCartContainingUnitFromAnotherVenue) {
		return (
			<>
				<SubHeadingSection heading={getStepHeading()} />
				<Content customClasses="add-units h-100">
					<div className="add-units__keys-in-cart text-center">
						<p className="notice">
							Your basket contains unit(s) from another venue. Unit
							subscriptions can only be created for one venue at a time. Please
							complete an order for the unit(s) in the basket or empty the
							basket before continuing.
						</p>
						<Link className="btn btn-primary mt-4" to={routes.CART}>
							Go to basket
						</Link>
						<Button
							label="Clear basket"
							className="btn btn-danger mt-4 ml-3"
							onClick={() => clearCart(false)}
						/>
					</div>
				</Content>
			</>
		);
	}

	return (
		<>
			<SubHeadingSection heading={getStepHeading()} />
			<Content customClasses="add-units h-100">
				<>
					<Route
						path={routes.ADD_UNIT_STEP_1}
						exact
						render={(routeProps) => (
							<ProductPage
								{...routeProps}
								hasVenue={venueList.length > 0}
								/* Switch the product details if the venue has a key offset. */
								product={product}
								quantity={quantity}
								updateQuantity={setQuantity}
								stepPassed={stepPassed}
								updateStepPassed={updateStepPassed}
								addNewVenue={onSelectAddNewVenue}
								shipping={shipping}
							/>
						)}
					/>
					<Route
						path={routes.ADD_UNIT_STEP_2}
						component={(routeProps) => (
							<SelectVenue
								{...routeProps}
								venue={venue}
								venueList={venueList}
								currentVenue={currentVenue}
								updateVenue={updateVenue}
								onSelectAddNewVenue={onSelectAddNewVenue}
								stepPassed={stepPassed}
								requiredPassedStep={1}
								updateStepPassed={updateStepPassed}
								cartHasItems={Object.keys(cart).length > 0}
							/>
						)}
					/>
					<Route
						path={routes.ADD_UNIT_KEY}
						exact
						component={(routeProps) => (
							<Key
								venue={venue}
								newKeyNumber={
									newKeyNumber < 10 ? '0' + newKeyNumber : newKeyNumber
								}
								addUnitToCart={handleAddUnitToCart}
								redirectToAddNewVenuePage={redirectToAddNewVenuePage}
								stepPassed={stepPassed}
								requiredPassedStep={2}
								updateStepPassed={updateStepPassed}
								fetching={fetching}
							/>
						)}
					/>
					<Route
						path={routes.ADD_UNIT_VENUE}
						component={AddUnitVenueComponent}
					/>
				</>
			</Content>
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { fetching } = state.global;

	// Get product pricing/data.
	// NOTE: the signup price will be higher if the user is using a custom key offset. This means the app
	// will add a different item to the cart.
	const unitProductId = parseInt(process.env.REACT_APP_UNIT_PRODUCT_ID);
	const product = state.customer.products.find(
		(product) => product.id === unitProductId
	);
	const unitCustomProductId = parseInt(
		process.env.REACT_APP_UNIT_CUSTOM_PRODUCT_ID
	);
	const unitCustomProduct = state.customer.products.find(
		(product) => product.id === unitCustomProductId
	);

	// Get the list of current venues so the user can select which existing venue the unit should be connected
	// with.

	// current venue id
	const { currentVenueId, venues: venueList } = state.customer;
	const currentVenue =
		venueList.find((venue) => parseInt(venue.id) === currentVenueId) || {};

	const { items: cart } = state.cart;

	const keyProductId = parseInt(
		process.env.REACT_APP_REPLACEMENT_KEY_PRODUCT_ID
	);
	const keyBoxProductId = parseInt(
		process.env.REACT_APP_REPLACEMENT_KEY_AND_BOX_PRODUCT_ID
	);

	// Check if the cart contains keys or boxes.
	const isCartContainingKeys = Object.values(cart).some(
		(item) =>
			item.productId === keyProductId || item.productId === keyBoxProductId
	);

	// Check if the cart contains units from another venue.
	const isCartContainingUnitFromAnotherVenue = Object.values(cart).some(
		(item) => {
			const containsUnit =
				item.productId === unitProductId ||
				item.productId === unitCustomProductId;
			if (containsUnit) {
				// Check that the unit is from another venue.
				return currentVenueId !== item.venueId;
			}

			return false;
		}
	);

	// Get shipping data.
	const { shipping } = state.customer;

	// Get the customer ID. This is passed to the cart when adding an item/unit.
	// const customerId = state.customer.customer.id;

	//
	return {
		fetching,
		product,
		shipping,
		unitCustomProduct,
		venueList,
		currentVenue,
		cart,
		isCartContainingKeys,
		isCartContainingUnitFromAnotherVenue,
	};
};

const mapDispatchToProps = {
	addUnitToCart: cartActionsCreator.addUnitToCart,
	updateItemQuantity: cartActionsCreator.updateItemQuantity,
	updateCurrentVenueId: customerActionCreators.updateCurrentVenueId,
	clearCart: cartActionsCreator.clearCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUnit);
