import React from 'react';

// Style.
import './Button.scss';

const Button = ({
	className = 'btn-lg btn-primary',
	onClick,
	label,
	disabled = false,
}) => {
	return (
		<button className={className} onClick={onClick} disabled={disabled}>
			{label}
		</button>
	);
};

export default Button;
