import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// modals
import CommentModal from './CommentModal';
import NoticeModal from './NoticeModal';

//
const GlobalModals = (props) => {
	const { isCommentModalActive, isNoticeModalActive, userIsLoggedIn } = props;

	// Control the modal class on <body>
	useEffect(() => {
		if (isCommentModalActive) {
			document.body.classList.add('modal-open');
		} else {
			document.body.classList.remove('modal-open');
		}
	}, [isCommentModalActive]);

	//
	return (
		<React.Fragment>
			{userIsLoggedIn && <CommentModal isCommentModalActive={isCommentModalActive} />}
			{isNoticeModalActive && <NoticeModal />}
		</React.Fragment>
	);
};

//
const mapStateToProps = state => {
	const { userIsLoggedIn } = state.login;
	const { isActive: isCommentModalActive } = state.commentModal;
	const { notices } = state.global;

	return {
		userIsLoggedIn,
		isCommentModalActive,
		isNoticeModalActive: notices.length > 0,
	};
};

//
export default connect(mapStateToProps)(GlobalModals);
