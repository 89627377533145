import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';

// hoc
import withAuthenticate from './components/hoc/withAuthenticate';

// Load components.
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import NotFound from './components/NotFound';
import AppBody from './containers/AppBody';
import AppMain from './containers/AppMain';

// Load all views.
import * as views from './views/';

// routes
import routes from '../src/config/routesConfig';

// Menu's state provider
import MenuProvider from './hooks/menuToggleContext';

//
const AppPage = ({ location }) => {
  return (
    <React.Fragment>
      <MenuProvider>
        {/* Header */}
        <Header />
        {/* Main container */}
        <AppBody>
          <Sidebar />
          <AppMain>
            <Switch>
              <Route
                path={routes.DASHBOARD}
                component={views.Dashboard}
                exact
              />
              <Route path={routes.PERSONAL} component={views.Personal} />
              <Route path="/venue/:venueId" component={views.Venue} />
              <Route path={routes.ADD_UNIT} component={views.AddUnit} />
              <Route
                path="/order-parts/:unitId"
                component={views.OrderReplacementParts}
              />
              <Route path={routes.CART} component={views.Cart} />
              <Route path={routes.CHECKOUT} component={views.Checkout} />
              <Route
                path={routes.ORDER_HISTORY_PAGE}
                component={views.OrderHistory}
                exact
              />
              <Route
                path={routes.ORDER_HISTORY}
                component={views.OrderHistory}
              />
              <Route path={routes.ADMIN} component={views.Admin} />
              <Route path="/contract/:contractId" component={views.Contract} />

              {/* 404 */}
              <Route component={NotFound} />
            </Switch>
          </AppMain>
        </AppBody>
      </MenuProvider>
    </React.Fragment>
  );
};

//
export default compose(withAuthenticate)(AppPage);
