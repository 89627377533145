import { getApi } from '../../services/api';

//
const apiCreateUser = async (api, formData) => {
  try {
    const { email, name, phone, firstName, lastName } = formData;
    const response = await api.post('/wp/v2/users', {
      username: email,
      email,
      name,
      // random password
      password:
        Math.random().toString(36).substr(2, 10) +
        Math.random().toString(36).substr(2, 10),
      meta: {
        phone,
      },
      first_name: firstName,
      last_name: lastName,
    });

    // success
    return {
      success: true,
      customerId: response.data.id,
    };
  } catch (error) {
    return {
      sucess: false,
      message: error.response ? error.response.data.message : error.message,
    };
  }
};

//
const createUser = (formData) => {
  const api = getApi();
  if (!api) {
    return {
      success: false,
      message: 'Error, API not configured.',
    };
  }

  // api create user
  return apiCreateUser(api, formData);
};

//
export default createUser;
