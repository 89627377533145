import React from 'react';
import { CSSTransition } from 'react-transition-group';

//
import './withSwitchPageTransition.scss';

//
const withSwitchPageTransition = (ComposedComponent) => {
	return (props) => {
		const [inProp, setInProp] = React.useState(false);

		React.useEffect(() => setInProp(true), []);
		// React.useEffect(() => setInProp(true), [false]);

		//
		return (
			<CSSTransition in={inProp} timeout={500} classNames="switch-page">
				<ComposedComponent {...props} />
			</CSSTransition>
		);
	};
};

//
export default withSwitchPageTransition;
