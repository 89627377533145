import React from 'react';

//
import routes from '../../../config/routesConfig';

// Common components.
import Button from '../../../components/common/Button';
import Content from '../../../containers/Content';
import InputNumber from '../../../components/common/InputNumber';

// Helpers.
import { roundPrice } from '../../../utils/cartHelperFunctions';

// Assets.
import productImage from '../../../assets/img/product-image.png';

// Style.
import './ProductPage.scss';

/**
 * This first step of the add unit process.
 */
const ProductPage = (props) => {
  const {
    product,
    quantity,
    updateQuantity,
    history,
    updateStepPassed,
    hasVenue,
    addNewVenue,
    shipping,
  } = props;

  const {
    price: signUpPrice,
    subscriptionSignUpFee: subscriptionPrice,
  } = product;

  const onChangeQuantity = (value) => {
    updateQuantity(value);
  };

  const onClickNextStep = () => {
    // has no venue => go to add new venue step
    if (!hasVenue) {
      // add new venue
      addNewVenue();
      return;
    }

    // update step passed
    updateStepPassed(1);

    // go to the step 2
    history.push(routes.ADD_UNIT_STEP_2);
  };

  // Get signup fee * qty
  const getInitialCost = () => {
    return roundPrice(
      quantity * (parseFloat(subscriptionPrice) + parseFloat(signUpPrice))
    );
  };

  // Get monthly fee * qty.
  const getMonthlyCost = () => {
    return roundPrice(quantity * signUpPrice);
  };

  const getShippingCost = () => {
    let price,
      { maxCost = 20, noClassCost = 9.5 } = shipping;

    maxCost = parseFloat(maxCost);

    // Format the noClassCost value if it contains a quantity multiplier.
    if (noClassCost.indexOf('*') !== -1) {
      noClassCost = noClassCost.split('*')[0];
    }

    // Calculate postage based on quantity.
    price = quantity * parseFloat(noClassCost);

    // Apply price cap.
    return {
      price: price > maxCost ? roundPrice(maxCost) : roundPrice(price),
      hasCap: price > maxCost,
    };
  };

  //
  return (
    <Content customClasses="product-page pb-5">
      <div className="row">
        <div className="col-12 col-lg-5 product-image">
          <img
            src={productImage}
            alt="CardsSafe Unit"
            style={{
              width: '100%',
              maxWidth: 350,
              height: 'auto',
              margin: 'auto',
              display: 'block',
            }}
          />
          {/* USP */}
          <div className="row usp">
            <div className="col-3 col-lg-6 col-xl-3 usp__single">
              <i className="icon-graph-up"></i>
              <p>Increase customer spend</p>
            </div>
            <div className="col-3 col-lg-6 col-xl-3 usp__single">
              <i className="icon-graph-down"></i>
              <p>Reduce costs and losses</p>
            </div>
            <div className="col-3 col-lg-6 col-xl-3 usp__single">
              <i className="icon-pci"></i>
              <p>Be PCI compliant</p>
            </div>
            <div className="col-3 col-lg-6 col-xl-3 usp__single">
              <i className="icon-save-money"></i>
              <p>
                Only £{roundPrice(parseFloat(signUpPrice))} per unit per month
                to rent
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-7 py-5 py-lg-0 product-details">
          {/* Main product information*/}
          <h3 className="product-details__heading">The CardsSafe Unit</h3>
          <div
            className="product-details__description"
            dangerouslySetInnerHTML={{ __html: product.description }}
          ></div>

          <div className="row align-items-end">
            <div className="col-12 col-xl-10 product-details__price-table">
              {/* Price table */}
              <dl className="row row-eq-height">
                <dt className="col-6">Sign-up Fee</dt>
                <dd className="col-6">£{getInitialCost()} + VAT</dd>
                <dt className="col-6">Per month after 1st free month</dt>
                <dd className="col-6">£{getMonthlyCost()} + VAT</dd>
                <dt className="col-6">+ P&P</dt>
                <dd
                  className={
                    getShippingCost().hasCap ? 'col-6 has-cap' : 'col-6'
                  }
                >
                  £{getShippingCost().price} + VAT
                </dd>
              </dl>
              {getShippingCost().hasCap && (
                <p className="product-details__has-cap-text">
                  Special offer price for postage!
                </p>
              )}
              {/* Quantity input*/}
              <InputNumber
                labelText="Select the number of units you wish to rent:"
                min="1"
                max="10"
                value={quantity}
                onChange={onChangeQuantity}
              />
            </div>
            <div className="col-12 col-sm-4 product-details__next">
              <Button
                className="btn btn-secondary"
                label="Next"
                onClick={onClickNextStep}
              />
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default ProductPage;
