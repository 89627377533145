import React from 'react';
import './Checkbox.scss';

const Checkbox = ({
	labelText,
	forAttribute,
	name,
	checked,
	onChange,
	errorMessage,
	inlineNotice = '',
	readOnly,
}) => {
	return (
		<React.Fragment>
			<label className="input-checkbox" htmlFor={forAttribute}>
				<input
					id={forAttribute}
					checked={checked}
					type="checkbox"
					name={name}
					value={1}
					onChange={onChange}
				/>{' '}
				{labelText}{' '}
			</label>
			{inlineNotice && <span className="inline-notice">{inlineNotice}</span>}
			{errorMessage && <span className="inline-error">{errorMessage}</span>}
		</React.Fragment>
	);
};

export default Checkbox;
