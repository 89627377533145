import React from 'react';
import { Link } from 'react-router-dom';

// Config.
import routes from '../../config/routesConfig';

// Common components.
// import Select from '../../components/common/Select';
import VenueSwitcher from './VenueSwitcher';
import LargeUnitSVG from '../../assets/svg/LargeUnitSVG';

// Style.
import './Welcome.scss';

const Welcome = props => {
	const { name, venueList, currentVenue } = props;

	//
	return (
		<div className="col-12">
			<div className="row h-100 justify-content-center align-items-center">
				<div className="col-12 col-sm-3 col-lg-3 dashboard__unit">
					<LargeUnitSVG />
				</div>
				<div className="col-12 col-sm-9 col-lg-6 dashboard__welcome">
					<h1 className="dashboard__welcome__heading">Welcome to CardsSafe!</h1>
					{currentVenue && currentVenue.name ? (
						<p>
							Hello {name}, you don't have any units for "{currentVenue.name}", click the button below to add a new
							subscription.
						</p>
					) : (
						<p>
							Hello {name}, it looks like you’re new here. Let’s get started by adding your first CardsSafe unit(s) -
							click the button below.
						</p>
					)}
					<div className="row">
						<div className="col-12 col-md-6">
							<Link className="btn btn-primary btn-primary-alt" to={routes.ADD_UNIT}>
								Get Started
							</Link>
						</div>

						{venueList && venueList.length > 1 && (
							<div className="col-12 col-md-12">
								<VenueSwitcher venueList={venueList} />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Welcome;
