import React from 'react';
import { Redirect } from 'react-router-dom';

// routes
import routes from '../../../config/routesConfig';

// the HOC to control user's progression. This is used to stop a user from
// advancing to a step which they should not be able to visit until the
// required steps have been passed.
const withProgressionControl = (StepComponent) => {
  return (props) => {
    const { stepPassed, requiredPassedStep } = props;

    // console.log('withProgressionControl', stepPassed, requiredPassedStep);

    // return the step component when stepPassed >= requiredPassedStep
    if (stepPassed >= requiredPassedStep) {
      return <StepComponent {...props} />;
    }

    // stepPassed < 1, always return step 1
    if (stepPassed < 1) {
      return <Redirect to={routes.ADD_UNIT_STEP_1} />;
    }

    // step 2
    if (stepPassed < 2) {
      return <Redirect to={routes.ADD_UNIT_STEP_2} />;
    }

    // key step
    if (stepPassed < 3) {
      return <Redirect to={routes.ADD_UNIT_KEY} />;
    }
  };
};

//
export default withProgressionControl;
