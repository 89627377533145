import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// HOC
import { withSwitchPageTransition } from '../../components/hoc';

// Redux.
import { customerActionCreators } from '../../redux/actions/customerActions';

// Utils.
import {
  getYearsInBusinessOptions,
  getChainOptions,
  getBusinessIndustryItems,
  getBusinessTypeOptions,
} from '../../utils/formHelperFunctions.js';

import { getUnitCustomPrice } from '../../utils/unitHelperFunctions';

// Child components.
import VenueForm from './VenueForm';
import VenueNotFound from './VenueNotFound';

// Common components.
import MiniCart from '../../components/MiniCart';
import SubHeadingSection from '../../components/SubHeadingSection';
import Content from '../../containers/Content';

// @todo add sub navigation to other venues if applicable.
// import SubNavigationBar from '../../components/SubNavigationBar';
// import routes from '../../config/routesConfig';

/**
 * This screen is where a single venue's setting are managed. This could be the name, address,
 * billing address, type of business, company number and more.
 */
const Venue = ({
  fetching,
  isAdmin,
  currentVenueId,
  venue,
  venueSubMenuItems,
  updateVenue,
  yearsInBusinessOptions,
  businessTypeOptions,
  chainOptions,
  businessIndustry,
  unitCustomPrice,
}) => {
  if (currentVenueId) {
    return (
      <>
        <SubHeadingSection heading={`Your venue: ${venue.name}`}>
          <MiniCart />
        </SubHeadingSection>
        {/* <SubNavigationBar
          active={currentVenueId}
          menuItems={venueSubMenuItems}
          menuLabel="Change venue:"
          baseUrl={routes.VENUE}
          baseKey="venueSubMenu"
        /> */}
        <Content customClasses="venue">
          <VenueForm
            {...venue}
            isAdmin={isAdmin}
            venueId={currentVenueId}
            yearsInBusinessOptions={yearsInBusinessOptions}
            businessTypeOptions={businessTypeOptions}
            businessIndustry={businessIndustry}
            chainOptions={chainOptions}
            updateVenue={updateVenue}
            fetching={fetching}
            unitCustomPrice={unitCustomPrice}
          />
        </Content>
      </>
    );
  } else {
    return (
      <>
        <SubHeadingSection heading="Error: Venue not found!" />
        <Content customClasses="venue">
          <VenueNotFound />
        </Content>
      </>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const { fetching } = state.global;

  const {
    user: { isAdmin },
  } = state.login;

  // The current venue ID.
  const { venues, currentVenueId, acf } = state.customer;

  const venueSubMenuItems = venues.map((venue, index) => {
    return { id: venue.id, label: venue.name };
  });

  // Extract the correct venue.
  const venue = venues.find((object) => object.id === currentVenueId);

  // console.log(venue);

  // ACF config -> Prop
  const yearsInBusinessOptions = getYearsInBusinessOptions(acf, true);

  // Business chains.
  const chainOptions = getChainOptions(acf, true);

  // Category and type of business e.g Hospitality -> Pub.
  const businessIndustry = getBusinessIndustryItems(acf);

  const businessTypeOptions = getBusinessTypeOptions();

  const unitCustomPrice = getUnitCustomPrice(state) || 0;

  return {
    fetching,
    isAdmin,
    currentVenueId,
    venue,
    venueSubMenuItems,
    yearsInBusinessOptions,
    businessTypeOptions,
    chainOptions,
    businessIndustry,
    unitCustomPrice,
  };
};

const mapDispatchToProps = {
  // Request to update the venue.
  updateVenue: customerActionCreators.updateVenueRequest,
};

export default compose(
  withSwitchPageTransition,
  connect(mapStateToProps, mapDispatchToProps)
)(Venue);
