import React from 'react';
import { connect } from 'react-redux';
import './Header.scss';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

// Components.
import Logo from '../../assets/svg/Logo';
import MenuToggle from './MenuToggle';

const Header = (props) => {
	const {
		fetching,
		location: { pathname },
		history,
	} = props;
	// scroll to top after fetching
	React.useEffect(() => {
		if (!fetching) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}
	}, [fetching, pathname]);

	//
	window.router = history;

	//
	return (
		<div className="header-sticky">
			<header className={'header container-fluid ' + (fetching ? 'fetching' : '')}>
				<div className="row align-items-center header__inner justify-content-center justify-content-lg-start">
					<div className="col logo">
						<Logo />
					</div>
					<div className=" flex-shrink-1 header__contact-details">
						<a className="icon-call-phone" href="tel:+448455001040">
							0845 500 1040
						</a>
						<a className="icon-mail" href="mailto:sales@cardssafe.com">
							sales@cardssafe.com
						</a>
					</div>
					<MenuToggle />
				</div>
			</header>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { userIsLoggedIn } = state.login;
	const { fetching } = state.global;

	return {
		fetching,
		userIsLoggedIn,
	};
};

export default compose(withRouter, connect(mapStateToProps))(Header);
