import React, { useState, useEffect } from 'react';
import get from 'lodash.get';

// Common components.
import RadioButtons from './RadioButtons';

// Style.
import './EstablishmentType.scss';

/**
 * Input for selecting the business industry. This is a combincation of a <Select>
 * element and child radio buttons which are icons.
 */
const EstablishmentType = (props) => {
  const {
    labelText,
    forAttribute,
    name,
    value,
    onChange = null,
    errorMessage,
    className = 'form-control',
    options = [],
    // defaultValue,
    // validationRules = [],
    // conditionalValidation = [],
    required = true, // Should the "required" styling be used?
  } = props;
  // Track which parent item is active so we can display the correct child options.
  const [selectedParentItem, setSelectedParentItem] = useState('');

  const getChildOptions = () => {
    if (selectedParentItem) {
      let currentChildren = options.find(
        (item) => item.parent === selectedParentItem
      );

      if (currentChildren.children) {
        return (
          <>
            {!value && (
              <p className="business-industry-prompt">
                Please select your industry from the options below
              </p>
            )}
            <RadioButtons
              forAttribute={name}
              name={name}
              value={value}
              onChange={onChange}
              // errorMessage={inlineErrorMessages.businessIndustry}
              buttons={currentChildren.children}
              useIcons
            />
          </>
        );
      }
    }
  };

  // Set the correct state on load.
  useEffect(() => {
    if (!value) {
      return;
    }

    let parent = options.find((item) => {
      return item.children.find((item) => item.value === value);
    });

    setSelectedParentItem(get(parent, 'parent', ''));
  }, [options, value]);

  // When the parent select changes.
  const handleSelectParentItem = (e) => {
    setSelectedParentItem(e.target.value);
  };

  return (
    <label className="input-select business-industry" htmlFor={forAttribute}>
      <span className="label">
        {labelText} {required && <span className="required">*</span>}
      </span>
      {errorMessage && <span className="inline-error">{errorMessage}</span>}
      <select
        id={forAttribute}
        name={name}
        className={className}
        onChange={handleSelectParentItem}
        value={selectedParentItem}
      >
        <option value="">--select---</option>
        {options.length > 0 &&
          options.map((item) => {
            let parent = item.parent;

            return (
              <option value={parent} key={parent}>
                {parent}
              </option>
            );
          })}
      </select>
      {/* The child options */}
      {getChildOptions()}
    </label>
  );
};

export default EstablishmentType;
