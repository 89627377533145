import React from 'react';

// Style.
import './Table.scss';

// Render a basic table of data. Note, this isn't actually a HTML
// table, it just looks like one.
const Table = ({ columnHeadings = [], children }) => {
	return (
		<div className="table">
			{columnHeadings.length > 0 && (
				<div className="table__head">
					{columnHeadings.map((column, index) => {
						return (
							<span className="table__head__column" key={column + index}>
								{column}
							</span>
						);
					})}
				</div>
			)}
			{/* Each "row" of the table */}
			{children}
		</div>
	);
};

export default Table;
