import React from 'react';
import { Link } from 'react-router-dom';

// routes
import routes from '../../../config/routesConfig';

// Get menu context
import { MenuContext } from '../../../hooks/menuToggleContext';

//
const CustomerMenuItems = props => {
	const { currentVenueId } = props;

	// Get menu context
	const { active, setActive } = React.useContext(MenuContext);
	const handleItemClick = e => {
		if (active) {
			setActive(false);
		}
	};
	//
	return (
		<React.Fragment>
			<Link className="icon-home" to={routes.DASHBOARD} onClick={handleItemClick}>
				Dashboard
			</Link>
			<Link className="icon-person-stalker" to={routes.PERSONAL} onClick={handleItemClick}>
				My profile
			</Link>
			{currentVenueId && (
				<Link className="icon-briefcase" to={routes.getEditVenueUrl(currentVenueId)} onClick={handleItemClick}>
					Edit this venue
				</Link>
			)}
			<Link className="icon-plus-round" to={routes.ADD_UNIT} onClick={handleItemClick}>
				Add unit(s)
			</Link>
			<Link className="icon-history" to={routes.ORDER_HISTORY} onClick={handleItemClick}>
				Order history
			</Link>
		</React.Fragment>
	);
};

//
export default CustomerMenuItems;
