import React, { createContext, useState, useEffect } from 'react';

// Menu context
export const MenuContext = createContext(null);

// Menu provider
const MenuToggleContext = ({ children }) => {
	const [active, setActive] = useState(false);

	useEffect(() => {
		const bodyElement = document.body;

		// Add class to body element
		if (active) {
			bodyElement.classList.add('menu-toggled');
		} else {
			bodyElement.classList.remove('menu-toggled');
		}
	}, [active]);
	return (
		<MenuContext.Provider value={{ active, setActive }}>
			{children}
		</MenuContext.Provider>
	);
};
export default MenuToggleContext;
