import React from 'react';
import './InputPassword.scss';

/**
 * Password input with strength meter.
 */
const InputPassword = (props) => {
  const {
    labelText,
    forAttribute,
    name,
    value = '',
    onChange,
    errorMessage,
    validationArgs,
    inlineNotice = '',
    readOnly,
    type = 'text',
    className = 'form-control',
    validationRules = [],
    conditionalValidation = [],
  } = props;

  const { meter = '' } = validationArgs || {};

  return (
    <label className="input-password" htmlFor={forAttribute}>
      <span className="label">{labelText}</span>

      {!readOnly && (
        <>
          <input
            id={forAttribute}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            className={className}
            placeholder={labelText}
            data-validation-rules={
              validationRules.length ? JSON.stringify(validationRules) : ''
            }
            data-conditional-validation={
              conditionalValidation.length
                ? JSON.stringify(conditionalValidation)
                : ''
            }
          />

          <span
            className={'input-password__meter input-password__meter--' + meter}
          >
            <span className="input-password__meter__label">{meter}</span>
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
          </span>

          {inlineNotice && (
            <span className="inline-notice">{inlineNotice}</span>
          )}
          {errorMessage && <span className="inline-error">{errorMessage}</span>}
        </>
      )}

      {readOnly && (
        <input
          id={forAttribute}
          name={name}
          type={type}
          defaultValue={value}
          className={className}
          readOnly
        />
      )}
    </label>
  );
};

export default InputPassword;
