import { combineReducers } from 'redux';

// Reducers
import { globalReducer } from './globalReducer'; // Handle global state such as "loading".
import { loginReducer } from './loginReducer';
import { cartReducer } from './cartReducer';
import { customerReducer } from './customerReducer';
import { commentModalReducer } from './commentModalReducer';

// root reducer
const rootReducer = combineReducers({
	global: globalReducer,
	login: loginReducer,
	cart: cartReducer,
	customer: customerReducer,
	commentModal: commentModalReducer,
});

//
export default rootReducer;
