import { getApi } from '../../services/api';

// Add an item to the cart using the CoCart plugin endpoint.
const apiAddToCart = async (api, item, shouldReturnCart) => {
  try {
    const endpoint = `/cocart/v1/add-item`;

    // CoCart requires the product ID to be a string.
    if (item.product_id) {
      item.product_id = item.product_id.toString();
    }

    const response = await api.post(endpoint, {
      ...item,
      return_cart: shouldReturnCart,
    });

    // success
    return {
      success: true,
      cart: shouldReturnCart ? response.data : {},
    };
  } catch (error) {
    // failure
    // console.log(error.response);
    return {
      success: false,
      message: error.response ? error.response.data.message : error.message,
    };
  }
};

//
const addToCart = (item, shouldReturnCart = true) => {
  const api = getApi();
  if (!api) {
    return {
      success: false,
      message: 'Api is not configured.',
    };
  }

  //
  return apiAddToCart(api, item, shouldReturnCart);
};

//
export default addToCart;
