import { getApi } from '../../services/api';
import pretendRestApi from '../../utils/pretendRestApi';
import getRandomItem from '../../utils/getRandomItem';

//
const fixtureRegisterUser = () => {
	const files = ['registerUserSuccess', 'registerUserFailure'];
	const responseFile = getRandomItem(files);

	let responseData = require(`../../fixtures/user/${responseFile}.json`);

	//
	return pretendRestApi(() => responseData, 1000);
};

//
const apiRegisterUser = async (api, payload) => {
	try {
		const {
			data: { email, firstName, lastName, password, phone, role },
		} = payload;

		const response = await api.post('/cardssafe-account/v1/registerCardsSafeCustomer', {
			username: email,
			email,
			first_name: firstName,
			last_name: lastName,
			password,
			phone,
			role,
		});

		// success
		return {
			success: true,
			payload: response.data.data,
		};
	} catch (error) {
		return {
			success: false,
			message: error.response ? error.response.data.message : error.message,
		};
	}
};

//
const registerUser = payload => {
	const api = getApi();
	// api is null - use fixture
	if (!api) {
		return fixtureRegisterUser();
	}

	// api register user
	return apiRegisterUser(api, payload);
};

//
export default registerUser;
