import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

export const persistConfig = {
	version: '1.0.0.4',
	key: 'root',
	storage,
	blacklist: ['commentModal', 'global'],
	migrate: (state, persistVersion) => {
		// console.log(persistVersion);

		const { _persist } = state || {};
		const { version: localVersion } = _persist || {};
		// console.log(localVersion, persistVersion);

		// localVersion does not match persistVersion => clear the local data
		if (localVersion !== persistVersion) {
			return Promise.resolve(null);
		}

		//
		return Promise.resolve(state);
	},
};
