import { put, call, takeLatest } from 'redux-saga/effects';

// api
import { getCustomerData as apiGetCustomerData } from '../../../api/user';

// redux
import { loginActionTypes } from '../../actions/loginActions';
import {
	customerActionCreators,
	customerActionTypes,
} from '../../actions/customerActions';
import { globalActionCreators } from '../../actions/globalActions';
import { cartActionsCreator } from '../../actions/cartActions';

// history
import routes from '../../../config/routesConfig';

/**
 * Saga to send API request for user profile data.
 * @param {string|int} customerId
 * @param {boolean} isPersist
 */
function* makeCustomerDataApiRequest({ customerId, venueId, isPersist }) {
	try {
		if (!isPersist) {
			// redirect == false means persisting data
			yield put(globalActionCreators.startFetching('Loading...'));
		}

		yield put(
			globalActionCreators.startFetching('Please wait, account data loading...')
		);

		// Make request for user profile.
		const response = yield call(apiGetCustomerData, customerId, isPersist);

		const { success } = response;

		// error
		if (!success) {
			const { message } = response;
			throw new Error(message);
		}

		// success
		yield put(
			customerActionCreators.customerDataLoaded(
				response.data,
				venueId,
				isPersist
			)
		);

		// Update the cart to the match the session on the server.
		yield put(cartActionsCreator.updateCart(response.data.cart, isPersist));
		// yield put(cartActionsCreator.updateCartRequest());

		// redirect to dashboard
		if (!isPersist) {
			window.router.push(routes.DASHBOARD);
		}
	} catch (error) {
		// console.log(error);
		yield put(
			globalActionCreators.addErrorNotice(
				'There was an error loading the profile data, please contact support.'
			)
		);
	} finally {
		// if (!isPersist) {
		//   yield put(globalActionCreators.endFetching());
		// }

		yield put(globalActionCreators.endFetching());
	}
}

// reset customer data
function* resetCustomerData() {
	yield put(customerActionCreators.resetData());
}

//
export default function* loadCustomerData() {
	yield takeLatest(
		customerActionTypes.CUSTOMER_DATA_REQUEST,
		makeCustomerDataApiRequest
	);

	// on user logout
	yield takeLatest(
		[
			loginActionTypes.USER_LOGOUT,
			loginActionTypes.VERIFY_TOKEN_FAILURE,
			loginActionTypes.USER_LOGIN_FAILURE,
		],
		resetCustomerData
	);
}
