import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// Common components.
import InputText from '../../../components/common/InputText';
import Button from '../../../components/common/Button';

// Assets.
import SVGKey from '../../../assets/svg/Key';

// HOC
import withProgressionControl from './withProgressionControl';

// Validation.
import * as validation from '../../../utils/validation';

// Style.
import './Key.scss';

/**
 * U.I which allows the user to input their chosen venue name and label which will be shown to them
 * via a styled SVG key.
 *
 * @param {Object} props
 */
const Key = (props) => {
	const {
		venue,
		addUnitToCart,
		redirectToAddNewVenuePage,
		updateStepPassed,
		newKeyNumber,
		fetching,
		venueList,
	} = props;

	// Max chars that can be printed on a key.
	const MAX_CHAR_LENGTH = 21;

	// Min chars before testing is conducted.
	// const MIN_CHAR_LENGTH = 3;

	// Key fields to be populated. The values will be set to an empty string if
	// not existing venue data is passed via props.
	const [venueName, setVenueName] = useState(venue.name || '');
	const [venueKeyLabel, setVenueKeyLabel] = useState(
		venue.keyLabel || venue.phone || ''
	);

	// Error hndling.
	const [venueNameError, setVenueNameError] = useState('');
	const [keyLabelError, setkeyLabelError] = useState('');

	// On redirect to the add new venue page
	const onRedirectToAddNewVenuePage = () => {
		// validate name & phone
		const { isValid: nameIsValid, message: nameErrorMessage } =
			validation.venueName(venueName);
		const { isValid: keyLabelIsValid, message: keyLabelErrorMessage } =
			validation.notEmpty(venueKeyLabel);

		// If validation does not pass.
		if (!nameIsValid || !keyLabelIsValid) {
			setVenueNameError(nameErrorMessage);
			setkeyLabelError(keyLabelErrorMessage);
			return;
		}

		// Update step passed
		updateStepPassed(3);

		// Dispatch parent handler
		redirectToAddNewVenuePage(venueName, venueKeyLabel);
	};

	// On venue name change
	const onVenueNameChange = (e) => {
		// Max char length.
		if (e.target.value.length > MAX_CHAR_LENGTH) {
			return;
		}

		// Validate venue name
		let { message: nameErrorMessage } = validation.venueName(
			e.target.value,
			venueList
		);
		setVenueNameError(nameErrorMessage);

		// Update venue name value
		setVenueName(e.target.value);
	};

	// on venue phone change
	const onkeyLabelChange = (e) => {
		// Max char length.
		if (e.target.value.length > MAX_CHAR_LENGTH) {
			return;
		}

		// if (e.target.value.length <= MIN_CHAR_LENGTH) {
		//   // update venue phone value
		//   setVenueKeyLabel(e.target.value);
		//   return;
		// }

		// validate venue key label
		let { message: notEmptyErrorMessage } = validation.notEmpty(e.target.value);
		setkeyLabelError(notEmptyErrorMessage);

		// Custom validation for venue key label.
		// let { message: venueKeyLabelErrorMessage } = validation.venueKeyLabel(
		//   e.target.value
		// );
		// setkeyLabelError(venueKeyLabelErrorMessage);

		// update venue phone value
		setVenueKeyLabel(e.target.value);
	};

	//
	return (
		<div className="key text-md-center">
			<div className="key__illustration">
				<SVGKey
					keyNumber={newKeyNumber}
					name={venueName}
					phone={venueKeyLabel}
				/>
			</div>
			<form className="key__inputs" autoComplete="off">
				{/* the venue is set */}
				{venue.id && (
					<>
						<p className="key__disclaimer">*Example key shown above</p>
						<p className="form-field">
							<InputText
								labelText="Venue Name"
								forAttribute="venueName"
								name="venueName"
								value={venue.name}
								readOnly
								required={false} // No need to show the required styling as this input is read-only
							/>
						</p>
						<p className="form-field">
							<InputText
								labelText="Contact info"
								forAttribute="venueKeyLabel"
								name="venueKeyLabel"
								value={venue.keyLabel ? venue.keyLabel : venue.phone}
								readOnly
								required={false}
							/>
						</p>
					</>
				)}

				{/* the venue is not set */}
				{!venue.id && (
					<>
						<p className="form-field help-text">
							<i className="icon-info-circle" />
							Maximum 21 characters per field
						</p>
						<p className="form-field">
							<InputText
								labelText="Venue Name"
								forAttribute="venueName"
								name="venueName"
								value={venueName}
								onChange={onVenueNameChange}
								errorMessage={venueNameError}
							/>
						</p>
						<p className="form-field">
							<InputText
								labelText="Contact Info"
								forAttribute="venueKeyLabel"
								name="venueKeyLabel"
								value={venueKeyLabel}
								onChange={onkeyLabelChange}
								errorMessage={keyLabelError}
							/>
						</p>
					</>
				)}
				{/* the venue is NOT set, go to the add new venue page  */}
				{!venue.id && (
					<div className="col-12 text-md-center mt-lg-4">
						<Button
							label="Next"
							className="btn btn-secondary"
							disabled={
								!venueName || !venueKeyLabel || venueNameError || keyLabelError
							}
							onClick={onRedirectToAddNewVenuePage}
						/>
					</div>
				)}
			</form>

			{/* the venue is set, add to cart and go to the cart page */}
			{venue.id && (
				<div className="col-12 text-md-center mt-lg-4">
					{' '}
					<Button
						className="btn btn-secondary"
						disabled={fetching}
						label={fetching ? 'Please wait...' : 'Next'}
						onClick={addUnitToCart}
					/>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	const { venues: venueList } = state.customer;
	return { venueList };
};

export default compose(connect(mapStateToProps), withProgressionControl)(Key);
