export const customerProfileUpdateRequest = state => {
	return state;
};

export const customerProfileUpdateSuccess = (state, { payload }) => {
	// Combine the old and updated customer data.
	const {
		email,
		first_name: firstName,
		last_name: lastName,
		meta: { role, phone },
	} = payload;

	let customer = { ...state.customer, email, firstName, lastName, role, phone };

	return {
		...state,
		customer,
	};
};

// Failed to update the profile for some reason.
// export const customerProfileUpdateFailure = (state, { message }) => {
// 	return {
// 		...state,
// 		profileNotice: message,
// 		profileWasUpdated: false,
// 	};
// };

// Reset the profile notice, used when the profile component unmounts.
// export const customerResetProfileNotice = state => {
// 	return {
// 		...state,
// 		profileNotice: null,
// 		profileWasUpdated: false,
// 	};
// };
