import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Helper functions.
// import { customerHasUnits } from '../../utils/customerHelperFunctions';
import { getInitials } from '../../utils/customerHelperFunctions';

//
import { loginActionCreators } from '../../redux/actions/loginActions';
import { commentModalActionCreators } from '../../redux/actions/commentModalActions';

//
import MainMenuItems from './MainMenuItems';

// Style.
import './Sidebar.scss';
import './primary_navigation.scss';

// Assets.
import wave1 from '../../assets/svg/color_wave_1.svg';
import wave2 from '../../assets/svg/color_wave_2.svg';
import wave3 from '../../assets/svg/color_wave_3.svg';
// import VenueSwitcher from '../../views/Dashboard/VenueSwitcher';

/**
 * The is the main sidebar which holds the user icon, primary menu and logout.
 */
const Sidebar = (props) => {
  const {
    logout,
    openCommentModal,
    userIsLoggedIn,
    userIsAdmin,
    isCustomerDataLoaded,
    currentVenueId,
    name,
    initials,
    // venueList,
  } = props;

  // Sticky sidebar listener.
  useEffect(() => {
    const _handleSidebarSticky = () => {
      // Cache the element
      let _sidebarElement, _mainContent;

      const headerHeight = parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(
          '--header-height'
        ) || 68,
        10
      );

      // Viewport height
      const viewportHeight = window.innerHeight;

      // Get sidebar height.
      _sidebarElement = _sidebarElement || document.querySelector('.sidebar');
      const _sidebarElementHeight = _sidebarElement.clientHeight;

      // Get main content
      _mainContent = _mainContent || document.querySelector('.app__main');
      const _mainContentHeight = _mainContent.clientHeight;

      // Make sure the main content's is equal or greater than the sidebar's height
      if (_mainContentHeight < _sidebarElementHeight) {
        _mainContent.style.minHeight = `${_sidebarElementHeight}px`;
      }

      // Window scroll position.
      const scrollOffset = window.pageYOffset;

      // Sidebar + header is taller than viewport
      if (viewportHeight < _sidebarElementHeight + headerHeight) {
        // _sidebarElement.classList.add('affix');
        if (
          viewportHeight + scrollOffset >=
          _sidebarElementHeight + headerHeight
        ) {
          _sidebarElement.classList.add('affix');
        } else {
          _sidebarElement.classList.remove('affix');
        }
      } else {
        // Always fixed to the bottom
        _sidebarElement.classList.add('affix');
      }
    };
    // First load
    _handleSidebarSticky();

    // On scroll
    window.addEventListener('scroll', _handleSidebarSticky, false);

    // Remove when component unmounts.
    return () => {
      window.removeEventListener('scroll', _handleSidebarSticky);
    };
  }, []);

  return (
    <aside className="col sidebar">
      <div className="sidebar__inner">
        <div className="user">
          <div className="user__avatar avatar">{initials}</div>
          <span className="user__name">
            {userIsAdmin && 'Admin: '}
            {name}
          </span>
          {/* {venueList.length > 1 && (
            <VenueSwitcher venueList={venueList} label={'Change venue'} />
          )} */}
        </div>
        <nav className="primary-navigation">
          <MainMenuItems
            userIsLoggedIn={userIsLoggedIn}
            userIsAdmin={userIsAdmin}
            isCustomerDataLoaded={isCustomerDataLoaded}
            currentVenueId={currentVenueId}
            openCommentModal={openCommentModal}
          />
        </nav>
        <div className="primary-navigation__bottom d-flex align-items-end">
          {userIsLoggedIn && (
            <button
              type="button"
              className="logout-button icon-power"
              onClick={logout}
              key="primaryMenuLogout"
            >
              Logout
            </button>
          )}
          <img className="wave--1" src={wave1} alt="" />
          <img className="wave--2" src={wave2} alt="" />
          <img className="wave--3" src={wave3} alt="" />
        </div>
      </div>
    </aside>
  );
};

const mapStateToProps = (state) => {
  const { userIsLoggedIn, user } = state.login;
  const { isAdmin } = user || {};
  const { customer, currentVenueId, venues } = state.customer;
  const { name } = user;
  const initials = getInitials(name);

  const customerHasUnits = Boolean(
    venues.find((venue) => venue.contracts.length > 0 && venue.contracts[0].id)
  );

  // A list of venues for the <select> which changes venue.
  const venueList = venues.map((object, key) => ({
    id: object.id,
    name: object.name,
  }));

  //
  return {
    userIsLoggedIn,
    // userIsAdmin: true,
    userIsAdmin: isAdmin,
    isCustomerDataLoaded: Boolean(customer),
    currentVenueId,
    customerHasUnits,
    name,
    initials,
    venueList,
  };
};

const mapDispatchToProps = {
  // Open and close the sidebar.
  toggleSidebar: () => {},
  logout: loginActionCreators.userLogout,
  openCommentModal: commentModalActionCreators.openCommentModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
