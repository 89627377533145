// Customer registration related reducers.

// After request is sent to the server.
export const customerRegisterRequest = (state) => {
  return state;
};

// When registration is complete.
export const customerRegisterSuccess = (state, { _payload }) => {
  // Combine the old and updated customer data.
  const {
    email,
    first_name: firstName,
    last_name: lastName,
    role,
    phone,
  } = _payload;

  let customer = { ...state.customer, email, firstName, lastName, role, phone };

  return {
    ...state,
    customer,
  };
};

// When registration fails.
export const customerRegisterFailure = (state, { error }) => {
  // console.log('customerRegisterFailure', error);

  return state;
};

// Password reset request.
export const passwordResetRequest = (state) => {
  return state;
};
