import { put, call, takeLatest } from 'redux-saga/effects';

// api
import { createOrder as apiCreateOrder } from '../../../api/order';

// redux
import { customerActionTypes } from '../../actions/customerActions';
import { globalActionCreators } from '../../actions/globalActions';
import { cartActionsCreator } from '../../actions/cartActions';

// config
import { noticeTypes } from '../../../config/globalConfig';
import routes from '../../../config/routesConfig';

/**
 * Saga to send API request for user profile data.
 * @param data string|int
 */
function* createOrder({ data }) {
  try {
    yield put(
      globalActionCreators.startFetching(
        'Creating your order, please wait a moment.'
      )
    );

    // Make request for user profile.
    const response = yield call(apiCreateOrder, data);

    // console.log(response);
    const { success, purchase_url: purchaseUrl, order_id: orderId } = response;

    // error
    if (!success) {
      const { message } = response;
      throw new Error(message);
    }

    // clear local cart
    yield put(cartActionsCreator.clearCart(true));

    // success
    if (orderId) {
      window.router.push(routes.getOrderConfirmationUrl(orderId));
    } else if (purchaseUrl) {
      // Redirect the user to the payment page.
      window.location.replace(purchaseUrl);
    }
  } catch (error) {
    yield put(
      globalActionCreators.addNotice({
        type: noticeTypes.error,
        content: error.message,
      })
    );
    // console.log(error);
  } finally {
    yield put(globalActionCreators.endFetching());
  }
}

//
export default function* watchUserOrder() {
  yield takeLatest(customerActionTypes.CREATE_ORDER_REQUEST, createOrder);
}
