import React from 'react';

import LargeUnitSVG from '../../assets/svg/LargeUnitSVG';

//
import './LargeUnit.scss';

//
const LargeUnit = ({ keyStartNumber = 1, onClickBox, selectedItems = [] }) => {
	return (
		<LargeUnitSVG
			startingNumber={keyStartNumber}
			onClickBox={onClickBox}
			selectedItems={selectedItems}
		/>
	);
};

export default LargeUnit;
