import { getApi } from '../../services/api';
import getRandomItem from '../../utils/getRandomItem';
import pretendRestApi from '../../utils/pretendRestApi';
import { fixtureAuthorizeUser } from './login';

/**
 * verify token
 *
 * @param api ApisauceInstance
 * @param token string
 */
const doVerifyToken = async (api, token) => {
	try {
		await api.post('/jwt-auth/v1/token/validate');
		// console.log(response);

		// success
		return {
			success: true,
		};
	} catch (error) {
		return {
			success: false,
			message: error.response ? error.response.data.message : error.message,
		};
	}
};

//
const fixtureVerifyToken = async () => {
	// const files = ['loginSuccess', 'verifyTokenFailure'];
	const files = ['loginSuccess'];
	const responseFile = getRandomItem(files);

	if (responseFile === 'loginSuccess') {
		return fixtureAuthorizeUser('loginSuccess');
	}

	//
	return await pretendRestApi(() => require(`../../fixtures/user/${responseFile}.json`));
};

//
const verifyToken = (credentials) => {
	const api = getApi();
	// api is null - use fixture
	if (!api) {
		return fixtureVerifyToken();
	}

	//
	const { token } = credentials;

	// login by username & password
	return doVerifyToken(api, token);
};

export default verifyToken;
