import React, { useState, useRef } from 'react';

// API functions.
import {
  findAddressByPostcode,
  getAddressLookupCache,
} from '../../api/getAddressByPostcode';

// Components
import InputText from './InputText';
import Select from './Select';

// Style.
import './Address.scss';

/**
 * Handle address lookup and display.
 */
const Address = ({
  heading,
  onInputChange,
  setFormData,
  formData,
  line1,
  line2,
  city,
  county,
  postcode,
  country,
  hideSearchInputOnLoad = false,
  // Allow parent components to hide the address lookup. This settings is designed to be used with the "copy address" option on the add unit venue step.
  forceHideSearchInput = false,
}) => {
  // Track the mode, postcodeLookup or manual.
  const [mode, setMode] = useState('postcodeLookup');

  // Any error messages from the API.
  const [error, setError] = useState(null);

  const [isFetching, setIsFetching] = useState(false);

  // Allow user to go back to the search input after results have been found.
  const [showAddressSearchInput, setShowAddressSearchInput] = useState(
    !hideSearchInputOnLoad
  );

  // Track the postcode value from the lookup.
  const [postcodeLookupValue, setPostcodeLookupValue] = useState('');

  /**** Address API caching. ****/
  // Check if we have a postcode set already.
  const addressLookupCache = postcode ? getAddressLookupCache(postcode) : null;
  // If we have cached data, extract and format it to look like an API response, then we will
  // pass it to the setApiResults state/hook call.
  const findAddressInitial = addressLookupCache
    ? { success: true, addresses: addressLookupCache }
    : [];

  // The results returned from the API request.
  const [apiResults, setApiResults] = useState(findAddressInitial);

  // Get the select element that shows address choices. We will use this to focus on the input
  // when results are returned from the API.
  const addressSelectInput = useRef(null);

  // Called on a new search request.
  const doSearch = (e) => {
    e.preventDefault();

    if (!postcodeLookupValue) {
      return;
    }

    setIsFetching(true);

    // Do API request.
    findAddressByPostcode(postcodeLookupValue, (response) => {
      if (response.success === false) {
        setError(response.message + '. Please enter your address below.');
        setIsFetching(false);

        // Switch the manual mode.
        setMode('manual');
      } else {
        // Reset any error message.
        setError(null);
        setApiResults(response.addresses);

        // Focus on the Select element.
        // addressSelectInput.current.focus();
        let select = null;
        if (addressSelectInput.current) {
          select = addressSelectInput.current.querySelector('select');

          if (select) {
            select.focus();
          }
        }

        setIsFetching(false);
      }
    });
  };

  const showSearchInput = (e) => {
    e.preventDefault();

    setShowAddressSearchInput(true);
    setMode('postcodeLookup');
    setApiResults([]);
  };

  // Convert certain values in the address lookup results.
  const mapValues = (country) => {
    let valuesToMap = {
      England: 'UK',
    };

    if (valuesToMap[country]) {
      return valuesToMap[country];
    }

    return country;
  };

  const setChosenAddress = (e) => {
    e.preventDefault();

    setShowAddressSearchInput(false);

    // Check if we need to switch to manual entry.
    if (e.target.value === 'manual') {
      setMode('manual');
    }

    // Take the +1 index into account, otherwise we will get the wrong result.
    let value = e.target.value - 1;

    if (!apiResults[value]) {
      return;
    }

    // Extract the results we need.
    let {
      line_1,
      line_2,
      post_town,
      county: _county,
      postcode: _postcode,
      country: _country,
    } = apiResults[value];

    _country = mapValues(_country);

    // The the form values in the state help in the parent's useForm hook.
    setFormData(
      {
        // ...formData,
        [line1.name]: line_1,
        [line2.name]: line_2,
        [city.name]: post_town,
        [county.name]: _county,
        [postcode.name]: _postcode,
        [country.name]: _country,
      },
      true // Run validation on the new data.
    );
  };

  return (
    <div className="input-address">
      {heading && <h3 className="input-address__heading">{heading}</h3>}
      <div className="row ">
        <div className="col-12 col-md-6">
          <div className="input-address__lookup">
            {/* Show the input if there are no results on display. */}
            {!forceHideSearchInput &&
              showAddressSearchInput &&
              mode === 'postcodeLookup' &&
              apiResults.length === 0 && (
                <p>
                  <InputText
                    labelText="Postcode"
                    forAttribute={`postcodeLookup-${postcode.name}`}
                    name="postcodeLookup"
                    value={postcodeLookupValue}
                    onChange={(e) => setPostcodeLookupValue(e.target.value)}
                  />
                  <button
                    className="btn btn-secondary"
                    onClick={doSearch}
                    disabled={isFetching}
                  >
                    {isFetching ? 'Searching...' : 'Find address'}
                  </button>
                </p>
              )}
            {/* Display any error message from the API. */}
            {error && <p className="input-address__error">{error}</p>}
            {/* Display results. */}
            {Array.isArray(apiResults) && apiResults.length > 0 && (
              <div className="input-address__results" ref={addressSelectInput}>
                <p>
                  Please select a result from the list or{' '}
                  <a href="#0" onClick={showSearchInput}>
                    search again
                  </a>
                </p>
                <Select
                  name="apiResults"
                  onChange={setChosenAddress}
                  forAttribute="apiResults"
                  required={false}
                  options={[
                    { id: null, value: '', label: '--select--' },
                    ...apiResults.map((item, index) => ({
                      id: index + 1,
                      label:
                        item.line_1 + (item.line_2 ? ', ' + item.line_2 : ''),
                    })),
                    {
                      id: null,
                      value: 'manual',
                      label: 'Enter address manually',
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {(line1.value ||
        line2.value ||
        city.value ||
        postcode.value ||
        county.value ||
        mode === 'manual') && (
        <div className="row">
          <div className="col-12 col-md-6">
            <p>
              <InputText
                labelText="Address line 1"
                forAttribute={line1.name}
                name={line1.name}
                value={line1.value}
                onChange={onInputChange}
                errorMessage={line1.error}
                validationRules={['notEmpty']}
              />
            </p>
            <p>
              <InputText
                labelText="Address line 2"
                forAttribute={line2.name}
                name={line2.name}
                value={line2.value}
                onChange={onInputChange}
                errorMessage={line2.error}
                validationRules={[]}
                required={false}
              />
            </p>
            <p>
              <InputText
                labelText="Town/City"
                forAttribute={city.name}
                name={city.name}
                value={city.value}
                onChange={onInputChange}
                errorMessage={city.error}
                validationRules={['notEmpty']}
              />
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p>
              <InputText
                labelText="County"
                forAttribute={county.name}
                name={county.name}
                value={county.value}
                onChange={onInputChange}
                errorMessage={county.error}
                validationRules={['notEmpty']}
              />
            </p>
            <p>
              <InputText
                labelText="Postcode"
                forAttribute={postcode.name}
                name={postcode.name}
                value={postcode.value}
                onChange={onInputChange}
                errorMessage={postcode.error}
                validationRules={['notEmpty']}
              />
            </p>
            <p>
              <InputText
                labelText="Country"
                forAttribute={country.name}
                name={country.name}
                value={country.value}
                onChange={onInputChange}
                errorMessage={country.error}
                validationRules={['notEmpty']}
              />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Address;
