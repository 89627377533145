import get from 'lodash.get';

import { getApi } from '../../services/api';
import getRandomItem from '../../utils/getRandomItem';
import pretendRestApi from '../../utils/pretendRestApi';
import decodeHTMLEntity from '../../utils/decodeHTMLEntity';

// login by username & password
const doLogin = async (api, username, password) => {
	try {
		const response = await api.post('/jwt-auth/v1/token', { username, password });
		//console.log(response);
		let venues = get(response, 'data.customer.venues', []);
		if (Array.isArray(venues) && venues.length > 0) {
			venues = venues.map(venue => ({
				...venue,
				name: decodeHTMLEntity(venue.name),
			}));
		}

		// success
		return {
			success: true,
			...response.data,
			customer: {
				...response.data.customer,
				venues,
			},
		};
	} catch (error) {
		return {
			success: false,
			message: error.response ? error.response.data.message : error.message,
		};
	}
};

//
export const fixtureAuthorizeUser = async file => {
	// const files = ['loginSuccess', 'loginSuccessIsAdmin', 'loginFailure1', 'loginFailure2'];
	const files = ['loginSuccessIsAdmin'];
	// const files = ['loginSuccess'];
	const responseFile = file || getRandomItem(files);

	//
	let responseData = require(`../../fixtures/user/${responseFile}.json`);

	//
	if (responseFile === 'loginSuccess') {
		responseData = {
			...responseData,
			customer: require(`../../fixtures/user/customerData.json`).data,
		};
	}

	return await pretendRestApi(() => responseData);
};

/**
 * login user
 *
 * @param credentials object
 */
const login = credentials => {
	const api = getApi();
	// api is null - use fixture
	if (!api) {
		return fixtureAuthorizeUser();
	}

	const { email: username, password } = credentials;

	// login by username & password
	return doLogin(api, username, password);
};

//
export default login;
