import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

// Style
// Bootstrap core.
import './assets/sass/bootstrap_config.scss';
// Icons
// import './assets/font/cardssafe-icons.css';
import './assets/font/style.css';

// Fonts.
import WebFont from 'webfontloader';

import App from './App';

WebFont.load({
  google: {
    families: [
      'Lato:300,300i,400,400i,700,900',
      'Open+Sans:300,300i,400,400i,600,700',
    ],
  },
});

ReactDOM.render(<App />, document.getElementById('root'));
