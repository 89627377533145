import { getApi } from '../../services/api';
import pretendRestApi from '../../utils/pretendRestApi';
import getRandomItem from '../../utils/getRandomItem';

//
const fixtureGetOrderHistory = () => {
  // const files = ['getOrderHistoryFailure', 'getOrderHistorySuccess'];
  const files = ['getOrderHistorySuccess'];
  const responseFile = getRandomItem(files);
  // console.log(responseFile);

  //
  let responseData = require(`../../fixtures/order/${responseFile}.json`);

  //
  responseData = {
    ...responseData,
    // fake order ids
    items: responseData.items.map((item) => ({
      ...item,
      id: Math.floor(Math.random() * 10000 + 1),
    })),
  };

  //
  return pretendRestApi(() => responseData, 1000);
};

//
const apiGetOrderHistory = async (api, form) => {
  try {
    const { page, customer, isAdmin } = form;

    const response = await api.get('/wc/v3/orders', {
      params: {
        page,
        customer,
        // Filter the type of orders to show based on role.
        status: isAdmin ? ['any'] : ['processing', 'completed'],
      },
    });
    // console.log(response);

    const {
      headers: { 'x-wp-totalpages': totalPage },
    } = response;

    // success
    return {
      success: true,
      totalPage,
      items: response.data,
    };
  } catch (error) {
    return {
      success: false,
      message:
        'Sorry we could not find your order history. Please contact us for help.',
    };
  }
};

//
const getOrderHistory = (form) => {
  const api = getApi();
  // api is null - use fixture
  if (!api) {
    return fixtureGetOrderHistory();
  }

  // restApi get order history
  return apiGetOrderHistory(api, form);
};

export default getOrderHistory;
