import React from 'react';

const SmallUnitSVG = ({ startingNumber = 1, onClickBox = null, selectedItems = [] }) => {
	const unitColor = '#666';

	const getKeyNumber = (number, withPrefix = true) => {
		// Add the starting number.
		number += startingNumber;

		return withPrefix && number < 10 ? '0' + number : number;
	};

	const draws = [
		'm4.125 33.556c0-2.75 2.249-5.075 4.997-5.167l70.256-2.348c2.748-0.092 4.747 2.083 4.747 4.833v15.126c0 2.75-2 5.042-4.749 5.093l-70.377 1.314c-2.749 0.051-4.874-2.157-4.874-4.907v-13.944z',
		'm4.125 59.556c0-2.75 2.25-5.031 5-5.069l70.25-0.974c2.75-0.038 4.75 2.181 4.75 4.931v15.056c0 2.75-2 5.026-4.75 5.059l-70.375 0.827c-2.75 0.032-4.875-2.191-4.875-4.941v-14.889z',
		'm4.125 86.556c0-2.75 2.25-5.04 4.999-5.089l70.252-1.253c2.749-0.049 4.749 2.161 4.749 4.911v15.5c0 2.75-2 5.023-4.75 5.051l-70.375 0.718c-2.75 0.028-4.875-2.199-4.875-4.949v-14.889z',
		'm4.125 113.56c0-2.75 2.25-5.012 5-5.027l70.25-0.377c2.75-0.015 4.75 2.223 4.75 4.973v15.319c0 2.75-1.75 5.056-4.5 5.056h-70.5c-2.75 0-5-2.306-5-5.056v-14.888z',
		'm4.125 140.56c0-2.75 2.25-5.009 5-5.019l70.25-0.268c2.75-0.01 4.75 2.231 4.75 4.981v16c0 2.75-2 4.978-4.75 4.95l-70.375-0.706c-2.75-0.027-4.875-2.3-4.875-5.05v-14.888z',
		'm4.125 170c0-2.75 2.25-4.944 4.999-4.876l70.253 1.752c2.749 0.068 4.748 2.374 4.748 5.124v15.375c0 2.75-1.998 4.916-4.746 4.813l-70.382-2.627c-2.748-0.103-4.872-2.437-4.872-5.187v-14.374z',
		'm4.125 196.38c0-2.75 2.249-4.927 4.998-4.837l70.255 2.299c2.748 0.09 4.747 2.413 4.747 5.163v15.375c0 2.75-1.999 4.927-4.747 4.837l-70.38-2.299c-2.749-0.09-4.873-2.413-4.873-5.163v-15.375z',
		'm4.125 223.75c0-2.75 2.249-4.938 4.998-4.86l70.254 1.971c2.749 0.077 4.748 2.39 4.748 5.14v15.375c0 2.75-1.999 4.923-4.747 4.829l-70.381-2.408c-2.749-0.094-4.872-2.421-4.872-5.171v-14.876z',
		'm4.125 250.12c0-2.75 2.249-4.92 4.997-4.821l70.256 2.518c2.748 0.099 4.747 2.429 4.747 5.179v15.375c0 2.75-1.998 4.896-4.744 4.768l-70.386-3.285c-2.747-0.128-4.87-2.482-4.87-5.232v-14.502z',
		'm4.125 276.44c0-2.75 2.248-4.9 4.995-4.779l70.26 3.114c2.747 0.121 4.745 2.471 4.745 5.221v16.333c0 2.75-1.994 4.837-4.737 4.639l-70.401-5.11c-2.743-0.198-4.862-2.611-4.862-5.361v-14.057z',
	];

	return (
		<svg
			className="small-unit-svg"
			width="114.62px"
			height="321.08px"
			enableBackground="new 0 0 114.625 321.083"
			version="1.1"
			viewBox="0 0 114.625 321.083"
		>
			<g fill={unitColor} stroke="#000" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2">
				<polygon points="87.625 23.375 0.631 25.625 1.605 312.34 43.438 320 88.605 319.12" />
				<polygon points="43.461 1.094 0.625 7.25 0.625 25.625 87.625 23.375 87.625 3.31" />
			</g>
			<polygon
				points="113.62 286.33 88.605 319.12 87.625 3.31 113.62 17.76"
				fill={unitColor}
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<path
				d="m113.12 260.05c0 5.967-0.492 11.195-1.437 11.679l-16.075 19.794c-1.088 0.558-2.051-4.241-2.051-10.723l0.9-247.4c0-6.482 1.051-11.225 2.138-10.6l15.008 8.732c0.943 0.542 1.517 5.817 1.517 11.785v216.73z"
				fill={unitColor}
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
			/>
			<path
				d="m83.715 23.375c0-7.556-2.008-13.456-10.925-13.425l-57.667 1.748c-7.249 0.47-10.182 4.386-10.182 11.833 0 0.214-0.018 1.774 0 1.983l78.774-2.139z"
				fill="#fff"
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
			/>
			{/* Draws */}
			{draws.map((path, index) => {
				let number = getKeyNumber(index, false);

				return (
					<path
						key={`draw=${number}`}
						d={path}
						fill={unitColor}
						stroke="#000"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						data-key={number}
						onClick={onClickBox && onClickBox.bind(this, number)}
						className={
							'small-unit-svg__box' + (selectedItems.find((item) => item.boxNumber === number) ? ' active' : '')
						}
					/>
				);
			})}
			{/* End of draws */}
			<polygon points="87.625 165.15 1.625 163.5 1.625 162 87.625 163" />
			<polygon
				points="101.12 20.332 98.125 18.795 98.125 10.559 101.12 12.192"
				fill="#662D91"
				stroke="#000"
				strokeMiterlimit="10"
			/>
			<path
				d="m79.935 33.087c0 2.489-2.052 4.586-4.579 4.685-2.522 0.098-4.564-1.833-4.564-4.313s2.042-4.577 4.564-4.684c2.527-0.106 4.579 1.825 4.579 4.312z"
				fill="#E6E6E6"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth=".5"
			/>
			<path
				d="m79.935 60.087c0 2.489-2.052 4.586-4.579 4.685-2.522 0.098-4.564-1.833-4.564-4.313s2.042-4.577 4.564-4.684c2.527-0.106 4.579 1.825 4.579 4.312z"
				fill="#E6E6E6"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth=".5"
			/>
			<path
				d="m79.935 86.207c0 2.559-2.108 4.714-4.706 4.815-2.593 0.101-4.691-1.883-4.691-4.433 0-2.549 2.099-4.705 4.691-4.814 2.597-0.109 4.706 1.875 4.706 4.432z"
				fill="#E6E6E6"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth=".5"
			/>
			<path
				d="m79.935 114.09c0 2.489-2.052 4.586-4.579 4.685-2.522 0.098-4.564-1.833-4.564-4.313s2.042-4.577 4.564-4.684c2.527-0.106 4.579 1.825 4.579 4.312z"
				fill="#E6E6E6"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth=".5"
			/>
			<path
				d="m79.935 142.25c0 2.583-2.129 4.759-4.751 4.861-2.617 0.102-4.736-1.901-4.736-4.475 0-2.573 2.119-4.749 4.736-4.86 2.622-0.111 4.751 1.892 4.751 4.474z"
				fill="#E6E6E6"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth=".5"
			/>
			<path
				d="m79.935 173.15c0 2.528-2.083 4.658-4.65 4.758-2.562 0.099-4.635-1.861-4.635-4.38 0-2.518 2.073-4.648 4.635-4.756 2.567-0.109 4.65 1.852 4.65 4.378z"
				fill="#E6E6E6"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth=".5"
			/>
			<path
				d="m79.935 200.02c0 2.523-2.087 4.65-4.657 4.75-2.565 0.1-4.643-1.858-4.643-4.373s2.077-4.64 4.643-4.749c2.57-0.107 4.657 1.85 4.657 4.372z"
				fill="#E6E6E6"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth=".5"
			/>
			<path
				d="m79.935 228.28c0 2.596-2.141 4.784-4.777 4.888-2.632 0.103-4.763-1.913-4.763-4.501 0-2.587 2.131-4.774 4.763-4.887 2.636-0.111 4.777 1.904 4.777 4.5z"
				fill="#E6E6E6"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth=".5"
			/>
			<path
				d="m79.935 254.19c0 2.544-2.099 4.688-4.683 4.79-2.579 0.102-4.667-1.874-4.667-4.411 0-2.535 2.088-4.679 4.667-4.789 2.584-0.11 4.683 1.865 4.683 4.41z"
				fill="#E6E6E6"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth=".5"
			/>
			<path
				d="m79.935 281.41c0 2.674-2.204 4.929-4.921 5.034-2.711 0.105-4.905-1.97-4.905-4.636 0-2.664 2.194-4.917 4.905-5.033 2.716-0.113 4.921 1.961 4.921 4.635z"
				fill="#E6E6E6"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth=".5"
			/>
			<rect transform="matrix(.0267 .9996 -.9996 .0267 48.346 -37.81)" x="38.76" y="5.422" width="9.66" height="1" />
			<g fontSize="5.9081">
				<text transform="translate(72.447 35.4)">{getKeyNumber(0)}</text>
				<text transform="translate(72.354 62.306)">{getKeyNumber(1)}</text>
				<text transform="translate(72.119 88.499)">{getKeyNumber(2)}</text>
				<text transform="translate(72.275 116.07)">{getKeyNumber(3)}</text>
				<text transform="translate(72.354 144.73)">{getKeyNumber(4)}</text>
				<text transform="translate(72.275 175.4)">{getKeyNumber(5)}</text>
				<text transform="translate(72.197 202.07)">{getKeyNumber(6)}</text>
				<text transform="translate(72.119 230.4)">{getKeyNumber(7)}</text>
				<text transform="translate(72.197 256.52)">{getKeyNumber(8)}</text>
				<text transform="translate(71.978 283.4)">{getKeyNumber(9)}</text>
			</g>
		</svg>
	);
};

export default SmallUnitSVG;
