import React from 'react';
import { connect } from 'react-redux';

// Actions
import { customerActionCreators } from '../../redux/actions/customerActions';

// API
import { searchCustomers as apiSearchCustomers } from '../../api/user';
import { searchVenues as apiSearchVenues } from '../../api/user';

// Common components.
import InputText from '../../components/common/InputText';
import Select from '../../components/common/Select';
import Table from '../../components/common/Table';
import TableRow from '../../components/common/TableRow';
import Button from '../../components/common/Button';
import Content from '../../containers/Content';

// Child components.
// import CustomerPreview from './CustomerPreview';

// Style.
import './FindCustomer.scss';
import { cartActionsCreator } from '../../redux/actions/cartActions';

//
class FindCustomer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			form: {
				term: '',
				type: 'name', // Default search type.
			},
			fetching: false,
			customers: [],
			venues: [],
		};

		this.handleSearchTermOnChange = this.handleSearchTermOnChange.bind(this);
		this.handleSearchTypeOnChange = this.handleSearchTypeOnChange.bind(this);
		this.doSearch = this.doSearch.bind(this);
		this.renderVenueResults = this.renderVenueResults.bind(this);
		this.renderCustomerResults = this.renderCustomerResults.bind(this);
	}

	/**
	 * Query users via the API
	 * @param {Object} queryArgs
	 */
	doSearch = async function (e) {
		e.preventDefault();

		const queryArgs = this.state.form;

		if (queryArgs.term === '') {
			alert('Please enter a search term.');
			return;
		}

		const { term, type } = queryArgs;

		// To hold return data.
		let customers = [];
		let venues = [];

		// Fetching state.
		this.setState({ fetching: true });

		// Request to API, the function depends on the type of search.
		switch (type) {
			case 'name':
				({ customers } = await apiSearchCustomers(term));
				break;

			case 'venue':
				({ venues } = await apiSearchVenues(term));
				break;

			default:
				break;
		}

		this.setState({
			fetching: false,
			customers,
			venues,
		});
	};

	// When the search term changes.
	handleSearchTermOnChange = (e) => {
		const newFormState = {
			...this.state.form,
			term: e.target.value,
		};

		this.setState({ form: newFormState });
	};

	// When the type of search changes.
	handleSearchTypeOnChange(e) {
		const newFormState = {
			...this.state.form,
			type: e.target.value,
		};

		this.setState({ form: newFormState });
	}

	// When a button in the table is clicked to load a customer profile.
	loadProfile(customer) {
		if (!customer) {
			return;
		}

		// Dispatch.
		this.props.clearCart(false);
		this.props.removeAllCouponCodes();

		// @todo check if there is a venue ID to load. There may not be if the user hasn't registered any venues yet.
		this.props.loadCustomer(customer.id, customer.venueId);
	}

	/**
	 * Render the search results when searching by venue name.
	 * @returns JSX
	 */
	renderVenueResults() {
		// Only render when "venue" is the search type.
		if (this.state.form.type !== 'venue') return null;

		// Only if there are results to display.
		if (this.state.venues.length === 0) return null;

		const customers = this.state.venues;

		return (
			<Table
				columnHeadings={['Business', 'Contract(s)', 'Contact', 'Address', '']}
			>
				{customers.map((customer, index) => (
					<TableRow
						key={`TableRow-${customer.id}-${index}`}
						cells={[
							{
								id: 'businessName',
								value: customer.business,
								className: 'business-name',
								escape,
							},
							{
								id: 'contractNumbers',
								value: customer.contract,
								className: 'contract-numbers',
							},
							{
								id: 'contact',
								value: customer.contact,
								className: 'customer-contact',
								escape,
							},
							{
								id: 'address',
								value: customer.address,
								className: 'address',
							},
							{
								id: 'load',
								value: (
									<Button
										onClick={this.loadProfile.bind(this, customer)}
										label="Load"
										className="btn btn-secondary icon-download"
									/>
								),
								className: 'load',
							},
						]}
					/>
				))}
			</Table>
		);
	}

	/**
	 * Render the search results when searching by venue name.
	 * @returns JSX
	 */
	renderCustomerResults() {
		// Only render when "venue" is the search type.
		if (this.state.form.type !== 'name') return null;

		// Only if there are results to display.
		if (this.state.customers.length === 0) return null;

		const customers = this.state.customers;

		return (
			<Table
				columnHeadings={['First name', 'Last name', 'Email', 'Venues', '']}
			>
				{customers.map((customer, index) => (
					<TableRow
						key={`TableRow-${customer.id}-${index}`}
						cells={[
							{
								id: 'firstName',
								value: customer.first_name,
								className: 'first-name',
								escape,
							},
							{
								id: 'lastName',
								value: customer.last_name,
								className: 'lastName',
							},
							{
								id: 'email',
								value: customer.email,
								className: 'customer-email',
								escape,
							},
							{
								id: 'venues',
								value:
									Array.isArray(customer.venues) && customer.venues.length > 0
										? customer.venues.join(', ')
										: 'No venues',
								className: 'venues',
							},
							{
								id: 'load',
								value: (
									<Button
										onClick={this.loadProfile.bind(this, customer)}
										label="Load"
										className="btn btn-secondary icon-download"
									/>
								),
								className: 'load',
							},
						]}
					/>
				))}
			</Table>
		);
	}

	render() {
		const { fetching } = this.state;

		return (
			<Content customClasses="find-customer">
				<div className="row">
					<div className="col-xs-12 col-md-8">
						<h3>Find customer</h3>
						<p>
							Use the search box below to locate a registered customer.{' '}
							<strong>Type the search term, select the type of search</strong>{' '}
							and <strong>click search</strong>. The "search type" control
							allows you to search the system via a customer's first/last name
							or the name of a venue.
						</p>
					</div>
					<div className="col-12">
						<form
							className="find-customer__form"
							onSubmit={this.doSearch}
							autoComplete={'off'}
						>
							<div className="find-customer__form__shell">
								<InputText
									labelText="Search term"
									forAttribute="term"
									name="term"
									value={this.state.form.term}
									onChange={this.handleSearchTermOnChange}
								/>
								<Select
									labelText="Search type"
									forAttribute="type"
									name="type"
									value={this.state.form.type}
									onChange={this.handleSearchTypeOnChange}
									options={[
										{
											id: 'name',
											value: 'name',
											name: 'Customer first/last name, email',
										},
										{ id: 'venue', value: 'venue', name: 'Venue Name' },
									]}
								/>
								<button type="submit" className="btn btn-secondary">
									Search
								</button>
							</div>
						</form>
					</div>
					<div className="col-12 find-customer__results">
						{fetching && <p className="find-customer__loading">Loading...</p>}
						{/* When we have results to show */}
						{this.renderVenueResults()}
						{this.renderCustomerResults()}
					</div>
				</div>
			</Content>
		);
	}
}

const mapStateToProps = (state) => {
	const { customer } = state.customer;
	return {
		isCustomerDataLoaded: !!customer,
	};
};

const mapDispatchToProps = {
	loadCustomer: customerActionCreators.customerDataRequest,
	removeAllCouponCodes: cartActionsCreator.removeAllCouponCodes,
	clearCart: cartActionsCreator.clearCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(FindCustomer);
