import { getApi } from '../../services/api';

// Get the cart session from the server/API.
const apiRemoveCoupon = async (api, code) => {
  try {
    const response = await api.delete(`/cocart/v1/coupon?coupon=${code}`);

    // success
    return {
      success: response.data.response,
      message: response.data.message,
    };
  } catch (error) {
    // failure
    // console.log(error.response);
    return {
      success: false,
      message: error.response ? error.response.data.message : error.message,
    };
  }
};

const removeCoupon = (code) => {
  // Get the real API call.
  const api = getApi();
  if (!api) {
    return {
      success: false,
      message: 'Api is not configured.',
    };
  }

  return apiRemoveCoupon(api, code);
};

export default removeCoupon;
