import { getApi } from '../../services/api';

//
async function apiCreateOrder(api, data) {
	try {
		const { venueId, customerId, cartItems, paymentMethod } = data;

		//
		const response = await api.post('/cardssafe-account/v1/createOrder', {
			venue_id: venueId,
			customer_id: customerId,
			line_items: cartItems,
			payment_method: paymentMethod,
		});

		return {
			success: true,
			// purchaseUrl: response.data.data.purchase_url,
			...response.data.data,
		};

		// console.log('apiCreateOrder', response);
	} catch (error) {
		// console.log('apiCreateOrder', error);
		return {
			success: false,
			message: error.response ? error.response.data.message : error.message,
		};
	}
}

//
function createOrder(data) {
	const api = getApi();
	// api is null - use fixture
	if (!api) {
		return {
			success: false,
			message: 'Api is not config',
		};
	}

	// restApi get order history
	return apiCreateOrder(api, data);
}

//
export default createOrder;
