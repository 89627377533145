import React from 'react';
import PropTypes from 'prop-types';
import './InputText.scss';

/**
 * Standard text input for forms such as user profile.
 */
const InputText = ({
  labelText,
  forAttribute,
  name,
  value = '',
  onChange,
  errorMessage,
  inlineNotice = '',
  readOnly,
  type = 'text',
  className = 'form-control',
  validationRules = [],
  conditionalValidation = [],
  required = true,
  ...rest
}) => {
  return (
    <label className="input-text" htmlFor={forAttribute}>
      <span className="label">
        {labelText} {required && <span className="required">*</span>}
      </span>

      {!readOnly && (
        <>
          <input
            {...rest}
            id={forAttribute}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            className={className}
            placeholder={labelText}
            data-validation-rules={
              validationRules.length ? JSON.stringify(validationRules) : ''
            }
            data-conditional-validation={
              conditionalValidation.length
                ? JSON.stringify(conditionalValidation)
                : ''
            }
          />
          {inlineNotice && (
            <span className="inline-notice">{inlineNotice}</span>
          )}
          {errorMessage && <span className="inline-error">{errorMessage}</span>}
        </>
      )}

      {readOnly && (
        <input
          id={forAttribute}
          name={name}
          type={type}
          defaultValue={value}
          className={className}
          readOnly
        />
      )}
    </label>
  );
};

InputText.propTypes = {
  labelText: PropTypes.string,
  validationRules: PropTypes.array,
};

export default InputText;
