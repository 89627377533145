import { take, put } from 'redux-saga/effects';
import { loginActionCreators } from '../actions/loginActions';
import { customerActionCreators } from '../actions/customerActions';
import { storeToken } from '../../services/api';

export function* watchPersist() {
  // listen to the persist/REHYDRATE action - one time only
  const { payload } = yield take('persist/REHYDRATE');

  if (!payload) {
    return;
  }

  // Get the token.
  const { token } = payload.login;

  // Login with the token.
  if (token) {
    // Store the token.
    storeToken(token);

    // login
    yield put(loginActionCreators.verifyTokenRequest({ token }));
  }

  // Load customer data
  const { customer, currentVenueId } = payload.customer;
  if (customer) {
    // The code below can be used to skip the loading of customer data from the
    // backend which can save time when coding.
    // console.log('skipped loading customer data.');
    // return;

    yield put(customerActionCreators.persistCustomerDataLoaded());

    // updating customer data
    yield put(
      customerActionCreators.customerDataRequest(
        customer.id,
        currentVenueId,
        true
      )
    );
  }
}
