import React from 'react';
import { NavLink } from 'react-router-dom';

// Route config.
import routes from '../../config/routesConfig';

// Components.
import Logo from '../../assets/svg/Logo';

// Style.
import './WelcomeBoard.scss';

/**
 * Large purple splash screen the user will see when they login or register.
 */
const WelcomeBoard = props => {
	const focusOnEmailInput = () => {
		const emailInput = document.getElementById('emailAddress');
		if (emailInput) {
			emailInput.focus();
		}
	};

	return (
		<div className="col-lg-6 d-flex justify-content-center align-items-center welcome-board ">
			<div className="welcome-board__content text-center text-lg-left">
				<div className="logo">
					<a href="https://cardssafe.com" title="Return to CardsSafe website"><Logo /></a>
				</div>
				<h1 className="welcome-board__title">Welcome To CardsSafe!</h1>
				<p>Please register or login to your account by clicking on the "sign in" or "create account" buttons below.</p>
				<div className="usp-block row">
					<div className="col-12 col-sm-4">
						<div className="usp-block__usp">
							<i className="icon-graph-up"></i>
							<p>Increase customer spend</p>
						</div>
					</div>
					<div className="col-12 col-sm-4">
						<div className="usp-block__usp">
							<i className="icon-graph-down"></i>
							<p>Reduce costs and losses</p>
						</div>
					</div>
					<div className="col-12 col-sm-4">
						<div className="usp-block__usp">
							<i className="icon-pci"></i>
							<p>Be PCI compliant</p>
						</div>
					</div>
				</div>
				<div className="welcome-board__actions">
					<NavLink className="btn btn-white" to={routes.LOGIN} onClick={focusOnEmailInput}>
						Sign in
					</NavLink>
					<NavLink className="btn btn-white" to={routes.REGISTER}>
						Create account
					</NavLink>
				</div>
			</div>
			<div className="stars">
				<span />
				<span />
				<span />
				<span />
				<span />
				<span />
			</div>
		</div>
	);
};

export default WelcomeBoard;
