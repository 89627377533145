import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

//
import rootReducer from './reducers'; // reducer
import { persistConfig } from '../config/persistConfig'; // persistConfig
import rootSaga from './sagas'; // saga
import transformDataToCamelCase from './middlewares/transformDataToCamelCase';

//
const configureStore = () => {
	/**
	 * @see https://github.com/rt2zz/redux-persist
	 */
	const persistedReducer = persistReducer(persistConfig, rootReducer);

	// saga
	const sagaMiddleware = createSagaMiddleware();

	//
	const middleware = [];
	const enhancers = [];

	// push sagaMiddleware
	middleware.push(sagaMiddleware);

	// push redux logger
	if (process.env.NODE_ENV === 'development') {
		middleware.push(logger);
	}

	// tranform data to camel case
	middleware.push(transformDataToCamelCase);

	// assemble middleware
	enhancers.push(applyMiddleware(...middleware));

	// store
	const store = createStore(persistedReducer, {}, compose(...enhancers));
	const persistor = persistStore(store);

	// activate saga
	sagaMiddleware.run(rootSaga);

	//
	return { store, persistor };
};

//
export default configureStore;