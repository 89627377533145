// Handle user account creation.

import { put, call, takeLatest } from 'redux-saga/effects';

// API
import { registerUser as apiRegisterUser } from '../../api/user';

// Redux
import {
  customerActionCreators,
  customerActionTypes,
} from '../actions/customerActions';
import { globalActionCreators } from '../actions/globalActions';
import { loginActionCreators } from '../actions/loginActions';

/**
 * Saga to send API request for user profile data.
 * @param contractId string|int
 */
function* registerUserSaga(payload) {
  try {
    // Loading state.
    yield put(globalActionCreators.startFetching());

    // Make request for user profile.
    const response = yield call(apiRegisterUser, payload);

    // const { success, message, payload: _payload } = response;
    const { success, message } = response;

    // Error.
    if (!success) {
      throw new Error(message);
    }

    // Success.
    // Update the user's data.
    // yield put(customerActionCreators.customerRegisterSuccess(_payload));
    yield put(customerActionCreators.customerRegisterSuccess());

    // Login dispatch.
    yield put(loginActionCreators.userLogin(payload.data));

    // Wait before logging in the user.
    // yield delay(3000);

    yield put(
      globalActionCreators.addSuccessNotice(
        'Your account is ready. You will now be logged in.'
      )
    );

    // yield put(
    // 	loginActionCreators.userLogin({
    // 		user: _payload.email,
    // 		password: _payload.password,
    // 	}),
    // );
    // login on register successful
  } catch (error) {
    // let message = 'Error: failed';
    const message = error.message;

    // Failure.
    // yield put(customerActionCreators.customerRegisterFailure(message));
    yield put(globalActionCreators.addErrorNotice(message));

    // console.log(error);
  } finally {
    yield put(globalActionCreators.endFetching());
  }
}

//
export default function* registerFlow() {
  yield takeLatest(
    customerActionTypes.CUSTOMER_REGISTER_REQUEST,
    registerUserSaga
  );
}
