import { getApi } from '../../services/api';

/**
 * Call the updateVenue endpoint, used for updating all venue info.
 */

//
/*
	{
  "venue_name": "",
  "customer": 1,
  "email": "",
  "phone": "",
  "default": "",
  "job_title": "",
  "business_type": "",
  "business_type_other": "",
  "business_years": "",
  "establishment_type": "",
  "establishment_type_other": "",
  "company_reg_no": "",
  "company_vat_no": "",
  "company_larger_group": "",
  "company_larger_group_name": "",
  "company_invoices": "",
  "company_invoices_name": "",
  "chain": "",
  "billing_address": {
    "line_1": "",
    "line_2": "",
    "country": "",
    "city": "",
    "state": "",
    "postcode": ""
  },
  "shipping_address": {
    "line_1": "",
    "line_2": "",
    "country": "",
    "city": "",
    "state": "",
    "postcode": ""
  },
  "key_offset": ""
} */

export const getVenueApiFields = venueData => {
	const {
		billingAddressLine1,
		billingAddressLine2,
		billingCountry,
		billingCity,
		// billingState,
		billingCounty: billingState,
		billingPostcode,
		businessStatus,
		venueAddressLine1,
		venueAddressLine2,
		venueCountry,
		venueCity,
		// venueState,
		venueCounty: venueState,
		venuePostcode,
		venueName,
		phone,
		keyLabel,
		purchaseOrderNumber,
		requirePurchaseOrderNumber,
		email,
		businessType,
		businessYears,
		establishmentType,
		companyRegNo,
		companyVatNo,
		companyLargerGroup,
		companyLargerGroupName,
		companyInvoices,
		companyInvoicesName,
	} = venueData;

	return {
		billing_address: {
			line_1: billingAddressLine1,
			line_2: billingAddressLine2,
			country: billingCountry,
			city: billingCity,
			state: billingState,
			postcode: billingPostcode,
		},
		business_status: businessStatus,
		business_type: businessType,
		business_years: businessYears,
		company_invoices: companyInvoices,
		company_invoices_name: companyInvoicesName,
		company_larger_group: companyLargerGroup,
		company_larger_group_name: companyLargerGroupName,
		company_reg_no: companyRegNo,
		company_vat_no: companyVatNo,
		email: email,
		establishment_type: establishmentType,
		key_label: keyLabel,
		venue_name: venueName,
		phone: phone,
		purchase_order_number: purchaseOrderNumber,
		require_purchase_order_number: requirePurchaseOrderNumber,
		shipping_address: {
			line_1: venueAddressLine1,
			line_2: venueAddressLine2,
			country: venueCountry,
			city: venueCity,
			state: venueState,
			postcode: venuePostcode,
		},
	};
};

const apiUpdateVenue = async (api, data) => {
	// console.log(data);
	// return {
	// 	success: true,
	// };
	const { venueId } = data;

	try {
		const response = await api.post(`/cardssafe-account/v1/updateVenue`, {
			...getVenueApiFields(data),
			// Important: Venue ID must be passed to update the post!
			id: venueId,
		});

		// console.log(response);
		// debugger;

		// success
		return {
			success: true,
			venue: response.data.data,
		};
	} catch (error) {
		return {
			success: false,
			message: error.response ? error.response.data.message : error.message,
		};
	}
};

//
const updateVenue = data => {
	const api = getApi();
	// api is null - use fixture
	if (!api) {
		return {
			success: false,
			message: 'Api is not configured.',
		};
	}
	//
	return apiUpdateVenue(api, data);
};

//
export default updateVenue;
