import { put, call, takeLatest } from 'redux-saga/effects';

// api
import { cancelContract as apiCancelContract } from '../../../api/user';

// redux
import { customerActionCreators, customerActionTypes } from '../../actions/customerActions';
import { globalActionCreators } from '../../actions/globalActions';

// config
import { noticeTypes } from '../../../config/globalConfig';

/**
 * Saga to send API request for user profile data.
 * @param contractId string|int
 */
function* cancelContract({ contractId }) {
	try {
		yield put(globalActionCreators.startFetching());

		// Make request for user profile.
		const response = yield call(apiCancelContract, contractId);
		const { success, contract } = response;

		// error
		if (!success) {
			const { message } = response;
			throw new Error(message);
		}

		// success
		yield put(customerActionCreators.cancelContractSuccess(contract));
		yield put(globalActionCreators.addSuccessNotice('Cancellation request received'));
	} catch (error) {
		yield put(globalActionCreators.addNotice({ type: noticeTypes.error, content: error.message }));
		yield put(customerActionCreators.cancelContractFailure(error));
		// console.log(error);
	} finally {
		yield put(globalActionCreators.endFetching());
	}
}

//
export default function* watchContract() {
	yield takeLatest(customerActionTypes.CANCEL_CONTRACT_REQUEST, cancelContract);
}
