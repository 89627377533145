// Round a price to two decimal places.
const roundPrice = (price) => {
  if (price === 0) return '0.00';

  if (!price) return '';

  return price.toFixed(2);
};

// Formatted total including postage
const getTotal = (
  postageCost,
  boxAndKeyPrice,
  replacementKeyPrice,
  tax,
  selectedItems,
  allReplacementKeys
) => {
  let keys = [],
    boxes = [],
    subTotal = 0,
    billableKeys = {},
    shippingIsFree = true,
    displayPostageCost = '0.00';

  // Separate keys and boxes.
  keys = selectedItems.filter((item) => {
    return item.boxOption === 'key-only';
  });

  boxes = selectedItems.filter((item) => {
    return item.boxOption === 'box-key';
  });

  // Tally how many keys are billable.
  keys.forEach((item) => {
    if (!billableKeys[item.unitId]) {
      billableKeys[item.unitId] = 1;
    } else {
      billableKeys[item.unitId] += 1;
    }
  });

  // Deduct any free keys that are available.
  allReplacementKeys.forEach((unit) => {
    let id = unit.id;

    if (billableKeys[id]) {
      billableKeys[id] -= unit.freeReplacementKeysAvailable;
    }
  });

  Object.keys(billableKeys).forEach((prop) => {
    const qty = billableKeys[prop];
    if (qty > 0) {
      subTotal = subTotal + replacementKeyPrice * qty;
      shippingIsFree = false;
    }
  });

  // Total the boxes.
  boxes.forEach((item) => {
    subTotal = subTotal + boxAndKeyPrice;
    shippingIsFree = false;
  });

  if (!shippingIsFree) {
    displayPostageCost = postageCost;
  }

  // Modify price using the shipping cost, if applicable.
  subTotal += !shippingIsFree ? postageCost : 0;

  return {
    total: roundPrice(subTotal + subTotal * tax),
    postageCost: displayPostageCost,
    billableKeys,
  };
};

//
export { roundPrice, getTotal };
