import React, { useState } from 'react';
import { connect } from 'react-redux';

// Common components.
import InputText from '../../../components/common/InputText';

// Utils.
import { getCurrentVenue } from '../../../utils/customerHelperFunctions';
import { customerActionCreators } from '../../../redux/actions/customerActions';

//
import routes from '../../../config/routesConfig';

// Step 1 - check the address details and update if required.
const CheckoutAddress = (props) => {
  const {
    venueId,
    billingAddress,
    shippingAddress,
    updateVenueAddresses,
  } = props;
  const [shipping, setShipping] = useState(shippingAddress);
  const [billing, setBilling] = useState(billingAddress);
  const [isAddressesChanged, updateIsAddressesChanged] = useState(false);

  const onAddressInputChange = (e) => {
    let val = e.target.value,
      name = e.target.name.split('-');

    // Update the state.
    if (name[0] === 'billing') {
      setBilling({
        ...billing,
        [name[1]]: val,
      });
      updateIsAddressesChanged(true);
    }

    if (name[0] === 'shipping') {
      setShipping({
        ...shipping,
        [name[1]]: val,
      });
      updateIsAddressesChanged(true);
    }
  };

  const onClickNext = () => {
    if (isAddressesChanged) {
      // addresses are changed - request update addresses
      updateVenueAddresses(
        { venueId, billingAddress: billing, shippingAddress: shipping },
        true
      );
    } else {
      // nothing change - redirect to the step 2 - payment
      props.history.push(routes.CHECKOUT_PAYMENT);
    }
  };

  return (
    <div className="checkout__address">
      <h3 className="checkout__step-heading">Step 1 of 3</h3>
      <p className="checkout__step-instructions">
        Please double-check that your invoice and venue/shipping addresses are
        correct before proceeding. You can update your addess details below if
        required.
      </p>
      <form className="row checkout__form">
        <div className="col-md-6">
          <h3 className="checkout__form__heading">Invoice address</h3>
          <p>
            <InputText
              labelText="Address Line 1"
              name="billing-line1"
              forAttribute="businessLine1"
              value={billing.line1}
              onChange={onAddressInputChange}
            />
          </p>
          <p>
            <InputText
              labelText="Address Line 2"
              name="billing-line2"
              forAttribute="businessLine2"
              value={billing.line2}
              onChange={onAddressInputChange}
            />
          </p>
          <p>
            <InputText
              labelText="Town/City"
              name="billing-city"
              forAttribute="businessCity"
              value={billing.city}
              onChange={onAddressInputChange}
            />
          </p>
          <p>
            <InputText
              labelText="Region"
              name="billing-state"
              forAttribute="businessState"
              value={billing.state}
              onChange={onAddressInputChange}
            />
          </p>
          <p>
            <InputText
              labelText="Postcode"
              name="billing-postcode"
              forAttribute="businessPostcode"
              value={billing.postcode}
              onChange={onAddressInputChange}
            />
          </p>
        </div>

        <div className="col-md-6">
          <h3 className="checkout__form__heading">Venue address</h3>
          <p>
            <InputText
              labelText="Address Line 1"
              name="shipping-line1"
              forAttribute="businessLine1"
              value={shipping.line1}
              onChange={onAddressInputChange}
            />
          </p>
          <p>
            <InputText
              labelText="Address Line 2"
              name="shipping-line2"
              forAttribute="businessLine2"
              value={shipping.line2}
              onChange={onAddressInputChange}
            />
          </p>
          <p>
            <InputText
              labelText="Town/City"
              name="shipping-city"
              forAttribute="businessCity"
              value={shipping.city}
              onChange={onAddressInputChange}
            />
          </p>
          <p>
            <InputText
              labelText="Region"
              name="shipping-state"
              forAttribute="businessState"
              value={shipping.state}
              onChange={onAddressInputChange}
            />
          </p>
          <p>
            <InputText
              labelText="Postcode"
              name="shipping-postcode"
              forAttribute="businessPostcode"
              value={shipping.postcode}
              onChange={onAddressInputChange}
            />
          </p>
        </div>
      </form>
      <div className="col-xs-12 col-sm-6 offset-sm-6 checkout__bottom-navigation">
        <button className="btn btn-secondary" onClick={onClickNext}>
          Next
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  // Get the current shipping and billing address information.
  const { id: venueId, billingAddress, shippingAddress } = getCurrentVenue(
    state
  );

  // console.log(venue);

  return {
    venueId,
    billingAddress,
    shippingAddress,
  };
};

const mapDispatchToProps = {
  updateVenueAddresses: customerActionCreators.updateVenueAddressesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddress);
