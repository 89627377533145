import dateFnsFormat from 'date-fns/format';
import { dateFormat, dateTimeFormat } from '../config/globalConfig';

// timestamp to date
export const timestampToDate = timestamp =>
	dateFnsFormat(new Date(parseInt(timestamp) * 1000), dateFormat);

// timestamp to date time
export const timestampToDateTime = timestamp =>
	dateFnsFormat(new Date(parseInt(timestamp) * 1000), dateTimeFormat);

// format date
export const formatDate = dateValue => dateFnsFormat(dateValue, dateFormat);

// format datetime
export const formatDateTime = dateValue =>
	dateFnsFormat(dateValue, dateTimeFormat);
