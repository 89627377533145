import createReducer from '../../createReducer';
import { customerActionTypes } from '../../actions/customerActions';

// Unit comment reducer
import {
  addUnitCommentSuccess,
  deleteUnitCommentSuccess,
  addGlobalCommentSuccess,
  deleteGlobalCommentSuccess,
} from './comment';

// Customer venue reducer.
import { switchVenue } from './venue';

// Contract.
import {
  cancelContractRequest,
  cancelContractSuccess,
  cancelContractFailure,
} from './contract';

// Profile view.
import {
  customerProfileUpdateRequest,
  customerProfileUpdateSuccess,
  // customerProfileUpdateFailure,
  // customerResetProfileNotice,
} from './profile';

// Venue.
import {
  addVenueRequest,
  addVenueSuccess,
  addVenueFailure,
  updateVenueAddressesSuccess,
  updateVenueSuccess,
  updateCurrentVenueId,
} from './venue';

// Register user account.
import {
  // customerRegisterRequest,
  // customerRegisterSuccess,
  customerRegisterFailure,
} from './register';

// Initial state.
export const initialState = {
  comments: [],
  customer: null, // Customer details such as name, email, phone
  venues: [], // All venue data which is arranged Venue -> Contract -> Units -> Comments
  products: [], // Product config.
  acf: [], // ACF config for form inputs.
  shipping: [], // Shipping data.
  currentVenueId: null, // The ID of the current venue which is being viewed.
  unitCommentError: null,

  // Contract
  contractError: null,

  // Profile view.
  // profileNotice: null,
  // profileWasUpdated: false,

  // venue
  venueFetching: false,
  venueError: null,
};

//
const shouldUpdateCustomerDataState = (state, payload) => {
  const { isPersist, customer } = payload;
  if (!isPersist) {
    // console.log('shouldUpdate => true');
    return true;
  }

  // fake customer_data_loaded state
  if (!customer) {
    return false;
  }

  let { currentVenueId } = state;
  // should update customer data state if the currentVenueId is null
  if (!currentVenueId) {
    return true;
  }

  // check if any fields need to update
  const fieldsToCheck = ['venues', 'customer', 'comments', 'products', 'acf'];
  const shouldUpdate = fieldsToCheck.find(
    (field) => JSON.stringify(state[field]) !== JSON.stringify(payload[field])
  );

  //
  return shouldUpdate;
};

// Called when the main payload of data is delivered.
const customerDataLoaded = (state, payload) => {
  if (!shouldUpdateCustomerDataState(state, payload)) {
    return state;
  }

  //
  const {
    venues,
    customer,
    comments,
    products,
    shipping,
    acf,
    currentVenueId: payloadCurrentVenueId,
  } = payload;

  // Load the first venue by default.
  let { currentVenueId } = state;

  // update customer data
  if (customer) {
    currentVenueId = payloadCurrentVenueId;
    // Handle the current venue ID.
    if (
      venues &&
      !venues.find((venue) => parseInt(venue.id) === currentVenueId)
    ) {
      currentVenueId = venues && venues.length > 0 ? venues[0].id : null;
    }

    //
    return {
      ...state,
      venues,
      customer,
      comments,
      products,
      shipping,
      acf,
      currentVenueId,
    };
  }

  // fix current venue id
  if (
    state.venues &&
    !state.venues.find((venue) => parseInt(venue.id) === currentVenueId)
  ) {
    currentVenueId =
      state.venues && state.venues.length > 0 ? state.venues[0].id : null;
  }

  // dispatch the action only
  return {
    ...state,
    currentVenueId,
  };
};

// Reset the UI on logout or switching user.
const resetData = () => initialState;

// Customer reducer.
export const customerReducer = createReducer(initialState, {
  // Loading and resetting data.
  [customerActionTypes.CUSTOMER_DATA_LOADED]: customerDataLoaded,
  [customerActionTypes.RESET_DATA]: resetData,

  // Unit comment.
  [customerActionTypes.ADD_UNIT_COMMENT_SUCCESS]: addUnitCommentSuccess,
  [customerActionTypes.DELETE_UNIT_COMMENT_SUCCESS]: deleteUnitCommentSuccess,

  // Global comment.
  [customerActionTypes.ADD_GLOBAL_COMMENT_SUCCESS]: addGlobalCommentSuccess,
  [customerActionTypes.DELETE_GLOBAL_COMMENT_SUCCESS]: deleteGlobalCommentSuccess,

  // Venue.
  [customerActionTypes.SWITCH_VENUE]: switchVenue,

  // Contract.
  [customerActionTypes.CANCEL_CONTRACT_REQUEST]: cancelContractRequest,
  [customerActionTypes.CANCEL_CONTRACT_SUCCESS]: cancelContractSuccess,
  [customerActionTypes.CANCEL_CONTRACT_FAILURE]: cancelContractFailure,

  // Profile.
  [customerActionTypes.CUSTOMER_PROFILE_UPDATE_REQUEST]: customerProfileUpdateRequest,
  [customerActionTypes.CUSTOMER_PROFILE_UPDATE_SUCCESS]: customerProfileUpdateSuccess,
  // [customerActionTypes.CUSTOMER_PROFILE_UPDATE_FAILURE]: customerProfileUpdateFailure,
  // [customerActionTypes.CUSTOMER_RESET_PROFILE_NOTICE]: customerResetProfileNotice,

  // Venue.
  [customerActionTypes.ADD_VENUE_REQUEST]: addVenueRequest,
  [customerActionTypes.ADD_VENUE_SUCCESS]: addVenueSuccess,
  [customerActionTypes.ADD_VENUE_FAILURE]: addVenueFailure,
  [customerActionTypes.UPDATE_VENUE_ADDRESSES_SUCCESS]: updateVenueAddressesSuccess,
  [customerActionTypes.UPDATE_VENUE_SUCCESS]: updateVenueSuccess,
  [customerActionTypes.UPDATE_CURRENT_VENUE_ID]: updateCurrentVenueId,

  // Register
  // [customerActionTypes.CUSTOMER_REGISTER_REQUEST]: customerRegisterRequest,
  // [customerActionTypes.CUSTOMER_REGISTER_SUCCESS]: customerRegisterSuccess,
  [customerActionTypes.CUSTOMER_REGISTER_FAILURE]: customerRegisterFailure,
});
