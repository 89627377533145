import { getApi } from '../../services/api';

// Get the cart session from the server/API.
const apiGetCart = async (api) => {
  try {
    // Note, it's possible to get a cart using the "cart key", which is the USER
    // ID or session from the coookie can also be used.
    // The cart key is the user ID.
    // const cartKey = 5514;
    // const endpoint = `/cocart/v1/get-cart?cart_key=${cartKey}`;

    const endpoint = `/cocart/v1/get-cart`;
    const response = await api.get(endpoint);

    // success
    return {
      success: true,
      cart: response.data,
    };
  } catch (error) {
    // failure
    // console.log(error.response);
    return {
      success: false,
      message: error.response ? error.response.data.message : error.message,
    };
  }
};

const getCart = () => {
  const api = getApi();
  if (!api) {
    return {
      success: false,
      message: 'Api is not configured.',
    };
  }

  return apiGetCart(api);
};

export default getCart;
