import React, { useState } from 'react';
import { connect } from 'react-redux';

// Common components.
import Select from '../../components/common/Select';
import ConfirmModal from '../../components/common/ConfirmModal';

// Redux.
import { customerActionCreators } from '../../redux/actions/customerActions';
import { cartActionsCreator } from '../../redux/actions/cartActions';

//
const VenueSwitcher = (props) => {
  const {
    venueList,
    switchVenue: dispatchSwitchVenue,
    currentVenueId,
    isCartHasItems,
    clearCart,
    label,
  } = props;

  // switch venue confirm box
  const [isConfirmBoxActive, setIsConfirmBoxActive] = useState(false);
  const [newVenueId, setNewVenueId] = useState(0);

  // do switch venue
  const switchVenue = (e) => {
    // close the confirm box
    setIsConfirmBoxActive(false);

    // dispatch switch venue action
    dispatchSwitchVenue(newVenueId);

    // clear cart on switching venue
    clearCart();
  };

  // on switch venue
  const onSwitchVenue = (e) => {
    // cart has no items -> do switch venue directly
    if (!isCartHasItems) {
      dispatchSwitchVenue(parseInt(e.target.value));
      return;
    }

    // cart has items -> open confirm box

    // set the new venue id temp value
    setNewVenueId(parseInt(e.target.value));

    // open the confirm box
    setIsConfirmBoxActive(true);
  };

  //
  return (
    <div className="dashboard__bar__switch venue-switcher">
      {/* Allow the user to switch venue which will load the new units*/}
      <Select
        labelText={label || 'Switch venue:'}
        options={venueList}
        value={currentVenueId}
        // onChange={switchVenue}
        onChange={onSwitchVenue}
        forAttribute={'switchVenue'}
        required={false}
      />

      {isConfirmBoxActive && (
        <ConfirmModal
          heading="Warning!"
          content={(props) => (
            <div className="content-wrap">
              <div className="icon icon-exclamation-triangle"></div>
              <div className="divider"></div>
              <h2>Are you sure you want to switch venue?</h2>
              <span>
                If you switch venue the items in your basket will be lost - are
                you sure you want to do this?
              </span>
            </div>
          )}
          okText="Yes, I'm sure"
          onConfirm={switchVenue}
          onCancel={() => setIsConfirmBoxActive(false)}
        />
      )}
    </div>
  );
};

//
const mapStateToProps = (state) => {
  const { currentVenueId } = state.customer;
  const { items: cartItems } = state.cart;

  return {
    currentVenueId,
    isCartHasItems: Object.keys(cartItems).length > 0,
  };
};

//
const mapDispatchToProps = {
  switchVenue: customerActionCreators.switchVenue,
  clearCart: cartActionsCreator.clearCart,
};

//
export default connect(mapStateToProps, mapDispatchToProps)(VenueSwitcher);
