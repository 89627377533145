import React from 'react';

// Common components.
import InputText from '../../components/common/InputText';
import Select from '../../components/common/Select';
import Button from '../../components/common/Button';
import EstablishmentType from '../../components/common/EstablishmentType';
import RadioButtons from '../../components/common/RadioButtons';
import Address from '../../components/common/Address';
import ErrorMessageBlock from '../../components/common/ErrorMessageBlock';

// Helper functions.
import { getHospitalityOptions } from '../../utils/formHelperFunctions';

// Custom hooks.
import useForm from '../../hooks/useForm';
import venueFormConfig from './venueFormConfig';

/**
 * The venue edit form.
 */
const VenueForm = (props) => {
	const {
		fetching,
		isAdmin,
		venueId,
		name: venueName,
		businessYears,
		businessStatus,
		billingAddress,
		businessType,
		shippingAddress,
		email,
		establishmentType,
		phone,
		updateVenue,
		yearsInBusinessOptions, // Config for <Select>
		// chainOptions, // Config for <Select>
		businessTypeOptions,
		businessIndustry, // Config for BusinessIndustry component.
		companyInvoices,
		companyInvoicesName,
		companyLargerGroup,
		companyLargerGroupName,
		companyRegNo,
		companyVatNo,
		requirePurchaseOrderNumber,
		purchaseOrderNumber,
		keyOffset,
		keyLabel,
	} = props;

	const { unitCustomPrice } = props;

	// These values are required for form validation config'.
	const hospitalityOptions = getHospitalityOptions(businessIndustry);

	// Add dynamic settings to the form config object. Values which are configured in WP and passed via the REST API are added here.
	if (venueFormConfig.businessType.depends.establishmentType) {
		venueFormConfig.businessType.depends.establishmentType = hospitalityOptions;
	}

	/** Setup the custom hook by passing the data. */
	const {
		formData,
		setFormData,
		formIsPristine,
		inlineErrorMessages,
		onInputChange,
		onFormSubmit,
	} = useForm(
		// Initial values for the useForm hook.
		{
			venueId,
			venueName,
			businessYears: businessYears ? businessYears : '',
			businessType: businessType || '',
			businessStatus: businessStatus
				? businessStatus
				: companyRegNo
				? 'Registered Company'
				: '',
			billingAddressLine1: billingAddress ? billingAddress.line1 : '',
			billingAddressLine2: billingAddress ? billingAddress.line2 : '',
			billingCity: billingAddress ? billingAddress.city : '',
			billingCounty: billingAddress ? billingAddress.state : '',
			billingPostcode: billingAddress ? billingAddress.postcode : '',
			billingCountry: billingAddress ? billingAddress.country : '',
			venueAddressLine1: shippingAddress ? shippingAddress.line1 : '',
			venueAddressLine2: shippingAddress ? shippingAddress.line2 : '',
			venueCity: shippingAddress ? shippingAddress.city : '',
			venueCounty: shippingAddress ? shippingAddress.state : '',
			venuePostcode: shippingAddress ? shippingAddress.postcode : '',
			venueCountry: shippingAddress ? shippingAddress.country : '',
			email,
			establishmentType: establishmentType || '',
			phone,
			companyInvoices,
			companyInvoicesName,
			companyLargerGroup,
			companyLargerGroupName,
			companyRegNo,
			companyVatNo,
			requirePurchaseOrderNumber,
			purchaseOrderNumber: purchaseOrderNumber || '',
			keyOffset,
			keyLabel,
		},
		// The action called when the form is ready to be submit.
		updateVenue,
		// The form config.
		venueFormConfig
	);

	return (
		<>
			<form onSubmit={onFormSubmit}>
				<div className="form-section">
					<div className="row">
						<div className="col-12 col-md-6">
							<p>
								<InputText
									labelText="Venue name"
									forAttribute="venueName"
									name="venueName"
									value={formData.venueName}
									onChange={onInputChange}
									errorMessage={
										inlineErrorMessages.venueName
											? inlineErrorMessages.venueName.message
											: ''
									}
									validationRules={['venueName', 'notEmpty']}
								/>
							</p>
							<p>
								<InputText
									labelText="Key label"
									forAttribute="keyLabel"
									name="keyLabel"
									value={formData.keyLabel}
									onChange={onInputChange}
									errorMessage={
										inlineErrorMessages.keyLabel
											? inlineErrorMessages.keyLabel.message
											: ''
									}
									validationRules={['keyLabel', 'notEmpty']}
									disabled={!isAdmin}
								/>
							</p>
							<p>
								<InputText
									labelText="Venue phone number:"
									forAttribute="phone"
									name="phone"
									value={formData.phone}
									onChange={onInputChange}
									errorMessage={
										inlineErrorMessages.phone
											? inlineErrorMessages.phone.message
											: ''
									}
									type="tel"
									validationRules={['phoneNumber']}
								/>
							</p>
							<p>
								<InputText
									labelText="Venue email address:"
									forAttribute="email"
									name="email"
									value={formData.email}
									onChange={onInputChange}
									errorMessage={
										inlineErrorMessages.email
											? inlineErrorMessages.email.message
											: ''
									}
									validationRules={['emailAddress']}
								/>
							</p>
							<p>
								<Select
									labelText="Years in business:"
									forAttribute="businessYears"
									name="businessYears"
									value={formData.businessYears}
									onChange={onInputChange}
									options={yearsInBusinessOptions}
									errorMessage={
										inlineErrorMessages.businessYears
											? inlineErrorMessages.businessYears.message
											: ''
									}
								/>
							</p>
						</div>
						<div className="col-12 col-md-6">
							{/* Business industry*/}
							<EstablishmentType
								labelText={'Business industry'}
								forAttribute="establishmentType"
								name="establishmentType"
								value={formData.establishmentType}
								onChange={onInputChange}
								errorMessage={
									inlineErrorMessages.establishmentType
										? inlineErrorMessages.establishmentType.message
										: ''
								}
								options={businessIndustry}
								validationRules={['notEmpty']}
							/>
							{/* The type of business: Tenanted or leased. Only show to the user if the 
							establishment type is in the hospitality industry. */}
							{hospitalityOptions.includes(formData.establishmentType) && (
								<p>
									<Select
										labelText="Business type:"
										forAttribute="businessType"
										name="businessType"
										value={formData.businessType}
										onChange={onInputChange}
										options={businessTypeOptions}
										errorMessage={
											inlineErrorMessages.businessType
												? inlineErrorMessages.businessType.message
												: ''
										}
										validationRules={['notEmpty']}
										conditionalValidation={{
											depend: establishmentType,
											expected: hospitalityOptions,
										}}
									/>
								</p>
							)}

							<RadioButtons
								label={
									<>
										Is &ldquo;<strong>{formData.venueName}</strong>&rdquo; part
										of a larger group?
									</>
								}
								forAttribute="companyLargerGroup"
								name="companyLargerGroup"
								value={formData.companyLargerGroup}
								onChange={onInputChange}
								errorMessage={
									inlineErrorMessages.companyLargerGroup
										? inlineErrorMessages.companyLargerGroup.message
										: ''
								}
								buttons={[
									{ value: '0', label: 'No' },
									{
										value: '1',
										label: 'Yes',
									},
								]}
							/>
							{formData.companyLargerGroup === '1' && (
								<p>
									<InputText
										labelText="Chain"
										forAttribute="companyLargerGroupName"
										name="companyLargerGroupName"
										value={formData.companyLargerGroupName}
										onChange={onInputChange}
										errorMessage={
											inlineErrorMessages.companyLargerGroupName
												? inlineErrorMessages.companyLargerGroupName.message
												: ''
										}
										validationRules={['notEmpty']}
										conditionalValidation={{
											depend: 'companyLargerGroup',
											expected: 'yes',
										}}
									/>
								</p>
							)}
						</div>
					</div>
				</div>
				<div className="form-section">
					<div className="row">
						<div className="col-12 col-md-6">
							<RadioButtons
								label="What is your trading status?"
								forAttribute="businessStatus"
								name="businessStatus"
								value={formData.businessStatus}
								onChange={onInputChange}
								errorMessage={
									inlineErrorMessages.businessStatus
										? inlineErrorMessages.businessStatus.message
										: ''
								}
								buttons={[
									{ value: 'Sole trader', label: 'I am a sole trader' },
									{
										value: 'Registered Company',
										label: 'This is a registered company',
									},
								]}
							/>
							{(formData.businessStatus === 'Registered Company' ||
								formData.companyRegNo) && (
								<p>
									<InputText
										labelText="Company number:"
										forAttribute="companyRegNo"
										name="companyRegNo"
										value={formData.companyRegNo}
										onChange={onInputChange}
										errorMessage={
											inlineErrorMessages.companyRegNo
												? inlineErrorMessages.companyRegNo.message
												: ''
										}
										validationRules={['notEmpty']}
										conditionalValidation={{
											depend: 'businessStatus',
											expected: 'Registered Company',
										}}
									/>
								</p>
							)}
						</div>
						<div className="col-12 col-md-6">
							<p>
								<InputText
									labelText="VAT number (if applicable)"
									forAttribute="companyVatNo"
									name="companyVatNo"
									value={formData.companyVatNo}
									onChange={onInputChange}
									required={false}
									errorMessage={
										inlineErrorMessages.companyVatNo
											? inlineErrorMessages.companyVatNo.message
											: ''
									}
								/>
							</p>
						</div>
						<div className="col-12 col-md-6">
							<RadioButtons
								label={
									<>
										Is &ldquo;<strong>{formData.venueName}</strong>&rdquo;
										responsible for paying CardsSafe invoices?
									</>
								}
								forAttribute="companyInvoices"
								name="companyInvoices"
								value={formData.companyInvoices}
								onChange={onInputChange}
								errorMessage={
									inlineErrorMessages.companyInvoices
										? inlineErrorMessages.companyInvoices.message
										: ''
								}
								buttons={[
									{ value: '0', label: 'No' },
									{
										value: '1',
										label: 'Yes',
									},
								]}
							/>
							{formData.companyInvoices === '0' && (
								<p>
									<InputText
										labelText="Name of the company who is responsible for paying invoices"
										forAttribute="companyInvoicesName"
										name="companyInvoicesName"
										value={formData.companyInvoicesName}
										onChange={onInputChange}
										errorMessage={
											inlineErrorMessages.companyInvoicesName
												? inlineErrorMessages.companyInvoicesName.message
												: ''
										}
										validationRules={['notEmpty']}
										conditionalValidation={{
											depend: 'companyInvoices',
											expected: '0',
										}}
									/>
								</p>
							)}
						</div>
						<div className="col-12 col-md-6">
							<RadioButtons
								label="Do you require a purchase order number?"
								forAttribute="requirePurchaseOrderNumber"
								name="requirePurchaseOrderNumber"
								value={formData.requirePurchaseOrderNumber}
								onChange={onInputChange}
								errorMessage={
									inlineErrorMessages.requirePurchaseOrderNumber
										? inlineErrorMessages.requirePurchaseOrderNumber.message
										: ''
								}
								buttons={[
									{ value: '0', label: 'No' },
									{
										value: '1',
										label: 'Yes',
									},
								]}
							/>
							{formData.requirePurchaseOrderNumber === '1' && (
								<p>
									<InputText
										labelText="Purchase order number"
										forAttribute="purchaseOrderNumber"
										name="purchaseOrderNumber"
										value={formData.purchaseOrderNumber}
										onChange={onInputChange}
										errorMessage={
											inlineErrorMessages.purchaseOrderNumber
												? inlineErrorMessages.purchaseOrderNumber.message
												: ''
										}
										validationRules={['notEmpty']}
										conditionalValidation={{
											depend: 'requirePurchaseOrderNumber',
											expected: '1',
										}}
									/>
								</p>
							)}
						</div>
					</div>
				</div>
				<div className="form-section">
					{/* Billing address */}
					<Address
						heading="Invoice address"
						line1={{
							name: 'billingAddressLine1',
							value: formData.billingAddressLine1,
							error: inlineErrorMessages.billingAddressLine1
								? inlineErrorMessages.billingAddressLine1.message
								: '',
						}}
						line2={{
							name: 'billingAddressLine2',
							value: formData.billingAddressLine2,
							error: inlineErrorMessages.billingAddressLine2
								? inlineErrorMessages.billingAddressLine2.message
								: '',
						}}
						city={{
							name: 'billingCity',
							value: formData.billingCity,
							error: inlineErrorMessages.billingCity
								? inlineErrorMessages.billingCity.message
								: '',
						}}
						county={{
							name: 'billingCounty',
							value: formData.billingCounty,
							error: inlineErrorMessages.billingCounty
								? inlineErrorMessages.billingCounty.message
								: '',
						}}
						postcode={{
							name: 'billingPostcode',
							value: formData.billingPostcode,
							error: inlineErrorMessages.billingPostcode
								? inlineErrorMessages.billingPostcode.message
								: '',
						}}
						country={{
							name: 'billingCountry',
							value: formData.billingCountry,
							error: inlineErrorMessages.billingCountry
								? inlineErrorMessages.billingCountry.message
								: '',
						}}
						onInputChange={onInputChange}
						setFormData={setFormData}
						formData={formData}
					/>
				</div>

				<div className="form-section">
					{/* Address of the venue */}
					<Address
						heading="Venue address"
						line1={{
							name: 'venueAddressLine1',
							value: formData.venueAddressLine1,
							error: inlineErrorMessages.venueAddressLine1
								? inlineErrorMessages.venueAddressLine1.message
								: '',
						}}
						line2={{
							name: 'venueAddressLine2',
							value: formData.venueAddressLine2,
							error: inlineErrorMessages.venueAddressLine2
								? inlineErrorMessages.venueAddressLine2.message
								: '',
						}}
						city={{
							name: 'venueCity',
							value: formData.venueCity,
							error: inlineErrorMessages.venueCity
								? inlineErrorMessages.venueCity.message
								: '',
						}}
						county={{
							name: 'venueCounty',
							value: formData.venueCounty,
							error: inlineErrorMessages.venueCounty
								? inlineErrorMessages.venueCounty.message
								: '',
						}}
						postcode={{
							name: 'venuePostcode',
							value: formData.venuePostcode,
							error: inlineErrorMessages.venuePostcode
								? inlineErrorMessages.venuePostcode.message
								: '',
						}}
						country={{
							name: 'venueCountry',
							value: formData.venueCountry,
							error: inlineErrorMessages.venueCountry
								? inlineErrorMessages.venueCountry.message
								: '',
						}}
						onInputChange={onInputChange}
						setFormData={setFormData}
						formData={formData}
					/>
				</div>
				{!!formData.keyOffset && (
					<div className="form-section">
						<div className="row">
							<div className="col-12 col-md-6">
								<p className="label">Custom key starting number</p>
								<p>
									If this venue's keys start at any number other than "1", you
									will see this displayed. This cannot be changed. Any future
									units added to this venue will automatically be assigned
									sequential keys and incur a £{unitCustomPrice} sign-up fee per
									unit.
								</p>
							</div>
							<div className="col-12 col-md-6">
								<p>
									<InputText
										labelText="Your starting key"
										forAttribute="keyOffset"
										name="keyOffset"
										value={formData.keyOffset}
										validationRules={[]}
										disabled={true}
										required={false}
									/>
								</p>
							</div>
						</div>
					</div>
				)}
				<Button
					className="btn btn-secondary"
					type="submit"
					disabled={fetching || formIsPristine}
					label="Save"
				/>
				<ErrorMessageBlock inlineErrorMessages={inlineErrorMessages} />
			</form>
		</>
	);
};

export default VenueForm;
