import React from 'react';

const LargeUnitSVG = ({ startingNumber = 1, onClickBox = null, selectedItems = [] }) => {
	const unitColor = '#666';

	const getKeyNumber = (number, withPrefix = true) => {
		// Add the starting number.
		number += startingNumber;

		return withPrefix && number < 10 ? '0' + number : number;
	};

	const draws = [
		'm5.812 49.001c0-2.75 2.232-5.075 4.98-5.167l115.89-3.855c2.748-0.092 5.13 2.083 5.13 4.833v29.249c0 2.75-2.391 5.042-5.14 5.093l-115.81 2.157c-2.749 0.051-5.053-2.157-5.053-4.907v-27.403z',
		'm5.812 89.617c0-2.75 2.233-5.031 4.983-5.069l115.88-1.599c2.75-0.038 5.133 2.181 5.133 4.931v29.14c0 2.75-2.391 5.026-5.141 5.059l-115.81 1.358c-2.75 0.032-5.054-2.191-5.054-4.941v-28.879z',
		'm5.812 131.79c0-2.75 2.233-5.04 4.982-5.089l115.89-2.058c2.749-0.049 5.132 2.161 5.132 4.911v29.834c0 2.75-2.391 5.023-5.141 5.051l-115.81 1.178c-2.75 0.028-5.054-2.199-5.054-4.949v-28.878z',
		'm5.812 173.97c0-2.75 2.233-5.012 4.983-5.027l115.88-0.62c2.75-0.015 5.133 2.223 5.133 4.973v29.552c0 2.75-2.531 4.924-5.281 4.924h-115.75c-2.75 0-4.967-2.174-4.967-4.924v-28.878z',
		'm5.812 216.15c0-2.75 2.233-5.009 4.983-5.019l115.88-0.44c2.75-0.01 5.133 2.231 5.133 4.981v30.615c0 2.75-2.391 4.978-5.141 4.95l-115.81-1.159c-2.75-0.027-5.054-2.3-5.054-5.05v-28.878z',
		'm5.812 262.14c0-2.75 2.233-4.944 4.982-4.876l115.89 2.876c2.749 0.068 5.131 2.374 5.131 5.124v29.639c0 2.75-2.389 4.916-5.137 4.814l-115.81-4.314c-2.748-0.102-5.051-2.436-5.051-5.186v-28.077z',
		'm5.812 303.34c0-2.75 2.232-4.927 4.981-4.837l115.89 3.774c2.748 0.09 5.13 2.413 5.13 5.163v29.639c0 2.75-2.39 4.927-5.138 4.837l-115.81-3.774c-2.749-0.09-5.052-2.413-5.052-5.163v-29.639z',
		'm5.812 346.11c0-2.75 2.232-4.937 4.981-4.86l115.89 3.235c2.749 0.077 5.131 2.39 5.131 5.14v29.639c0 2.75-2.39 4.923-5.138 4.829l-115.81-3.954c-2.748-0.094-5.051-2.421-5.051-5.171v-28.858z',
		'm5.812 387.31c0-2.75 2.232-4.92 4.98-4.821l115.89 4.134c2.748 0.099 5.13 2.429 5.13 5.179v29.64c0 2.75-2.389 4.896-5.135 4.768l-115.82-5.394c-2.747-0.128-5.049-2.482-5.049-5.232v-28.274z',
		'm5.812 428.42c0-2.75 2.231-4.9 4.979-4.779l115.89 5.113c2.747 0.121 5.128 2.471 5.128 5.221v31.136c0 2.75-2.385 4.838-5.128 4.639l-115.83-8.39c-2.743-0.198-5.041-2.611-5.041-5.361v-27.579z',
	];

	return (
		<svg
			className="large-unit-svg"
			width="178.31px"
			height="500.34px"
			enableBackground="new 0 0 178.313 500.337"
			viewBox="0 0 178.313 500.337"
		>
			<g fill={unitColor} stroke="#000" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2">
				<polygon points="136.22 35.907 0.322 39.423 1.843 487.31 67.191 499.27 137.75 497.91" />
				<polygon points="67.275 1.101 0.312 10.718 0.312 39.423 136.31 35.907 136.31 4.563" />
			</g>

			<polygon
				points="177.31 446.68 137.99 497.91 136.58 4.563 177.31 27.136"
				fill={unitColor}
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<path
				d="m175.81 405.63c0 9.32-0.648 17.488-2.123 18.243l-25.053 30.921c-1.699 0.871-3.174-6.625-3.174-16.75l1.422-386.47c0-10.125 1.648-17.535 3.347-16.559l23.328 13.641c1.474 0.847 2.253 9.087 2.253 18.409v338.56z"
				fill={unitColor}
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
			/>
			<path
				d="m130.11 35.907c0-11.803-3.136-21.02-17.065-20.972l-90.083 2.729c-11.323 0.735-15.906 6.853-15.906 18.487 0 0.334-0.027 2.772 0 3.098l123.05-3.342z"
				fill="#fff"
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
			/>
			{/* Draws */}
			{draws.map((path, index) => {
				let number = getKeyNumber(index, false);

				return (
					<path
						key={`draw=${number}`}
						d={path}
						fill={unitColor}
						stroke="#000"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						data-key={number}
						onClick={onClickBox && onClickBox.bind(this, number)}
						className={
							'small-unit-svg__box' + (selectedItems.find((item) => item.boxNumber === number) ? ' active' : '')
						}
					/>
				);
			})}
			{/* End of draws */}
			<polygon points="136.31 257.37 2.312 254.8 2.312 252.46 136.31 254.02" />
			<polygon
				points="156.81 29.154 152.81 26.753 152.81 13.887 156.81 16.438"
				fill="#662D91"
				stroke="#000"
				strokeMiterlimit="10"
			/>
			<path
				d="m123.98 57.373c0 7.388-6.09 13.613-13.592 13.905-7.485 0.292-13.547-5.438-13.547-12.801 0-7.361 6.062-13.585 13.547-13.901 7.503-0.318 13.592 5.413 13.592 12.797z"
				fill="#fff"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="1"
			/>
			<path
				d="m123.98 99.549c0 7.389-6.09 13.613-13.592 13.906-7.485 0.292-13.547-5.439-13.547-12.801 0-7.361 6.062-13.586 13.547-13.901 7.503-0.317 13.592 5.413 13.592 12.796z"
				fill="#fff"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="1"
			/>
			<path
				d="m123.98 140.52c0 7.594-6.257 13.991-13.969 14.292-7.694 0.299-13.923-5.589-13.923-13.156 0-7.564 6.229-13.963 13.923-14.288 7.713-0.325 13.969 5.564 13.969 13.152z"
				fill="#fff"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="1"
			/>
			<path
				d="m123.98 183.9c0 7.388-6.09 13.613-13.592 13.905-7.485 0.292-13.547-5.439-13.547-12.801 0-7.361 6.062-13.586 13.547-13.901 7.503-0.317 13.592 5.413 13.592 12.797z"
				fill="#fff"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="1"
			/>
			<path
				d="m123.98 228.12c0 7.667-6.318 14.124-14.101 14.429-7.769 0.302-14.059-5.644-14.059-13.282s6.29-14.096 14.059-14.425c7.783-0.328 14.101 5.618 14.101 13.278z"
				fill="#fff"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="1"
			/>
			<path
				d="m123.98 276.27c0 7.504-6.183 13.824-13.804 14.123-7.602 0.294-13.754-5.525-13.754-13 0-7.474 6.152-13.797 13.754-14.118 7.622-0.321 13.804 5.498 13.804 12.995z"
				fill="#fff"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="1"
			/>
			<path
				d="m123.98 318.23c0 7.489-6.194 13.802-13.821 14.098-7.615 0.296-13.781-5.515-13.781-12.979 0-7.463 6.166-13.771 13.781-14.096 7.627-0.318 13.821 5.49 13.821 12.977z"
				fill="#fff"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="1"
			/>
			<path
				d="m123.98 362.55c0 7.704-6.354 14.199-14.18 14.507-7.812 0.305-14.135-5.679-14.135-13.359 0-7.678 6.323-14.171 14.135-14.504 7.827-0.331 14.18 5.648 14.18 13.356z"
				fill="#fff"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="1"
			/>
			<path
				d="m123.98 402.9c0 7.55-6.229 13.916-13.897 14.217-7.656 0.301-13.854-5.562-13.854-13.092 0-7.524 6.197-13.887 13.854-14.215 7.668-0.326 13.897 5.536 13.897 13.09z"
				fill="#fff"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="1"
			/>
			<path
				d="m123.98 445.74c0 7.937-6.541 14.629-14.604 14.942-8.048 0.312-14.561-5.847-14.561-13.76 0-7.906 6.513-14.594 14.561-14.938 8.063-0.336 14.604 5.821 14.604 13.756z"
				fill="#fff"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="1"
			/>
			<rect
				transform="matrix(.0267 .9996 -.9996 .0267 74.272 -58.992)"
				x="59.884"
				y="7.862"
				width="15.09"
				height="1.562"
			/>

			<g fontSize="17.536">
				<text transform="translate(101.41 63.072)">{getKeyNumber(0)}</text>
				<text transform="translate(101.42 105.25)">{getKeyNumber(1)}</text>
				<text transform="translate(101.04 146.24)">{getKeyNumber(2)}</text>
				<text transform="translate(101.42 189.6)">{getKeyNumber(3)}</text>
				<text transform="translate(100.9 233.85)">{getKeyNumber(4)}</text>
				<text transform="translate(101.21 281.98)">{getKeyNumber(5)}</text>
				<text transform="translate(101.18 323.94)">{getKeyNumber(6)}</text>
				<text transform="translate(100.83 368.27)">{getKeyNumber(7)}</text>
				<text transform="translate(101.11 408.6)">{getKeyNumber(8)}</text>
				<text transform="translate(100.25 451.48)">{getKeyNumber(9)}</text>
			</g>
		</svg>
	);
};

export default LargeUnitSVG;
