import get from 'lodash.get';

import { getApi } from '../../services/api';
import pretendRestApi from '../../utils/pretendRestApi';

import decodeHTMLEntity from '../../utils/decodeHTMLEntity';

const fixtureGetCustomerData = () => {
  return pretendRestApi(() => require(`../../fixtures/user/customerData.json`));
};

const apiGetCustomerData = async (api, userId, isPersist) => {
  try {
    const response = await api.get(
      `/cardssafe-account/v1/getCustomer/${userId}`,
      {
        params: {
          isPersist: !!isPersist,
        },
      }
    );
    // console.log(response.data);
    const venues = get(response, 'data.data.venues', []);
    const data = {
      success: true,
      data: {
        ...response.data.data,
        venues: venues.map((venue) => ({
          ...venue,
          name: decodeHTMLEntity(venue.name),
        })),
      },
    };
    // console.log({ data });

    // success
    return data;
  } catch (error) {
    return {
      success: false,
      message: error.response ? error.response.data.message : error.message,
    };
  }
};

const getCustomerData = (userId, isPersist) => {
  const api = getApi();
  // api is null - use fixture
  if (!api) {
    return fixtureGetCustomerData();
  }

  // restApi get user profile
  return apiGetCustomerData(api, userId, isPersist);
};

export default getCustomerData;
