import React from 'react';
import { connect } from 'react-redux';

// Style.
import './AppMain.scss';

const AppMain = ({ fetching, fetchingMessage, children }) => {
  return (
    <div className={'app__main col' + (fetching ? ' fetching' : '')}>
      {children}
      <div className="loading-overlay">
        <div className="loading-overlay__icon">
          <i className="icon icon-cardssafe-key"></i>
        </div>
        {fetchingMessage && (
          <p className="loading-overlay__text">{fetchingMessage}</p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  let { fetching, fetchingMessage } = state.global;

  return {
    fetching,
    fetchingMessage,
  };
};

export default connect(mapStateToProps)(AppMain);
