import get from 'lodash.get';

import { getApi } from '../../services/api';

// Function for querying the endpoint to search for venues.
const apiSearchVenues = async (api, term) => {
  try {
    const response = await api.get('/cardssafe-account/v1/findVenues', {
      params: {
        search: term,
      },
    });

    const { data } = response.data;

    // Loop over the response and extract/format the venue data.
    const venues = data.map((venue) => {
      const {
        customer,
        billing_address: billingAddress,
        venue_name,
        venue_id: venueId,
        contracts,
      } = venue;

      // Extract the address
      // @todo should this be the shipping address instead of billing address.
      const line1 = get(billingAddress, 'line_1');
      const city = get(billingAddress, 'city');
      const state = get(billingAddress, 'state');
      const postcode = get(billingAddress, 'postcode');
      const country = get(billingAddress, 'country');

      return {
        id: customer.ID,
        business: venue_name,
        contact: customer.user_firstname + ' ' + customer.user_lastname,
        contract: contracts.join(', '),
        address: `${line1} ${city} ${state} ${postcode} ${country}`,
        venueId,
      };
    });

    return {
      venues,
    };
  } catch (error) {
    return {
      success: false,
      customers: [],
      message: error.response ? error.response.data.message : error.message,
    };
  }
};

/**
 * Call the API endpoint which searches for venues by their name.
 *
 * @param {string} term The name of a venue to search for.
 * @returns object
 */
const searchVenues = (term) => {
  const api = getApi();
  // api is null - use fixture
  if (!api) {
    console.log('Test data not defined for this endpoint.');
    return [];
  }

  // restApi search customers
  return apiSearchVenues(api, term);
};

//
export default searchVenues;
