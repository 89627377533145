/**
 * Handle user interactions such as login and updating user data.
 */

import createReducer from '../createReducer';
import { loginActionTypes } from '../actions/loginActions';

// the initial state
export const initialState = {
	userIsLoggedIn: false,
	fetching: false,
	error: null,
	user: null,
	token: null,
};

// case USER_LOGIN_REQUEST
const userLoginRequest = (state) => {
	return {
		...state,
		fetching: true,
		error: null,
	};
};

// case USER_LOGIN_SUCCESS
const userLoginSuccess = (state, { user, token }) => {
	// for verifying token - user & token will not be returned
	if (!user || !token) {
		return {
			...state,
			userIsLoggedIn: true,
			fetching: false,
		};
	}

	// for login - set user & token data
	return {
		...state,
		userIsLoggedIn: true,
		fetching: false,
		user,
		token,
	};
};

// case USER_LOGOUT
const userLoginFailure = (state, { error }) => {
	return {
		...state,
		userIsLoggedIn: false,
		fetching: false,
		error,
		user: null,
		token: null,
	};
};

// case USER_LOGOUT
const userLogout = (state) => initialState;

// token verification
const verifyTokenFailure = (state, { error }) => {
	return {
		...initialState,
		error,
	};
};

// user reducer
export const loginReducer = createReducer(initialState, {
	[loginActionTypes.USER_LOGIN_REQUEST]: userLoginRequest,
	[loginActionTypes.USER_LOGIN_SUCCESS]: userLoginSuccess,
	[loginActionTypes.USER_LOGIN_FAILURE]: userLoginFailure,
	[loginActionTypes.USER_LOGOUT]: userLogout,
	[loginActionTypes.VERIFY_TOKEN_FAILURE]: verifyTokenFailure,
});
