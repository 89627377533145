/**
 * Customer venue interactions.
 */

export const switchVenue = (state, { venueId }) => ({
	...state,
	currentVenueId: parseInt(venueId),
});

// add new venue
export const addVenueRequest = state => ({
	...state,
	venueFetching: true,
	venueError: null,
});

export const addVenueSuccess = (state, { venue: newVenue }) => {
	const { currentVenueId } = state;
	return {
		...state,
		venueFetching: false,
		currentVenueId: currentVenueId ? currentVenueId : newVenue.id, // update currentVenueId if it's null
		venues: [...state.venues, newVenue],
	};
};

export const addVenueFailure = (state, { error }) => ({
	...state,
	venueError: error,
	venueFetching: false,
});

//
export const updateVenueAddressesSuccess = (state, { venueId, billingAddress, shippingAddress }) => {
	const newVenues = state.venues.map(venue => {
		if (venue.id !== venueId) {
			return venue;
		}

		//
		return {
			...venue,
			billingAddress,
			shippingAddress,
		};
	});

	//
	return {
		...state,
		venues: newVenues,
	};
};

export const updateVenueSuccess = (state, { venue: newVenue }) => {
	return {
		...state,
		venues: state.venues.map(venue => {
			if (venue.id === newVenue.id) {
				return newVenue;
			}
			return venue;
		}),
	};
};

export const updateCurrentVenueId = (state, { currentVenueId }) => {
	return {
		...state,
		currentVenueId,
	};
};
