import React from 'react';
import { connect } from 'react-redux';

// redux
import { globalActionCreators } from '../../redux/actions/globalActions';

//
import Notice from './Notice';

//
import './NoticeModal.scss';

//
const NoticeModal = props => {
	const { notices, removeNotice } = props;

	//
	return (
		<div className="notice-modal">
			{notices.map(notice => (
				<Notice key={notice.id} {...notice} removeNotice={removeNotice} />
			))}
		</div>
	);
};

// state
const mapStateToProps = state => {
	const { notices } = state.global;
	return {
		notices,
	};
};

//
const mapDispatchToProps = {
	removeNotice: globalActionCreators.removeNotice,
};

//
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(NoticeModal);
