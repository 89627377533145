const routes = {
	DASHBOARD: '/',
	PERSONAL: '/personal',
	ORDER_PARTS: '/order-parts',
	CART: '/basket',
	LOGIN: '/sign-in',
	REGISTER: '/create-account',
	ADMIN: '/admin',
	ADD_CUSTOMER: '/admin/add-customer',
	CONTRACT: '/contract',
	FORGOT_PASSWORD: '/password-reset',

	// add unit routes
	ADD_UNIT: '/add-unit',
	ADD_UNIT_STEP_1: '/add-unit',
	ADD_UNIT_STEP_2: '/add-unit/select-venue',
	ADD_UNIT_STEP_3: '/add-unit/step-3',
	ADD_UNIT_KEY: '/add-unit/key',
	ADD_UNIT_VENUE: '/add-unit/add-venue',
	ADD_UNIT_VENUE_STEP_1: '/add-unit/add-venue',
	ADD_UNIT_VENUE_STEP_2: '/add-unit/add-venue/step-2',

	// venue
	VENUE: '/venue',
	getEditVenueUrl: venueId => `/venue/${venueId}`,

	// order history
	ORDER_HISTORY: '/order-history',
	ORDER_HISTORY_PAGE: '/order-history/page/:pageIndex',
	getOrderHistoryPaginationUrl: page => `/order-history/page/${page}`,

	// checkout
	CHECKOUT: '/checkout',
	CHECKOUT_PAYMENT: '/checkout/payment',
	CHECKOUT_ORDER_CONFIRMATION: '/checkout/order-confirmation/:order_id',
	CHECKOUT_ORDER_CONFIRMATION_SUCCESS: '/checkout/order-confirmation/success',
	CHECKOUT_ORDER_CONFIRMATION_FAILURE: '/checkout/order-confirmation/failure',
	CHECKOUT_ORDER_CONFIRMATION_CANCELLED: '/checkout/order-confirmation/cancelled',
	getOrderConfirmationUrl: orderId => `/checkout/order-confirmation/${orderId}`,
};

//
export default routes;
