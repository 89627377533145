// add unit comment
export const addUnitCommentSuccess = (state, { unitId, comment }) => {
	const { venues } = state;

	// find the comment owner {venueId, contractId, unitId}
	const commentOwner = venues.reduce((commentOwner, venue) => {
		const contract = venue.contracts.find(contract => contract.units.find(unit => unit.id === unitId));

		//
		if (contract) {
			commentOwner = {
				...commentOwner,
				unitId,
				contractId: contract.id,
				venueId: venue.id,
			};
		}

		//
		return commentOwner;
	}, {});

	//
	const newVenues = venues.map(venue => {
		// the unit is NOT belong to this venue
		if (venue.id !== commentOwner.venueId) {
			return venue;
		}

		//
		const newContracts = venue.contracts.map(contract => {
			if (contract.id !== commentOwner.contractId) {
				return contract;
			}

			const newUnits = contract.units.map(unit => {
				if (unit.id !== commentOwner.unitId) {
					return unit;
				}

				// update unit comments
				unit.comments.push(comment);

				//
				return unit;
			});

			// update contract
			return {
				...contract,
				units: newUnits,
			};
		});

		// update venue
		return {
			...venue,
			contracts: newContracts,
		};
	});

	// update state
	return {
		...state,
		venues: newVenues,
		unitCommentError: null,
	};
};

// delete comment success
export const deleteUnitCommentSuccess = (state, { commentId }) => {
	const { venues } = state;

	// find the comment owner {venueId, contractId, unitId}
	const commentOwner = venues.reduce((commentOwner, venue) => {
		venue.contracts.find(contract =>
			contract.units.find(unit => {
				const comment = unit.comments.find(comment => comment.id === commentId);
				if (comment) {
					commentOwner = {
						...commentOwner,
						unitId: unit.id,
						contractId: contract.id,
						venueId: venue.id,
					};
					return true;
				}
				return false;
			}),
		);

		//
		return commentOwner;
	}, {});

	//
	const newVenues = venues.map(venue => {
		// the unit is NOT belong to this venue
		if (venue.id !== commentOwner.venueId) {
			return venue;
		}

		//
		const newContracts = venue.contracts.map(contract => {
			if (contract.id !== commentOwner.contractId) {
				return contract;
			}

			const newUnits = contract.units.map(unit => {
				if (unit.id !== commentOwner.unitId) {
					return unit;
				}

				// update unit comments
				return {
					...unit,
					comments: unit.comments.filter(comment => comment.id !== commentId),
				};
			});

			// update contract
			return {
				...contract,
				units: newUnits,
			};
		});

		// update venue
		return {
			...venue,
			contracts: newContracts,
		};
	});

	// update state
	return {
		...state,
		venues: newVenues,
		unitCommentError: null,
	};
};

// add global comment
export const addGlobalCommentSuccess = (state, { comment }) => {
	return {
		...state,
		comments: [...state.comments, comment],
	};
};

// delete global comment
export const deleteGlobalCommentSuccess = (state, { commentId }) => {
	return {
		...state,
		comments: state.comments.filter(comment => comment.id !== commentId),
	};
};
