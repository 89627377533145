import React from 'react';

/**
 * Display message to the user when they routing tries to display a contract which doesn't exist.
 */
const NoContract = props => {
	return <div>Sorry, no contract was found with that number.</div>;
};

export default NoContract;
