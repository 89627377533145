import React, { useState } from 'react';

// Form inputs.
import InputText from '../../../components/common/InputText';
import RadioButtons from '../../../components/common/RadioButtons';
import Checkbox from '../../../components/common/Checkbox';
import Address from '../../../components/common/Address';
import Button from '../../../components/common/Button';

// Style.
import './UnitOptions.scss';
import ErrorMessageBlock from '../../../components/common/ErrorMessageBlock';

// The final step for adding a new venue. This is where the user will select the
// business address, billing details (such as a purchase order number and VAT
// number) and also a key key offset number. A key offset allows the user to
// specify that there first unit, and therefore all subsiquent units, should
// start at a number other than 1. This comes with an additional cost to the user.
const UnitOptions = (props) => {
	const {
		copyVenueAddressToBilling,
		formData,
		inlineErrorMessages,
		onInputChange,
		setFormData,
		venueFetching,
	} = props;

	// Track the checkbox for copying the address.
	const [copyBillingAddress, setCopyBillingAddress] = useState(false);

	// Called when the "copy billing address" checkbox is interacted with.
	const maybeCopyVenueAddressToBilling = (e) => {
		if (e.target.checked) {
			copyVenueAddressToBilling();
		}

		// Toggle the state which remembers if the copy checkbox is active.
		setCopyBillingAddress(e.target.checked);
	};

	return (
		<div className="unit-options">
			<h1>Step 2 of 2</h1>
			<p>Tell us about your invoice preferences</p>

			<div className="form-section">
				<div className="row">
					<div className="col-12 col-md-6">
						<RadioButtons
							label="What is your trading status?"
							forAttribute="businessStatus"
							name="businessStatus"
							value={formData.businessStatus}
							onChange={onInputChange}
							errorMessage={
								inlineErrorMessages.businessStatus
									? inlineErrorMessages.businessStatus.message
									: ''
							}
							validationRules={['notEmpty']}
							buttons={[
								{ value: 'Sole trader', label: 'I am a sole trader' },
								{
									value: 'Registered Company',
									label: 'This is a registered company',
								},
							]}
						/>
						{(formData.businessStatus === 'Registered Company' ||
							formData.companyRegNo) && (
							<p>
								<InputText
									labelText="Company number:"
									forAttribute="companyRegNo"
									name="companyRegNo"
									value={formData.companyRegNo}
									onChange={onInputChange}
									errorMessage={
										inlineErrorMessages.companyRegNo
											? inlineErrorMessages.companyRegNo.message
											: ''
									}
									validationRules={['notEmpty']}
									conditionalValidation={{
										depend: 'businessStatus',
										expected: 'Registered Company',
									}}
								/>
							</p>
						)}
					</div>
					<div className="col-12 col-md-6">
						<p>
							<InputText
								labelText="VAT number (if applicable)"
								forAttribute="companyVatNo"
								name="companyVatNo"
								value={formData.companyVatNo}
								onChange={onInputChange}
								errorMessage={
									inlineErrorMessages.companyVatNo
										? inlineErrorMessages.companyVatNo.message
										: ''
								}
								validationRules={['vatNumber']}
								required={false}
							/>
						</p>
					</div>
					<div className="col-12 col-md-6">
						<RadioButtons
							label="Do you require a purchase order number?"
							forAttribute="requirePurchaseOrderNumber"
							name="requirePurchaseOrderNumber"
							value={formData.requirePurchaseOrderNumber}
							onChange={onInputChange}
							errorMessage={
								inlineErrorMessages.requirePurchaseOrderNumber
									? inlineErrorMessages.requirePurchaseOrderNumber.message
									: ''
							}
							validationRules={['notEmpty']}
							buttons={[
								{ value: '0', label: 'No' },
								{
									value: '1',
									label: 'Yes',
								},
							]}
						/>
						{formData.requirePurchaseOrderNumber === '1' && (
							<p>
								<InputText
									labelText="Purchase order number"
									forAttribute="purchaseOrderNumber"
									name="purchaseOrderNumber"
									value={formData.purchaseOrderNumber}
									onChange={onInputChange}
									errorMessage={
										inlineErrorMessages.purchaseOrderNumber
											? inlineErrorMessages.purchaseOrderNumber.message
											: ''
									}
									validationRules={['notEmpty']}
									conditionalValidation={{
										depend: 'requirePurchaseOrderNumber',
										expected: 'yes',
									}}
								/>
							</p>
						)}
					</div>
					<div className="col-12 col-md-6">
						<RadioButtons
							label={
								<>
									Is &ldquo;{formData.name}&rdquo; responsible for paying
									CardsSafe invoices?
								</>
							}
							forAttribute="companyInvoices"
							name="companyInvoices"
							value={formData.companyInvoices}
							onChange={onInputChange}
							errorMessage={
								inlineErrorMessages.companyInvoices
									? inlineErrorMessages.companyInvoices.message
									: ''
							}
							validationRules={['notEmpty']}
							buttons={[
								{ value: '0', label: 'No' },
								{
									value: '1',
									label: 'Yes',
								},
							]}
						/>
						{formData.companyInvoices === '0' && (
							<p>
								<InputText
									labelText="Name of the company who is responsible for paying invoices"
									forAttribute="companyInvoicesName"
									name="companyInvoicesName"
									value={formData.companyInvoicesName}
									onChange={onInputChange}
									errorMessage={
										inlineErrorMessages.companyInvoicesName
											? inlineErrorMessages.companyInvoicesName.message
											: ''
									}
									validationRules={['notEmpty']}
									conditionalValidation={{
										depend: 'companyInvoices',
										expected: '0',
									}}
								/>
							</p>
						)}
					</div>
				</div>
			</div>

			<div className="form-section">
				{/*<h3>Billing address</h3>*/}
				{/* Allow user to copy venue address */}

				<Checkbox
					labelText="Copy venue address"
					forAttribute="copyBillingAddress"
					onChange={maybeCopyVenueAddressToBilling}
					checked={copyBillingAddress}
				/>
				{/* Billing address */}
				<Address
					heading="Invoice address"
					line1={{
						name: 'billingAddressLine1',
						value: formData.billingAddressLine1,
						error: inlineErrorMessages.billingAddressLine1
							? inlineErrorMessages.billingAddressLine1.message
							: '',
					}}
					line2={{
						name: 'billingAddressLine2',
						value: formData.billingAddressLine2,
						error: inlineErrorMessages.billingAddressLine2
							? inlineErrorMessages.billingAddressLine2.message
							: '',
					}}
					city={{
						name: 'billingCity',
						value: formData.billingCity,
						error: inlineErrorMessages.billingCity
							? inlineErrorMessages.billingCity.message
							: '',
					}}
					county={{
						name: 'billingCounty',
						value: formData.billingCounty,
						error: inlineErrorMessages.billingCounty
							? inlineErrorMessages.billingCounty.message
							: '',
					}}
					postcode={{
						name: 'billingPostcode',
						value: formData.billingPostcode,
						error: inlineErrorMessages.billingPostcode
							? inlineErrorMessages.billingPostcode.message
							: '',
					}}
					country={{
						name: 'billingCountry',
						value: formData.billingCountry,
						error: inlineErrorMessages.billingCountry
							? inlineErrorMessages.billingCountry.message
							: '',
					}}
					onInputChange={onInputChange}
					setFormData={setFormData}
					formData={formData}
					forceHideSearchInput={copyBillingAddress}
				/>
			</div>

			{/* Unit key offset options */}
			<div className="form-section">
				<h3>Alternative unit starting number</h3>
				<div className="attention">
					<p>
						If you would like a different number than 1 (one) for the starting
						number, please enter your preferred starting number in the field
						below – otherwise, please press “Add new venue”. Please note, there
						is a <strong>£40 extra charge</strong> per unit for this service.
						This will also apply to any future units that are purchased at this
						venue.
					</p>
				</div>
				<Checkbox
					labelText={'Use a custom starting number'}
					inlineNotice={'+£40.00 per unit'}
					forAttribute="useKeyOffset"
					name="useKeyOffset"
					checked={formData.useKeyOffset === true}
					onChange={onInputChange}
					errorMessage={
						inlineErrorMessages.useKeyOffset
							? inlineErrorMessages.useKeyOffset.message
							: ''
					}
				/>
				{formData.useKeyOffset === true && (
					<p>
						<InputText
							labelText="Enter your starting number. "
							forAttribute="keyOffset"
							name="keyOffset"
							value={formData.keyOffset}
							onChange={onInputChange}
							errorMessage={
								inlineErrorMessages.keyOffset
									? inlineErrorMessages.keyOffset.message
									: ''
							}
							conditionalValidation={{
								depend: 'useKeyOffset',
								expected: true,
							}}
						/>
					</p>
				)}
			</div>

			<Button
				className="btn btn-primary"
				// disabled={!formIsComplete}
				type="submit"
				label={venueFetching ? 'Please wait...' : 'Add new venue'}
			/>
			{/* Error reporting */}
			<ErrorMessageBlock inlineErrorMessages={inlineErrorMessages} />
		</div>
	);
};

//
export default UnitOptions;
