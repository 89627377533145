import React from 'react';

import decodeHTMLEntity from '../../utils/decodeHTMLEntity';

// components
import TableRow from '../../components/common/TableRow';

/**
 * Show a replacement item in the cart.
 *
 * @param {Object} props
 */
const ReplacePartItem = (props) => {
  const {
    lineSubtotal,
    boxOption,
    boxNumber,
    venueName,
    itemKey,
    removeItemFromCart,
  } = props;

  // Check what type of replacement part this is.
  const boxOrKey = boxOption === 'key-only' ? 'Key' : 'Box & key';

  const icon =
    boxOption === 'key-only' ? (
      <i key={itemKey} className="icon-cardssafe-key" />
    ) : (
      <i key={itemKey} className="icon-cardssafe-box" />
    );

  const data = [
    {
      id: itemKey,
      value: [icon, boxOrKey],
      // className: boxOption === 'key-only' ? 'icon-cardssafe-key' : 'icon-cardssafe-box',
      responsiveLabel: 'Item',
    },
    {
      id: 'keyNumbers',
      value: boxNumber, // @TODO correct key numbers.
      responsiveLabel: 'Key Number(s)',
    },
    {
      id: 'venue',
      value: decodeHTMLEntity(venueName),
      responsiveLabel: 'Venue',
    },
    {
      id: 'price',
      value: '£' + lineSubtotal,
      responsiveLabel: 'Price',
    },
    {
      id: 'remove',
      value: (
        <button
          type="button"
          className=" icon-trash-bin"
          onClick={removeItemFromCart.bind(this, itemKey)}
        >
          <span className="sr-only">Delete</span>
        </button>
      ),
      className: 'remove',
      responsiveLabel: 'Remove',
    },
  ];

  //
  return <TableRow cells={data} />;
};

export default ReplacePartItem;
