// Extract the correct venue.
const getCurrentVenue = (state) => {
  // The current venue ID.
  const { venues, currentVenueId } = state.customer;

  return venues.find((object) => object.id === currentVenueId) || {};
};

// Convert a name into initial like this: Dan Ransome -> DR
const getInitials = (name) =>
  name
    .split(' ')
    .map((word) => {
      return word.length > 0 ? word[0].toUpperCase() : word;
    })
    .join('');

export { getCurrentVenue, getInitials };
