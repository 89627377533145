import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// HOC
import { withSwitchPageTransition } from '../../components/hoc';

// Redux.
import { customerActionCreators } from '../../redux/actions/customerActions';

// Common components.
import InputText from '../../components/common/InputText';
import Button from '../../components/common/Button';
import InputPassword from '../../components/common/InputPassword';
import SubHeadingSection from '../../components/SubHeadingSection';
import MiniCart from '../../components/MiniCart';
import Content from '../../containers/Content';
import ErrorMessageBlock from '../../components/common/ErrorMessageBlock';

// Custom hooks.
import useForm from '../../hooks/useForm';

// Form styling.
import './Personal.scss';
import './../../components/Notice/Notice.scss';

// Config for the form. This sets up validation and required inputs.
import personalFormConfig from './personalFormConfig';

/**
 * This view represents the 'edit user' and 'edit profile' functionality.
 */
const Personal = (props) => {
  const {
    fetching,
    customer,
    updateProfile,
    isAdmin,
    resetPassword, // Action to reset password.
  } = props;

  // Main profile data.
  const { id: customerId, firstName, lastName, phone, email, role } = customer;

  // Track when the form has been submit and the password has been changed as we
  // will need to empty the passwork input when the componenet re-mounts after
  // the save.
  const passwordHasBeenUpdated = useRef(false);

  const handleUpdateProfile = (formData) => {
    // If the password field has a value it will have been updated and the input
    // will be reset after the redux action has completed. This is tracked using
    // the useRef hook and the data is cleared on mount using the useEffect hook.
    if (formData.password !== '') {
      passwordHasBeenUpdated.current = true;
    }

    // Dispatch the redux action
    updateProfile(formData);
  };

  // Setup the custom hook by passing the data.
  const {
    formData,
    formIsPristine,
    inlineErrorMessages,
    onInputChange,
    onFormSubmit,
    setFormIsPristine,
    setFormData,
    validationArgs,
  } = useForm(
    {
      customerId: isAdmin ? customerId : 0, // pass the customer id if isAdmin
      firstName: firstName || '',
      lastName: lastName || '',
      phone: phone || '',
      email: email || '',
      role: role || '',
      password: '',
    },
    handleUpdateProfile,
    personalFormConfig
  );

  // Empty/reset the password input when the form has been saved.
  // Disable the submit.
  useEffect(() => {
    if (passwordHasBeenUpdated.current) {
      setFormData({ password: '' });
      setFormIsPristine(true);
      passwordHasBeenUpdated.current = false;
    }
  });

  /**
   * Trigger a password reset email
   */
  const sendPasswordResetEmail = () => {
    const { email } = formData;

    resetPassword({ email });
  };

  return (
    <>
      <SubHeadingSection
        heading={isAdmin ? `Update user profile` : `Update your profile`}
      >
        <MiniCart />
      </SubHeadingSection>
      <Content customClasses="user-profile">
        <div className="row user-profile__intro-block">
          <div className="col">
            <p>
              Use the form below to update your personal profile. If you wish to
              edit your business details, you will need to visit the{' '}
              <strong>edit this venue</strong> page.
            </p>
          </div>
        </div>
        {/* The profile form */}
        <form className="form" onSubmit={onFormSubmit}>
          <p>
            <InputText
              labelText="First Name"
              forAttribute="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={onInputChange}
              errorMessage={
                inlineErrorMessages.firstName
                  ? inlineErrorMessages.firstName.message
                  : ''
              }
              validationRules={['firstName']}
            />
          </p>
          <p>
            <InputText
              labelText="Last Name"
              forAttribute="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={onInputChange}
              errorMessage={
                inlineErrorMessages.lastName
                  ? inlineErrorMessages.lastName.message
                  : ''
              }
              validationRules={['lastName']}
            />
          </p>
          <p>
            <InputText
              labelText="Phone"
              forAttribute="phone"
              name="phone"
              value={formData.phone}
              onChange={onInputChange}
              errorMessage={
                inlineErrorMessages.phone
                  ? inlineErrorMessages.phone.message
                  : ''
              }
              type="tel"
              validationRules={['phoneNumber']}
            />
          </p>
          <p>
            <InputText
              labelText="Email"
              forAttribute="email"
              name="email"
              value={formData.email}
              onChange={onInputChange}
              errorMessage={
                inlineErrorMessages.email
                  ? inlineErrorMessages.email.message
                  : ''
              }
              type="email"
              validationRules={['emailAddress']}
            />
          </p>
          <p>
            <InputText
              labelText="Role/position"
              forAttribute="role"
              name="role"
              value={formData.role}
              onChange={onInputChange}
              errorMessage={
                inlineErrorMessages.role ? inlineErrorMessages.role.message : ''
              }
              validationRules={['notEmpty']}
            />
          </p>
          <p>
            {/* Admin users can only trigger a password update email */}
            {!isAdmin && (
              <InputPassword
                labelText="Update your password"
                forAttribute="password"
                name="password"
                value={formData.password}
                onChange={onInputChange}
                errorMessage={
                  inlineErrorMessages.password
                    ? inlineErrorMessages.password.message
                    : ''
                }
                validationArgs={validationArgs.password}
                type="password"
                validationRules={['password']}
              />
            )}
          </p>
          <div className="col-12">
            <Button
              type="submit"
              disabled={fetching || formIsPristine}
              label={fetching ? 'Please wait...' : 'Save'}
              className="btn btn-primary"
            />
            <ErrorMessageBlock inlineErrorMessages={inlineErrorMessages} />
            {isAdmin && (
              <p className="mt-4 notice">
                <i
                  className="icon icon-info-circle"
                  style={{ lineHeight: 1, marginRight: 10 }}
                ></i>
                When updating an email address, please{' '}
                <strong>save first</strong> and then click the "Send password
                reset email" button below.
              </p>
            )}
          </div>
        </form>
        {isAdmin && (
          <div className="form-section" style={{ marginTop: 30 }}>
            <h2>Reset user's password</h2>
            <p>
              Click the button below to send a password reset to this user. You
              cannot set a user's password on their behalf.
            </p>
            <Button
              type="submit"
              disabled={false}
              label="Send password reset email"
              className="btn btn-secondary"
              onClick={sendPasswordResetEmail}
            />
          </div>
        )}
      </Content>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { fetching } = state.global;
  const { customer } = state.customer;
  const {
    user: { isAdmin },
  } = state.login;

  return {
    isAdmin,
    fetching,
    customer,
  };
};

const mapDispatchToProps = {
  updateProfile: customerActionCreators.customerProfileUpdateRequest,
  resetPassword: customerActionCreators.passwordResetRequest,
};

export default compose(
  withSwitchPageTransition,
  connect(mapStateToProps, mapDispatchToProps)
)(Personal);
