import React from 'react';

// Config.
import routes from '../../../config/routesConfig';

// HOC
import withProgressionControl from './withProgressionControl';

// Style.
import './SelectVenue.scss';
import { Link } from 'react-router-dom';
import Button from '../../../components/common/Button';
// import Button from '../../../components/common/Button';

/**
 * This first step of the add unit process. We will ask the user if they are adding a
 * new venue or using an existing venue. This step is skipped if the user has no venues
 * as they must create a new one.
 */
const SelectVenue = (props) => {
	const {
		venueList,
		// venue,
		updateVenue: parentUpdateSelectedVenue,
		onSelectAddNewVenue: parentOnSelectAddNewVenue,
		currentVenue,
		history,
		updateStepPassed,
		cartHasItems, // Boolean value which indicated if the cart has items in it already.
	} = props;

	// On select add new venue.
	const onSelectAddNewVenue = () => {
		if (cartHasItems) return;

		// Dispatch parent handler
		parentOnSelectAddNewVenue();
	};

	// On select existing venue
	const onSelectExistingVenue = (venue) => {
		parentUpdateSelectedVenue(venue);

		// update step passed
		updateStepPassed(2);

		// go to the key page
		history.push(routes.ADD_UNIT_KEY);
	};

	// If there is more than one existing venue the list will be sorted so the
	// current venue is the first option, this will save the user from having to
	// look through the list.
	if (venueList.length > 1 && currentVenue) {
		venueList.sort((a) => {
			if (currentVenue.id === a.id) return -1;

			return 0;
		});
	}

	//
	return (
		<div className="select-venue text-md-center">
			<h2 className="select-venue__title">
				Is this order for a new or existing venue?
			</h2>
			<div className="row select-venue__options ">
				<div className="col-12 col-md-6 select-venue__new text-center">
					<h4 className="flip-card__title">A new venue</h4>
					<Button
						className="btn btn-primary my-3 mx-auto new-venue"
						onClick={onSelectAddNewVenue}
						label="Create a new venue"
					/>
					{cartHasItems && (
						<div className="text-center">
							<p className="p-2 m-0">
								Please <Link to={routes.CART}>complete your current order</Link>{' '}
								or empty your basket to add a new venue.
							</p>
						</div>
					)}
				</div>
				<div className="col-12 col-md-6 select-venue__existing text-center">
					<h4 className="flip-card__title">Your existing venues</h4>
					{venueList.map((venue) => (
						<Button
							key={`venue-${venue.id}`}
							className="btn btn-secondary d-block mt-4 mx-auto"
							label={venue.name}
							onClick={(e) => {
								e.preventDefault();
								// console.log(venue.id, venue);
								// updateSelectedVenue(venue);
								onSelectExistingVenue(venue);
							}}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default withProgressionControl(SelectVenue);
