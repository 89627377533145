import React from 'react';
import PropTypes from 'prop-types';

import './ConfirmModal.scss';

const ConfirmModal = ({
  heading,
  content: ConfirmModalContent,
  okText,
  cancelText,
  onConfirm,
  onCancel,
}) => {
  return (
    <div className="confirm-modal text-center">
      <h3 className="confirm-modal__heading">{heading}</h3>
      <div className="confirm-modal__body">
        <ConfirmModalContent />
        <div className="confirm-modal__actions">
          <button
            className="btn btn-outline-primary border border-primary"
            type="button"
            onClick={onCancel}
          >
            {cancelText || 'Cancel'}
          </button>
          <button className="btn btn-primary" type="button" onClick={onConfirm}>
            {okText || 'OK'}
          </button>
        </div>
      </div>
    </div>
  );
};

//
ConfirmModal.propTypes = {
  heading: PropTypes.string.isRequired,
  content: PropTypes.func.isRequired,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

//
export default ConfirmModal;
