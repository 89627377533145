import React from 'react';

// Style.
import './Content.scss';

// Main content area, used mainly for style reuse purposes.
const Content = ({ customClasses = '', children }) => {
	return <div className={`content ${customClasses}`}>{children}</div>;
};

export default Content;
