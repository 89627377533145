import React from 'react';
import { NavLink } from 'react-router-dom';

// Style.
import './Pagination.scss';

// Pagination
const Pagination = ({ currentPage = 1, totalPage, getPageUrl, fetching }) => {
  // Stop the pagination being clicked if the API is currently in use.
  const cancelIfFetching = (e) => {
    if (fetching) {
      e.preventDefault();
    }

    return;
  };

  //
  let links = [];
  for (let pageIndex = 1; pageIndex <= totalPage; pageIndex++) {
    links.push(
      <li
        className={`page-item ${pageIndex === currentPage ? 'current' : ''}`}
        key={'pagination-' + pageIndex}
      >
        <NavLink
          key={pageIndex}
          to={getPageUrl(pageIndex)}
          onClick={cancelIfFetching}
          className="page-link"
        >
          {pageIndex}
        </NavLink>
      </li>
    );
  }

  //
  return (
    <nav aria-label="Order Navigation">
      <ul className="pagination justify-content-center">
        {currentPage > 1 && (
          <li className="page-item">
            <NavLink
              to={getPageUrl(currentPage - 1)}
              onClick={cancelIfFetching}
              className="page-link"
            >
              Previous
            </NavLink>
          </li>
        )}
        {links}
        {currentPage + 1 <= totalPage && (
          <li className="page-item">
            <NavLink
              to={getPageUrl(currentPage + 1)}
              onClick={cancelIfFetching}
              className="page-link"
            >
              Next
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
};

//
export default Pagination;
