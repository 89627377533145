import { getApi } from '../../services/api';
import getRandomItem from '../../utils/getRandomItem';

//
const fixtureAddComment = () => {
	const files = ['addCommentFailure', 'addCommentSuccess'];
	// const files = ['addCommentSuccess'];
	const responseFile = getRandomItem(files);
	// console.log(responseFile);

	//
	const responseData = require(`../../fixtures/comment/${responseFile}.json`);

	//
	const { success } = responseData;

	//
	if (success) {
		// fake comment id
		const commentId = Math.floor(Math.random() * 10000 + 1);

		//
		return {
			...responseData,
			comment: {
				...responseData.comment,
				id: commentId,
				content: `#${commentId} - ${responseData.comment.content}`,
			},
		};
	}

	//
	return responseData;
};

//
const apiAddComment = async (api, form) => {
	try {
		// console.log({ form });
		// {form: {unitId: 0, commentContent: "a"}}
		const { unitId, commentContent: content, userId, customerId } = form;

		//
		const response = await api.post('/cardssafe-account/v1/comments', {
			type: unitId ? 'unit' : 'global',
			// author_id: unitId ? customerId : userId,
			author_id: userId,
			object_id: unitId ? unitId : customerId,
			content,
		});

		// success
		const {
			data: [comment],
		} = response.data;

		//
		return {
			success: true,
			comment,
		};
	} catch (error) {
		return {
			success: false,
			message: error.response ? error.response.data.message : error.message,
		};
	}
};

//
const addComment = form => {
	const api = getApi();
	// api is null - use fixture
	if (!api) {
		return fixtureAddComment();
	}

	// restApi add comment
	return apiAddComment(api, form);
};

export default addComment;
