// action types
export const cartActionTypes = {
  UPDATE_CART_REQUEST: 'UPDATE_CART_REQUEST',
  UPDATE_CART: 'UPDATE_CART',
  UPDATE_ITEM_QUANTITY: 'UPDATE_ITEM_QUANTITY',
  REMOVE_ITEM: 'REMOVE_ITEM',
  CLEAR_CART: 'CLEAR_CART',
  // replacement parts
  ADD_REPLACEMENT_PARTS_TO_CART: 'ADD_REPLACEMENT_PARTS_TO_CART',
  ADD_REPLACEMENT_PARTS_TO_CART_FAILURE:
    'ADD_REPLACEMENT_PARTS_TO_CART_FAILURE',
  // unit
  ADD_UNIT_TO_CART: 'ADD_UNIT_TO_CART',
  // Validate a coupon code.
  APPLY_COUPON_CODE: 'APPLY_COUPON_CODE',
  UPDATE_COUPON_CODE: 'UPDATE_COUPON_CODE',
  REMOVE_COUPON_CODE: 'REMOVE_COUPON_CODE',
  REMOVE_COUPON_CODES: 'REMOVE_COUPON_CODES',
  SET_COUPON_CODE_NOTICE: 'SET_COUPON_CODE_NOTICE',
};

// UPDATE_CART_REQUEST
const updateCartRequest = () => ({
  type: cartActionTypes.UPDATE_CART_REQUEST,
});

//
const updateCart = (cart, isPersist) => ({
  type: cartActionTypes.UPDATE_CART,
  cart,
  isPersist,
  camelCase: true,
});

// update item quantity
const updateItemQuantity = (itemKey, quantity) => ({
  type: cartActionTypes.UPDATE_ITEM_QUANTITY,
  itemKey,
  quantity,
});

// remove item
const removeItem = (itemKey) => ({
  type: cartActionTypes.REMOVE_ITEM,
  itemKey,
});

// clear cart
const clearCart = (localOnly) => ({
  type: cartActionTypes.CLEAR_CART,
  localOnly,
});

// add replacement parts to cart
const addReplacementPartsToCart = (parts) => ({
  type: cartActionTypes.ADD_REPLACEMENT_PARTS_TO_CART,
  parts,
});
const addReplacementPartsToCartFailure = () => ({
  type: cartActionTypes.ADD_REPLACEMENT_PARTS_TO_CART_FAILURE,
});

// add unit to cart
const addUnitToCart = (unit) => ({
  type: cartActionTypes.ADD_UNIT_TO_CART,
  unit,
});

// Action which is fired when we need to check a coupon code is valid.
const applyCouponCode = (code) => ({
  type: cartActionTypes.APPLY_COUPON_CODE,
  code,
});

// Remove a coupon code.
const removeCouponCode = (code) => ({
  type: cartActionTypes.REMOVE_COUPON_CODE,
  code,
});

// When the user is typing into the coupon input.
const updateCouponCode = (code) => ({
  type: cartActionTypes.UPDATE_COUPON_CODE,
  code,
});

// When we want to manually reset the coupon code in the redux state. This is
// typically called when switching user account.
const removeAllCouponCodes = () => ({
  type: cartActionTypes.REMOVE_COUPON_CODES,
});

const setCouponCodeNotice = (message) => ({
  type: cartActionTypes.SET_COUPON_CODE_NOTICE,
  message,
});

//
export const cartActionsCreator = {
  updateCartRequest,
  updateCart,
  updateItemQuantity,
  removeItem,
  clearCart,
  addReplacementPartsToCart,
  addReplacementPartsToCartFailure,
  addUnitToCart,
  applyCouponCode,
  removeCouponCode,
  updateCouponCode,
  removeAllCouponCodes,
  setCouponCodeNotice,
};
