import { noticeTypes } from '../../config/globalConfig';

// Action types.
export const globalActionTypes = {
  START_FETCHING: 'START_FETCHING', // Redux
  END_FETCHING: 'END_FETCHING', // Redux
  //
  ADD_NOTICE: 'ADD_NOTICE',
  REMOVE_NOTICE: 'REMOVE_NOTICE',
};

// start fetching
const startFetching = (fetchingMessage = '') => {
  return {
    type: globalActionTypes.START_FETCHING,
    fetchingMessage,
  };
};

// end fetching
const endFetching = () => ({
  type: globalActionTypes.END_FETCHING,
});

// add notice
const addNotice = (notice) => ({
  type: globalActionTypes.ADD_NOTICE,
  notice,
});

//
const addErrorNotice = (content) => ({
  type: globalActionTypes.ADD_NOTICE,
  notice: {
    type: noticeTypes.error,
    content,
  },
});

//
const addSuccessNotice = (content) => ({
  type: globalActionTypes.ADD_NOTICE,
  notice: {
    type: noticeTypes.success,
    content,
  },
});

// remove notice
const removeNotice = (noticeId) => ({
  type: globalActionTypes.REMOVE_NOTICE,
  noticeId,
});

//
export const globalActionCreators = {
  startFetching,
  endFetching,
  // notice actions
  addNotice,
  addErrorNotice,
  addSuccessNotice,
  removeNotice,
};
