import React from 'react';
import { connect } from 'react-redux';

// Routes.
import routes from '../../config/routesConfig';

// Child components.
import SmallUnit from '../../components/SmallUnit';
import AddNewUnit from './AddNewUnit';

// Common components.
import MiniCart from '../../components/MiniCart';
import SubHeadingSection from '../../components/SubHeadingSection';
import SubNavigationBar from '../../components/SubNavigationBar';
import AddNewUnitButton from '../../components/common/AddNewUnitButton';
import VenueSwitcher from './VenueSwitcher';

// Redux.
import { commentModalActionCreators } from '../../redux/actions/commentModalActions';

// Utilities.
import {
  getUnitsByVenue,
  getUnitContactNumber,
} from '../../utils/unitHelperFunctions';
import { getCurrentVenue } from '../../utils/customerHelperFunctions';

// Style.
import './Summary.scss';

/**
 * This is the main dashboard that the user will see when they are logged in and they have units.
 */
const Summary = (props) => {
  const {
    venue,
    venueList,
    isAdmin,
    openCommentModal,
    // switchVenue: dispatchSwitchVenue,
    currentVenueId,
    allVenueUnits,
  } = props;

  // Track which unit' ID is being used in the modal.
  // const [commentModalUnitId, setCommentModalUnitId] = useState(0);

  const currentVenueName = venue.name;
  const contracts = venue.contracts;

  // The venue's key offset. This is used to calculate the key numbers dynamically since units always
  // start from "1" in the database. For example, if the venue offset is 20 it means that the first unit's key
  // (actually 1) will be converted into the correct display number using the offset of 20.
  const keyOffset = parseInt(venue.keyOffset) || 1;

  /**
   * Get title value depending on the user and venue.
   * @return {string}
   */
  const summaryTitle = isAdmin
    ? 'Dashboard: ' + currentVenueName
    : currentVenueName + ' Units';

  //
  return (
    <div className="col-12 dashboard">
      <SubHeadingSection heading={summaryTitle}>
        <AddNewUnitButton />
      </SubHeadingSection>

      {/* Allow the user to click on a contract number and navigate to a new page*/}
      <SubNavigationBar
        menuItems={contracts}
        menuLabel="View contract:"
        baseUrl={routes.CONTRACT}
        baseKey="ContractNavigation"
      />

      <div className="row dashboard__bar">
        {venueList.length > 1 && (
          <div className="col col-md-4">
            <VenueSwitcher venueList={venueList} />
          </div>
        )}
        <div
          className={
            'col-12 ' + (venueList.length > 1 ? 'col-md-4' : 'col-md-8')
          }
        >
          <p className="dashboard__intro">
            You are viewing "{currentVenueName}" which has{' '}
            {allVenueUnits.length} unit(s). Your keys start at #{keyOffset}.
          </p>
        </div>
        <div className="col-12 mt-2 mt-sm-0 col-md-4 text-xl-right">
          {/* Show a compact version of the cart*/}
          <MiniCart />
        </div>
      </div>
      {/* Loop over all units */}
      <div className="dashboard__units row-eq-height">
        {allVenueUnits.map((unit, unitIndex) => {
          if (!unit.hasOwnProperty('id')) {
            return null;
          }

          // Find the contract start date for this unit.
          const contractForUnit = contracts.find((_contract) => {
            return _contract.id === unit.contract;
          });

          return (
            <div
              className="col col-sm-12 col-lg-6 col-xxl-4 mt-lg-4 mt-4"
              key={currentVenueId + unit.id}
            >
              <SmallUnit
                key={`unit-${unit.id}-${unit.comments.length}`}
                {...unit}
                keyStartNumber={keyOffset + unitIndex * 10}
                unitIndex={unitIndex}
                openCommentModal={openCommentModal}
                isAdmin={isAdmin}
                startDate={contractForUnit ? contractForUnit.startDate : null}
              />
            </div>
          );
        })}
        {/* Button to add new unit which displays inline with other units */}
        {allVenueUnits.length > 0 && (
          <div className="col col-sm-12 col-lg-6 col-xxl-4 mt-lg-4 mt-4">
            <AddNewUnit />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user } = state.login;
  const { isAdmin } = user || {};
  const { unitCommentError, currentVenueId } = state.customer;
  const { items: cartItems } = state.cart;

  // All units for this venue, sorted in order.
  const allVenueUnits = getUnitsByVenue(getCurrentVenue(state));

  // Add the contract number.
  if (allVenueUnits.length > 0) {
    allVenueUnits.forEach((unit) => {
      unit.contract = getUnitContactNumber(state, unit.id);
    });
  }

  return {
    isAdmin,
    unitCommentError,
    currentVenueId,
    allVenueUnits,
    isCartHasItems: Object.keys(cartItems).length > 0,
  };
};

const mapDispatchToProps = {
  // switchVenue: customerActionCreators.switchVenue,
  openCommentModal: commentModalActionCreators.openCommentModal,
  // clearCart: cartActionsCreator.clearCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
