// Action types.
export const customerActionTypes = {
  CUSTOMER_DATA_REQUEST: 'CUSTOMER_DATA_REQUEST',
  CUSTOMER_DATA_LOADED: 'CUSTOMER_DATA_LOADED',
  PERSIST_CUSTOMER_DATA_LOADED: 'PERSIST_CUSTOMER_DATA_LOADED',
  RESET_DATA: 'RESET_DATA',
  // unit comment
  ADD_UNIT_COMMENT_SUCCESS: 'ADD_UNIT_COMMENT_SUCCESS',
  DELETE_UNIT_COMMENT_SUCCESS: 'DELETE_UNIT_COMMENT_SUCCESS',

  // global comment
  ADD_GLOBAL_COMMENT_SUCCESS: 'ADD_GLOBAL_COMMENT_SUCCESS',
  DELETE_GLOBAL_COMMENT_SUCCESS: 'DELETE_GLOBAL_COMMENT_SUCCESS',

  // Switching to a new venue.
  SWITCH_VENUE: 'SWITCH_VENUE',

  // contract
  CANCEL_CONTRACT_REQUEST: 'CANCEL_CONTRACT_REQUEST',
  CANCEL_CONTRACT_SUCCESS: 'CANCEL_CONTRACT_SUCCESS',
  CANCEL_CONTRACT_FAILURE: 'CANCEL_CONTRACT_FAILURE',

  // Update customer data.
  CUSTOMER_PROFILE_UPDATE_REQUEST: 'CUSTOMER_PROFILE_UPDATE_REQUEST',
  CUSTOMER_PROFILE_UPDATE_SUCCESS: 'CUSTOMER_PROFILE_UPDATE_SUCCESS',
  CUSTOMER_PROFILE_UPDATE_FAILURE: 'CUSTOMER_PROFILE_UPDATE_FAILURE',

  // venue
  ADD_VENUE_REQUEST: 'ADD_VENUE_REQUEST',
  ADD_VENUE_SUCCESS: 'ADD_VENUE_SUCCESS',
  ADD_VENUE_FAILURE: 'ADD_VENUE_FAILURE',
  UPDATE_VENUE_REQUEST: 'UPDATE_VENUE_REQUEST',
  UPDATE_VENUE_SUCCESS: 'UPDATE_VENUE_SUCCESS',
  UPDATE_VENUE_FAILURE: 'UPDATE_VENUE_FAILURE',
  UPDATE_VENUE_ADDRESSES_REQUEST: 'UPDATE_VENUE_ADDRESSES_REQUEST',
  UPDATE_VENUE_ADDRESSES_SUCCESS: 'UPDATE_VENUE_ADDRESSES_SUCCESS',
  UPDATE_VENUE_ADDRESSES_FAILURE: 'UPDATE_VENUE_ADDRESSES_FAILURE',
  UPDATE_CURRENT_VENUE_ID: 'UPDATE_CURRENT_VENUE_ID',

  // Register a new account.
  CUSTOMER_REGISTER_REQUEST: 'CUSTOMER_REGISTER_REQUEST',
  CUSTOMER_REGISTER_SUCCESS: 'CUSTOMER_REGISTER_SUCCESS',
  CUSTOMER_REGISTER_FAILURE: 'CUSTOMER_REGISTER_FAILURE',

  // Password reset.
  PASSWORD_RESET_REQUEST: 'PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_FAILURE: 'PASSWORD_RESET_FAILURE',
  PASSWORD_UPDATE_REQUEST: 'PASSWORD_UPDATE_REQUEST',
  PASSWORD_UPDATE_SUCCESS: 'PASSWORD_UPDATE_SUCCESS',
  PASSWORD_UPDATE_FAILURE: 'PASSWORD_UPDATE_FAILURE',

  //
  CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
};

//////////////////////////
// Main payload of data //
//////////////////////////

// CUSTOMER_DATA_REQUEST
const customerDataRequest = (customerId, venueId, isPersist) => ({
  type: customerActionTypes.CUSTOMER_DATA_REQUEST,
  customerId,
  venueId,
  isPersist,
});

// CUSTOMER_DATA_LOADED
const customerDataLoaded = (data, venueId, isPersist) => {
  return {
    type: customerActionTypes.CUSTOMER_DATA_LOADED,
    ...data,
    currentVenueId: venueId,
    isPersist,
    camelCase: true,
  };
};

// PERSIST_CUSTOMER_DATA_LOADED
const persistCustomerDataLoaded = () => {
  return {
    type: customerActionTypes.PERSIST_CUSTOMER_DATA_LOADED,
    isPersist: true,
    camelCase: true,
  };
};

// CLEAR_DATA
const resetData = () => ({
  type: customerActionTypes.RESET_DATA,
});

///////////////
// Comments  //
///////////////

// ADD_UNIT_COMMENT_SUCCESS
const addUnitCommentSuccess = (unitId, comment) => ({
  type: customerActionTypes.ADD_UNIT_COMMENT_SUCCESS,
  unitId,
  comment,
  camelCase: true,
});

// DELETE_COMMENT_SUCCESS
const deleteUnitCommentSuccess = (commentId) => ({
  type: customerActionTypes.DELETE_UNIT_COMMENT_SUCCESS,
  commentId,
});

// ADD_GLOBAL_COMMENT_SUCCESS
const addGlobalCommentSuccess = (comment) => ({
  type: customerActionTypes.ADD_GLOBAL_COMMENT_SUCCESS,
  comment,
  camelCase: true,
});

// DELETE_GLOBAL_COMMENT_SUCCESS
const deleteGlobalCommentSuccess = (commentId) => ({
  type: customerActionTypes.DELETE_GLOBAL_COMMENT_SUCCESS,
  commentId,
});

///////////
// Venue //
///////////

// SWITCH_VENUE
const switchVenue = (venueId) => ({
  type: customerActionTypes.SWITCH_VENUE,
  venueId,
});

////////////////
// Contracts  //
////////////////

// CANCEL_CONTRACT_REQUEST
const cancelContractRequest = (contractId) => ({
  type: customerActionTypes.CANCEL_CONTRACT_REQUEST,
  contractId,
});

// CANCEL_CONTRACT_SUCCESS
const cancelContractSuccess = (contract) => ({
  type: customerActionTypes.CANCEL_CONTRACT_SUCCESS,
  contract,
});

// CANCEL_CONTRACT_FAILURE
const cancelContractFailure = (error) => ({
  type: customerActionTypes.CANCEL_CONTRACT_FAILURE,
  error,
});

///////////////////////
// User profile page //
///////////////////////

// CUSTOMER_PROFILE_UPDATE_REQUEST
const customerProfileUpdateRequest = (payload) => ({
  type: customerActionTypes.CUSTOMER_PROFILE_UPDATE_REQUEST,
  payload,
});

// CUSTOMER_PROFILE_UPDATE_SUCCESS
const customerProfileUpdateSuccess = (payload) => ({
  type: customerActionTypes.CUSTOMER_PROFILE_UPDATE_SUCCESS,
  payload,
});

// CUSTOMER_PROFILE_UPDATE_FAILURE
const customerProfileUpdateFailure = (message) => ({
  type: customerActionTypes.CUSTOMER_PROFILE_UPDATE_FAILURE,
  message,
});

///////////
// Venue //
///////////
const updateCurrentVenueId = (venueId) => ({
  type: customerActionTypes.UPDATE_CURRENT_VENUE_ID,
  currentVenueId: venueId,
});

// Add new venue.
const addVenueRequest = (venue) => ({
  type: customerActionTypes.ADD_VENUE_REQUEST,
  venue,
});

const addVenueSuccess = (venue) => ({
  type: customerActionTypes.ADD_VENUE_SUCCESS,
  venue,
  camelCase: true,
});

const addVenueFailure = (error) => ({
  type: customerActionTypes.ADD_VENUE_FAILURE,
  error,
});

const updateVenueRequest = (venue) => ({
  type: customerActionTypes.UPDATE_VENUE_REQUEST,
  venue,
});

const updateVenueSuccess = (venue) => ({
  type: customerActionTypes.UPDATE_VENUE_SUCCESS,
  venue,
  camelCase: true,
});

const updateVenueFailure = (error) => ({
  type: customerActionTypes.UPDATE_VENUE_FAILURE,
  error,
});

// update venue addresses
const updateVenueAddressesRequest = (
  { venueId, billingAddress, shippingAddress },
  redirectToCheckoutPaymentOnSuccess
) => ({
  type: customerActionTypes.UPDATE_VENUE_ADDRESSES_REQUEST,
  venueId,
  billingAddress,
  shippingAddress,
  redirectToCheckoutPaymentOnSuccess,
});

const updateVenueAddressesSuccess = (
  venueId,
  billingAddress,
  shippingAddress
) => ({
  type: customerActionTypes.UPDATE_VENUE_ADDRESSES_SUCCESS,
  venueId,
  billingAddress,
  shippingAddress,
});

const updateVenueAddressesFailure = (error) => ({
  type: customerActionTypes.UPDATE_VENUE_ADDRESSES_FAILURE,
  error,
});

/////////////////////////////////////
// Register a new customer account //
/////////////////////////////////////

// CUSTOMER_REGISTER_REQUEST
const customerRegisterRequest = (data) => ({
  type: customerActionTypes.CUSTOMER_REGISTER_REQUEST,
  data,
});

// CUSTOMER_REGISTER_SUCCESS
const customerRegisterSuccess = (user) => ({
  type: customerActionTypes.CUSTOMER_REGISTER_SUCCESS,
  // user,
});

// CUSTOMER_REGISTER_FAILURE
const customerRegisterFailure = (error) => ({
  type: customerActionTypes.CUSTOMER_REGISTER_FAILURE,
  error,
});

////////////////////
// Password reset //
////////////////////

// PASSWORD_RESET_REQUEST
const passwordResetRequest = ({ email }) => ({
  type: customerActionTypes.PASSWORD_RESET_REQUEST,
  email,
});

// PASSWORD_RESET_SUCCESS
const passwordResetSuccess = (message) => ({
  type: customerActionTypes.PASSWORD_RESET_SUCCESS,
  message,
});

// PASSWORD_RESET_FAILURE
const passwordResetFailure = (message) => ({
  type: customerActionTypes.PASSWORD_RESET_FAILURE,
  message,
});

const passwordUpdateRequest = (payload) => ({
  type: customerActionTypes.PASSWORD_UPDATE_REQUEST,
  payload,
});

const passwordUpdateSuccess = () => ({
  type: customerActionTypes.PASSWORD_UPDATE_SUCCESS,
});

const passwordUpdateFailure = () => ({
  type: customerActionTypes.PASSWORD_UPDATE_FAILURE,
});

//////////////////
// Create order //
//////////////////
const createOrderRequest = (data) => ({
  type: customerActionTypes.CREATE_ORDER_REQUEST,
  data,
});

export const customerActionCreators = {
  // Main data payload.
  customerDataRequest,
  customerDataLoaded,
  persistCustomerDataLoaded,
  resetData,

  // Unit comments.
  addUnitCommentSuccess,
  deleteUnitCommentSuccess,

  // Global comments.
  addGlobalCommentSuccess,
  deleteGlobalCommentSuccess,

  // Switching to a new venue.
  switchVenue,

  // Contract.
  cancelContractRequest,
  cancelContractSuccess,
  cancelContractFailure,

  // Profile page.
  customerProfileUpdateRequest,
  customerProfileUpdateSuccess,
  customerProfileUpdateFailure,

  // Venue.
  addVenueRequest,
  addVenueSuccess,
  addVenueFailure,
  updateVenueRequest,
  updateVenueSuccess,
  updateVenueFailure,
  updateVenueAddressesRequest,
  updateVenueAddressesSuccess,
  updateVenueAddressesFailure,
  updateCurrentVenueId,

  // Register new account.
  customerRegisterRequest,
  customerRegisterSuccess,
  customerRegisterFailure,

  // Password reset.
  passwordResetRequest,
  passwordResetSuccess,
  passwordResetFailure,
  passwordUpdateRequest,
  passwordUpdateSuccess,
  passwordUpdateFailure,

  // Create order.
  createOrderRequest,
};
