import { getApi } from '../../services/api';

async function apiMarkOrderAsComplete(api, orderId) {
	try {
		// api call
		const response = await api.post(`/wc/v3/orders/${orderId}`, { status: 'completed' });

		return {
			success: true,
			order: response.data,
		};
	} catch (error) {
		return {
			success: false,
			message: error.response ? error.response.data.message : error.message,
		};
	}
}

async function markOrderAsComplete(orderId) {
	const api = getApi();
	// api is null - use fixture
	if (!api) {
		return {
			success: false,
			message: 'Api is not config',
		};
	}

	// restApi get order history
	return apiMarkOrderAsComplete(api, orderId);
}

export default markOrderAsComplete;
