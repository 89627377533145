import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { cartActionsCreator } from '../../redux/actions/cartActions';

// Components.
import SubHeadingSection from '../../components/SubHeadingSection';
import Table from '../../components/common/Table';
import Button from '../../components/common/Button';

// Items/rows in the cart.
import ReplacePartItem from './ReplacePartItem';
import UnitItem from './UnitItem';

// Helpers.
import { roundPrice } from '../../utils/cartHelperFunctions';
import { getUnitsByVenue } from '../../utils/unitHelperFunctions';
import { getCurrentVenue } from '../../utils/customerHelperFunctions';

// Style.
import './Cart.scss';

// Static values used by the cart.
const REACT_APP_UNIT_PRODUCT_ID = parseInt(
	process.env.REACT_APP_UNIT_PRODUCT_ID
);
const REACT_APP_UNIT_CUSTOM_PRODUCT_ID = parseInt(
	process.env.REACT_APP_UNIT_CUSTOM_PRODUCT_ID
);
const REACT_APP_REPLACEMENT_KEY_PRODUCT_ID = parseInt(
	process.env.REACT_APP_REPLACEMENT_KEY_PRODUCT_ID
);
const REACT_APP_REPLACEMENT_KEY_AND_BOX_PRODUCT_ID = parseInt(
	process.env.REACT_APP_REPLACEMENT_KEY_AND_BOX_PRODUCT_ID
);

/**
 * Count how many units there are as only one unit can be deleted at a time
 * and it must be the unit with the highest number.
 */
const getKeysWhichCanBeDeleted = (items) => {
	// Used to collect the IDs of the units which are eligable for deletion from
	// the cart.
	const unitsEligableForDeletion = {};

	// First, extract all unit producsts in the cart. If there are no units we can
	// return.
	const units = items.filter((item) => {
		const { productId } = item;
		if (
			productId === REACT_APP_UNIT_PRODUCT_ID ||
			productId === REACT_APP_UNIT_CUSTOM_PRODUCT_ID
		) {
			return true;
		}

		return false;
	});

	if (units.length === 0) return unitsEligableForDeletion;

	units.forEach((unit) => {
		const { venueId, unitStartNumber, key } = unit;

		// Compare the current unit against and data that's been stored. If we find
		// a higher key number the value will need to be replaced.
		if (unitsEligableForDeletion.venueId) {
			if (unitStartNumber > unitsEligableForDeletion.venueId.unitStartNumber) {
				unitsEligableForDeletion.venueId = {
					unitStartNumber,
					key,
				};
			}
		} else {
			// No record exits so add it.
			unitsEligableForDeletion[venueId] = {
				unitStartNumber,
				key,
			};
		}
	});

	const keys = Object.values(unitsEligableForDeletion).map(
		(keyAndStartNumber) => keyAndStartNumber.key
	);

	return keys;
};

/**
 * Display the cart contents to the user.
 */
const Cart = (props) => {
	const { items, cartTotals, clearCart, couponCodeNotice, removeItem } = props;
	const {
		shippingTotal,
		subtotal,
		totalTax,
		total,
		discountTotal = null,
	} = cartTotals || {};

	// Heading for the cart table.
	const columnHeadings = ['Item', 'Key number(s)', 'Venue', 'Price', 'Remove'];

	// Flag which indicates if there are items in the cart.
	const hasItems = items.length > 0;

	// Called when the empty cart button is clicked.
	const onClickEmptyBasket = () => {
		clearCart(false);
	};

	/**
	 * Dispatch action to write updated coupon value to the store.
	 */
	const onUpdateCouponCode = (e) => {
		props.updateCouponCode(e.target.value);
	};

	/**
	 * Check if there is a coupon code applied. If there is we will remove it,
	 * if there is not, we will try to add a new one.
	 */
	const applyOrRemoveCouponCode = (e) => {
		if (props.couponCode === '' || discountTotal === 0) {
			// Dispatch the action with the code.
			props.applyCouponCode(props.couponCode);
		} else {
			props.removeCouponCode(props.couponCode);
		}
	};

	/**
	 * Get the ID of the unit which can be removed from the cart.
	 */
	const keysForDeletion = getKeysWhichCanBeDeleted(items);

	return (
		<div className="cart">
			<SubHeadingSection heading="Basket">
				<Button
					onClick={onClickEmptyBasket}
					label="Empty basket"
					className={'btn btn-lg btn-primary'}
				/>
			</SubHeadingSection>
			{hasItems ? (
				<div className="row">
					<div className="col-12">
						<Table columnHeadings={columnHeadings}>
							{items.map((item) => {
								//
								const { productId, lineTotal } = item;

								// Output the correct table rows.
								switch (productId) {
									// A box or a key.
									case REACT_APP_REPLACEMENT_KEY_PRODUCT_ID:
									case REACT_APP_REPLACEMENT_KEY_AND_BOX_PRODUCT_ID:
										return (
											<ReplacePartItem
												{...item}
												itemKey={item.key}
												removeItemFromCart={removeItem}
												productPrice={lineTotal}
											/>
										);
									case REACT_APP_UNIT_PRODUCT_ID:
									case REACT_APP_UNIT_CUSTOM_PRODUCT_ID:
										return (
											<UnitItem
												{...item}
												itemKey={item.key}
												removeItemFromCart={
													keysForDeletion.includes(item.key) ? removeItem : null
												}
												updateItemQuantity={props.updateItemQuantity}
												productPrice={`£${lineTotal}`}
											/>
										);
									default:
										return null;
								}
							})}
						</Table>
					</div>

					<div className="col-12 col-md-4 ml-auto cart__summary">
						<dl className="row row-eq-height data-table">
							<dt>Subtotal</dt>
							<dd>£{subtotal}</dd>
							{/* If there's a discount applied we will show the amount deducted. */}
							{discountTotal > 0 && (
								<Fragment>
									<dt className="cart__discount--label">Discount</dt>
									<dd className="cart__discount--amount">
										-£{roundPrice(discountTotal)}
									</dd>
								</Fragment>
							)}
							<dt>Postage</dt>
							<dd>£{roundPrice(parseFloat(shippingTotal))}</dd>
							<dt>Tax</dt>
							<dd>£{roundPrice(totalTax)}</dd>
							<dt>Total</dt>
							<dd>£{total}</dd>
						</dl>
						{/* Coupon input */}
						<div className="cart__coupon">
							<input
								type="text"
								className="cart__coupon__input"
								value={props.couponCode}
								onChange={onUpdateCouponCode}
								placeholder="Coupon code"
							/>
							<Button
								className="btn btn-small"
								onClick={applyOrRemoveCouponCode}
								label={discountTotal === 0 ? 'Apply' : 'Remove'}
							/>
							{/* If we have a notice to display... */}
							{couponCodeNotice && (
								<p className="cart__coupon__notice">{couponCodeNotice}</p>
							)}
						</div>
						<div className="cart__go-to-checkout">
							<Link className="btn btn-secondary" to={'/checkout'}>
								Go to checkout
							</Link>
						</div>
					</div>
				</div>
			) : (
				<div className="col-12 cart__empty">
					<i className="icon icon-shopping-cart"></i>
					<p>
						You have no items in your basket. Please return to the{' '}
						<Link to={'/'}>Dashboard</Link>.
					</p>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	const { items, cartTotals, couponCode, couponCodeNotice } = state.cart;

	// Sort items in cart.
	const cartItems = Object.values(items).sort((first, second) => {
		if (first.boxNumber && second.boxNumber) {
			return first.boxNumber < second.boxNumber ? -1 : 1;
		}
		return 0;
	});

	// Extract all units for the current venue.
	const allVenueUnits = getUnitsByVenue(getCurrentVenue(state));

	// Get any replacement keys
	const allReplacementKeys = allVenueUnits.map((unit) => {
		return {
			id: unit.id,
			freeReplacementKeysAvailable: parseInt(unit.freeReplacementKeysAvailable),
		};
	});

	return {
		items: cartItems,
		cartTotals,
		couponCode,
		couponCodeNotice,
		allReplacementKeys,
	};
};

const mapDispatchToProps = {
	removeItem: cartActionsCreator.removeItem,
	updateItemQuantity: cartActionsCreator.updateItemQuantity,
	clearCart: cartActionsCreator.clearCart,
	applyCouponCode: cartActionsCreator.applyCouponCode,
	updateCouponCode: cartActionsCreator.updateCouponCode,
	removeCouponCode: cartActionsCreator.removeCouponCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
