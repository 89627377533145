import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { globalActionCreators } from '../../redux/actions/globalActions';

// Helpers.
import camelCaseKeys from 'camelcase-keys';
import { formatDate } from '../../utils/dateHelperFunctions';

// Route config.
import routes from '../../config/routesConfig';

// Components.
import Pagination from '../../components/common/Pagination';
import SubHeadingSection from '../../components/SubHeadingSection';
import AddNewUnitButton from '../../components/common/AddNewUnitButton';
import Table from '../../components/common/Table';
import TableRow from '../../components/common/TableRow';

// Containers.
import Content from '../../containers/Content';

// API for getting order items.
import { getOrderHistory as apiGetOrderHistory } from '../../api/order';
import { getInvoice as apiGetInvoice } from '../../api/order';

// Style.
import './OrderHistory.scss';
import Button from '../../components/common/Button';

// Display the order history page.
const OrderHistory = (props) => {
	const {
		match,
		fetching,
		startFetching,
		endFetching,
		addErrorNotice,
		customerId,
		isAdmin,
		replacementKeyProductId,
		replacementKeyAndBoxProductId,
	} = props;

	// Local state for order history data.
	const [orderHistory, setOrderHistory] = useState({
		totalPage: 0,
		items: [],
		queryComplete: false,
	});

	// Current page for paginated results.
	const { pageIndex: currentPage } = match.params;

	/**
	 * Request a download URL from the server.
	 * @param {integer} id The invoice/order number.
	 * @returns void
	 */
	const downloadInvoice = async (id) => {
		if (!id) return;
		startFetching('Creating invoice...');
		const invoice = await apiGetInvoice(id);
		if (invoice.success && invoice.data) {
			window.open(decodeURI(invoice.data), '_blank');
		} else {
			addErrorNotice(invoice.message || 'Could not create invoice');
		}
		endFetching();
	};

	const summaryTitle =
		'Order history' + (currentPage > 1 ? ', page ' + currentPage : '');

	// Load data when the current page changes (and on load)
	useEffect(() => {
		let isMounted = true;

		// Query order history from the API.
		new Promise(async (resolve, reject) => {
			// Dispatch start fetching action.
			startFetching();

			const dataRaw = await apiGetOrderHistory({
				page: currentPage || 1,
				customer: customerId,
				isAdmin,
			});

			// Dispatch end fetching action.
			endFetching();

			// unmounted
			if (!isMounted) {
				resolve();
				return;
			}

			const data = dataRaw
				? camelCaseKeys(dataRaw, { deep: true })
				: { success: false };
			const { success, items: dataItems, totalPage } = data;

			if (success) {
				setOrderHistory({
					items: dataItems,
					totalPage,
					queryComplete: true, // Used for displaying feedback to user.
				});
			}
			resolve();
		});

		// componentWillUnmount
		return () => {
			isMounted = false;
		};
	}, [currentPage, customerId, endFetching, startFetching, isAdmin]);

	const columnHeadings = [
		'Order ID',
		'Contract No',
		'Items',
		'Date',
		'Total',
		'VAT',
		'Invoice',
	];

	//
	return (
		<>
			<SubHeadingSection heading={summaryTitle}>
				<AddNewUnitButton />
			</SubHeadingSection>
			<Content customClasses="order-history">
				{isAdmin && (
					<div className="row">
						<div className="col-12 key">
							<span className="key-warning">
								<span className="key-colour"></span>= Pending or abandoned order
							</span>
						</div>
					</div>
				)}
				{/* Items in the table, if we have any data to display. */}
				{orderHistory.items.length > 0 && (
					<Table columnHeadings={columnHeadings}>
						{orderHistory.items.map((row, index) => {
							let orderItems = row.lineItems.map((item, index) => {
								let label = item.name;

								// If this is key or box we will try and find the number.
								if (
									item.productId === replacementKeyProductId ||
									item.productId === replacementKeyAndBoxProductId
								) {
									let meta = item.metaData.find((data) => {
										return data.key === 'boxNumber';
									});

									if (meta) label += ' (' + meta.value + ')';
								}

								return label;
							});
							return (
								<TableRow
									key={`order-${row.id}`}
									cells={[
										{
											id: row.id,
											value: row.id,
											className: '',
											responsiveLabel: 'Order ID',
										},
										{
											id: 'contractId',
											value: row.contractId,
											responsiveLabel: 'Contract',
										},
										{
											id: 'items',
											value: orderItems.join(', '),
											responsiveLabel: 'Items',
										},
										{
											id: 'date',
											value: formatDate(row.dateCreatedGmt), // format date.
											responsiveLabel: 'Date',
										},
										{
											id: 'price',
											value: `${row.total} GBP`,
											responsiveLabel: 'Total',
										},
										{
											id: 'tax',
											value: `${row.totalTax} GBP`,
											responsiveLabel: 'Tax',
										},
										{
											id: 'invoice',
											value: (
												<Button
													label={`Download`}
													className="btn btn-small btn-secondary"
													onClick={(e) => {
														e.preventDefault();
														downloadInvoice(row.id);
													}}
												/>
											),
											className: 'download-invoice',
											responsiveLabel: 'Invoice',
										},
									]}
									className={row.status === 'pending' ? 'pending' : ''}
								/>
							);
						})}
					</Table>
				)}
				{/* If no results... */}
				{orderHistory.items.length === 0 && orderHistory.queryComplete && (
					<p className="order-history__loading">
						No order history to display at this time.
					</p>
				)}

				{/* Pagination */}
				{orderHistory.totalPage > 1 && (
					<Pagination
						currentPage={currentPage ? parseInt(currentPage) : 1}
						totalPage={parseInt(orderHistory.totalPage)}
						getPageUrl={routes.getOrderHistoryPaginationUrl}
						fetching={fetching}
					/>
				)}
			</Content>
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { fetching } = state.global;

	// Check if the user is admin so certain order statuses can be filtered out.
	const {
		user: { isAdmin },
	} = state.login;

	const {
		customer: { id: customerId },
	} = state.customer;

	const replacementKeyProductId = parseInt(
		process.env.REACT_APP_REPLACEMENT_KEY_PRODUCT_ID
	);
	const replacementKeyAndBoxProductId = parseInt(
		process.env.REACT_APP_REPLACEMENT_KEY_AND_BOX_PRODUCT_ID
	);

	return {
		isAdmin,
		fetching,
		customerId,
		replacementKeyProductId,
		replacementKeyAndBoxProductId,
	};
};

const mapDispatchToProps = {
	startFetching: globalActionCreators.startFetching,
	endFetching: globalActionCreators.endFetching,
	addErrorNotice: globalActionCreators.addErrorNotice,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
