import camelcaseKeys from 'camelcase-keys';

//
const transformDataToCamelCase = store => next => action => {
	const { camelCase } = action;

	//
	if (camelCase) {
		// transform data to camel case
		next(camelcaseKeys(action, { deep: true }));
	} else {
		// do not transform
		next(action);
	}
};

// export
export default transformDataToCamelCase;
