import React from 'react';
import { Link } from 'react-router-dom';

// routes
import routes from '../../../config/routesConfig';

//
import AdminMenuItems from './AdminMenuItems';
import CustomerMenuItems from './CustomerMenuItems';

//
const MainMenuItems = props => {
	const { userIsLoggedIn, userIsAdmin, isCustomerDataLoaded, currentVenueId, openCommentModal } = props;

	// User is not logged in.
	if (!userIsLoggedIn) {
		return <Link to={routes.LOGIN}>Login</Link>;
	}

	// admin menu items
	if (userIsAdmin) {
		return (
			<AdminMenuItems
				isCustomerDataLoaded={isCustomerDataLoaded}
				currentVenueId={currentVenueId}
				openCommentModal={openCommentModal}
			/>
		);
	}

	// customer menu items
	return <CustomerMenuItems currentVenueId={currentVenueId} />;
};

//
export default MainMenuItems;
