import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { customerActionCreators } from '../../redux/actions/customerActions';

// Components.
import ContactDetails from '../../components/ContactDetails';

// Common components.
import WelcomeBoard from '../../components/WelcomeBoard';
import InputText from '../../components/common/InputText';
import InputPassword from '../../components/common/InputPassword';

// Custom hooks.
import useForm from '../../hooks/useForm';

// Style.
import '../../assets/sass/frontPage.scss';
import './Register.scss';
import personalFormConfig from '../Personal/personalFormConfig';

/**
 * User registration form.
 */
const Register = ({
  fetching,
  userIsLoggedIn,
  // error,
  registerUser,
  // userCheckIfAccountExists,
}) => {
  // Setup the form config.
  let formConfig = personalFormConfig;

  // Overwrite the options from the personal form to make the password mandatatory.
  formConfig.password.required = true;

  // Setup the custom hook by passing the data.
  const {
    formData,
    inlineErrorMessages,
    validationArgs,
    onInputChange,
    onFormSubmit,
  } = useForm(
    // Initial data.
    {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      role: '',
      password: '',
    },
    // On submit.
    registerUser,
    // Form config.
    formConfig
  );

  /**
   * Check if an email address is registered with the same domain. This could
   * stop a user from registereing with another email address when they are
   * already using another email address.
   */
  // const checkIfSimilarEmailAddressExists = () => {};

  // The user should be redirected to the Dashboard if they are logged in.
  if (userIsLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className="container-fluid front-page front-page--register">
      <div className="row">
        <WelcomeBoard />
        <div className="col-lg-6 ">
          <div className="row h-100 justify-content-center">
            <div className="col-12 align-self-start">
              <ContactDetails />
            </div>
            <div className="col align-self-middle front-page__content">
              <h2 className="front-page__title">Create account</h2>
              <p>
                Please enter the information below to get started with
                CardsSafe! <strong>Please note:</strong> all fields are
                required.
              </p>

              <form onSubmit={onFormSubmit}>
                <p>
                  <InputText
                    labelText="Email address"
                    forAttribute="email"
                    name="email"
                    value={formData.email}
                    onChange={onInputChange}
                    errorMessage={
                      inlineErrorMessages.email
                        ? inlineErrorMessages.email.message
                        : ''
                    }
                    validationRules={['emailAddress']}
                  />
                </p>
                <p>
                  <InputText
                    labelText="First name"
                    forAttribute="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={onInputChange}
                    errorMessage={
                      inlineErrorMessages.firstName
                        ? inlineErrorMessages.firstName.message
                        : ''
                    }
                    validationRules={['firstName']}
                  />
                </p>
                <p>
                  <InputText
                    labelText="Last name"
                    forAttribute="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={onInputChange}
                    errorMessage={
                      inlineErrorMessages.lastName
                        ? inlineErrorMessages.lastName.message
                        : ''
                    }
                    validationRules={['lastName']}
                  />
                </p>
                <p>
                  <InputText
                    labelText="Job role/position"
                    forAttribute="role"
                    name="role"
                    value={formData.role}
                    onChange={onInputChange}
                    errorMessage={
                      inlineErrorMessages.role
                        ? inlineErrorMessages.role.message
                        : ''
                    }
                    validationRules={['notEmpty']}
                  />
                </p>

                <p>
                  <InputText
                    labelText="Phone number"
                    forAttribute="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={onInputChange}
                    errorMessage={
                      inlineErrorMessages.phone
                        ? inlineErrorMessages.phone.message
                        : ''
                    }
                    validationRules={['phoneNumber']}
                  />
                </p>
                <p>
                  <InputPassword
                    labelText="Password"
                    forAttribute="password"
                    name="password"
                    value={formData.password}
                    onChange={onInputChange}
                    errorMessage={
                      inlineErrorMessages.password
                        ? inlineErrorMessages.password.message
                        : ''
                    }
                    validationArgs={validationArgs.password}
                    type="password"
                    validationRules={['password']}
                  />
                </p>
                {/* The submit button is disabled until all fields have been entered */}
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={fetching}
                >
                  {fetching ? 'Please wait...' : 'Create my account'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { fetching } = state.global;
  const { userIsLoggedIn, error } = state.login;

  return {
    fetching,
    userIsLoggedIn,
    error,
  };
};

const mapDispatchToProps = {
  registerUser: customerActionCreators.customerRegisterRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
