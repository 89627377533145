import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Route } from 'react-router-dom';
import pubsub from 'pubsub-js';

// Config.
import routes from '../../../config/routesConfig';
import pubsubEvents from '../../../config/pubsubEvents';

// Utils.
import {
  getYearsInBusinessOptions,
  getChainOptions,
  getBusinessIndustryItems,
  getBusinessTypeOptions,
  getHospitalityOptions,
} from '../../../utils/formHelperFunctions.js';

// Redux.
import { customerActionCreators } from '../../../redux/actions/customerActions';

// Common components.
import StageNavigation from '../../../components/StageNavigation';

// The two steps for adding a new venue.
import AboutTheVenue from './AboutTheVenue';
import UnitOptions from './UnitOptions';

// HOC
import withProgressionControl from './withProgressionControl';

// Custom hooks.
import useForm from '../../../hooks/useForm';
import unitOptionsFormConfig from './addUnitVenueFormConfig';
// import useTraceUpdate from '../../../hooks/useTraceUpdate';

// Style.
import './AddUnitVenue.scss';

/**
 * This component is used when the user adds a new "venue" to the system.
 */
const AddUnitVenue = (props) => {
  const {
    venueFetching, // A boolean flag which indicates if an request is running.
    onAddNewVenueSuccess, // Function which should be called when a new venue has been added to the system.
    addVenueRequest,
    venue: unitVenue,
    customerId, // The current customer ID
    yearsInBusinessOptions, // Form config.
    // chainOptions, // Form config.
    businessTypeOptions, // Form config.
    businessIndustryItems, // Form config.
  } = props;

  const venue = unitVenue || {};

  // These values are required for form validation config'.
  const hospitalityOptions = getHospitalityOptions(businessIndustryItems);

  // Add dynamic settings to the form config object. Values which are configured in WP and passed via the REST API are added here.
  if (unitOptionsFormConfig.businessType.depends.establishmentType) {
    unitOptionsFormConfig.businessType.depends.establishmentType =
      hospitalityOptions;
  }

  // Extract values and set default values for the form inputs on the various
  // steps for adding a new unit.
  const {
    name,
    keyLabel,
    phone,
    email,
    businessYears = '',
    venueAddressLine1 = '',
    venueAddressLine2 = '',
    venueCounty = '',
    venueCity = '',
    venuePostcode = '',
    venueCountry = '',
    billingAddressLine1 = '',
    billingAddressLine2 = '',
    billingCounty = '',
    billingCity = '',
    billingPostcode = '',
    billingCountry = '',
    useKeyOffset = false,
    keyOffset,
    businessType,
    establishmentType,
    requirePurchaseOrderNumber,
    purchaseOrderNumber,
    companyInvoices,
    companyInvoicesName,
    companyLargerGroup,
    companyLargerGroupName,
    companyRegNo,
    companyVatNo,
  } = venue;

  /**
   * Create the venue when all data is ready. This is the submit method for the whole form.
   */
  const addNewVenue = useCallback(
    (formData) => {
      // Do not submit if there is a running request.
      if (venueFetching) {
        return;
      }

      // Init the redux action which will be handled by Redux Saga.
      addVenueRequest({
        ...formData,
        customerId,
      });
    },
    [customerId, addVenueRequest, venueFetching]
  );

  // Setup the custom useForm hook by passing the data.
  const {
    formData, // The data in the form.
    setFormData, // Function to update form data.
    inlineErrorMessages, // Error messages for each input.
    setInlineErrorMessages, // Allow components to manually set error messages.
    onInputChange, // Attached to each input in the form.
    canAdvanceRoutes, // The stages of the form which the user can advance to.
    updateCanAdvanceRoutes, // Update the stages of the form which is the user can view.
    copyVenueAddressToBilling, // Function used to duplicate the venue address in the billing section.
    isFormValid, // Can be called to check the form is valid in its current state.
    onFormSubmit, // Called to submit the form.
  } = useForm(
    {
      name,
      keyLabel,
      phone,
      email,
      businessYears,
      venueAddressLine1,
      venueAddressLine2,
      venueCounty,
      venueCity,
      venuePostcode,
      venueCountry,
      billingAddressLine1,
      billingAddressLine2,
      billingCounty,
      billingCity,
      billingPostcode,
      billingCountry,
      useKeyOffset,
      keyOffset,
      businessType,
      companyLargerGroup,
      companyInvoices,
      companyInvoicesName,
      companyLargerGroupName,
      companyRegNo,
      establishmentType,
      companyVatNo,
      requirePurchaseOrderNumber,
      purchaseOrderNumber,
    },
    addNewVenue,
    unitOptionsFormConfig // Custom config relating to the add unit stage. These settings ensure than the data we need is entered by the user.
  );

  /**
   * Handle logic when a new venue has been created. This code is called via a
   * pubsub event.
   */
  const handleAddVenueSuccess = useCallback(
    (eventName, venue) => {
      // dispatch to AddUnit action
      onAddNewVenueSuccess({
        ...venue,
        keyOffset: formData.keyOffset
          ? Math.max(parseInt(formData.keyOffset), 1)
          : 1,
      });
    },
    [onAddNewVenueSuccess, formData.keyOffset]
  );

  // On mount, first time only.
  React.useLayoutEffect(() => {
    // subscribe add venue success
    pubsub.subscribe(pubsubEvents.ADD_VENUE_SUCCESS, handleAddVenueSuccess);

    // On unmount.
    return () => {
      // Unsubscribe.
      pubsub.unsubscribe(pubsubEvents.ADD_VENUE_SUCCESS, handleAddVenueSuccess);
    };
  }, [handleAddVenueSuccess]);

  // // For debugging prop/render issues.
  // useTraceUpdate(
  //   {
  //     formData,
  //     setFormData,
  //     inlineErrorMessages,
  //     onInputChange,
  //     canAdvanceRoutes,
  //     updateCanAdvanceRoutes,
  //     copyVenueAddressToBilling,
  //     isFormValid,
  //     onFormSubmit,
  //     handleAddVenueSuccess,
  //   },
  //   'AddUnitVenue'
  // );

  return (
    <div className="add-venue">
      <StageNavigation
        // The first step is always accessible
        activeRoutes={[routes.ADD_UNIT_VENUE_STEP_1, canAdvanceRoutes]}
        links={[
          {
            to: routes.ADD_UNIT_VENUE_STEP_1,
            label: 'About the venue',
          },
          {
            to: routes.ADD_UNIT_VENUE_STEP_2,
            label: 'Invoicing',
          },
        ]}
      />
      <form className="add-venue__form" onSubmit={onFormSubmit}>
        <Route
          path={routes.ADD_UNIT_VENUE_STEP_1}
          exact
          render={(routeProps) => {
            return (
              <AboutTheVenue
                businessIndustry={businessIndustryItems}
                businessTypeOptions={businessTypeOptions}
                canAdvanceRoutes={canAdvanceRoutes}
                formData={formData}
                inlineErrorMessages={inlineErrorMessages}
                isFormValid={isFormValid}
                onInputChange={onInputChange}
                route={routeProps}
                setFormData={setFormData}
                setInlineErrorMessages={setInlineErrorMessages}
                updateCanAdvanceRoutes={updateCanAdvanceRoutes}
                yearsInBusinessOptions={yearsInBusinessOptions}
              />
            );
          }}
        />
        <Route
          path={routes.ADD_UNIT_VENUE_STEP_2}
          exact
          render={(routeProps) => {
            return (
              <UnitOptions
                copyVenueAddressToBilling={copyVenueAddressToBilling}
                formData={formData}
                inlineErrorMessages={inlineErrorMessages}
                onInputChange={onInputChange}
                setFormData={setFormData}
                venueFetching={venueFetching}
              />
            );
          }}
        />
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  // Customer details.
  const {
    venueFetching,
    customer: { id: customerId },
    acf,
  } = state.customer;

  // ACF config, form settings from the WP admin area.
  const yearsInBusinessOptions = getYearsInBusinessOptions(acf, true);
  const chainOptions = getChainOptions(acf, true);
  const businessIndustryItems = getBusinessIndustryItems(acf);
  const businessTypeOptions = getBusinessTypeOptions();

  return {
    customerId,
    venueFetching,
    yearsInBusinessOptions,
    chainOptions,
    businessIndustryItems,
    businessTypeOptions,
  };
};

const mapDispatchToProps = {
  // Action to add a new venue.
  addVenueRequest: customerActionCreators.addVenueRequest,
};

export default compose(
  withProgressionControl,
  connect(mapStateToProps, mapDispatchToProps)
)(AddUnitVenue);
