import React from 'react';
import { Link } from 'react-router-dom';

// Routing.
import routes from '../../config/routesConfig';

// Simple "add new unit" button.
const AddNewUnitButton = props => {
	return (
		<Link className="btn btn-primary" to={routes.ADD_UNIT}>
			Add New Unit(s)
		</Link>
	);
};

export default AddNewUnitButton;
