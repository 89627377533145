import React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../config/routesConfig';

const AddNewUnit = props => {
	return (
		<div className="justify-content-center add-new-unit d-flex h-100 ">
			<Link to={routes.ADD_UNIT} className="align-self-center ">
				<span className="plus">
					<i className="icon-plus-round"></i>
				</span>
				<span className="text">Add new unit</span>
			</Link>
		</div>
	);
};

export default AddNewUnit;
