import React from 'react';
import { connect } from 'react-redux';

// Hooks.
import useForm from '../../hooks/useForm';

// redux
import { adminActionCreators } from '../../redux/actions/adminActions';

// Common components.
import InputText from '../../components/common/InputText';
import Button from '../../components/common/Button';
import Content from '../../containers/Content';

// Style.
import './CreateUser.scss';
import personalFormConfig from '../Personal/personalFormConfig';

// @todo The form is working but "role" is now showing up in the user edit screen after the account has been created. Need to check that the API is working properly.

/**
 * Componenet for adding a new user to the system. This is used by admin level users only.
 * @param {Object}
 */
const CreateUser = ({ fetching, createUser }) => {
  // Base the form config off of the standard set of user profile data.
  const formConfig = personalFormConfig;
  delete formConfig.password; // Password input is not required.

  // Setup the form hook.
  const {
    formData,
    inlineErrorMessages,
    onInputChange,
    onFormSubmit,
  } = useForm(
    {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      role: '',
    },
    createUser,
    formConfig
  );

  return (
    <Content customClasses="create-user">
      <p>
        Enter the details below to create a new user. The user will receive an
        email with a link to create a password.
      </p>
      <form className="form" autoComplete="off" onSubmit={onFormSubmit}>
        <p>
          <InputText
            labelText="First Name:"
            forAttribute="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={onInputChange}
            errorMessage={
              inlineErrorMessages.firstName
                ? inlineErrorMessages.firstName.message
                : ''
            }
          />
        </p>
        <p>
          <InputText
            labelText="Last Name:"
            forAttribute="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={onInputChange}
            errorMessage={
              inlineErrorMessages.lastName
                ? inlineErrorMessages.lastName.message
                : ''
            }
          />
        </p>
        <p>
          <InputText
            labelText="Phone:"
            forAttribute="phone"
            name="phone"
            value={formData.phone}
            onChange={onInputChange}
            errorMessage={
              inlineErrorMessages.phone ? inlineErrorMessages.phone.message : ''
            }
            type="tel"
          />
        </p>
        <p>
          <InputText
            labelText="Email:"
            forAttribute="email"
            name="email"
            value={formData.email}
            onChange={onInputChange}
            errorMessage={
              inlineErrorMessages.email ? inlineErrorMessages.email.message : ''
            }
            type="email"
          />
        </p>
        <p>
          <InputText
            labelText="Role/position:"
            forAttribute="role"
            name="role"
            value={formData.role}
            onChange={onInputChange}
            errorMessage={
              inlineErrorMessages.role ? inlineErrorMessages.role.message : ''
            }
          />
        </p>

        <div className="col-12">
          <Button
            type="submit"
            disabled={fetching}
            label={fetching ? 'Please wait...' : 'Create profile'}
            className="btn btn-secondary"
          />
        </div>
      </form>
    </Content>
  );
};

const mapStateToProps = (state) => {
  const { fetching } = state.global;

  return { fetching };
};

const mapDispatchToProps = {
  createUser: adminActionCreators.createUserRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
