import axios from 'axios';

// config
import { useFixtureApi } from '../config/globalConfig';

/**
 * init the api
 */
const api = useFixtureApi
  ? null
  : axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      withCredentials: false, // For cookies.
    });

//
export const getApi = () => api;

// token handlers
export const storeToken = (token) => {
  if (!api) {
    return;
  }

  // api.setHeader('Authorization', `Bearer ${token}`)
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

// Clear token
export const clearToken = () =>
  api && delete api.defaults.headers.common['Authorization'];
