import createReducer from '../createReducer';
import { commentModalActionTypes } from '../actions/commentModalActions';

// The initial state
const initialState = {
	isActive: false,
	heading: null,
	unitId: null, // 0 or null means displaying global comment
	error: null,
};

// OPEN_COMMENT_MODAL
const openCommentModal = (state, { heading, unitId }) => ({
	...state,
	heading,
	unitId,
	isActive: true,
});

// CLOSE_COMMENT_MODAL
const closeCommentModal = state => initialState;

// ADD_COMMENT_REQUEST
const addCommentRequest = state => ({
	...state,
	error: null,
});

// ADD_COMMENT_FAILURE
const addCommentFailure = (state, { error }) => ({
	...state,
	error,
});

// DELETE_COMMENT_REQUEST
const deleteCommentRequest = state => ({
	...state,
	error: null,
});

// DELETE_COMMENT_FAILURE
const deleteCommentFailure = (state, { error }) => ({
	...state,
	error,
});

// reducer
export const commentModalReducer = createReducer(initialState, {
	// Open and close the modal.
	[commentModalActionTypes.OPEN_COMMENT_MODAL]: openCommentModal,
	[commentModalActionTypes.CLOSE_COMMENT_MODAL]: closeCommentModal,

	// Add/delete comments.
	[commentModalActionTypes.ADD_COMMENT_REQUEST]: addCommentRequest,
	[commentModalActionTypes.ADD_COMMENT_FAILURE]: addCommentFailure,
	[commentModalActionTypes.DELETE_COMMENT_REQUEST]: deleteCommentRequest,
	[commentModalActionTypes.DELETE_COMMENT_FAILURE]: deleteCommentFailure,
});
