/*
Each field in the config object should be specified by a key which is the same as the input's name attribute. Each nested object can then contain the following props;

required: Boolean
requiredMessage: String A general purpose error message for this input.
depends:Object A config object which makes the validation of the current input based on another input's value. This is useful when certain inputs are only required on a conditional basis.
validationRules: Array One or more validation methods for an input.
*/

const venueFormConfig = {
	// The main name value for the venue.
	venueName: {
		required: true,
		requiredMessage: 'Please enter the name of your venue.',
	},
	// The label which is displayed on keys.
	keyLabel: {
		required: true,
		requiredMessage: 'Please enter a key label value.',
		validationRules: [{ name: 'notEmpty' }, { name: 'keyLabel' }],
	},
	// The venue phone number.
	phone: {
		required: true,
		requiredMessage: 'Please enter a phone number for your venue.',
		validationRules: [{ name: 'phoneNumber' }],
	},
	email: {
		required: true,
		requiredMessage: 'Please enter an email address for this venue.',
		validationRules: [
			{
				name: 'emailAddress',
			},
		],
	},
	// How many years the business has been established.
	businessYears: {
		required: true,
		requiredMessage:
			'Please select the number of years that this venue has been in business for.',
	},
	// Type of business. This is applicable when the user's business is part of
	// the hospitality industry.
	businessType: {
		depends: {
			// Note, this value needs to be placed with dynamic values before it can
			// be used.
			establishmentType: [],
		},
		required: true,
		requiredMessage: 'Select your type of business.',
	},

	establishmentType: {
		required: true,
		requiredMessage: 'Please select your business industry.',
	},

	// Is the venue part of a larger group, and if so, which one?
	companyLargerGroup: {
		required: true,
		requiredMessage:
			'Please tell us if this venue is part of a larger company/group.',
	},
	companyLargerGroupName: {
		depends: {
			companyLargerGroup: '1',
		},
		required: true,
		requiredMessage: 'Please enter the company group name.',
	},

	// Sole trader or registered business, and business number is required.
	businessStatus: {
		required: true,
		requiredMessage: 'Please select your trading status.',
	},
	companyRegNo: {
		required: true,
		requiredMessage: 'Please enter your company registration number.',
		depends: {
			businessStatus: 'Registered Company',
		},
	},
	// Who is responsible for paying invoices?
	companyInvoices: {
		required: true,
		requiredMessage:
			'Please tell us if this business is responsible for paying CardsSafe invoices.',
	},
	companyInvoicesName: {
		depends: {
			companyInvoices: '0',
		},
		required: true,
		requiredMessage:
			'Please tell us the name of the company that is responsible for paying invoices.',
	},
	// Does the client use a purchase order number?
	requirePurchaseOrderNumber: {
		required: true,
		requiredMessage:
			'Please tell us if you required a purchase order (PO) number.',
	},
	purchaseOrderNumber: {
		depends: {
			requirePurchaseOrderNumber: '1',
		},
		required: true,
		requiredMessage:
			'Please enter the purchase order number that CardsSafe should use.',
	},
	// Optional VAT number.
	companyVatNo: {
		required: false,
	},
	// Invoice/billing address.
	billingAddressLine1: {
		required: true,
		requiredMessage: 'Please enter a value for Invoice address line 1.',
	},
	billingAddressLine2: {
		required: false, // Note that address line 2 is not madatory.
		requiredMessage: 'Please enter a value for Invoice address line 2.',
	},
	billingCity: {
		required: true,
		requiredMessage: 'Please enter a value for Invoice city.',
	},
	billingCounty: {
		required: true,
		requiredMessage: 'Please enter a value for Invoice county.',
	},
	billingPostcode: {
		required: true,
		requiredMessage: 'Please enter a value for Invoice postcode.',
	},
	billingCountry: {
		required: true,
		requiredMessage: 'Please enter a value for Invoice country.',
	},
	// The address of the venue.
	venueAddressLine1: {
		required: true,
		requiredMessage: 'Please enter a value for Venue address line 1.',
	},
	venueAddressLine2: {
		required: false,
		requiredMessage: 'Please enter a value for Venue address line 2.',
	},
	venueCity: {
		required: true,
		requiredMessage: 'Please enter a value for Venue city.',
	},
	venueCounty: {
		required: true,
		requiredMessage: 'Please enter a value for Venue county.',
	},
	venuePostcode: {
		required: true,
		requiredMessage: 'Please enter a value for Venue postcode.',
	},
	venueCountry: {
		required: true,
		requiredMessage: 'Please enter a value for Venue country.',
	},
};
export default venueFormConfig;
