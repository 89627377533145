import React, { useState } from 'react';
import PropTypes from 'prop-types';

// styles
import './Tooltip.scss';

// A simple tooltip.
const Tooltip = ({ icon, text, children }) => {
	const [isDisplayTooltip, setIsDisplayTooltip] = useState(false);

	const toggleDisplay = () => {
		setIsDisplayTooltip(!isDisplayTooltip);
	};

	return (
		<span className="tooltip-wrapper">
			<span onClick={toggleDisplay}>{children}</span>

			{isDisplayTooltip && (
				<span className="tooltip">
					<i className="icon-cardssafe-unit"></i> {text}
				</span>
			)}
		</span>
	);
};

Tooltip.propTypes = {
	icon: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

export default Tooltip;
