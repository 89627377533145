import React from 'react';

// Style.
import './TableRow.scss';

// A single row from a table layout.
const TableRow = ({ cells = [], className = '' }) => {
  return (
    <div className={`table__row align-items-center ${className}`}>
      {cells.map((cell, index) => {
        // Should the contents be escaped.
        const { escape, className, value, id, responsiveLabel = '' } = cell;

        return escape ? (
          <span
            className={`col table__row__cell ${className || ''}`}
            key={`${id}-${index}`}
            dangerouslySetInnerHTML={{ __html: value }}
            data-label={responsiveLabel}
          />
        ) : (
          <span
            className={`col table__row__cell ${className || ''}`}
            key={`${id}-${index}`}
            data-label={responsiveLabel}
          >
            {!escape && value}
          </span>
        );
      })}
    </div>
  );
};

export default TableRow;
