import React from 'react';

const SVGKey = ({ keyNumber = '01', name = '', phone = '' }) => {
	return (
		<svg
			width="682.79px"
			height="214px"
			version="1.1"
			viewBox="0 0 682.789 214"
		>
			<path
				d="m675.5 131.84v-60.17c4-0.482 5.789-3.166 5.789-6.415 0-3.388-2.521-5.918-5.876-6.22l-68.401-36.53h-449.51v-2.917c0-9.949-6.84-18.083-15.499-18.083h-65.667c-41.086 0-74.834 27.474-74.834 61.375v88.344c0 33.901 33.748 61.281 74.834 61.281h65.667c8.659 0 15.499-7.947 15.499-17.896v-5.104h449.51l68.488-44.835c4-0.481 5.574-3.166 5.574-6.415s-1.574-5.934-5.574-6.415z"
				fill="#aa359f"
				stroke="#000"
				strokeLinecap="round"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<circle
				cx="636"
				cy="104"
				r="10"
				fill="none"
				stroke="#000"
				strokeLinecap="round"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<path
				d="m136.5 171.36c0 7.31-4.723 13.143-11.084 13.143h-48.242c-30.184 0-54.674-20.109-54.674-45.015v-64.901c0-24.906 24.49-45.084 54.674-45.084h48.242c6.361 0 11.084 5.971 11.084 13.28v128.58z"
				fill="none"
				stroke="#000"
				strokeLinecap="round"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<path
				d="m128.5 164.99c0 6.586-2.894 12.509-8.626 12.509h-38.47c-27.198 0-49.904-18.787-49.904-41.229v-58.48c0-22.442 22.706-39.291 49.904-39.291h38.47c5.732 0 8.626 4.046 8.626 10.633v115.86z"
				fill="none"
				stroke="#000"
				strokeLinecap="round"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<circle
				cx="83.667"
				cy="74"
				r="25.667"
				fill="#fff"
				stroke="#000"
				strokeLinecap="round"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<text transform="matrix(0 -1 1 0 92.346 87.634)" fontSize="27">
				{keyNumber}
			</text>
			<polygon
				points="663.5 55 168 55 168 48 650 48"
				fill="none"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<polygon
				points="663.5 152 168 152 168 159 650 159"
				fill="none"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<rect x="156" y="21" width="4" height="170" />
			<rect
				x="171.5"
				y="74.5"
				width="419"
				height="63"
				fill="#fff"
				stroke="#000"
				strokeMiterlimit="10"
			/>
			<text transform="translate(544.95 115.1)" fontSize="31">
				{keyNumber}
			</text>
			<text transform="translate(200 105)" fontSize="25">
				{name}
			</text>
			<text transform="translate(200 130)" fontSize="18">
				{phone}
			</text>
		</svg>
	);
};

export default SVGKey;
