import validator from 'validator';
import { PasswordMeter } from 'password-meter';
import { minimumPasswordStrength } from '../../config/globalConfig';

// RegEx for testing phone numbers.
const PHONEREGEX = new RegExp('^[0-9+() ]+$');

/**
 * Validate a user's first name.
 */
export const firstName = (value) => {
  // Check we have a value.
  if (validator.isEmpty(value)) {
    return {
      isValid: false,
      message: 'You must enter your first name.',
    };
  }

  // @TODO Check for disallowed characters.

  return {
    isValid: true,
  };
};

/**
 * Validate a user's last name.
 */
export const lastName = (value) => {
  // Check we have a value.
  if (validator.isEmpty(value)) {
    return {
      isValid: false,
      message: 'You must enter your last name.',
    };
  }

  // @TODO Check for disallowed characters.

  return {
    isValid: true,
  };
};

/**
 * Validate a phone number. This could be a mobile or landline number.
 */
export const phoneNumber = (value) => {
  if (!value) {
    return {
      message: 'You must enter a phone number.',
      isValid: false,
    };
  }

  if (value.length < 10) {
    return {
      message: 'Your phone number is too short',
      isValid: false,
    };
  }

  // Check for allowed chars.
  if (!PHONEREGEX.test(value)) {
    return {
      message: 'You many only use numbers and these characters: ( ) + -',
      isValid: false,
    };
  }

  return {
    isValid: true,
  };
};

/**
 * Validate an email address/
 */
export const emailAddress = (value) => {
  // Note: check if value is null or undefined first, otherwise isEmail will
  // throw an error if called without passing a string.
  if (!value || !validator.isEmail(value)) {
    return {
      isValid: false,
      message: 'Your email address is not valid.',
    };
  }

  return {
    isValid: true,
  };
};

/**
 * Validate a password.
 */
export const password = (value) => {
  /**
   * @see https://www.npmjs.com/package/password-meter
   */
  const testPasswordResult = new PasswordMeter().getResult(value);
  // console.log(testPasswordResult);
  const { percent: score } = testPasswordResult;

  // Weak.
  if (score < 30) {
    return {
      isValid: false,
      message: 'Your password is too weak.',
      ruleArgs: { score, meter: 'weak' },
    };
  }

  // Medium.
  if (score < minimumPasswordStrength) {
    return {
      isValid: false,
      message:
        'Your password is medium strength, add special characters, numbers and punctuation to make it stronger!',
      ruleArgs: { score, meter: 'medium' },
    };
  }

  // Strong enough.,
  return {
    isValid: true,
    message: 'Great! That is strong password!',
    ruleArgs: { score, meter: 'strong' },
  };
};

// validate venue name
export const venueName = (value, venueList = []) => {
  // empty
  if (!value) {
    return {
      isValid: false,
      message: 'Please enter the venue name.',
    };
  }

  if (value.length < 3) {
    return {
      isValid: false,
      message: 'Please enter at least 3 characters.',
    };
  }

  // exist
  const existingVenue = venueList.find((venue) => venue.name === value);
  if (existingVenue) {
    return {
      isValid: false,
      message:
        'This venue already exists. Please go back and select the existing venue to procced with this name.',
    };
  }

  //
  return {
    isValid: true,
  };
};

/**
 * Validate a venue key description.
 *
 * @param {Mixed} value
 */
export const venueKeyLabel = (value) => {
  // If the label contains only numbers there must be enough characters for a
  // phone number.
  // Check for allowed chars.
  if (PHONEREGEX.test(value)) {
    if (value.length < 10) {
      return {
        message: 'A phone number must contain at least 10 characters.',
        isValid: false,
      };
    } else {
      return {
        isValid: true,
      };
    }
  }

  // If the value contains an @ symbol we will assume it's an email address and
  // test if it's valid for use.
  return emailAddress(value);
};

// Check if an input is empty.
export const notEmpty = (value) => {
  if (value === null || value === undefined || value.length === 0) {
    return {
      isValid: false,
      message: 'Please enter a value.',
    };
  }

  return {
    isValid: true,
  };
};

// Integer only.
export const integer = (value) => {
  if (!validator.isInt(value)) {
    return {
      isValid: false,
      message: 'You may only enter numbers.',
    };
  }

  return {
    isValid: true,
  };
};

// Check that a value doesn't start with "0".
export const notStartWithZero = (value) => {
  if (value[0] === '0') {
    return {
      isValid: false,
      message: 'Should not start with 0',
    };
  }
  return {
    isValid: true,
  };
};

// Check that a value is not equal to "1".
export const notEqualToOne = (value) => {
  if (value === '1') {
    return {
      isValid: false,
      message:
        'If you want to use "1" as your starting number you do not need to use the custom starting number option.',
    };
  }
  return {
    isValid: true,
  };
};

//
export const maxLength = (value, conditionString) => {
  const maxLengthValue = parseInt(conditionString);

  // A value may not have been entered.
  if (!value) {
    return {
      isValid: true,
    };
  }

  if (value.length > maxLengthValue) {
    return {
      isValid: false,
      message: 'The field should be at most 3 characters of length',
    };
  }
  return {
    isValid: true,
  };
};
