/**
 * The global reducer handles properties which are used globally.
 */
import createReducer from '../createReducer';
import { globalActionTypes } from '../actions/globalActions';
import randomId from '../../utils/randomId';

// The initial state
const initialState = {
  fetching: false,
  fetchingMessage: '', // Notice text next to loading icon.
  notices: [],
};

// Call to start fetching state. Used for animations.
const startFetching = (state, { fetchingMessage = '' }) => {
  return {
    ...state,
    fetching: true,
    fetchingMessage,
  };
};

// Call to end fetching state.
const endFetching = (state) => ({
  ...state,
  fetching: false,
  fetchingMessage: '',
});

// Add a global notice.
const addNotice = (state, { notice }) => ({
  ...state,
  notices: [
    ...state.notices,
    {
      ...notice,
      id: randomId(),
    },
  ],
});

// Remove a global notice.
const removeNotice = (state, { noticeId }) => ({
  ...state,
  notices: state.notices.filter((notice) => notice.id !== noticeId),
});

export const globalReducer = createReducer(initialState, {
  [globalActionTypes.START_FETCHING]: startFetching,
  [globalActionTypes.END_FETCHING]: endFetching,
  // notices
  [globalActionTypes.ADD_NOTICE]: addNotice,
  [globalActionTypes.REMOVE_NOTICE]: removeNotice,
});
