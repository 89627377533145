/**
 * Various helper functions which format data so it's ready for use with forms.
 */

// Return a formatted list of options for a <select> element.
export const getYearsInBusinessOptions = (acf, withBlank = false) => {
  if (!acf.yearsInBusiness) {
    return [];
  }

  let options = acf.yearsInBusiness.map((item) => {
    return {
      id: item.option,
      name: item.option,
    };
  });

  if (withBlank) {
    return [{ id: null, value: '', name: '--select--' }].concat(options);
  } else {
    return options;
  }
};

// Return a formatted list of options for the billing chain.
export const getChainOptions = (acf, withBlank = false) => {
  if (!acf.billingChain) {
    return [];
  }

  let options = acf.billingChain.map((item) => {
    return {
      id: item.option,
      name: item.option,
    };
  });

  if (withBlank) {
    return [{ id: null, value: '', name: '--select--' }].concat(options);
  } else {
    return options;
  }
};

/**
 * Return a parent -> child list of business industries. The parent is a sector category and the children
 * are the actual options that the user will select. The settings come from the ACF API which is controlled
 * in the admin area.
 */
export const getBusinessIndustryItems = (acf, withBlank = false) => {
  if (!acf.businessIndustries) {
    return [];
  }

  let options = [];

  acf.businessIndustries.forEach((item) => {
    // To collect child items.
    let nestedOptions = [];

    // Loop over teh child items.
    if (item.businessIndustryOptions) {
      nestedOptions = item.businessIndustryOptions.map((item) => {
        return {
          id: item.option.trim().toLowerCase().replace(/\s/g, '-'),
          name: item.option,
          value: item.option,
          label: item.option,
        };
      });
    }

    options.push({
      parent: item.industryName,
      children: nestedOptions,
    });
  });

  if (withBlank) {
    return [{ id: null, value: '', name: '--select--' }].concat(options);
  } else {
    return options;
  }
};

/**
 * Options for businessType inputs.
 * @return {array}
 */
export const getBusinessTypeOptions = () => {
  return [
    { id: null, value: '', name: '--select--' },
    { id: 'Not applicable', value: 'Not applicable', name: 'Not applicable' },
    { id: 'Tenanted', value: 'Tenanted', name: 'Tenanted' },
    { id: 'Managed', value: 'Managed', name: 'Managed' },
    { id: 'Freehold', value: 'Freehold', name: 'Freehold' },
  ];
};

/**
 * Return a segment of the business indursty options. Value only.
 * @param  {string} businessIndustry
 * @return {array}
 */
export const getHospitalityOptions = (
  businessIndustry,
  segment = 'Hospitality'
) => {
  // Extract the Hospitality items from the settings.
  let selectedSegment = businessIndustry.filter((item) => {
    return item.parent === segment;
  });

  // If there's a match, return the children (values only)
  if (selectedSegment.length > 0) {
    return selectedSegment[0].children.map((option) => option.value);
  }

  return [];
};
