import { getApi } from '../../services/api';

const apiUpdateVenueAddresses = async (api, data) => {
  const { venueId, billingAddress, shippingAddress } = data;

  try {
    await api.post(`/cardssafe-account/v1/updateVenue`, {
      id: venueId,
      billing_address: {
        line_1: billingAddress.line1,
        line_2: billingAddress.line2,
        country: billingAddress.country,
        city: billingAddress.city,
        state: billingAddress.state,
        postcode: billingAddress.postcode,
      },
      shipping_address: {
        line_1: shippingAddress.line1,
        line_2: shippingAddress.line2,
        country: shippingAddress.country,
        city: shippingAddress.city,
        state: shippingAddress.state,
        postcode: shippingAddress.postcode,
      },
    });

    // success
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response ? error.response.data.message : error.message,
    };
  }
};

const updateVenueAddresses = (data) => {
  const api = getApi();
  // api is null - use fixture
  if (!api) {
    return {
      success: false,
      message: 'Api is not configured.',
    };
  }

  return apiUpdateVenueAddresses(api, data);
};

export default updateVenueAddresses;

// Sample data.
/*
	{
  "venue_name": "",
  "customer": 1,
  "email": "",
  "phone": "",
  "default": "",
  "job_title": "",
  "business_type": "",
  "business_type_other": "",
  "business_years": "",
  "establishment_type": "",
  "establishment_type_other": "",
  "company_reg_no": "",
  "company_vat_no": "",
  "company_larger_group": "",
  "company_larger_group_name": "",
  "company_invoices": "",
  "company_invoices_name": "",
  "chain": "",
  "billing_address": {
    "line_1": "",
    "line_2": "",
    "country": "",
    "city": "",
    "state": "",
    "postcode": ""
  },
  "shipping_address": {
    "line_1": "",
    "line_2": "",
    "country": "",
    "city": "",
    "state": "",
    "postcode": ""
  },
  "key_offset": ""
} */
