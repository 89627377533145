import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Config.
import routes from '../../config/routesConfig';

import './MiniCart.scss';

// Show the quantity of items in the cart and link to the cart page.
const MiniCart = ({ quantity }) => {
	return (
		<div className="mini-cart">
			<Link className="icon-shopping-cart" to={routes.CART}>
				{quantity} item(s)
			</Link>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		quantity: state.cart.items ? Object.keys(state.cart.items).length : 0,
	};
};

export default connect(mapStateToProps)(MiniCart);
