import React from 'react';

import './Select.scss';

/**
 * <select> input for forms.
 */
const Select = (props) => {
  const {
    labelText,
    forAttribute,
    name,
    value,
    onChange = null,
    errorMessage,
    className = 'form-control',
    options = [],
    validationRules = [],
    conditionalValidation = {},
    required = true, // Should the "required" styling be used?
  } = props;

  return (
    <label className="input-select" htmlFor={forAttribute}>
      <span className="label">
        {labelText} {required && <span className="required">*</span>}
      </span>
      <select
        id={forAttribute}
        name={name}
        className={className}
        onChange={onChange}
        value={value}
        data-validation-rules={
          validationRules.length ? JSON.stringify(validationRules) : ''
        }
        data-conditional-validation={JSON.stringify(conditionalValidation)}
      >
        {options.map((option, index) => {
          const optionValue = option.id || option.value;
          const optionLabel = option.name || option.label;

          return (
            <option key={`${index}-${name}`} value={optionValue}>
              {optionLabel}
            </option>
          );
        })}
      </select>
      {errorMessage && <span className="inline-error">{errorMessage}</span>}
    </label>
  );
};

export default Select;
