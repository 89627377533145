import React from 'react';
import { connect } from 'react-redux';

// Routes.
import routes from '../../config/routesConfig';

// Common components.
import SubHeadingSection from '../../components/SubHeadingSection';
import SubNavigationBar from '../../components/SubNavigationBar';
import AddNewUnitButton from '../../components/common/AddNewUnitButton';

// Child components.
import NoContract from './NoContract';
import ContractDetails from './ContractDetails';

/**
 * View and manage a single contract. Includes dates, general stats and ability to cancel.
 */
const Contract = ({ match, contracts }) => {
  // Get the current contract ID from the route.
  const { contractId } = match.params;

  // Check that the contract exists and has data.
  let currentContract = contracts.find(
    (contractObject) => contractObject.id === contractId
  );

  return (
    <div className="contract">
      <SubHeadingSection heading={'Your contract'}>
        <AddNewUnitButton />
      </SubHeadingSection>
      <SubNavigationBar
        active={contractId}
        menuItems={contracts}
        menuLabel="View contract:"
        baseUrl={routes.CONTRACT}
        baseKey="ContractNavigation"
      />
      {currentContract ? (
        <ContractDetails {...currentContract} />
      ) : (
        <NoContract />
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    user: { isAdmin },
  } = state.login;

  const { currentVenueId, venues } = state.customer;

  // Extract the current venue and then its contracts.
  const venue = venues.find((object) => object.id === currentVenueId);

  // Filter out any incomplete contracts for front-end users. Admin users can see all contracts.
  const contracts = venue.contracts || [];

  return {
    isAdmin,
    contracts,
  };
};

const mapDispatchToProps = {
  // Action to request contract cancellation.
};

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
