import createReducer from '../createReducer';
import { cartActionTypes } from '../actions/cartActions';

// the initial state
export const initialState = {
  loading: false,
  items: {},
  cartTotals: null,
  // Track any coupon codes in the cart.
  couponCode: '',
  couponCodeNotice: null,
};

//
const shouldUpdateCart = (state, payload) => {
  const {
    isPersist,
    cart: { cartTotals, coupons, ...cartItems },
  } = payload;

  // not persisting -> force updating cart
  if (!isPersist) {
    return true;
  }

  // Check if coupon code has changed.
  if (Array.isArray(coupons) && coupons[0] !== state.couponCode) {
    return true;
  }

  // do not update cart if the cart on server side is the same as local
  return JSON.stringify(state.items) !== JSON.stringify(cartItems);
};

// update cart
const updateCart = (state, payload) => {
  if (!shouldUpdateCart(state, payload)) {
    return state;
  }

  //
  const {
    cart: { cartTotals, coupons, ...cartItems },
  } = payload;

  return {
    ...state,
    items: cartItems ? cartItems : {},
    cartTotals,
    couponCode: Array.isArray(coupons) ? coupons[0] : '',
    loading: false,
  };
};

// clear cart
const clearCart = () => initialState;

//
const addReplacementPartsToCart = (state) => {
  return {
    ...state,
    loading: true,
  };
};

//
const addReplacementPartsToCartFailure = (state) => {
  return {
    ...state,
    loading: false,
  };
};

// Update the coupon code in the Redux store when the input value is changed.
const updateCouponCode = (state, { code = null }) => {
  return {
    ...state,
    couponCode: code,
  };
};

// When a coupon is being applied we need to set the loading state of the cart.
const applyCouponCode = (state) => {
  return {
    ...state,
    loading: true,
  };
};

// Remove a specific coupon code.
const removeCouponCode = (state) => {
  return {
    ...state,
    loading: true,
  };
};

// Remove all coupon codes.
const removeAllCouponCodes = (state) => {
  return {
    ...state,
    couponCode: '',
    couponCodeNotice: null,
  };
};

// Set a notice message which is returned by the API.
const setCouponCodeNotice = (state, { message }) => {
  return {
    ...state,
    couponCodeNotice: message,
  };
};

// cart reducer
export const cartReducer = createReducer(initialState, {
  [cartActionTypes.ADD_REPLACEMENT_PARTS_TO_CART]: addReplacementPartsToCart,
  [cartActionTypes.ADD_REPLACEMENT_PARTS_TO_CART_FAILURE]: addReplacementPartsToCartFailure,
  [cartActionTypes.UPDATE_CART]: updateCart,
  [cartActionTypes.CLEAR_CART]: clearCart,
  [cartActionTypes.UPDATE_COUPON_CODE]: updateCouponCode,
  [cartActionTypes.APPLY_COUPON_CODE]: applyCouponCode,
  [cartActionTypes.REMOVE_COUPON_CODE]: removeCouponCode,
  [cartActionTypes.REMOVE_COUPON_CODES]: removeAllCouponCodes,
  [cartActionTypes.SET_COUPON_CODE_NOTICE]: setCouponCodeNotice,
});
