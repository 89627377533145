// This file contains functions which enable address lookup via postcode. This uses
// the "Ideal Postcodes" service. The API key must be confirgured in the .env files.
import IdealPostcodes from 'ideal-postcodes';

// const idealPostcodes = IdealPostcodes(process.env.REACT_APP_IDEAL_POSTCODES_API_KEY);

/**
 * Perform an address lookup requst using a postcode.
 *
 * @param {string} postcode
 * @param {function} callback
 *
 * @see https://ideal-postcodes.co.uk/documentation/testing
 */
export const findAddressByPostcode = (postcode, callback) => {
	// For testing use ID1 1QD
	// @see https://ideal-postcodes.co.uk/documentation/testing

	// try loading cache first
	const addresses = getAddressLookupCache(postcode);
	if (addresses) {
		callback({
			success: true,
			addresses,
		});
		return;
	}

	// API

	// init the api
	const idealPostcodes = IdealPostcodes(process.env.REACT_APP_IDEAL_POSTCODES_API_KEY);

	// use `ID1 1QD` for testing
	idealPostcodes.lookupPostcode(postcode, (error, addresses) => {
		if (!Array.isArray(addresses) || addresses.length < 1) {
			callback({
				success: false,
				message: 'Postcode not found',
			});
			return;
		}

		// Cache found results to save allowance.
		_saveAddressLookupCache(postcode, addresses);

		callback({
			success: true,
			addresses,
		});
	});
};

/**
 * Return a cached result if possible.
 *
 * @param  {String} postcode
 */
export const getAddressLookupCache = postcode => {
	if (window && window.hasOwnProperty('UKAddressLookupCache') && window.UKAddressLookupCache[postcode]) {
		return window.UKAddressLookupCache[postcode];
	}
	return false;
};

const _saveAddressLookupCache = (postcode, addresses) => {
	if (window) {
		if (!window.hasOwnProperty('UKAddressLookupCache')) {
			window.UKAddressLookupCache = {};
		}

		window.UKAddressLookupCache[postcode] = addresses;
	}
};
