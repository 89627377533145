import { roundPrice } from './cartHelperFunctions';
import { getCurrentVenue } from './customerHelperFunctions';

/**
 * Return a unit by it's ID.
 */
const getUnitById = (unitId, venues) => {
	// A default return value.
	let targetUnit = null;

	venues.find((venue) =>
		venue.contracts.find((contract) => {
			// Check if the contract has any units.
			if (!contract.units) return false;
			return contract.units.find((unit) => {
				if (unit.id === unitId) {
					targetUnit = unit;
					return true;
				}
				return false;
			});
		})
	);

	return targetUnit;
};

// Get all units for the current venue.
const getUnitsByVenue = (venue) => {
	if (!venue || !venue.contracts || venue.contracts.length < 1) {
		return [];
	}

	// Loop the contracts, collect the units and flatten.
	let allUnits = venue.contracts
		.map((contract) => {
			return contract.units || [];
		})
		.flat();

	// Order by key number.
	allUnits.sort((first, second) => {
		return parseInt(first.keyStartNumber) - parseInt(second.keyStartNumber);
	});

	return allUnits;
};

/**
 * Return the contract number for a unit, using its ID.
 *
 * @param {Object} state
 * @param {Number} unitId
 */
const getUnitContactNumber = (state, unitId) => {
	let currentVenueContracts = getCurrentVenue(state).contracts;
	let contractNumber = 0;

	currentVenueContracts.forEach((contract) => {
		if (!contract.units) return;

		if (contract.units.find((u) => u.id === unitId)) {
			contractNumber = contract.id;
		}
	});

	return contractNumber;
};

// Get unit price for key offset.
const getUnitCustomPrice = (state) => {
	const unitCustomProductId = parseInt(
		process.env.REACT_APP_UNIT_CUSTOM_PRODUCT_ID
	);
	const unitCustomProduct = state.customer.products.find(
		(product) => product.id === unitCustomProductId
	);
	if (
		unitCustomProduct &&
		unitCustomProduct.subscriptionSignUpFee &&
		unitCustomProduct.price
	) {
		return roundPrice(
			parseInt(unitCustomProduct.subscriptionSignUpFee) +
				parseInt(unitCustomProduct.price)
		);
	}
};

export {
	getUnitById,
	getUnitsByVenue,
	getUnitContactNumber,
	getUnitCustomPrice,
};
