import { getApi } from '../../services/api';

// Remove a single item from the cart using its cart key/ID.
const apiRemoveItem = async (api, itemKey) => {
  try {
    const response = await api.delete('/cocart/v1/item', {
      params: {
        cart_item_key: itemKey,
        return_cart: true,
      },
    });

    // success
    return {
      success: true,
      cart: response.data,
    };
  } catch (error) {
    // failure
    // console.log(error.response);
    return {
      success: false,
      message: error.response ? error.response.data.message : error.message,
    };
  }
};

//
const removeItem = (itemKey) => {
  const api = getApi();
  if (!api) {
    return {
      success: false,
      message: 'Api is not config',
    };
  }

  //
  return apiRemoveItem(api, itemKey);
};

//
export default removeItem;
