import React from 'react';

const Logo = () => {
	return (
		<svg
			width="376.35px"
			height="100.6px"
			enableBackground="new 0 0 376.346 100.597"
			version="1.1"
			viewBox="0 0 376.346 100.597"
		>
			<path
				d="m44.567 2.655v2.656h287.21c10.848 1e-3 20.646 4.39 27.758 11.498 7.108 7.112 11.496 16.91 11.498 27.759v11.461c-2e-3 10.849-4.39 20.646-11.498 27.759-7.112 7.108-16.91 11.495-27.758 11.497h-287.21c-10.849-2e-3 -20.646-4.389-27.758-11.497-7.108-7.113-11.497-16.91-11.498-27.759v-11.461c1e-3 -10.849 4.39-20.647 11.498-27.759 7.112-7.108 16.909-11.497 27.758-11.498v-5.311c-12.299-2e-3 -23.457 4.992-31.514 13.053-8.061 8.057-13.055 19.216-13.053 31.515v11.461c-2e-3 12.299 4.992 23.458 13.053 31.514 8.057 8.061 19.215 13.055 31.514 13.054h287.21c12.298 1e-3 23.457-4.993 31.514-13.054 8.062-8.057 13.054-19.215 13.053-31.514v-11.461c1e-3 -12.299-4.991-23.458-13.053-31.515-8.057-8.061-19.216-13.055-31.514-13.053h-287.21v2.655"
				fill="#fff"
			/>
			<path
				d="m72.562 38.965c-0.709-8.322-7.418-12.709-16.386-12.967-15.611-0.452-20.644 12.838-20.644 24.257 0 13.031 5.484 24.256 19.225 24.256 9.096 0 16.967-5.161 17.612-12.967h-6.774c-0.773 4.968-5.29 7.419-10.257 7.419-10.129 0-13.031-9.096-13.031-18.708 0-8.193 2.128-18.709 13.611-18.709 4.774 0 9.548 2.967 9.871 7.419h6.773"
				fill="#fff"
			/>
			<path
				d="m98.376 57.802c0.129 6-1.226 12.257-8.451 12.257-3.613 0-6.386-1.999-6.386-5.999 0-4.709 4.967-6.064 8.709-6.258h6.128zm-5.548-4.257c-7.676 0-15.741 2.644-15.741 11.741 0 6.322 5.677 9.225 11.29 9.225 4.451 0 8.064-1.742 10.064-5.806h0.129c0.064 1.484 0.193 2.903 0.322 4.387 0.064 0.774 1.161 0.452 1.742 0.452h4.128c-0.193-2.71-0.257-5.419-0.257-6.839v-15.483c0-9.032-6.581-11.032-13.226-11.032-6.386 0-11.934 2.516-12.257 9.613h6.451c0.065-3.354 2.968-5.161 6.065-5.161 6.516 0 6.838 3.29 6.838 8.903h-5.548z"
				fill="#fff"
			/>
			<path
				d="m118.44 57.48c0-6.838 1.355-10.967 9.095-11.225 0.84 0 1.807 0 2.646 0.322v-5.806c-0.71 0-1.355-0.064-2.129-0.064-4.451 0-8.58 2.258-9.483 6.709h-0.129v-6.258h-6.129v32.386h6.129v-16.064z"
				fill="#fff"
			/>
			<path
				d="m138.75 57.287c0-6.065 1.806-12.839 9.16-12.839 6.967 0 9.161 6.967 9.161 12.839 0 6.063-1.742 12.967-9.161 12.967-6.903 0-9.16-6.904-9.16-12.967m18.257-11.742h-0.13c-1.87-3.87-5.935-5.355-10.063-5.355-10.064 0-14.516 8.387-14.516 17.16 0 8.323 3.806 17.161 13.419 17.161 4.709 0 8.967-1.612 11.16-6.129h0.13v0.323l0.193 4.839h6.258c-0.129-1.871-0.323-3.807-0.323-5.742v-40.837h-6.128v18.58"
				fill="#fff"
			/>
			<path
				d="m169.71 49.996c0 12.644 19.677 7.226 19.677 14.451 0 4.193-3.677 5.612-7.29 5.612-3.742 0-6.451-2.128-6.58-6.128h-6.452c-0.063 7.483 5.613 10.58 12.387 10.58 6.645 0 14.387-2.452 14.387-10.386 0-7.291-6.322-7.936-11.806-9.742-2.323-0.709-7.871-0.968-7.871-4.58 0-3.613 2.646-5.161 5.935-5.161 3.548 0 6.71 1.354 6.451 5.483h6.452c0.451-7.354-6.452-9.935-12.58-9.935-5.871 0-12.71 3.033-12.71 9.806z"
				fill="#fff"
			/>
			<path
				d="m201.53 59.931c-0.258 10.451 6.838 14.58 16.579 14.58 9.42 0 18.902-3.742 18.902-14.322 0-11.741-11.677-11.741-20.257-14.515-4.064-1.29-7.806-1.613-7.806-6.903 0-5.547 6.257-7.225 10.838-7.225 4.645 0 9.031 1.806 9.29 7.031h6.773c0-9.031-7.806-12.579-15.74-12.579-8.71 0-17.936 3.741-17.936 13.87 0 10.451 12.774 12.192 20.45 13.87 3.871 0.774 7.613 2.645 7.613 7.161 0 6.515-6.451 8.064-11.741 8.064-6.258 0-10.064-2.451-10.193-9.032h-6.772"
				fill="#fff"
			/>
			<path
				d="m264.37 57.802c0.129 6-1.226 12.257-8.452 12.257-3.613 0-6.386-1.999-6.386-5.999 0-4.709 4.968-6.064 8.709-6.258h6.129zm-5.549-4.257c-7.677 0-15.74 2.644-15.74 11.741 0 6.322 5.677 9.225 11.289 9.225 4.451 0 8.063-1.742 10.063-5.806h0.13c0.063 1.484 0.192 2.903 0.322 4.387 0.064 0.774 1.161 0.452 1.742 0.452h4.128c-0.193-2.71-0.257-5.419-0.257-6.839v-15.483c0-9.032-6.581-11.032-13.226-11.032-6.387 0-11.936 2.516-12.258 9.613h6.451c0.065-3.354 2.968-5.161 6.064-5.161 6.516 0 6.839 3.29 6.839 8.903h-5.547z"
				fill="#fff"
			/>
			<path
				d="m287.72 73.544v-28.128h7.484v-4.258h-7.484v-5.161c-0.192-3.548 1.808-4.451 5.29-4.451 1.033 0 2.065 0.129 3.098 0.194v-5.097c-1.484-0.258-2.903-0.645-4.388-0.645-4.58 0-9.935 1.871-10.129 8.967v6.193h-6.32v4.258h6.32v28.128h6.129"
				fill="#fff"
			/>
			<path
				d="m326.99 58.512v-3.032c0-8.967-4.58-15.096-14.257-15.29-10.259 0-15.032 7.936-15.032 17.419 0 9.548 4.129 16.902 14.516 16.902 7.741 0 13.419-3.677 14.387-11.031h-6.451c-0.71 4.709-3.098 6.579-7.871 6.579-6.257 0-8.579-5.87-8.128-11.547h22.836zm-22.837-4.258c0-5.29 3.032-9.612 8.128-9.612 5.935 0 8.387 4.193 8.258 9.612h-16.386z"
				fill="#fff"
			/>
			<path
				d="m347 26.016h-2.851l-3.36 7.984-3.253-7.984h-2.877v11.318h1.774v-9.706h0.055l3.763 9.706h1.049l3.871-9.706h0.055v9.706h1.774v-11.318m-14.357 0h-9.167v1.612h3.655v9.706h1.882v-9.706h3.63v-1.612"
				fill="#fff"
			/>
		</svg>
	);
};

export default Logo;
