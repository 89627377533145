import { getApi } from '../../services/api';
import pretendRestApi from '../../utils/pretendRestApi';

//
const fixtureSearchCustomers = () => {
	// return require(`../../fixtures/user/searchCustomers.json`);
	return pretendRestApi(() =>
		require(`../../fixtures/user/searchCustomers.json`)
	);
};

//
const apiSearchCustomers = async (api, term) => {
	try {
		const response = await api.get('/cardssafe-account/v1/findCustomers', {
			params: {
				search: term,
			},
		});

		// success
		const { data } = response.data;

		const customers = data.map((customer) => {
			const { id, email, first_name, last_name, venues } = customer;

			// Extract a list of venue names.
			let venueData = [];
			venues.forEach((venue) => {
				venueData.push(venue.post_title);
			});

			return {
				id,
				first_name,
				last_name,
				email,
				venues: venueData,
			};
		});

		return {
			customers,
		};
	} catch (error) {
		return {
			success: false,
			customers: [],
			message: error.response ? error.response.data.message : error.message,
		};
	}
};

//
const searchCustomers = (term) => {
	const api = getApi();
	// api is null - use fixture
	if (!api) {
		return fixtureSearchCustomers();
	}

	// restApi search customers
	return apiSearchCustomers(api, term);
};

//
export default searchCustomers;
