import { put, call, takeLatest } from 'redux-saga/effects';

// Pubsub library and config for emitting events to other parts of the application.
import pubsub from 'pubsub-js';
import pubsubEvents from '../../../config/pubsubEvents';

// API functions.
import {
  addVenue as apiAddVenue,
  updateVenueAddresses as apiUpdateVenueAddresses,
  updateVenue as apiUpdateVenue,
} from '../../../api/venue';

// Redux.
import {
  customerActionCreators,
  customerActionTypes,
} from '../../actions/customerActions';
import { globalActionCreators } from '../../actions/globalActions';

// Routes.
import routes from '../../../config/routesConfig';

/**
 * Saga to send API request for adding new venue
 * @param venue object
 */
function* addVenue({ venue }) {
  try {
    yield put(
      globalActionCreators.startFetching(
        `Adding ${venue.name} to your account...`
      )
    );

    // Make request for user profile.
    const response = yield call(apiAddVenue, venue);

    // console.log(response);
    const { success } = response;

    // error
    if (!success) {
      const { message } = response;
      throw new Error(message);
    }

    const { venue: responseVenue } = response;

    // Success.
    yield put(customerActionCreators.addVenueSuccess(responseVenue));

    // Publish pubsub add venue success event. There is corresponding code in
    // AddUnit.js which will add the required unit(s) to the cart when this
    // event is emmited.
    pubsub.publish(pubsubEvents.ADD_VENUE_SUCCESS, responseVenue);
  } catch (error) {
    yield put(customerActionCreators.addVenueFailure(error));
    // console.log(error);
  } finally {
    yield put(globalActionCreators.endFetching());
  }
}

/**
 * Called when updating a venue from the Venue/Venue.js component.
 */
function* updateVenue({ venue }) {
  try {
    yield put(globalActionCreators.startFetching());

    // Make request to update the venue.
    const response = yield call(apiUpdateVenue, venue);

    const { success } = response;

    // error
    if (!success) {
      const { message } = response;
      throw new Error(message);
    }

    const { venue: responseVenue } = response;

    yield put(
      globalActionCreators.addSuccessNotice(
        'Your new settings have been saved.'
      )
    );
    yield put(customerActionCreators.updateVenueSuccess(responseVenue));
  } catch (error) {
    // console.log(error);

    yield put(globalActionCreators.addErrorNotice(error.message));
  } finally {
    yield put(globalActionCreators.endFetching());
  }
}

// Saga to handle the address being updated.
function* updateVenueAddresses({
  venueId,
  billingAddress,
  shippingAddress,
  redirectToCheckoutPaymentOnSuccess,
}) {
  try {
    yield put(globalActionCreators.startFetching());

    // Make request for user profile.
    const response = yield call(apiUpdateVenueAddresses, {
      venueId,
      billingAddress,
      shippingAddress,
    });

    // console.log(response);
    const { success } = response;

    // error
    if (!success) {
      const { message } = response;
      throw new Error(message);
    }

    // success
    yield put(
      customerActionCreators.updateVenueAddressesSuccess(
        venueId,
        billingAddress,
        shippingAddress
      )
    );

    //
    if (redirectToCheckoutPaymentOnSuccess) {
      window.router.push(routes.CHECKOUT_PAYMENT);
    }
  } catch (error) {
    yield put(customerActionCreators.updateVenueAddressesFailure(error));
    // console.log(error);
  } finally {
    yield put(globalActionCreators.endFetching());
  }
}

export default function* watchVenue() {
  yield takeLatest(customerActionTypes.ADD_VENUE_REQUEST, addVenue);
  yield takeLatest(customerActionTypes.UPDATE_VENUE_REQUEST, updateVenue);
  yield takeLatest(
    customerActionTypes.UPDATE_VENUE_ADDRESSES_REQUEST,
    updateVenueAddresses
  );
}
