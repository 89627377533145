import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

// Routes
import routes from './config/routesConfig';

// Redux setup.
import { Provider } from 'react-redux';
import configureStore from './redux/store';

// Load all views.
import * as views from './views/';
import AppPage from './AppPage';
import GlobalModals from './components/GlobalModals';

const { store, persistor } = configureStore();

const App = () => {
	return (
		<Provider store={store}>
			<PersistGate
				loading={<div className="loader">Loading...</div>}
				persistor={persistor}
			>
				<div className="app">
					{/* Setup routing, */}
					<Router basename={process.env.REACT_APP_SITE_BASE_URL}>
						<Switch>
							<Route path={routes.LOGIN} component={views.Login} exact />
							<Route path={routes.REGISTER} component={views.Register} exact />
							<Route
								path={routes.FORGOT_PASSWORD}
								component={views.PasswordReset}
								exact
							/>
							<Route path="/" component={AppPage} />
						</Switch>
					</Router>
					{/* Modal windows */}
					<GlobalModals />
				</div>
			</PersistGate>
		</Provider>
	);
};

export default App;
