import React from 'react';

//
import './InputNumber.scss';

/**
 * Number input with plus and minus buttons.
 */
const InputNumber = props => {
	const {
		labelText,
		forAttribute,
		name,
		value: defaultValue,
		onChange,
		errorMessage,
		className = 'form-control',
		min = 1,
		max = 99,
	} = props;

	//
	const [value, updateValue] = React.useState(defaultValue);

	// decrease
	const decreaseNumber = () => {
		const newValue = Math.max(min, value - 1);
		updateValue(newValue);
		onChange(newValue);
	};

	// increase
	const increaseNumber = () => {
		const newValue = Math.min(max, value + 1);
		updateValue(newValue);
		onChange(newValue);
	};

	//
	const handleOnChange = e => {
		const newValue = parseInt(e.target.value);
		updateValue(newValue);
		onChange(newValue);
	};

	//
	return (
		<div className="input-number">
			<label htmlFor={forAttribute}>{labelText}</label>
			<span className="input-number__control">
				<button
					className="input-number__minus"
					type="button"
					onClick={decreaseNumber}
				>
					-
				</button>
				<input
					id={forAttribute}
					name={name}
					type={'number'}
					value={value}
					min={min}
					max={max}
					onChange={handleOnChange}
					className={className}
				/>
				<button
					className="input-number__plus"
					type="button"
					onClick={increaseNumber}
				>
					+
				</button>
			</span>
			{errorMessage && <span className="inline-error">{errorMessage}</span>}
		</div>
	);
};

export default InputNumber;
