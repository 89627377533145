import React from 'react';
import { NavLink } from 'react-router-dom';

// routes
import routes from '../../../config/routesConfig';

// Get menu context
import { MenuContext } from '../../../hooks/menuToggleContext';

/**
 * The primary menu for admin users.
 *
 * @param {Object} props
 */
const AdminMenuItems = (props) => {
  const { isCustomerDataLoaded, currentVenueId, openCommentModal } = props;

  // open global comment modal
  const handleOpenGlobalCommentModal = (e) => {
    e.preventDefault();
    openCommentModal(`All comments`, 0);
  };

  // Get menu context
  const { active, setActive } = React.useContext(MenuContext);
  const handleItemClick = (e) => {
    if (active) {
      setActive(false);
    }
  };

  //
  return (
    <>
      {/* customer loaded menu items */}
      {isCustomerDataLoaded && (
        <>
          <NavLink
            className="icon-home"
            exact
            to={routes.DASHBOARD}
            onClick={handleItemClick}
          >
            Summary & Parts
          </NavLink>
          <NavLink
            className="icon-person-stalker"
            to={routes.PERSONAL}
            onClick={handleItemClick}
          >
            Edit user
          </NavLink>
          {currentVenueId && (
            <NavLink
              className="icon-briefcase"
              to={routes.getEditVenueUrl(currentVenueId)}
              onClick={handleItemClick}
            >
              Edit venue
            </NavLink>
          )}
          <NavLink
            className="icon-plus-round"
            to={routes.ADD_UNIT}
            onClick={handleItemClick}
          >
            Add Unit
          </NavLink>
          <NavLink
            className="icon-history"
            to={routes.ORDER_HISTORY}
            onClick={handleItemClick}
          >
            Order history
          </NavLink>

          <button
            className="icon-comments-o"
            href="#"
            onClick={handleOpenGlobalCommentModal}
          >
            Comments
          </button>
        </>
      )}

      {/* switch user menu item */}
      <NavLink className="icon-arrow-swap" to={routes.ADMIN}>
        Switch customer
      </NavLink>
    </>
  );
};

//
export default AdminMenuItems;
