import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Style.
import './SubNavigationBar.scss';

/**
 * Reusable sub navigation. Designed to be used with Contract and Venue navigation.
 */
const SubNavigationBar = ({
  active,
  menuItems = [],
  menuLabel,
  baseUrl,
  baseKey,
}) => {
  return (
    <nav className="sub-navigation-bar">
      <span className="sub-navigation-bar__header">{menuLabel}</span>
      {menuItems.map((menuItem, index) => {
        let className = 'sub-navigation-bar__item';

        if (active && menuItem.id === active) className += ' active';

        // Specific class for empty contract numbers.
        if (menuItem.hasOwnProperty('units') && menuItem.units.length === 0) {
          className += ' warning';
        }

        return (
          <Link
            className={className}
            key={baseKey + menuItem.id}
            to={baseUrl + '/' + menuItem.id}
          >
            {/* Fallback to the ID if a label is not provided */}
            {menuItem.label ? menuItem.label : menuItem.id}
          </Link>
        );
      })}
    </nav>
  );
};

//
SubNavigationBar.propTypes = {
  active: PropTypes.any,
  menuItems: PropTypes.array.isRequired,
  menuLabel: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  baseKey: PropTypes.any.isRequired,
};

//
export default SubNavigationBar;
