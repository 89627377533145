import { getApi } from '../../services/api';

//
async function apiClearCart(api) {
  try {
    await api.post('/cocart/v1/clear');

    // console.log(response);

    // success
    return {
      success: true,
    };
  } catch (error) {
    // failure
    // console.log(error.response);
    return {
      success: false,
      message: error.response ? error.response.data.message : error.message,
    };
  }
}

function clearCart() {
  const api = getApi();
  if (!api) {
    return {
      success: false,
      message: 'Api is not config',
    };
  }

  //
  return apiClearCart(api);
}

//
export default clearCart;
