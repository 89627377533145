/**
 * The global config
 *
 * NOTE: please put local variables to the .env file
 */

export const CREATE_POST_URL = `${process.env.REACT_APP_BASE_URL}/wp/v2/posts`;

// use fixture or rest api
export const useFixtureApi = false;

// Set the global minimum password strength;
export const minimumPasswordStrength = 66;

/**
 * @see https://momentjs.com/docs/#/displaying/
 */
export const dateFormat = 'DD/MM/YYYY';
export const dateTimeFormat = 'DD/MM/YYYY hh:mm a';

// notice types
export const noticeTypes = {
	error: 'error',
	success: 'success',
};
