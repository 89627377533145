import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import routes from '../../config/routesConfig';

// redux
import { customerActionCreators } from '../../redux/actions/customerActions';

//
import Tooltip from '../../components/common/Tooltip';
import ComfirmModal from '../../components/common/ConfirmModal';

// helpers
import { timestampToDate } from '../../utils/dateHelperFunctions';

// Style.
import './ContractDetails.scss';
import { globalActionCreators } from '../../redux/actions/globalActions';
import { getContract as apiGetContract } from '../../api/user';
import Button from '../../components/common/Button';

//
const ContractDetails = (props) => {
	const {
		id: contractId,
		startDate,
		endDate,
		nextPaymentDate,
		recurringTotal,
		units = [], // In case a contract is created and an order is abandoned, there will be no units so this is a fallback.
		cancelContract,
		status = 'Unknown',
		// contractPdf,
		startFetching,
		endFetching,
		addErrorNotice,
	} = props;

	const [isConfirmBoxActive, setIsConfirmBoxActive] = React.useState(false);

	// Open the confirm dialogue box.
	const onConfirm = () => {
		// close the confirm box
		setIsConfirmBoxActive(false);

		// cancel contract
		cancelContract(contractId);
	};

	/**
	 * Request a download URL from the server.
	 * @returns void
	 */
	const downloadContract = async () => {
		startFetching('Creating PDF, please wait...');
		const contract = await apiGetContract(contractId);
		if (contract.success && contract.data) {
			window.open(decodeURI(contract.data), '_blank');
		} else {
			addErrorNotice(contract.message || 'Could not create contract PDF.');
		}
		endFetching();
	};

	return (
		<div className="row">
			<div className="contract-details">
				<div className="row">
					{/* Allow the user to exit to the Dashboard*/}
					<Link to={routes.DASHBOARD} className="contract-details__back">
						&larr; Back
					</Link>
					<h3 className="contract-details__heading">Contract #{contractId}</h3>
					<div className="col-12 contract-details__data">
						<div className="row">
							<div className="col">
								<p className="h3">About this contract</p>
							</div>
							<div className="col text-lg-right">
								{nextPaymentDate && (
									<button
										type="button"
										className="btn btn-secondary"
										onClick={() => setIsConfirmBoxActive(true)}
									>
										Request cancellation
									</button>
								)}
							</div>
						</div>

						<dl className="row contract-details__data__list">
							<dt>Start date</dt>
							<dd>{timestampToDate(startDate)}</dd>
							<dt>Anniversary date</dt>
							<dd>{timestampToDate(endDate)}</dd>
							<dt>Next payment date</dt>
							<dd>
								{!nextPaymentDate && 'Not applicable'}
								{nextPaymentDate &&
									`£${recurringTotal} (inc' VAT) on ${timestampToDate(
										nextPaymentDate
									)}`}
							</dd>
							<dt>Status</dt>
							<dd className="text-capitalize">{status}</dd>
							<dt>Units in this contract</dt>
							<dd>
								{units &&
									units.length > 0 &&
									units.map((unit) => (
										<Tooltip
											key={`unit-${unit.id}`}
											icon="icon"
											text={`Keys ${unit.keyStartNumber} - ${
												parseInt(unit.keyStartNumber) + 9
											}`}
										>
											#{unit.id}
										</Tooltip>
									))}
								{units.length === 0 && (
									<span>There are no active units for this contract.</span>
								)}
							</dd>

							<dt>Download contract</dt>
							<dd className="contract-details__download-button">
								<Button
									label={`Download`}
									className="btn btn-small btn-secondary"
									onClick={(e) => {
										e.preventDefault();
										downloadContract();
									}}
								/>
							</dd>
						</dl>
					</div>
				</div>

				{isConfirmBoxActive && (
					<ComfirmModal
						heading="Warning!"
						content={(props) => (
							<div className="content-wrap">
								<div className="icon icon-exclamation-triangle"></div>
								<div className="divider"></div>
								<h2 className="title">
									Are you sure you want to cancel your contract?
								</h2>
								<span className="description">
									The minimum contract term will still apply even if you request
									a cancellaction. You will need to return your CardsSafe unit
									to us. Please call us on 0845 500 1040 for details.
								</span>
							</div>
						)}
						okText="I want to cancel"
						onConfirm={onConfirm}
						onCancel={() => setIsConfirmBoxActive(false)}
					/>
				)}
			</div>
		</div>
	);
};

//
const mapStateToProps = (state) => {
	const { contractError } = state.customer;
	return {
		error: contractError,
	};
};

//
const mapDispatchToProps = {
	cancelContract: customerActionCreators.cancelContractRequest,
	endFetching: globalActionCreators.endFetching,
	startFetching: globalActionCreators.startFetching,
	addErrorNotice: globalActionCreators.addErrorNotice,
};

//
export default connect(mapStateToProps, mapDispatchToProps)(ContractDetails);
