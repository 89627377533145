import React from 'react';
import './MenuToggle.scss';

// Get menu context
import { MenuContext } from '../../hooks/menuToggleContext';

// Toggle component
const MenuToggle = () => {
	const { active, setActive } = React.useContext(MenuContext);

	// Cache the body element
	const toggleMenu = (e) => {
		e.preventDefault();

		setActive(!active);
	};

	return (
		<button className="menu-toggle" onClick={toggleMenu}>
			<span className="bar bar__1"></span>
			<span className="bar bar__2"></span>
			<span className="bar bar__3"></span>
		</button>
	);
};
export default MenuToggle;
