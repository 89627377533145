import { getApi } from '../../services/api';

function getOrder(orderId) {
	return new Promise((resolve, reject) => {
		const api = getApi();
		// api is null - use fixture
		if (!api) {
			reject(new Error('Api is not config'));
			return;
		}

		// call api
		api
			.get(`/wc/v3/orders/${orderId}`)
			.then(response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export default getOrder;
