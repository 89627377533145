import React from 'react';

// Common components.
import Button from '../../components/common/Button';

// Import basic config for labels.
import { unitBoxOptions } from './BoxOptions';

// Style.
import './Summary.scss';

/**
 * Small sidebar summary which shows items that will be added to the cart.
 */
const Summary = (props) => {
  const {
    allVenueUnits,
    selectedItems = [],
    onClickRemove,
    onClickAddToBasket,
    postageCost,
    total,
    isAddingItemsToCart,
  } = props;

  /**
   * Convert a box number into a unit number using the starting number and starting
   * number + 10 as a key range.
   *
   * @return {int}
   */
  const convertBoxNumberToUnitNumber = (boxNumber) => {
    let u = allVenueUnits.filter((item) => {
      // Check this box number is in range.
      return (
        boxNumber >= parseInt(item.keyStartNumber) &&
        boxNumber < parseInt(item.keyStartNumber) + 10
      );
    });

    if (u.length > 0) {
      return u[0].unitNumber;
    }

    return 0;
  };

  /**
   * Return the classname for an icon.
   */
  const getIcon = (type) => {
    if (type === 'key-only') {
      return ' icon-cardssafe-key';
    }

    if (type === 'box-key') {
      return ' icon-cardssafe-box';
    }
  };

  // A single row in the summary table.
  const createSummaryItem = (boxNumber, boxOption, price) => {
    return (
      <div className="summary__row" key={`summaryItem-${boxNumber}`}>
        <span className={'summary__row__icon' + getIcon(boxOption)} />
        <div className="summary__row__description">
          Unit N<sub>o</sub>
          {convertBoxNumberToUnitNumber(boxNumber)} Draw N<sub>o</sub>
          {boxNumber}
          <p className="summary__row__variant">
            {unitBoxOptions.find((option) => option.value === boxOption).label}
            <span className="summary__item-price"> - £{price}</span>
          </p>
        </div>
        <button
          type="button"
          className="ml-sm-auto summary__row__remove icon-trash-bin"
          onClick={onClickRemove.bind(this, boxNumber)}
        >
          <span className="sr-only">Remove</span>
        </button>
      </div>
    );
  };

  /* Pricing */

  return (
    <div className="summary">
      <h3 className="summary__heading">Your selection</h3>
      {/* The items */}
      <React.Fragment>
        {selectedItems.length > 0 &&
          selectedItems.map(({ boxNumber, boxOption, price }) => {
            return createSummaryItem(boxNumber, boxOption, price);
          })}
      </React.Fragment>
      {selectedItems.length === 0 && (
        <p className="summary__empty">
          You have no selected parts at the moment. Please click the number(s)
          in the CardsSafe unit to add parts.
        </p>
      )}
      {/* Postage */}
      {selectedItems.length > 0 && (
        <div className="summary__postage">
          <span className="label">Postage & packaging:</span> £{postageCost}
        </div>
      )}

      {/* Total price */}
      <div className="summary__total">
        <span className="label">Total:</span> £{total}
      </div>
      <div className="summary__add-to-cart">
        <Button
          className="btn btn-primary"
          onClick={onClickAddToBasket}
          label={isAddingItemsToCart ? 'Please wait...' : 'Add to basket'}
          disabled={selectedItems.length === 0}
        />
      </div>
    </div>
  );
};

export default Summary;
