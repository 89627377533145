// action types
export const loginActionTypes = {
	USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST', // Saga
	USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS', // Redux
	USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE', // Redux
	USER_LOGOUT: 'USER_LOGOUT', // Saga
	// token verification
	VERIFY_TOKEN_REQUEST: 'VERIFY_TOKEN_REQUEST',
	// VERIFY_TOKEN_SUCCESS: 'VERIFY_TOKEN_SUCCESS',
	VERIFY_TOKEN_FAILURE: 'VERIFY_TOKEN_FAILURE',
};

// Login action.
export const userLogin = credentials => {
	return {
		type: loginActionTypes.USER_LOGIN_REQUEST,
		credentials,
	};
};

// When a user has been logged in.
const userLoginSuccess = (user, token) => ({
	type: loginActionTypes.USER_LOGIN_SUCCESS,
	user,
	token,
	camelCase: true,
});

// When a user failed to login.
const userLoginFailure = error => ({
	type: loginActionTypes.USER_LOGIN_FAILURE,
	error,
});

// Logout action
export const userLogout = () => ({
	type: loginActionTypes.USER_LOGOUT,
});

// verify token request
const verifyTokenRequest = credentials => ({
	type: loginActionTypes.VERIFY_TOKEN_REQUEST,
	credentials,
});

// verify token failure
const verifyTokenFailure = error => ({
	type: loginActionTypes.VERIFY_TOKEN_FAILURE,
	error,
});

//
export const loginActionCreators = {
	// user login
	userLogin,
	userLoginSuccess,
	userLoginFailure,
	// verify token
	verifyTokenRequest,
	verifyTokenFailure,
	//
	userLogout,
};
