const getRandomItem = items => {
	const min = 0;
	const max = items.length;

	//
	const itemIndex = Math.floor(Math.random() * (max - min)) + min;

	//
	return items[itemIndex];
};

export default getRandomItem;
