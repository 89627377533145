export const commentModalActionTypes = {
	// open/close the comment modal
	OPEN_COMMENT_MODAL: 'OPEN_COMMENT_MODAL',
	CLOSE_COMMENT_MODAL: 'CLOSE_COMMENT_MODAL',

	// add/delete comments
	ADD_COMMENT_REQUEST: 'ADD_COMMENT_REQUEST',
	ADD_COMMENT_FAILURE: 'ADD_COMMENT_FAILURE',
	DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
	DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE',
};

// OPEN_COMMENT_MODAL
const openCommentModal = (heading, unitId) => ({
	type: commentModalActionTypes.OPEN_COMMENT_MODAL,
	heading,
	unitId,
});

// CLOSE_COMMENT_MODAL
const closeCommentModal = () => ({
	type: commentModalActionTypes.CLOSE_COMMENT_MODAL,
});

// ADD_COMMENT_REQUEST
const addCommentRequest = (form) => ({
	type: commentModalActionTypes.ADD_COMMENT_REQUEST,
	form,
});

// ADD_COMMENT_FAILURE
const addCommentFailure = (error) => ({
	type: commentModalActionTypes.ADD_COMMENT_FAILURE,
	error,
});

// DELETE_COMMENT_REQUEST
const deleteCommentRequest = (form) => ({
	type: commentModalActionTypes.DELETE_COMMENT_REQUEST,
	form,
});

// DELETE_COMMENT_FAILURE
const deleteCommentFailure = (error) => ({
	type: commentModalActionTypes.DELETE_COMMENT_FAILURE,
	error,
});

export const commentModalActionCreators = {
	openCommentModal,
	closeCommentModal,
	//
	addCommentRequest,
	addCommentFailure,
	deleteCommentRequest,
	deleteCommentFailure,
};