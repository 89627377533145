import React from 'react';
import { connect } from 'react-redux';

// Style.
import './AppBody.scss';

const AppBody = ({ userIsLoggedIn, children }) => {
	return (
		<div className="app__body container-fluid">
			<div className="row">{children}</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { userIsLoggedIn } = state.login;

	return { userIsLoggedIn };
};

export default connect(mapStateToProps)(AppBody);
