import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

//
const withCustomerDataLoaded = ComposedComponent => {
	const CustomerDataLoaded = props => {
		const { isCustomerDataLoaded, userIsAdmin } = props;

		// Redirect the user if they are no logged in.
		if (userIsAdmin && !isCustomerDataLoaded) {
			return <Redirect to="/admin" />;
		}

		//
		return <ComposedComponent {...props} />;
	};

	//
	const mapStateToProps = state => {
		const { user } = state.login;
		const { isAdmin } = user || {};
		const { customer } = state.customer;

		//
		return {
			userIsAdmin: isAdmin,
			isCustomerDataLoaded: !!customer,
		};
	};

	return connect(mapStateToProps)(CustomerDataLoaded);
};

//
export default withCustomerDataLoaded;
