import React from 'react';
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';

// Config.
import routes from '../../config/routesConfig';

// Common components.
import SubHeadingSection from '../../components/SubHeadingSection';
import Content from '../../containers/Content';

// hoc
import withAuthenticate from '../../components/hoc/withAuthenticate';

// Child components.
import FindCustomer from './FindCustomer';
import CreateUser from './CreateUser';

// Style.
import './Admin.scss';

//
const Admin = props => {
	//
	const { user } = props;

	//
	return (
		<div className="admin">
			{/* Default route, search for customer */}
			<Route
				path={routes.ADMIN}
				exact
				component={() => (
					<React.Fragment>
						<SubHeadingSection heading={`Welcome, ${user.name}`}>
							<Link className="btn btn-primary" to={routes.ADD_CUSTOMER}>
								Add customer
							</Link>
						</SubHeadingSection>
						<Content>
							<FindCustomer />
						</Content>
					</React.Fragment>
				)}
			/>
			{/* Route to add a new customer */}
			<Route
				path={routes.ADD_CUSTOMER}
				exact
				component={() => (
					<React.Fragment>
						<SubHeadingSection heading="Create a new customer">
							<Link className="btn btn-primary" to={routes.ADMIN}>
								Search customers
							</Link>
						</SubHeadingSection>
						<CreateUser />
					</React.Fragment>
				)}
			/>
		</div>
	);
};

//
const mapStateToProps = state => {
	//
	const { user } = state.login;

	//
	return {
		user,
	};
};

//
const mapDispatchToProps = {};

//
export default withAuthenticate(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Admin)
);
