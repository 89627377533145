import { getApi } from '../../services/api';
import getRandomItem from '../../utils/getRandomItem';

//
const fixtureDeleteComment = () => {
	const files = ['deleteCommentFailure', 'deleteCommentSuccess'];
	const responseFile = getRandomItem(files);
	// console.log(responseFile);

	//
	return require(`../../fixtures/comment/${responseFile}.json`);
};

//
const apiDeleteComment = async (api, commentId) => {
	try {
		await api.delete(`/cardssafe-account/v1/comments/${commentId}`);
		// const response = await api.delete(`/cardssafe-account/v1/comments/123`);
		// console.log(response);

		// success
		return {
			success: true,
		};
	} catch (error) {
		return {
			success: false,
			message: error.response ? error.response.data.message : error.message,
		};
	}
};

//
const deleteComment = (commentId) => {
	const api = getApi();
	// api is null - use fixture
	if (!api) {
		return fixtureDeleteComment();
	}

	// restApi delete comment
	return apiDeleteComment(api, commentId);
};

export default deleteComment;
