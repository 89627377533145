import React from 'react';

// Style.
import './RadioButtons.scss';

/**
 * Render one of more radio buttons.
 *
 * @param  {string} options.label
 * @param  {string} options.forAttribute
 * @param  {string} options.name
 * @param  {mixed} options.value
 * @param  {function} options.onChange
 * @param  {string} options.errorMessage
 * @param  {string} options.inlineNotice
 * @param  {array}  options.buttons      Array of options.
 * @param  {boolean} options.required Indicate if the required asterisk styling
 * should be used.
 */
const RadioButtons = (props) => {
  const {
    label, // A description for this set of radio buttons.
    forAttribute,
    name,
    value,
    onChange,
    errorMessage,
    inlineNotice = '',
    buttons = [],
    useIcons = false,
    required = true,
  } = props;

  return (
    <div className="button-group">
      {label && (
        <p className="label">
          {label} {required && <span className="required">*</span>}
        </p>
      )}
      {inlineNotice && <span className="inline-notice">{inlineNotice}</span>}
      {errorMessage && <span className="inline-error">{errorMessage}</span>}
      {buttons.map((button, index) => {
        let buttonValue = button.value;

        // Ignore null value
        if (buttonValue === null || buttonValue === '') {
          return null;
        }

        // Is this radio button active.
        const isChecked = button.value === value;

        return (
          <React.Fragment key={`${forAttribute}_${index}`}>
            <span
              className={'button-group__single' + (useIcons ? ' has-icon' : '')}
            >
              <input
                id={`${forAttribute}-${index}`}
                checked={isChecked}
                type="radio"
                name={name}
                value={button.value}
                onChange={onChange}
              />{' '}
              <label
                htmlFor={`${forAttribute}-${index}`}
                className={`icon-${button.id}`}
              >
                {button.label}
              </label>
            </span>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default RadioButtons;
