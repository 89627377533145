import { take, put, fork, takeLatest, call, cancel } from 'redux-saga/effects';

//
import {
  commentModalActionTypes,
  commentModalActionCreators,
} from '../actions/commentModalActions';
import { customerActionCreators } from '../actions/customerActions';
// import { loginActionTypes } from '../actions/loginActions';
import { globalActionCreators } from '../actions/globalActions';

// config
import { noticeTypes } from '../../config/globalConfig';

// api
import {
  addComment as apiAddComment,
  deleteComment as apiDeleteComment,
} from '../../api/comment';

// add comment
function* addComment({ form }) {
  try {
    const response = yield call(apiAddComment, form);
    // console.log(form, response);
    const { success } = response;

    // failure
    if (!success) {
      const { message } = response;
      throw new Error(message);
    }

    // success
    const { unitId } = form;
    const { comment } = response;

    // dispatch success action
    if (unitId) {
      // for unit comments
      yield put(customerActionCreators.addUnitCommentSuccess(unitId, comment));
    } else {
      // for global comments
      yield put(customerActionCreators.addGlobalCommentSuccess(comment));
    }
  } catch (error) {
    yield put(
      globalActionCreators.addNotice({
        type: noticeTypes.error,
        content: error.message,
      })
    );
    yield put(commentModalActionCreators.addCommentFailure(error));
  }
}

//
function* deleteComment({ form }) {
  try {
    const { unitId, commentId } = form;

    //
    const response = yield call(apiDeleteComment, commentId);

    const { success } = response;

    // failure
    if (!success) {
      const { message } = response;
      throw new Error(message);
    }

    // success
    if (unitId) {
      // for unit comments
      yield put(customerActionCreators.deleteUnitCommentSuccess(commentId));
    } else {
      // for global comments
      yield put(customerActionCreators.deleteGlobalCommentSuccess(commentId));
    }
  } catch (error) {
    yield put(
      globalActionCreators.addNotice({
        type: noticeTypes.error,
        content: error.message,
      })
    );
    yield put(commentModalActionCreators.deleteCommentFailure(error));
  }
}

//
function* watchCommentModalActions() {
  yield takeLatest(commentModalActionTypes.ADD_COMMENT_REQUEST, addComment);
  yield takeLatest(
    commentModalActionTypes.DELETE_COMMENT_REQUEST,
    deleteComment
  );
}

//
export default function* watchCommentModal() {
  while (true) {
    // listen to an open comment modal action
    yield take(commentModalActionTypes.OPEN_COMMENT_MODAL);

    //
    const commentModalActionsTask = yield fork(watchCommentModalActions);

    // listen to close comment modal
    yield take(commentModalActionTypes.CLOSE_COMMENT_MODAL);

    // cancel all comment modal actions
    yield cancel(commentModalActionsTask);
  }
}
