import { getApi } from '../../services/api';

//
const apiUpdateItemQuantity = async (api, itemKey, quantity) => {
  // const { productId } = item;

  //
  try {
    // clear cart - for testing
    // const clearCartResponse = await api.post('/cocart/v1/clear');
    // console.log({ clearCartResponse });

    const response = await api.post('/cocart/v1/item', {
      cart_item_key: itemKey,
      quantity,
      refresh_totals: true,
      return_cart: true,
    });
    // console.log({ response });

    // success
    return {
      success: true,
      cart: response.data,
    };
  } catch (error) {
    // failure
    // console.log(error.response);
    return {
      success: false,
      message: error.response ? error.response.data.message : error.message,
    };
  }
};

//
const updateItemQuantity = (itemKey, quantity) => {
  const api = getApi();
  if (!api) {
    return {
      success: false,
      message: 'Api is not config',
    };
  }

  //
  return apiUpdateItemQuantity(api, itemKey, quantity);
};

//
export default updateItemQuantity;
