import React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../config/routesConfig';
import Proptypes from 'prop-types';

// SVG
import SmallUnitSVG from '../../assets/svg/SmallUnitSVG';

// Style.
import './SmallUnit.scss';

/**
 * Display a small unit, as used on the dashboard.
 */
const SmallUnit = ({
  id: unitId,
  unitIndex,
  comments,
  openCommentModal,
  keyStartNumber,
  contract,
  isAdmin,
  startDate,
}) => {
  // Min days after order before parts can be ordered.
  const MIN_DAYS_TO_ORDER_PARTS = 3;

  const commentCount = comments.length;

  const handleOpenUnitCommentModal = () => {
    openCommentModal(`Unit #${unitIndex + 1}`, unitId);
  };

  // Minimum time must pass after start date before replacement parts can be ordered.
  const showReplacementPartsButton = () => {
    let now = new Date(),
      start = new Date(startDate * 1000); // NOTE: working in milliseconds, not seconds.

    // Add the days.
    start.setDate(start.getDate() + MIN_DAYS_TO_ORDER_PARTS);

    // If the time now is greater than the contract start date + MIN_DAYS_TO_ORDER_PARTS.
    return now > start;
  };

  return (
    <div className="small-unit">
      <div className="small-unit__icon">
        <Link
          to={routes.ORDER_PARTS + '/' + unitId}
          title="Order replacement parts for this unit"
        >
          <SmallUnitSVG number={keyStartNumber} />
        </Link>
      </div>
      <div className="small-unit__data row">
        <div className="col-12">
          <div className="row">
            <p className="col-sm-6 small-unit__data__unit-id">
              Unit No: {unitIndex + 1}
            </p>
            <p className="col-sm-6 text-sm-right small-unit__data__contract">
              Contract: {contract}
            </p>
          </div>
          <p className="small-unit__data__keys">
            Keys {parseInt(keyStartNumber)}-{parseInt(keyStartNumber) + 9}
          </p>
        </div>
        <div className="col-12 align-self-end">
          {isAdmin && (
            <button
              className="small-unit__data__comments"
              onClick={handleOpenUnitCommentModal}
            >
              Comments {commentCount}
            </button>
          )}
          {showReplacementPartsButton() && (
            <Link
              className="small-unit__data__order-parts"
              to={routes.ORDER_PARTS + '/' + unitId}
            >
              Order replacement parts
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

//
SmallUnit.propTypes = {
  openCommentModal: Proptypes.func.isRequired,
};

//
export default SmallUnit;
