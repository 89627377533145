import { getApi } from '../../services/api';
// import pretendRestApi from '../../utils/pretendRestApi';
// import getRandomItem from '../../utils/getRandomItem';

//
const apiUpdatePassword = async (api, payload) => {
	// console.log(payload);
	try {
		await api.post('/cardssafe-account/v1/updatePassword', {
			...payload,
		});

		// success
		return {
			success: true,
			message: 'Your password has been updated. You will be logged in, please wait a moment.',
		};
	} catch (error) {
		return {
			sucess: false,
			message: error.response ? error.response.data.message : error.message,
		};
	}
};

//
const updatePassword = (payload) => {
	const api = getApi();
	// api is null - use fixture
	if (!api) {
		return {
			success: false,
			message: 'API is not config.',
		};
	}

	// api password reset
	return apiUpdatePassword(api, payload);
};

//
export default updatePassword;
