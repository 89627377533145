import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash.get';

import apiGetOrder from '../../../api/order/getOrder';
import apiMarkOrderAsComplete from '../../../api/order/markOrderAsComplete';

import { customerActionCreators } from '../../../redux/actions/customerActions';

// helpers
import { formatDateTime } from '../../../utils/dateHelperFunctions';
import { roundPrice } from '../../../utils/cartHelperFunctions';

// config
import routes from '../../../config/routesConfig';
// import getOrder from '../../../api/order/getOrder';

// Common components.
// import NotFound from '../../../components/NotFound';

//
// import PaymentSuccessful from './PaymentSuccessful';
// import PaymentFailure from './PaymentFailure';
// import PaymentCancelled from './PaymentCancelled';

/**
 * Checkout confirmation
 */
const CheckoutConfirmation = ({
  match,
  isAdmin,
  customer,
  currentVenueId,
  requestCustomerData,
}) => {
  // Bug with window opening too small.
  if (
    document.body.clientWidth < window.outerWidth &&
    document.body.clientWidth < 100
  ) {
    window.location.reload();
  }

  // Try to get the order ID from the query string.
  const orderId = get(match, 'params.order_id');

  // Removed the order items for the initial release.
  const DISPLAY_ORDER_ITEMS = false;

  // Manage state.
  const [order, updateOrder] = useState({});
  const [loading, updateLoadingStatus] = useState(false);
  const [hasError, updateHasError] = useState(false);

  // Get order on load. This effect only run on the first render of the component.
  useEffect(() => {
    // Get order detail
    const getOrderDetail = () => {
      // start loading
      updateLoadingStatus(true);

      // api get order detail.
      apiGetOrder(orderId)
        .then((order) => {
          // on success, update order detail
          updateOrder(order);

          // end loading
          updateLoadingStatus(false);

          //
          const orderStatus = get(order, 'status', null);
          // update user data after completing order
          if (orderStatus === 'completed') {
            requestCustomerData(customer.id, currentVenueId, true);
          }
        })
        .catch((error) => {
          updateLoadingStatus(false);
          updateHasError(true);
        });
    };

    getOrderDetail();
  }, [currentVenueId, customer, orderId, requestCustomerData]);

  // fucntion to mark order as complete
  const markOrderAsComplete = () => {
    // start loading
    updateLoadingStatus(true);

    apiMarkOrderAsComplete(orderId).then(({ success, order }) => {
      if (success) {
        updateOrder(order);
      }

      // end loading
      updateLoadingStatus(false);

      // update user data after completing order
      requestCustomerData(customer.id, currentVenueId, true);
    });
  };

  // console.log(order);

  return (
    <div className="row">
      {loading && (
        <div className="col-12 text-center pt-5">
          <h2>Please wait...</h2>
        </div>
      )}

      {hasError && (
        <div className="col-12 text-center pt-5">
          <h2>Error: order not found!</h2>
        </div>
      )}

      {order.id && (
        <div className="order-details">
          <div className="row">
            {/* Allow admin to mark order as complete. */}
            {isAdmin && (
              <div
                className="col-12 text-center"
                style={{ marginTop: 30, marginBottom: 60 }}
              >
                <p className="h3">
                  Have you taken payment on behalf of the customer?
                </p>

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={markOrderAsComplete}
                  disabled={order.status === 'completed'}
                >
                  {order.status === 'completed'
                    ? 'Order is complete'
                    : loading
                    ? 'Please wait...'
                    : 'Mark as complete'}
                </button>
              </div>
            )}
            {/* Allow the user to exit to the Dashboard*/}
            <Link to={routes.DASHBOARD} className="order-details__back">
              &larr; Exit
            </Link>
            <h3 className="order-details__heading">
              Order #{order.id}{' '}
              {order.contract_id && order.contract_id !== 'N/A' && (
                <span className="order-details__heading__contract-number">
                  , Contract #{order.contract_id}
                </span>
              )}
            </h3>
            <div className="col-12 order-details__data">
              <div className="row">
                <div className="col">
                  <p className="h3">About this order</p>
                </div>
              </div>

              <dl className="row order-details__data__list">
                <dt>Date</dt>
                <dd>{formatDateTime(order.date_created)}</dd>
                <dt>Subtotal</dt>
                <dd>
                  £
                  {roundPrice(
                    parseFloat(order.total) -
                      parseFloat(order.total_tax) -
                      parseFloat(order.shipping_total)
                  )}
                </dd>
                <dt>Shipping</dt>
                <dd>£{order.shipping_total}</dd>
                <dt>Tax</dt>
                <dd>£{order.total_tax}</dd>
                <dt>Total</dt>
                <dd>£{order.total}</dd>
                <dt>Payment method</dt>
                <dd>{order.payment_method_title}</dd>
              </dl>

              {DISPLAY_ORDER_ITEMS && (
                <React.Fragment>
                  <div className="row">
                    <div className="col" style={{ paddingTop: 30 }}>
                      <p className="h3">Items in this order</p>
                    </div>
                  </div>

                  <dl className="row order-details__data__list">
                    {order.line_items &&
                      order.line_items.map((item, index) => {
                        return (
                          <React.Fragment key={'line_item-' + index}>
                            <dt>Name</dt>
                            <dd>{item.name}</dd>
                            <dt>Price (inc' tax)</dt>
                            <dd>
                              £
                              {roundPrice(
                                parseFloat(item.total) +
                                  parseFloat(item.total_tax)
                              )}
                            </dd>
                          </React.Fragment>
                        );
                      })}
                  </dl>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const isAdmin = get(state.login, 'user.isAdmin');
  const { customer, currentVenueId } = state.customer;
  return { isAdmin, customer, currentVenueId };
};

const mapDispatchToProps = {
  requestCustomerData: customerActionCreators.customerDataRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutConfirmation);
