import { useState, useCallback, useEffect, useRef } from 'react';
import get from 'lodash.get';

// Validation helper fuctions.
import * as validation from '../utils/validation';

// For debugging issues with prop changes.
// import useTraceUpdate from './useTraceUpdate';

// These address fields are appended with dynamic values such as "billing" to save write full variable name over and over again.
const CORE_ADDRESS_FIELDS = [
  'AddressLine1',
  'City',
  'County',
  'Postcode',
  'Country',
];

/**
 * This custom hook is used to provide form values and connect with validation
 * for views that use form elements.
 *
 * @var {formValues} object The form's input values.
 * @var {formDispatch} function Called when the form is submit/completed.
 * @var {formConfig} object An object containing validation rules for the
 * applicable form.
 */
const useForm = (formValues, formDispatch, formConfig = {}) => {
  // Store a referene to the initial form values. If these values change a comparison
  // will need to be run to determine if the formData values should be updated.
  const initialFormValues = useRef(formValues);

  // State for the form input values.
  const [formData, updateFormData] = useState(formValues);
  // const [formData, updateFormData] = useState({});

  // Track if the form has been interacted with.
  const [formIsPristine, setFormIsPristine] = useState(true);

  // Form error messages which will display inline/under elements.
  const [inlineErrorMessages, setInlineErrorMessages] = useState({});

  // The validation rules.
  const [validationArgs, setValidationArgs] = useState({});

  // Used to track wich routes can be viewed when using multiple step forms.
  const [canAdvanceRoutes, setCanAdvanceRoutes] = useState('');

  useEffect(() => {
    let resetValues = false;

    Object.keys(formValues).forEach((key) => {
      const o = initialFormValues.current[key];
      const n = formValues[key];

      // If the values do not match the formData state will be updated to match the change in initial form props.
      if (o !== n) {
        resetValues = true;
      }
    });

    if (resetValues) {
      updateFormData(formValues);
      // Reset the ref to the latest version.
      initialFormValues.current = formValues;
    }

    // updateFormData(formValues);
  }, [formValues]);

  /**
   * Update the routes which can be accessed by forms with multiple stages.
   */
  const updateCanAdvanceRoutes = useCallback(
    (routes) => {
      // Prevent uneccesary re-renders of components.
      if (routes === canAdvanceRoutes) return;

      setCanAdvanceRoutes(routes);
    },
    [setCanAdvanceRoutes, canAdvanceRoutes]
  );

  /**
   * Update data in the form. This wrapper function allows for future expansion
   * on values before they are commited to state.
   *
   * @param {Object} newData The new data to add to state.
   * @param {Boolean} validateNewData Should validation be run on the new data.
   */
  const setFormData = (newData, validateNewData = false) => {
    updateFormData({ ...formData, ...newData });

    // console.log('newData', newData);

    if (validateNewData) {
      Object.keys(newData).forEach((fieldName) => {
        const fieldValue = newData[fieldName];
        validateFieldAndHandleErrors(fieldName, fieldValue);
      });

      // Check if the address fields have been updated as we may need to remove the
      // custom address error which replaces single address error messages.
      // const addressErrors = handleBulkAddressErrors(inlineErrorMessages);
      // console.log('addressErrors', addressErrors);
    }
  };

  /**
   * Called when an input's value changes. Form inputs are connected using this
   * function. The name attribute of the input is used to get and set values,
   * and also refers to the validation rules which is used on the input so all
   * values MUST match.
   *
   * @returns {void}
   */
  const onInputChange = (e) => {
    const target = e.target;
    const fieldName = target.name;
    let value = target.value;

    // Check if we need to toggle a checkbox value.
    if (target.type === 'checkbox') {
      value = target.checked;
    }

    // Update the form data.
    setFormData({ [fieldName]: value });

    // Pass form name and value to function which handles error messages which display below each input.
    validateFieldAndHandleErrors(fieldName, value);

    // Allow the save button to be clicked. On some forms the submit button remains disabled until this prop changes.
    setFormIsPristine(false);
  };

  /**
   * Remove a specific error message from state, if it exists.
   *
   * @param {String} fieldName The name of a field to delete from inlineErrorMessages state
   */
  const maybeDeleteInlineErrorMessage = (fieldName) => {
    // Update the state using the current state. Note that updates to state may be automatically batched
    // together by React and therefore the last update will "win" if the function call is synchronous. To
    // perform an async update we will need to work with the state argument which is supplied to the second
    // value returned by the useState hook, which in this case is "setInlineErrorMessages".
    setInlineErrorMessages((previousState) => {
      if (previousState.hasOwnProperty(fieldName)) {
        // Extract the other error messages so they can be set again.
        const { [fieldName]: toBeRemoved, ...rest } = previousState;

        // console.log('errors to be retained:', rest);
        // console.log('errors to be removed:', toBeRemoved.message);

        return rest;
      }

      return previousState;
    });
  };

  /**
   * Check if a field is active in the DOM. A field is also checked for being a
   * required piece of data in relation to other inputs in the form.
   */
  // const isActiveField = useCallback(
  //   (field) => {
  //     // ------
  //     // The code below has been commented out and left for reference, it check
  //     // if the input is in the DOM instead of using the formConfig setup.
  //     // The is for possible performance reasons, I suspect it would be better
  //     // to store references to each input so this could use a refactor in the future.

  //     // // Try to get the input via the DOM. There is conditional validation data
  //     // // attached tp certain fields which can be used to check if the input
  //     // // should have a value and is therefore "active" in the current state.
  //     // // const element = document.

  //     // const fieldFromDom = document.getElementsByName(field);

  //     // if (fieldFromDom.length === 0) {
  //     //   console.log('no fields we found for:', field);
  //     //   return false;
  //     // }
  //     // ------

  //     // Element is visible by default.
  //     let isActive = true;

  //     // Extract the custom config for this single field.
  //     const depends = get(formConfig, `${field}.depends`, null);

  //     if (depends) {
  //       isActive = !Object.keys(depends).find((dependField) => {
  //         // Support settings passed as an array.
  //         if (Array.isArray(depends[dependField])) {
  //           console.log(
  //             'array found for depends options',
  //             depends[dependField],
  //             formData[dependField],
  //             depends[dependField].indexOf(formData[dependField]),
  //             depends[dependField].indexOf(formData[dependField]) > -1
  //           );

  //           return depends[dependField].indexOf(formData[dependField]) === -1;
  //         }
  //         return formData[dependField] !== depends[dependField];
  //       });
  //     }

  //     return isActive;
  //   },
  //   [formData, formConfig]
  // );

  /**
   * Check if an input is empty.
   *
   * @param {String} field The name attribute of the input that's being tested.
   *
   * @returns {Boolean} Returns true if empty.
   */
  const fieldIsEmpty = (field) => {
    // Get the value of the input.
    const value = formData[field];

    // If the input is empty or an equivelant value.
    if (value === null || value === undefined || value.length === 0)
      return true;

    return false;
  };

  /**
   * Validate a single field using the settings in the form config file.
   *
   * @param {String} field    The name of the field we're testing against.
   * @param {Mixed}  newValue A value for this input. The onChange handler passes this directly as the useState call to update the formData values will not have completed when this function is called.
   */
  const validateField = (field, newValue = undefined) => {
    // Get the value. This could be passed in, or it could be from state. For
    // onChange events the value must be passed because it's not up to date
    // when this function is called.
    const value = newValue !== undefined ? newValue : formData[field];

    let isValid = true; // Did validation pass.
    let message = ''; // Any error message(s).
    let elementValidationArgs = {}; // Any addtional meta data to pass back from validation.

    // Form config must be setup, otherwise inputs are always valid.
    if (!formConfig[field]) {
      console.error(`Form config missing for ${field}`);
      return {
        isValid,
        message,
        elementValidationArgs,
      };
    }

    // Check if the field is required.
    const isRequired = get(formConfig[field], 'required', null);

    // Get validation rules for this field.
    const rules = get(formConfig[field], 'validationRules', []);

    // Allow config file error messages.
    const requiredMessage = get(formConfig[field], 'requiredMessage', null);

    // Apply the "not empty" validation rule if not other rules are being used.
    if (isRequired && rules.length === 0) rules.push({ name: 'notEmpty' });

    // console.log(`validationRules for ${field}`, rules, requiredMessage);

    // If the input is not required and the input is empty, validation should not run.
    // This stops errors messages being shown to the user for empty input which can occur
    // when the user interacts with an optional input and then removes then deletes the
    // inputted data because they decide to abandon the edits. In this instance the user
    // should be able to procced without having to pass validation for the field in question.
    if (!isRequired && !validation.notEmpty(value).isValid) {
      return {
        isValid,
        message,
        elementValidationArgs,
      };
    }

    // Run validation for each rule.
    rules.forEach((rule) => {
      // Get the name of the validation function and any extra params to pass.
      const { name, condition } = rule;

      // Check that the rule exists before calling it.
      if (!validation[name]) {
        // console.warn(
        //   `validation[${name}] does not exist as a validation method`
        // );
        return;
      }

      // Run the validation.
      let {
        message: _message, // Any error message.
        isValid: _isValid, // Boolean value indicating if validation passed.
        ruleArgs, // Any extra data that was passed back from validation. The password strength validation uses this prop.
      } = validation[name](value, condition);

      elementValidationArgs = {
        ...elementValidationArgs,
        ...ruleArgs,
      };

      if (!_isValid) {
        isValid = false;

        // Overwrite basic error message for a notEmpty validation check. To avoid repeating error messages there must only be one validaton rule to use the message overwrite.
        if (name === 'notEmpty' && requiredMessage && rules.length === 1) {
          _message = requiredMessage;
        }

        // Append message if there is more than one error.
        if (message.length > 0) {
          message += ' ' + _message;
        } else {
          message = _message;
        }
      }
    });

    return {
      isValid,
      message,
      elementValidationArgs,
    };
  };

  /**
   * Call validation and handle the return data. This is a wrapper for validation that also sets the error messages.
   */
  const validateFieldAndHandleErrors = (fieldName, value) => {
    // 1. Call validation.
    const validationCheck = validateField(fieldName, value);

    // 2. Handle the response.
    // Set the current validation arguments for this input. Some inputs require addition data beyond a boolean value and an error message, this data is passed here.
    if (validationCheck.elementValidationArgs) {
      setValidationArgs({
        ...validationArgs,
        [fieldName]: validationCheck.elementValidationArgs,
      });
    }

    // Validation failed, set the error message.
    if (!validationCheck.isValid) {
      setInlineErrorMessages({
        ...inlineErrorMessages,
        [fieldName]: {
          message: validationCheck.message,
        },
      });
    }
    // Validation was successful so the error message may need to removed.
    else {
      maybeDeleteInlineErrorMessage(fieldName);
    }

    // Run validation on any dependent or associated inputs. There could be an existing error for
    // an input which is now no longer required and this should trigger a re-evaluation as a result.
    const connectedFieldsToValidate = Object.keys(formConfig).filter((key) => {
      const conf = formConfig[key];

      // If the depends prop exists and it matches the field that is currently being validated return
      // true.
      return conf.depends && conf.depends[fieldName];
    });

    // Loop over any related fields and run validation.
    connectedFieldsToValidate.forEach((fieldName) => {
      // If validation is required it will be run below, otherwise the error message may be removed.
      if (runConditionalFieldValidation(fieldName, value)) {
        // validateFieldAndHandleErrors(fieldName, formData[fieldName]);
      } else {
        maybeDeleteInlineErrorMessage(fieldName);
      }
    });
  };

  /**
   * If all address fields are empty a single error will be shown to the user instead of one
   * error for every field. This function sets and removes those error messages.
   *
   * @returns {void}
   */
  /*
  NOTE: For the current release this code is not used but may be added in a future release so this is kept
  for reference.
  */
  // const handleBulkAddressErrors = (allErrors = {}) => {
  //   [
  //     {
  //       key: 'venue',
  //       message: 'The venue address is empty.',
  //       fieldName: 'venueAddress',
  //     },
  //     {
  //       key: 'billing',
  //       message: 'The invoice address is empty.',
  //       fieldName: 'billingAddress',
  //     },
  //   ].forEach((bulkField) => {
  //     const { key, message, fieldName } = bulkField;
  //     // Venue address fields.
  //     const allVenueFieldsEmpty = CORE_ADDRESS_FIELDS.map(
  //       (coreField) => key + coreField
  //     ).every(
  //       (fieldName) =>
  //         formData.hasOwnProperty(fieldName) &&
  //         !validation.notEmpty(formData[fieldName]).isValid
  //     );

  //     if (allVenueFieldsEmpty) {
  //       CORE_ADDRESS_FIELDS.forEach((coreField) => {
  //         delete allErrors[key + coreField];
  //       });

  //       // If all fields are empty, add one error to represent them all.
  //       allErrors[fieldName] = {
  //         elementId: null,
  //         placeholder: null,
  //         message,
  //       };
  //     } else {
  //       // If not, delete the representative error as it's no longer required.
  //       delete allErrors['venueAddress'];
  //     }
  //   });

  //   return allErrors;
  // };

  /**
   * Using the formConfig, work out if a dependent field should be run through validation. This usually means
   * that the parent or associated value will be checked for the required value.
   *
   * @param {String} fieldName The name of the field to be tested.
   *
   * @returns {Boolean}
   */
  const runConditionalFieldValidation = (fieldName, value) => {
    const depends = get(formConfig[fieldName], 'depends', null);

    if (!depends) return false;

    const conditionsMetToRunValidation = Object.keys(depends).find(
      (dependField) => {
        // Support settings passed as an array.
        if (Array.isArray(depends[dependField])) {
          return depends[dependField].indexOf(value) > -1;
        } else {
          // console.log(
          //   value,
          //   depends[dependField],
          //   value === depends[dependField]
          // );
          // Check if the current data in the form matches the value that is required by the config.
          return value === depends[dependField];
        }
      }
    );

    // No futher validation required if the input does not meet the conditions.
    if (conditionsMetToRunValidation === undefined) {
      return false;
    }

    return true;
  };

  /**
   * Handle validation using the JSON configuration object which is passed in the args.
   */
  const validateUsingFormConfig = () => {
    // If there's no extra config this doesn't need to run.
    if (!formConfig) {
      // Warning as every form should have a JSON config file for validation rules.
      console.error(
        'No form config was found. Please add a config file for this form.'
      );
      return {};
    }

    // Collect all error messages.
    let allErrors = {};

    // Loop over all form config and apply the rules.
    Object.keys(formConfig).forEach((field) => {
      // Make sure every field in formData has its own config rule, if not an error will display in the console.
      if (!formConfig[field]) {
        console.error(`No form config was found for ${field}`);
        return;
      }

      // 1.
      // Skip inputs which are not required && have no value.
      const isRequired = get(formConfig[field], 'required', null);
      if (!isRequired && fieldIsEmpty(field)) return false;

      // 2.
      // Skip inputs which depends on other input's values that do not meet the required
      // conditions. i.e Don't validate a conditional input if the condition isn't met.
      const depends = get(formConfig[field], 'depends', null);
      if (depends) {
        const conditionsMetToRunValidation = Object.keys(depends).find(
          (dependField) => {
            // Support settings passed as an array.
            if (Array.isArray(depends[dependField])) {
              return depends[dependField].indexOf(formData[dependField]) > -1;
            } else {
              // Check if the current data in the form matches the value that is required by the config.
              return formData[dependField] === depends[dependField];
            }
          }
        );

        // No futher validation required if the input does not meet the conditions.
        if (conditionsMetToRunValidation === undefined) {
          return false;
        }
      }

      // 3.
      // Perform validation on all data in the form. While some inputs may be optional
      // they still need to pass validation if a value is found.
      const validationResult = validateField(field);
      if (!validationResult.isValid) {
        allErrors[field] = { elementId: field, ...validationResult };
      }
    });

    // If all the address fields are empty they will be removed and replaced with a single message.
    // allErrors = handleBulkAddressErrors(allErrors);

    return allErrors;
  };

  /**
   * Check the form is complete and ready for submisson.
   *
   * @param {Array}   filterFields     An optional array of fields which can be passed to base the validity of the form on. This means that there could an error with an input elsewhere in the form, so long as the filtered fields are OK, the test will pass.
   * @param {Boolean} setErrorMessages Should inline error messages be set.
   */
  const isFormValid = (filterFields = [], setErrorMessages = false) => {
    // Gather all fields with errors.
    let allErrors = validateUsingFormConfig();

    // Filter out fields which are not required to pass the test.
    if (filterFields.length > 0) {
      // @todo not working.
      const keysToRemove = Object.keys(allErrors).filter(
        (key) => !filterFields.includes(key)
      );

      keysToRemove.forEach((toDelete) => delete allErrors[toDelete]);
    }

    // Optional argument which will set error messages so they can display in the UI.
    if (Object.keys(allErrors).length > 0) {
      if (setErrorMessages) {
        setInlineErrorMessages(allErrors);
      }
    }

    // If there are any errors, the form is not valid and the error messages will be returned.
    if (Object.keys(allErrors).length > 0) {
      return allErrors;
    }

    // If there are no errors, return bool\true
    return true;
  };

  /**
   * When the user clicks save or submit.
   */
  const onFormSubmit = (e) => {
    e.preventDefault();

    // Final check to see if the form data is valid. The second boolean argument sets the inline
    // errors on the form.
    if (true !== isFormValid([], true)) {
      // Form has errors and cannot be submit.
      return;
    }

    // Dispatch using the action that was passed in. This is typically used to connect with Redux.
    formDispatch(formData);
  };

  /**
   * Helper function which will copy address values from "venue" to "billing".
   *
   * @returns {void}
   */
  const copyVenueAddressToBilling = () => {
    // The fields to copy. Extend the core address fields.
    const fieldMaps = ['AddressLine2', ...CORE_ADDRESS_FIELDS];

    // Copy the values from "venue" to "billing".
    const billingAddress = fieldMaps.reduce(
      (billingAddressData, fieldSuffix) => {
        billingAddressData[`billing${fieldSuffix}`] =
          formData[`venue${fieldSuffix}`];
        return billingAddressData;
      },
      {}
    );

    setFormData({ ...billingAddress }, true);
  };

  // // For debugging.
  // useTraceUpdate(
  //   {
  //     formValues,
  //     formDispatch,
  //     formData,
  //     setFormData,
  //     inlineErrorMessages,
  //     canAdvanceRoutes,
  //     setCanAdvanceRoutes,
  //   },
  //   'useForm'
  // );

  // Values to be used in the component.
  return {
    formData,
    formIsPristine,
    inlineErrorMessages,
    setInlineErrorMessages,
    validationArgs,
    onInputChange,
    onFormSubmit,
    setFormIsPristine,
    setFormData,
    copyVenueAddressToBilling,
    canAdvanceRoutes,
    setCanAdvanceRoutes,
    updateCanAdvanceRoutes,
    isFormValid,
  };
};

export default useForm;
