import { all, fork } from 'redux-saga/effects';

// sagas
import loadCustomerData from './loadCustomerData';
import watchContract from './contract';
import watchProfile from './profile';
import watchVenue from './venue';
import watchUserOrder from './order';

//
export default function* watchCustomer() {
	yield all([
		fork(loadCustomerData),
		fork(watchContract),
		fork(watchProfile),
		fork(watchVenue),
		fork(watchUserOrder),
	]);
}
