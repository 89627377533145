import React, { useState } from 'react';
import { connect } from 'react-redux';

// Common components.
import SubHeadingSection from '../../components/SubHeadingSection';
import MiniCart from '../../components/MiniCart';
import SubNavigationBar from '../../components/SubNavigationBar';

// Child components.
import Summary from './Summary';
import BoxOptions from './BoxOptions';
import LargeUnit from './LargeUnit';

// Redux
import { cartActionsCreator } from '../../redux/actions/cartActions';
import { globalActionCreators } from '../../redux/actions/globalActions';

// Utilities.
import {
	getUnitById,
	getUnitsByVenue,
	getUnitContactNumber,
} from '../../utils/unitHelperFunctions';
import { getCurrentVenue } from '../../utils/customerHelperFunctions';

// Helpers
import { getTotal } from '../../utils/cartHelperFunctions';

// Style.
import './OrderReplacementParts.scss';

/**
 * The user can select a unit, then order any number of replacement keys or keys and boxes
 * which show in a summary section. They are then added to the cart for purchase.
 */
const OrderReplacementParts = (props) => {
	const {
		unit,
		allVenueUnits,
		unitId,
		venueName,
		addReplacementPartsToCart,
		allReplacementKeys,
		// addSuccessNotice,
		addErrorNotice,
		isCartContainsUnitProduct,
		replacementKeyProduct,
		replacementKeyAndBoxProduct,
		isAddingItemsToCart,
		keyOffset,
	} = props;

	// Store which parts have been selected by the user.
	const [selectedItems, updateSelectedItem] = useState([]);

	// Setup relating to pricing.
	// @TODO connect to real product and tax data from WooCommerce.
	const postageCost = 3.99;
	const boxAndKeyPrice = parseFloat(replacementKeyAndBoxProduct.price);
	const replacementKeyPrice = parseFloat(replacementKeyProduct.price);
	const tax = 0.2;

	// Totals, used for the cart/summary total.
	const {
		postageCost: displayPostageCost,
		total,
		// billableKeys
	} = getTotal(
		postageCost,
		boxAndKeyPrice,
		replacementKeyPrice,
		tax,
		selectedItems,
		allReplacementKeys
	);

	// Handle the unit's boxes being selected.
	const toggleLargeUnitBox = (boxNumber) => {
		// Check if item is already in state and remove.
		if (selectedItems.find((item) => item.boxNumber === boxNumber)) {
			updateSelectedItem(
				selectedItems.filter((item) => item.boxNumber !== boxNumber)
			);
			return;
		}

		// New list
		let newSelectedItems = [
			...selectedItems,
			{ boxNumber, boxOption: 'key-only', unitId },
		];

		// Sort the list.
		newSelectedItems = newSelectedItems.sort((box1, box2) =>
			box1.boxNumber > box2.boxNumber ? 1 : -1
		);

		// Add pricing before updating the state.
		newSelectedItems = updatePrices(newSelectedItems);

		// Update the state.
		updateSelectedItem(newSelectedItems);
	};

	// Update box option (box or box+key)
	const updateBoxOption = (boxNumber, boxOption) => {
		let newSelectedItems = selectedItems.map((item) =>
			item.boxNumber === boxNumber ? { ...item, boxOption } : item
		);

		// Add pricing before updating the state.
		newSelectedItems = updatePrices(newSelectedItems);

		// Update the state.
		updateSelectedItem(newSelectedItems);
	};

	// Remove item from the summary.
	const removeItem = (boxNumber) =>
		updateSelectedItem(
			selectedItems.filter((item) => item.boxNumber !== boxNumber)
		);

	// Update prices after certain actions.
	const updatePrices = (items) => {
		// let { billableKeys } = getTotal(
		//   postageCost,
		//   boxAndKeyPrice,
		//   replacementKeyPrice,
		//   tax,
		//   items,
		//   allReplacementKeys
		// );

		let priceAdjustedSelectedItems = items.map((item) => {
			let price = 0;

			// Calculate the correct price.
			switch (item.boxOption) {
				case 'key-only':
					price = replacementKeyPrice;

					break;

				case 'box-key':
					price = boxAndKeyPrice;
					break;

				default:
					break;
			}

			item.price = price;

			return item;
		});

		allReplacementKeys.forEach(({ id, freeReplacementKeysAvailable }) => {
			priceAdjustedSelectedItems
				.filter((item) => item.boxOption === 'key-only' && item.unitId === id)
				.forEach((item, index) => {
					// Set the price to all keys inside the limit to zero.
					if (index < freeReplacementKeysAvailable) {
						item.price = 0;
					}
				});
		});

		return items;
	};

	// Add to basket
	const handleAddToBasket = () => {
		if (selectedItems.length < 1) {
			return;
		}

		// The cart should not allow replacement parts (simple products) and subscription products (new units) in the same order.
		if (isCartContainsUnitProduct) {
			addErrorNotice(
				'Sorry, you cannot order new units and replacement parts in the same order. Please process your current order and create a second order if required.'
			);
			return;
		}

		//
		const parts = selectedItems.map(({ boxNumber, boxOption }) => ({
			// type: 'replacement-part',
			product_id:
				boxOption === 'key-only'
					? replacementKeyProduct.id
					: replacementKeyAndBoxProduct.id,
			cart_item_data: {
				unitId,
				boxNumber,
				boxOption,
				venueName,
			},
		}));

		// Dispatch the add to cart.
		addReplacementPartsToCart(parts);
	};

	// Get the starting number for this unit and the amount of free replacement keys that
	// are still available.
	const { keyStartNumber, freeReplacementKeysAvailable, contract } = unit;

	const getCurrentUnitNumber = () => {
		let id = null;

		allVenueUnits.forEach((unit, index) => {
			if (unit.id === unitId) {
				id = index + 1;
			}
		});

		return id;
	};

	//
	return (
		<div className="order-replacement-parts">
			<SubHeadingSection heading="Order replacement parts">
				<MiniCart />
			</SubHeadingSection>
			<SubNavigationBar
				active={unitId}
				menuItems={allVenueUnits}
				menuLabel="Switch unit:"
				baseUrl="/order-parts"
				baseKey="orderPartsSubMenu"
			/>

			<div className="row order-replacement-parts__select-draws">
				<div className="col-md-3 order-replacement-parts__unit">
					<LargeUnit
						keyStartNumber={keyOffset + (getCurrentUnitNumber() - 1) * 10}
						onClickBox={toggleLargeUnitBox}
						selectedItems={selectedItems}
					/>
				</div>
				<div className="col-md-6">
					<BoxOptions
						unitId={unitId}
						contractNumber={contract}
						unitNumber={getCurrentUnitNumber()}
						keyStartNumber={keyStartNumber}
						selectedItems={selectedItems}
						freeReplacementKeysAvailable={
							parseInt(freeReplacementKeysAvailable) || 0
						}
						onClickBoxOption={updateBoxOption}
					/>
				</div>
				<div className="col-md-3">
					<Summary
						allReplacementKeys={allReplacementKeys}
						allVenueUnits={allVenueUnits}
						selectedItems={selectedItems}
						onClickRemove={removeItem}
						onClickAddToBasket={handleAddToBasket}
						total={total}
						postageCost={displayPostageCost}
						isAddingItemsToCart={isAddingItemsToCart}
					/>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	// cart
	const { items: cartItems, loading: isAddingItemsToCart } = state.cart;

	const unitProductId = parseInt(process.env.REACT_APP_UNIT_PRODUCT_ID);
	const isCartContainsUnitProduct = Object.keys(cartItems).find(
		(cartItemKey) => cartItems[cartItemKey].productId === unitProductId
	);

	// Venues.
	const { venues, products } = state.customer;

	// UnitId. The ID of the current unit.
	const unitId = parseInt(ownProps.match.params.unitId) || null;

	// Extract current unit from venues.
	const unit = getUnitById(unitId, venues);

	// Venue data.
	const venue = venues.find((venue) =>
		venue.contracts.find(
			(contract) =>
				contract.units && contract.units.find((unit) => unit.id === unitId)
		)
	);
	const keyOffset = parseInt(venue.keyOffset) || 1;

	//////////////////////////////
	// Unit sub navigation data //
	//////////////////////////////

	// Extract all units for the current venue.
	const allVenueUnits = getUnitsByVenue(getCurrentVenue(state));

	// Add labels for the unit sub navigation.
	allVenueUnits.forEach((unit, unitIndex) => {
		// Representitive number (e.g Unit 2)
		unit.unitNumber = unitIndex + 1;
		unit.keyStartNumber = keyOffset + unitIndex * 10;

		unit.label =
			'Unit ' +
			(unitIndex + 1) +
			' (' +
			unit.keyStartNumber +
			' - ' +
			(unit.keyStartNumber + 9) +
			')';

		// Add the contract number.
		unit.contract = getUnitContactNumber(state, unit.id);
	});

	///////////////////////////
	// Replacement key logic //
	///////////////////////////

	const allReplacementKeys = allVenueUnits.map((unit) => {
		return {
			id: unit.id,
			freeReplacementKeysAvailable: parseInt(unit.freeReplacementKeysAvailable),
		};
	});

	//////////////////
	// Product data //
	//////////////////

	// A single key.
	const replacementKeyProductId = parseInt(
		process.env.REACT_APP_REPLACEMENT_KEY_PRODUCT_ID
	);
	const replacementKeyProduct = products.find(
		(product) => product.id === replacementKeyProductId
	);

	// A single box + key.
	const replacementKeyAndBoxProductId = parseInt(
		process.env.REACT_APP_REPLACEMENT_KEY_AND_BOX_PRODUCT_ID
	);
	const replacementKeyAndBoxProduct = products.find(
		(product) => product.id === replacementKeyAndBoxProductId
	);

	return {
		unit,
		allVenueUnits,
		allReplacementKeys,
		unitId,
		venueName: venue.name,
		keyOffset,
		isCartContainsUnitProduct,
		replacementKeyProduct,
		replacementKeyAndBoxProduct,
		isAddingItemsToCart,
	};
};

//
const mapDispatchToProps = {
	addReplacementPartsToCart: cartActionsCreator.addReplacementPartsToCart,
	addSuccessNotice: globalActionCreators.addSuccessNotice,
	addErrorNotice: globalActionCreators.addErrorNotice,
};

//
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OrderReplacementParts);
