import { getApi } from '../../services/api';

// Get the cart session from the server/API.
const apiApplyCoupon = async (api, code) => {
  try {
    const response = await api.post(`/cocart/v1/coupon`, {
      coupon: code,
    });

    // console.log(response.data);

    // success
    return {
      // success: response.data.data.status === 200,
      success: response.data.response,
      ...response.data,
    };
  } catch (error) {
    // failure
    // console.log(error.response);
    return {
      success: false,
      message: error.response ? error.response.data.message : error.message,
    };
  }
};

const applyCoupon = (code) => {
  // Get the real API call.
  const api = getApi();
  if (!api) {
    return {
      success: false,
      message: 'Api is not configured.',
    };
  }

  return apiApplyCoupon(api, code);
};

export default applyCoupon;
