import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { customerActionCreators } from '../../redux/actions/customerActions';
import get from 'lodash.get';

// Components.
import WelcomeBoard from '../../components/WelcomeBoard';
import ContactDetails from '../../components/ContactDetails';

// Common components.
import InputText from '../../components/common/InputText';
import InputPassword from '../../components/common/InputPassword';
import Button from '../../components/common/Button';

// Routes
import routes from '../../config/routesConfig';

// Custom hooks.
import useForm from '../../hooks/useForm';

// Style.
// import '../../assets/sass/frontPage.scss';
import './PasswordReset.scss';

const PasswordReset = ({
  fetching,
  userIsLoggedIn,
  makePasswordResetRequest,
  updatePassword,
  location,
}) => {
  const urlParams = new URLSearchParams(get(location, 'search'));
  const key = urlParams.get('key');
  const userLogin = urlParams.get('user_login') || '';

  // Fields for the form.
  const formFields = key
    ? {
        email: userLogin,
        password: '',
        key,
      }
    : { email: '' };

  // Define the submit action - this depends if the user is requesting a password reset email or inputting a new password (second step).
  const formAction = key
    ? // has key => update password
      updatePassword
    : // no key => request password reset email
      makePasswordResetRequest;

  // The form config, depending on which "step" the user is on.
  const formConfig = key
    ? {
        email: {
          required: true,
          requiredMessage: 'Please enter your email address.',
          validationRules: [{ name: 'emailAddress' }],
        },
        password: {
          required: true,
          requiredMessage: 'Please enter a password',
          validationRules: [{ name: 'password' }],
        },
      }
    : {
        email: {
          required: true,
          requiredMessage: 'Please enter your email address.',
          validationRules: [{ name: 'emailAddress' }],
        },
      };

  // Setup the custom hook by passing the data.
  const {
    formData,
    // formIsPristine,
    // formIsComplete,
    inlineErrorMessages,
    onInputChange,
    onFormSubmit,
    validationArgs, // Extra data which is supplied by the userForm hook after validation is run.
  } = useForm(formFields, formAction, formConfig);

  // The user should be redirected to the Dashboard if they are logged in.
  if (userIsLoggedIn) {
    return <Redirect to="/" />;
  }

  const emailDisableProps = key ? { disabled: true } : {};

  return (
    <div className="container-fluid front-page front-page--password-reset">
      <div className="row">
        <WelcomeBoard />
        <div className="col-md-6 ">
          <div className="row h-100 justify-content-center">
            <div className="col-12 align-self-start">
              <ContactDetails />
            </div>
            <div className="col align-self-middle front-page__content">
              <h2 className="front-page__title">Reset your password</h2>
              <p className="description">
                {key
                  ? 'Enter your new password to reset'
                  : 'Enter your email address to receive a password reset email.'}
              </p>

              <form onSubmit={onFormSubmit}>
                <p>
                  <InputText
                    labelText="Your email address"
                    forAttribute="email"
                    name="email"
                    value={formData.email}
                    onChange={onInputChange}
                    errorMessage={
                      inlineErrorMessages.email
                        ? inlineErrorMessages.email.message
                        : ''
                    }
                    {...emailDisableProps}
                  />
                </p>
                {key && (
                  <p>
                    <InputPassword
                      labelText="Password"
                      forAttribute="password"
                      name="password"
                      value={formData.password}
                      onChange={onInputChange}
                      errorMessage={
                        inlineErrorMessages.password
                          ? inlineErrorMessages.password.message
                          : ''
                      }
                      validationArgs={validationArgs.password}
                      type="password"
                    />
                  </p>
                )}
                <Link
                  className="front-page--password-reset__back"
                  to={routes.LOGIN}
                >
                  Go back
                </Link>
                <Button
                  type="submit"
                  disabled={fetching}
                  label={fetching ? 'Please wait...' : 'Submit'}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { fetching } = state.global;
  const { userIsLoggedIn } = state.login;

  return {
    fetching,
    userIsLoggedIn,
  };
};

const mapDispatchToProps = {
  // Action to request new password.
  makePasswordResetRequest: customerActionCreators.passwordResetRequest,
  updatePassword: customerActionCreators.passwordUpdateRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
