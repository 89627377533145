import { getApi } from '../../services/api';
import pretendRestApi from '../../utils/pretendRestApi';
import getRandomItem from '../../utils/getRandomItem';

//
const fixturePasswordReset = () => {
  const files = ['passwordResetSuccess', 'passwordResetFailure'];
  const responseFile = getRandomItem(files);

  let responseData = require(`../../fixtures/user/${responseFile}.json`);

  //
  return pretendRestApi(() => responseData, 1000);
};

//
const apiPasswordReset = async (api, { email }) => {
  try {
    const response = await api.get('/cardssafe-account/v1/lostPassword', {
      params: { user_login: email },
    });

    // success
    return {
      success: true,
      message: response.data.data,
    };
  } catch (error) {
    // console.log('error', error);
    // failure
    return {
      success: false,
      message: error,
    };
  }
};

//
const passwordReset = (payload) => {
  const api = getApi();
  // api is null - use fixture
  if (!api) {
    return fixturePasswordReset();
  }

  // api password reset
  return apiPasswordReset(api, payload);
};

//
export default passwordReset;
