import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Common components.
import InputText from '../../components/common/InputText';
import Button from '../../components/common/Button';

// Components.
import WelcomeBoard from '../../components/WelcomeBoard';
import ContactDetails from '../../components/ContactDetails';

// Routes.
import routes from '../../config/routesConfig';

// Custom hooks.
import useForm from '../../hooks/useForm';

// Import actions.
import { userLogin, userLogout } from '../../redux/actions/loginActions';

// Style.
// import '../../assets/sass/frontPage.scss';
import './Login.scss';

const Login = ({ fetching, userIsLoggedIn, user, userLogin }) => {
  // Setup the custom hook by passing the data.
  const {
    formData,
    // formIsComplete,
    inlineErrorMessages,
    onInputChange,
    onFormSubmit,
  } = useForm(
    {
      email: '',
      password: '',
    },
    // The action that will be called on form submit.
    userLogin,
    // Form validation config.
    {
      email: {
        required: true,
        requiredMessage: 'Please enter your email address',
      },
      password: {
        required: true,
        requiredMessage: 'Please enter your password',
      },
    }
  );

  // The user should be redirected to the Dashboard if they are logged in.
  if (userIsLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className="container-fluid front-page front-page--login">
      <div className="row">
        <WelcomeBoard />
        <div className="col-lg-6 ">
          <div className="row h-100 justify-content-center">
            <div className="col-12 align-self-start">
              <ContactDetails />
            </div>
            <div className="col align-self-middle front-page__content">
              <h2 className="front-page__title">Sign in</h2>
              <p className="description">
                To login, please fill out the form below.
              </p>
              <form
                className="front-page__form"
                onSubmit={onFormSubmit}
                autoComplete="off"
              >
                <p>
                  <InputText
                    labelText="Your e-mail"
                    forAttribute="emailAddress"
                    name="email"
                    value={formData.email}
                    onChange={onInputChange}
                    errorMessage={
                      inlineErrorMessages.email
                        ? inlineErrorMessages.email.message
                        : ''
                    }
                    validationRules={['emailAddress']}
                  />
                </p>
                <p>
                  <InputText
                    labelText="Your password"
                    forAttribute="password"
                    name="password"
                    value={formData.password}
                    onChange={onInputChange}
                    errorMessage={
                      inlineErrorMessages.password
                        ? inlineErrorMessages.password.message
                        : ''
                    }
                    type="password"
                    validationRules={['notEmpty']}
                  />
                </p>
                {/* Password reset link */}
                <Link
                  to={routes.FORGOT_PASSWORD}
                  className="front-page--login__password-reset"
                >
                  Forgot password?
                </Link>
                <Button
                  type="submit"
                  disabled={fetching}
                  label={fetching ? 'Please wait...' : 'Sign In'}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { fetching } = state.global;
  const { userIsLoggedIn, user } = state.login;

  return {
    fetching,
    user,
    userIsLoggedIn,
  };
};

const mapDispatchToProps = {
  userLogin,
  userLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
