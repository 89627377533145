import React from 'react';

// Style
import './ErrorMessageBlock.scss';

/**
 * Display inline error messages, usually below a form element.
 *
 * @param {object} inlineErrorMessage Any error message to display
 */
const ErrorMessageBlock = ({ inlineErrorMessages = {} }) => {
  // const scrollToElement = (e) => {
  //   e.preventDefault();

  //   window.scrollTo({
  //     top: e.target.,
  //     behavior: 'smooth'
  //   });
  // };

  if (Object.keys(inlineErrorMessages).length === 0) {
    return null;
  }

  return (
    <div className="error-message-block" id="error-message-block">
      <p>Before you can proceed you must fix the issues below;</p>
      <ul>
        {Object.keys(inlineErrorMessages).map((key, index) => {
          let { message, placeholder } = inlineErrorMessages[key];
          // return (
          //   <li key={`error-${index}`}>
          //     <a
          //       href={`#${elementId}`}
          //       className="error-message-block__link"
          //     >
          //       <i className="icon icon-thumbs-down"></i> {message}{' '}
          //       {placeholder && `(${placeholder})`}
          //     </a>
          //   </li>
          // );

          return (
            <li key={`error-${index}`}>
              <i className="icon icon-thumbs-down"></i> {message}{' '}
              {placeholder && `(${placeholder})`}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ErrorMessageBlock;
