// Handle user account creation.

import { put, call, takeLatest, delay } from 'redux-saga/effects';

// API
import { passwordReset as apiPasswordReset } from '../../api/user';
import { updatePassword as apiUpdatePassword } from '../../api/user';

// Redux
import {
  customerActionCreators,
  customerActionTypes,
} from '../actions/customerActions';
import { globalActionCreators } from '../actions/globalActions';
import { loginActionCreators } from '../actions/loginActions';

/**
 * Saga to send API request for user profile data.
 * @param contractId string|int
 */
function* passwordResetSaga(payload) {
  try {
    // Loading state.
    yield put(globalActionCreators.startFetching());

    // Make request for user profile.
    const response = yield call(apiPasswordReset, payload);

    const { success, message } = response;

    // Error.
    if (!success) {
      // console.log('Password could not be reset.');
      throw new Error(message);
    }

    // Success.
    yield put(customerActionCreators.passwordResetSuccess(message, payload));
    yield put(globalActionCreators.addSuccessNotice(message));
  } catch (error) {
    // console.log(error);
    // Failure.
    yield put(
      customerActionCreators.passwordResetFailure(
        'Error, could not reset password.'
      )
    );
    yield put(
      globalActionCreators.addErrorNotice('Error, could not reset password.')
    );
  } finally {
    yield put(globalActionCreators.endFetching());
  }
}

function* updatePassword(action) {
  try {
    const { payload } = action;
    // Loading state.
    yield put(globalActionCreators.startFetching());

    // console.log(payload);

    // Make request for user profile.
    const response = yield call(apiUpdatePassword, payload);

    const { success, message } = response;

    // Error.
    if (!success) {
      // console.log('error was thrown');
      throw new Error(message);
    }

    // Success.
    yield put(customerActionCreators.passwordResetSuccess(message, payload));
    yield put(globalActionCreators.addSuccessNotice(message));

    // Redirect the user.
    yield delay(3000);
    // window.router.push(routes.LOGIN);
    // window.location.replace(routes.LOGIN);

    // Login dispatch.
    let { email, password } = payload;
    yield put(loginActionCreators.userLogin({ email, password }));
  } catch (error) {
    // Failure.
    yield put(
      customerActionCreators.passwordResetFailure(
        'Error, could not reset password.'
      )
    );
    yield put(
      globalActionCreators.addErrorNotice('Error, could not reset password.')
    );
  } finally {
    yield put(globalActionCreators.endFetching());
  }
}

//
export default function* passwordReset() {
  yield takeLatest(
    customerActionTypes.PASSWORD_RESET_REQUEST,
    passwordResetSaga
  );
  yield takeLatest(customerActionTypes.PASSWORD_UPDATE_REQUEST, updatePassword);
}
