export const cancelContractRequest = (state, { contractId }) => ({
	...state,
	contractError: null,
});

// When a contract is cancelled we will update the relavant contract in the Redux store.
export const cancelContractSuccess = (state, { contract }) => {
	const contractId = contract.id;

	// Loop over venues.
	const venues = state.venues.map((venue, index) => {
		// Loop over each contract for this venue.
		if (venue.contracts.length > 0) {
			venue.contracts.map((item, index) => {
				// Find the contract we need to update the data for.
				if (item.id === contractId) {
					// Update to the contract that was passed to this reducer.
					//item = contract;
					item.status = contract.status;
				}
				return item;
			});
		}
		return venue;
	});

	return {
		...state,
		venues,
	};

	//
	// const contractOwner = state.venues.reduce((owner, venue) => {
	// 	venue.contracts.find(contract => {
	// 		if (contract.id === contractId) {
	// 			owner = {
	// 				...owner,
	// 				venueId: venue.id,
	// 				contractId,
	// 			};
	// 			return true;
	// 		}
	// 		return false;
	// 	});

	// 	//
	// 	return owner;
	// });

	//
	// const newVenues = state.venues.map(venue => {
	// 	if (venue.id !== contractOwner.venueId) {
	// 		return venue;
	// 	}

	// 	//
	// 	return {
	// 		...venue,
	// 		contracts: venue.contracts.filter(contract => contract.id !== contractId),
	// 	};
	// });

	//
	// return {
	// 	...state,
	// 	venues: newVenues,
	// };
};

//
export const cancelContractFailure = (state, { error }) => ({
	...state,
	contractError: error,
});
