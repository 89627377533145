import React from 'react';
import { NavLink } from 'react-router-dom';

import './StageNavigation.scss';

/**
 * Show a step navigation.
 *
 * @param  {Array}  options.links        The links/URLs
 * @param  {Function} options.onClick:   parentOnClick, Passed from another component.
 * @param  {Array} options.activeRoutes  Used to separate clickable links from "dummy" links.
 */
const StageNavigation = ({
  links = [],
  onClick: parentOnClick,
  activeRoutes = null,
}) => {
  // on click
  const handleOnClick = () => parentOnClick && parentOnClick();

  return (
    <nav className={`row stage-navigation children-${links.length}`}>
      {links.map((link, index) => {
        // activeRoutes is null or the link is active
        if (!activeRoutes || activeRoutes.indexOf(link.to) > -1) {
          return (
            <NavLink
              key={link.to}
              onClick={handleOnClick}
              exact
              className="col"
              to={link.to}
              isActive={(match, location) => {
                return match;
              }}
            >
              {link.label}
            </NavLink>
          );
        }

        // non-active routes
        return (
          <span key={link.to} className="col">
            {link.label}
          </span>
        );
      })}
    </nav>
  );
};

export default StageNavigation;
