import { put, takeLatest, call } from 'redux-saga/effects';

// redux
import { adminActionTypes } from '../actions/adminActions';
import { globalActionCreators } from '../actions/globalActions';
import { customerActionCreators } from '../actions/customerActions';

// api
import { createUser as apiCreateUser } from '../../api/admin';

// config
import { noticeTypes } from '../../config/globalConfig';

//
function* createUser(payload) {
  try {
    yield put(globalActionCreators.startFetching());

    const { formData } = payload;
    // console.log(formData);
    const response = yield call(apiCreateUser, formData);

    // console.log(form, response);
    const { success } = response;

    // failure
    if (!success) {
      const { message } = response;
      throw new Error(message);
    }

    // success - request customer data
    const { customerId } = response;
    yield put(customerActionCreators.customerDataRequest(customerId));
  } catch (error) {
    yield put(
      globalActionCreators.addNotice({
        type: noticeTypes.error,
        content: error.message,
      })
    );
  } finally {
    yield put(globalActionCreators.endFetching());
  }
}

export default function* watchAdminActions() {
  yield takeLatest(adminActionTypes.ADMIN_CREATE_USER_REQUEST, createUser);
}
