import { getApi } from '../../services/api';

/**
 * Request a contract PDF from the api.
 *
 * @param {integer} params $id the contract ID
 * @returns void
 */
const getContract = async (id) => {
	try {
		const api = getApi();

		const response = await api.get(`cs/v1/contract/${id}`);

		if (response.data) {
			return {
				success: true,
				data: response.data,
			};
		} else {
			return {
				success: false,
			};
		}
	} catch (error) {
		return {
			success: false,
			message: error.response ? error.response.data.message : error.message,
		};
	}
};

export default getContract;
