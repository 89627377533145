/*
Each field in the config object should be specified by a key which is the same as the input's name attribute. Each nested object can then contain the following props;

required: Boolean
requiredMessage: String A general purpose error message for this input.
depends:Object A config object which makes the validation of the current input based on another input's value. This is useful when certain inputs are only required on a conditional basis.
validationRules: Array One or more validation methods for an input.
*/

const personalFormConfig = {
	phone: {
		required: true,
		requiredMessage: 'Please enter a valid phone number.',
		validationRules: [{ name: 'phoneNumber' }],
	},
	email: {
		required: true,
		requiredMessage: 'Please enter an email address.',
		validationRules: [
			{
				name: 'emailAddress',
			},
		],
	},
	firstName: {
		required: true,
		requiredMessage: 'Please enter your first name.',
	},
	lastName: {
		required: true,
		requiredMessage: 'Please enter your last name.',
	},
	role: {
		required: true,
		requiredMessage: 'Please tell us your role in the business.',
	},
	password: {
		required: false,
		validationRules: [{ name: 'password' }],
	},
};
export default personalFormConfig;
