import React, { useState } from 'react';
import { connect } from 'react-redux';

//
import { customerActionCreators } from '../../../redux/actions/customerActions';

//
const CheckoutPayment = (props) => {
  const {
    createOrder: dispatchCreateOrder,
    currentVenueId,
    customerId,
    cartItems,
    hasUnitInCart,
    isAdmin,
  } = props;

  // Only allow the user to click on the Pay Now button when they have agreed to terms and conditions.
  const [checkedOptions, setCheckedOptions] = useState(0);
  const [isCreatedOrder, setIsCreatedOrder] = useState(false);

  // The amount of terms which the user must agree to.
  const termsQuantity = () => {
    if (isAdmin) {
      return 0;
    }

    return hasUnitInCart ? 2 : 1;
  };

  const onCheckboxChange = (e) => {
    e.target.checked
      ? setCheckedOptions(checkedOptions + 1)
      : setCheckedOptions(checkedOptions - 1);
  };

  // Send the user to payment gateway.
  const createOrder = (e) => {
    setIsCreatedOrder(true);
    dispatchCreateOrder({
      venueId: currentVenueId,
      customerId,
      cartItems,
      paymentMethod: e.target.value,
    });
  };

  return (
    <div className="checkout__payment">
      <h3 className="checkout__step-heading">Step 2 of 3</h3>
      <p className="checkout-step-insutructions">
        Payments are taken via Worldpay. You will be redirected to a secure
        location where you will be asked for your payment details, so please
        have these to hand.
      </p>
      <div className="row">
        <div className="col-md-6">
          {!isAdmin && (
            <p>
              <input
                type="checkbox"
                value="1"
                name="TermsAndConditions"
                onChange={onCheckboxChange}
              />{' '}
              I have read and agree to the website{' '}
              <a
                className="external-link"
                href="https://cardssafe.com/terms-conditions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions <i className="icon icon-ios-help"></i>
              </a>
            </p>
          )}

          {!isAdmin && hasUnitInCart && (
            <p>
              <input
                type="checkbox"
                value="1"
                name="AgreeToRentalContract"
                onChange={onCheckboxChange}
              />{' '}
              I have read and agree to the{' '}
              <a
                className="external-link"
                href="https://cardssafe.com/rental-terms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Rental Contract <i className="icon icon-ios-help"></i>
              </a>
            </p>
          )}

          {/* This button will take the user off the website to pay with Worldpay */}
          <div className="checkout__payment__method">
            <button
              disabled={checkedOptions !== termsQuantity() || isCreatedOrder}
              onClick={createOrder}
              value="worldpay"
              className="btn btn-secondary"
            >
              Secure payment via Worldpay
            </button>
            <span className="checkout__payment__note">
              (you will be redirected to WorldPay)
            </span>
          </div>
          {isAdmin && (
            <div className="checkout__payment__method">
              <button
                onClick={createOrder}
                className="btn btn-secondary"
                value="cs_woo_pay_with_terminal_gateway"
              >
                Pay with terminal
              </button>
            </div>
          )}
        </div>
        <div className="col-md-6 checkout__payment__gateway-logos">
          {/* <i className="paymentMethod amex paymentMethod-card"></i> */}
          {/* <i className="paymentMethod dinersClub paymentMethod-card"></i> */}
          {/* <i className="paymentMethod jcb paymentMethod-card"></i> */}
          <i className="paymentMethod mastercard paymentMethod-card"></i>
          <i className="paymentMethod visa paymentMethod-card"></i>
        </div>
      </div>
    </div>
  );
};

//
const mapStateToProps = (state) => {
  const {
    currentVenueId,
    customer: { id: customerId },
  } = state.customer;
  const {
    user: { isAdmin },
  } = state.login;
  const { items: cartItems } = state.cart;
  const hasUnitInCart = Object.keys(cartItems).some((key) => {
    if (
      cartItems[key].productId ===
      parseInt(process.env.REACT_APP_UNIT_PRODUCT_ID)
    ) {
      return true;
    }
    if (
      cartItems[key].productId ===
      parseInt(process.env.REACT_APP_UNIT_CUSTOM_PRODUCT_ID)
    ) {
      return true;
    }
    return false;
  });

  return {
    isAdmin,
    currentVenueId,
    customerId,
    cartItems,
    hasUnitInCart,
  };
};

//
const mapDispatchToProps = {
  createOrder: customerActionCreators.createOrderRequest,
};

//
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPayment);
