import { getApi } from '../../services/api';
import addToCart from './addToCart';

// Add multiple items to the cart. Each item is sent to the backend in a single
// request. This is because we want to represent single items in the cart,
// rather than duplicates of the same item.
const apiAddIMultipleItemsToCart = async (api, items) => {
  try {
    // Store responces from the requests.
    const responses = [];

    // Loop over the items to be added to the cart.
    for (let itemId = 0; itemId < items.length; itemId++) {
      const response = await addToCart(items[itemId]);
      responses.push(response);
    }

    //
    // const responses = await Promise.all(addItemsToCartPromises);
    // console.log({ responses });

    const failureResponse = responses.find((response) => !response.success);

    // Failure
    if (failureResponse) {
      throw new Error(failureResponse.message);
    }

    // Success
    return {
      success: true,
    };
  } catch (error) {
    // Failure
    // console.log(error);
    return {
      success: false,
      message: error.response ? error.response.data.message : error.message,
    };
  }
};

const addIMultipleItemsToCart = (items) => {
  const api = getApi();
  if (!api) {
    return {
      success: false,
      message: 'Api is not configured.',
    };
  }

  return apiAddIMultipleItemsToCart(api, items);
};

export default addIMultipleItemsToCart;
