import React from 'react';

// Style.
import './ContactDetails.scss';

/**
 * Phone and email in header.
 *
 */
const ContactDetails = props => {
	return (
		<div className="contact-details">
			<div className="contact-details__inner">
				<a className="icon-call-phone" href="tel:+448455001040">
					0845 500 1040
				</a>
				<a className="icon-mail" href="mailto:sales@cardssafe.com">
					sales@cardssafe.com
				</a>
			</div>
		</div>
	);
};

export default ContactDetails;
